import AddPrescriptionInfoStepsComponent from './AddPrescriptionInfoSteps.component'
import BottomStepNavigator from '../../BottomStepNavigator.component'
import QuotesRefreshProvider from '../../../../context/QuotesRefreshContext'
import React from 'react'
import RouteConstants from '../../../../constants/RouteConstants'
import { useNavigate } from 'react-router-dom'

interface AddPrescriptionStepProps {
  buttonLoading: boolean
  completePrescriptionStep: () => void
  pathContext: string
  handleStepBack: () => void
  forSpouse: boolean
}

const AddPrescriptionStep: React.FC<AddPrescriptionStepProps> = ({
  buttonLoading,
  completePrescriptionStep,
  pathContext,
  handleStepBack,
  forSpouse,
}) => {
  const navigate = useNavigate()
  return (
    <div style={{ width: '100%' }}>
      <QuotesRefreshProvider>
        <AddPrescriptionInfoStepsComponent
          handleNext={completePrescriptionStep}
          pathContext={pathContext}
          isSpouse={forSpouse}
        />
      </QuotesRefreshProvider>
      <BottomStepNavigator
        handleNext={completePrescriptionStep}
        handleBack={handleStepBack}
        handleViewPlans={() => {
          window.scrollTo({ top: 0, behavior: 'smooth' })
          navigate(RouteConstants.DASHBOARD)
        }}
        isLoading={buttonLoading}
        currentInfoStep='Prescriptions'
      />
    </div>
  )
}

export default AddPrescriptionStep
