import React, { useState } from 'react'
import {
  addPrescription,
  getDosageForDrugName,
  getDrugNamesForPrefix,
  getPrescriptions,
  updatePrescription,
} from '../../../service/prescriptions'
import { isEmpty, isUndefined } from 'lodash'

import Alert from '@mui/material/Alert'
import ApiManager from '../../../api/ApiManager'
import BouncingDotsLoader from '../../common/Loading/BouncingDotsLoader'
import { HttpMethods } from '../../../enums/HttpMethodsEnum'
import ModalComponent from '../../common/Modal/Modal.component'
import OverlayLoader from '../../common/OverlayLoader/OverlayLoader'
import PrescriptionDrugCard from './PrescriptionDrugCard.component'
import { PrescriptionDrugFrequencyEnum } from '../../../enums/PrescriptionDrugFrequencyEnum'
import PrescriptionSavedPrompt from '../Modals/PrescriptionSavedPrompt'
import { QuotesRefreshContext } from '../../../context/QuotesRefreshContext'
import SearchBar from '../../common/Search/Search.component'
import SectionRow from '../../common/SectionRow'
import { SnackbarTypes } from '../../../enums/SnackbarTypesEnum'
import StringConstants from '../../../constants/StringConstants'
import UrlConstants from '../../../constants/UrlConstants'
import customerStore from '../../../datastore/CustomerStore'
import { getApiErrorMessage } from '../../../utils/StringUtils'
import { getPlanYear } from '../../../utils/CommonUtils'
import mPartDPlansFilterStore from '../../../datastore/medicareQuotes/MPartPlanFilterQuotesStore'
import medicareAdvantageFilterQuoteStore from '../../../datastore/medicareQuotes/MedicareAdvantageFilterQuoteStore'
import { observer } from 'mobx-react'
import { pollQuotesPullStatus } from '../../../utils/quotesPullStatus'
import prescriptionStore from '../../../datastore/PrescriptionStore'
import snackbarStore from '../../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../../global/theme'

interface DrugCard {
  title: string
  dosage: string[]
  frequencyOptions: string[]
  quantity: number
  id: string
  dosageNamesWithUsage?: { name: string; commonDosage: boolean }[]
}

interface DrugDataProps {
  id: string
  name: string
  currentDosage: {
    id: number
    name: string
    qty: number
    strength: string
    strengthUOM: string
    form: string
    alt: boolean
    packages: []
    proxyNdc: string
    commonDosage: boolean
    frequency: number
  }
  isSpouse: boolean
}

interface SearchPromptProps {
  addPrescriptionState: {
    AddedDrugs: any[]
    PrescriptionDrug: any[]
    addingDrugs: boolean
  }
  setAddPrescriptionState: React.Dispatch<
    React.SetStateAction<{
      AddedDrugs: any[]
      PrescriptionDrug: any[]
      addingDrugs: boolean
    }>
  >
  loading?: boolean
  setLoading?: React.Dispatch<boolean>
  handleNext?: () => void
  forSpouse: boolean
  redirect?: boolean
  addDrugLoading?: boolean
  setAddDrugLoading?: (flag: boolean) => void
  isEditDrug?: boolean
  setIsEditDrug?: (flag: boolean) => void
}

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 25px;
`

const Container = styled.div`
  width: 100%;
  h3 {
    color: ${theme.colors.primary};
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 10px;
  }
`

const DisableText = styled.div`
  font-size: 14px;
  margin-left: 5px;
  color: ${theme.colors.secondary};
`

const SearchBarWrapper = styled.div``

const SearchPrompt: React.FC<SearchPromptProps> = ({
  addPrescriptionState,
  setAddPrescriptionState,
  handleNext,
  forSpouse,
  loading = false,
  setLoading,
  redirect,
  addDrugLoading,
  setAddDrugLoading = () => {},
  setIsEditDrug = () => {},
  isEditDrug = false,
}) => {
  const [searchDrug, setSearchDrug] = React.useState(false)
  const [isSearching, setIsSearching] = React.useState(false)
  const [overlayLoading, setOverlayLoading] = React.useState(false)
  const [genericDrug, setGenericDrug] = React.useState<any>()
  const [loadingFetch, setLoadingFetch] = React.useState<boolean>(false)
  const [state, setState] = React.useState({
    loading: true,
    buttonLoading: false,
    searchClickLoading: false,
    showAlert: false,
    alertMessage: '',
  })

  const [showSuccessfulPopUp, setShowSuccessfulPopUp] = useState<boolean>(false)
  const [updatedDrug, setUpdatedDrug] = useState<{
    name: string
    quantity: number
    frequency: string
  }>({} as { name: string; quantity: number; frequency: string })

  const [existingDrug, setExistingDrug] = useState<any>({})

  const { isQuotesRefreshing, setIsQuotesRefreshing } =
    React.useContext(QuotesRefreshContext)

  const fetchRxDrugNames = (drugPrefix: string) => {
    setLoadingFetch(true)
    setState({
      ...state,
      loading: true,
    })
    getDrugNamesForPrefix({ $drugPrefix: drugPrefix })
      .then((response: any) => {
        const data = response.data.data.map(
          (x: { id: string; name: string }) => {
            return { title: x.name, id: x.id }
          }
        )
        prescriptionStore.setDrugNameList(data)
        setState({
          ...state,
          loading: false,
        })
      })
      .catch((err) => {
        console.log('msg: ', err)
        snackbarStore.set({
          snackbarMessage: getApiErrorMessage('fetch RX drug name'),
          snackbarOpen: true,
          snackbarType: SnackbarTypes.ERROR,
        })
      })
      .finally(() => {
        setLoadingFetch(false)
      })
  }

  const fetchPrescriptionDrugs = () => {
    setState({
      ...state,
      loading: true,
    })
    setOverlayLoading(() => true)

    getPrescriptions({ 'for-spouse': forSpouse })
      .then((response: any) => {
        const data: DrugDataProps[] = response.data.data
        setAddPrescriptionState({
          ...addPrescriptionState,
          AddedDrugs: data,
        })
        setState({
          ...state,
          loading: false,
        })
        if (customerStore.get().forSpouse === false) {
          ApiManager.makeApiCallWithAuthentication(
            UrlConstants.PATCH_INFO_STEPS.USECASE,
            HttpMethods.PATCH_METHOD,
            {
              $infoStep: 'prescription',
            }
          )
            .then((res) => {
              if (res.status === 200) {
              } else throw res
            })
            .catch((err) => {
              snackbarStore.set({
                snackbarMessage: 'Something went wrong',
                snackbarOpen: true,
                snackbarType: 'error',
              })
            })
        }
      })
      .catch((err: any) => {
        console.log('err: ->', err)
        let message = err.data
          ? err.data.message
            ? err.data.message
            : err.data
          : getApiErrorMessage('fetch prescription drug')

        if (/No prescription drugs are available/.test(message)) return
        else {
          snackbarStore.set({
            snackbarMessage: message,
            snackbarOpen: true,
            snackbarType: SnackbarTypes.ERROR,
          })
        }
      })
      .finally(() => {
        setLoading && setLoading(false)
        setOverlayLoading(() => false)
      })
  }

  React.useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
    fetchPrescriptionDrugs()
  }, [])

  const fetchDrugInfo = (
    drugName: string
  ): Promise<{ name: string; commonDosage: boolean }[]> => {
    let data: { name: string; commonDosage: boolean }[] = []
    const encodedDrugName = encodeURIComponent(drugName)
    return new Promise((resolve, reject) => {
      if (!isEmpty(drugName)) {
        setOverlayLoading(() => true)
        getDosageForDrugName({ $drugName: drugName })
          .then((response: any) => {
            if (response.status === 200) {
              const drugDetails = response.data.data.drugs
              const dosageDetails = response.data.data.drugDosage
              data = dosageDetails.dosages.map(
                (x: { id: string; name: string; commonDosage: boolean }) => {
                  return {
                    name: x.name,
                    commonDosage: x.commonDosage,
                  }
                }
              )

              prescriptionStore.setCustomerDosage(dosageDetails.dosages || [])

              if (drugDetails.length > 1) {
                setGenericDrug({
                  genericDrugDosage: dosageDetails.dosages.filter(
                    (item: any) => item.alt === true
                  ),
                  drugName: drugDetails.filter(
                    (item: any) => item.drugType === 'GENERIC'
                  )[0].name,
                })
              }

              // Commented by RB
              // snackbarStore.set({
              //   snackbarMessage:
              //     StringConstants.SNACKBAR_DATA_FETCH_SUCCESS_MSG,
              //   snackbarOpen: true,
              //   snackbarType: SnackbarTypes.SUCCESS,
              // })
              setState({
                ...state,
                showAlert: false,
                alertMessage: '',
              })
              resolve(data)
            } else throw response
          })
          .catch((err) => {
            setState({
              ...state,
              showAlert: true,
              alertMessage: `${drugName} cannot be added. Please try a different drug.`,
            })
            reject(err)
          })
          .finally(() => setOverlayLoading(() => false))
      }
    })
  }

  const fetchSearchResults = (searchWord: any) => {
    setGenericDrug({})
    fetchRxDrugNames(searchWord)
  }

  const handleSearchClick = (item: Object) => {
    if (!isSearching) {
      setOverlayLoading(() => true)
      setIsSearching(true)
      const itemCast: DrugCard = item as DrugCard
      itemCast.frequencyOptions = Object.values(PrescriptionDrugFrequencyEnum)
      itemCast.quantity = prescriptionStore.quantity

      fetchDrugInfo(itemCast.id)
        .then((dosage: { name: string; commonDosage: boolean }[]) => {
          itemCast.dosage = dosage.map((obj) => obj.name)
          itemCast.dosageNamesWithUsage = dosage
          setAddPrescriptionState({
            ...addPrescriptionState,
            addingDrugs: true,
            PrescriptionDrug: [{ ...itemCast, isSpouse: forSpouse }],
          })
          setSearchDrug(false)
        })
        .catch((err) => {
          itemCast.dosage = []
          setSearchDrug(true)
        })
        .finally(() => {
          setIsSearching(false)
          setOverlayLoading(() => false)
        })
    }
  }

  const addPrescriptionDrug = (
    prescriptionDrug: DrugDataProps
  ): Promise<DrugDataProps> => {
    return new Promise((resolve, reject) => {
      setOverlayLoading(() => true)
      addPrescription(prescriptionDrug)
        .then((response: any) => {
          setUpdatedDrug({
            name: prescriptionDrug.currentDosage.name,
            quantity: prescriptionDrug.currentDosage.qty,
            frequency:
              prescriptionDrug.currentDosage.frequency.toString() ===
              PrescriptionDrugFrequencyEnum.EveryMonth
                ? 'month'
                : prescriptionDrug.currentDosage.frequency.toString() ===
                  PrescriptionDrugFrequencyEnum.EveryTwoMonths
                ? 'two months'
                : prescriptionDrug.currentDosage.frequency.toString() ===
                  PrescriptionDrugFrequencyEnum.EveryThreeMonths
                ? 'three months'
                : prescriptionDrug.currentDosage.frequency.toString() ===
                  PrescriptionDrugFrequencyEnum.EverySixMonths
                ? 'six months'
                : 'twelve months',
          })
          setOverlayLoading(() => false)
          resolve(response.data)
          if (!forSpouse) {
            medicareAdvantageFilterQuoteStore.clearStore()
            mPartDPlansFilterStore.clearStore()
            pollQuotesPullStatus(getPlanYear())
            setIsQuotesRefreshing(true)
          }
        })
        .catch((err) => {
          setOverlayLoading(() => false)
          reject(err)
        })
    })
  }

  const handleAddDrug = (data: DrugDataProps) => {
    setAddDrugLoading(true)
    setOverlayLoading(() => true)
    setState({
      ...state,
      buttonLoading: true,
    })
    addPrescriptionDrug(data)
      .then((response: any) => {
        if (customerStore.get().forSpouse === false) {
          ApiManager.makeApiCallWithAuthentication(
            UrlConstants.PATCH_INFO_STEPS.USECASE,
            HttpMethods.PATCH_METHOD,
            {
              $infoStep: 'prescription',
            }
          )
            .then((res) => {
              if (res.status === 200) {
                // TODO: add drugs to store
                customerStore.setInfoSteps([
                  ...customerStore.infoSteps,
                  'prescription',
                ])
              } else throw res
            })
            .catch((err) => {
              snackbarStore.set({
                snackbarMessage: 'Something went wrong',
                snackbarOpen: true,
                snackbarType: 'error',
              })
            })
        }
        snackbarStore.set({
          snackbarMessage: StringConstants.SNACKBAR_SAVE_SUCCESS_MSG,
          snackbarOpen: true,
          snackbarType: SnackbarTypes.SUCCESS,
        })
        setSearchDrug(false)
        setAddPrescriptionState({
          addingDrugs: false,
          PrescriptionDrug: [],
          AddedDrugs: [response, ...addPrescriptionState.AddedDrugs],
        })
        //TODO: Validate this block before Prod
        try {
          if (!data.isSpouse) {
            medicareAdvantageFilterQuoteStore.setDrugNameOnAdditionOfDrug(
              data.name
            )
            mPartDPlansFilterStore.setDrugNameOnAdditionOfDrug(data.name)
          }
        } catch (error) {
          console.log('Add error: ', error)
        }
      })
      .catch((err) => {
        if (err.data?.message === 'Prescription drug is already been added') {
          snackbarStore.set({
            snackbarMessage: 'Drug has already been added',
            snackbarOpen: true,
            snackbarType: SnackbarTypes.ERROR,
          })
        } else {
          snackbarStore.set({
            snackbarMessage: getApiErrorMessage('add prescription drug'),
            snackbarOpen: true,
            snackbarType: SnackbarTypes.ERROR,
          })
        }
      })
      .finally(() => {
        setGenericDrug({})
        setState({
          ...state,
          buttonLoading: false,
        })
        setOverlayLoading(() => false)
        setAddDrugLoading(false)
        setShowSuccessfulPopUp(true)
      })
  }

  const updatePrescriptionDrug = (
    prescriptionDrug: Partial<DrugDataProps>
  ): Promise<DrugDataProps> => {
    return new Promise((resolve, reject) => {
      updatePrescription(prescriptionDrug)
        .then((response: any) => {
          if (response.status === 200) {
            setUpdatedDrug({
              name: prescriptionDrug.currentDosage!.name,
              quantity: prescriptionDrug.currentDosage!.qty,
              frequency:
                prescriptionDrug.currentDosage!.frequency.toString() ===
                PrescriptionDrugFrequencyEnum.EveryMonth
                  ? 'month'
                  : prescriptionDrug.currentDosage!.frequency.toString() ===
                    PrescriptionDrugFrequencyEnum.EveryTwoMonths
                  ? 'two months'
                  : prescriptionDrug.currentDosage!.frequency.toString() ===
                    PrescriptionDrugFrequencyEnum.EveryThreeMonths
                  ? 'three months'
                  : prescriptionDrug.currentDosage!.frequency.toString() ===
                    PrescriptionDrugFrequencyEnum.EverySixMonths
                  ? 'six months'
                  : 'twelve months',
            })
            resolve(response.data.data)
          } else throw response
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  const handleEditDrug = (data: Partial<DrugDataProps>) => {
    setAddDrugLoading(true)
    setState({
      ...state,
      buttonLoading: true,
    })
    setOverlayLoading(() => true)
    updatePrescriptionDrug(data)
      .then((response: any) => {
        if (!forSpouse) {
          medicareAdvantageFilterQuoteStore.clearStore()
          mPartDPlansFilterStore.clearStore()
          pollQuotesPullStatus(getPlanYear())
          setIsQuotesRefreshing(true)
        }
        // TODO: add drugs to store
        snackbarStore.set({
          snackbarMessage: StringConstants.SNACKBAR_SAVE_SUCCESS_MSG,
          snackbarOpen: true,
          snackbarType: SnackbarTypes.SUCCESS,
        })
        let index = addPrescriptionState.AddedDrugs.findIndex(
          (x: DrugDataProps) => x.id === response.id
        )
        let addedDrugsAfterUpdate = addPrescriptionState.AddedDrugs
        addedDrugsAfterUpdate[index] = response
        setSearchDrug(false)
        if (!isUndefined(addPrescriptionState.PrescriptionDrug[0])) {
          setExistingDrug(addPrescriptionState.PrescriptionDrug[0])
        }
        setAddPrescriptionState({
          addingDrugs: false,
          PrescriptionDrug: [],
          AddedDrugs: addedDrugsAfterUpdate,
        })
        setIsEditDrug(false)
      })
      .catch((err) => {
        snackbarStore.set({
          snackbarMessage: getApiErrorMessage('update prescription drug'),
          snackbarOpen: true,
          snackbarType: SnackbarTypes.ERROR,
        })
        setIsEditDrug(true)
      })
      .finally(() => {
        setState({
          ...state,
          buttonLoading: false,
        })
        setOverlayLoading(() => false)
        setAddDrugLoading(false)
        setShowSuccessfulPopUp(true)
      })
  }

  const handleCancel = () => {
    setGenericDrug({})
    setIsEditDrug(false)
    if (addPrescriptionState.addingDrugs) {
      setSearchDrug(false)
    } else setSearchDrug(true)
    setAddPrescriptionState({
      ...addPrescriptionState,
      PrescriptionDrug: [],
      addingDrugs: false,
    })
  }

  const getDrugName = () => {
    return state.alertMessage.split(
      ' cannot be added. Please try a different drug.'
    )[0]
  }
  return loading ? (
    <BouncingDotsLoader />
  ) : (
    <Container>
      <h3>Add your prescription drugs</h3>
      <p>
        By knowing your prescriptions, we can calculate plan coverage pricing
        and your out-of-pocket costs.
      </p>
      {state.showAlert && state.alertMessage && (
        <Alert
          severity='error'
          onClose={() => {
            setState({
              ...state,
              showAlert: false,
              alertMessage: '',
            })
          }}
        >
          <i>{getDrugName()}</i> cannot be added. Please try a different drug.
        </Alert>
      )}
      {(!addPrescriptionState.addingDrugs || (searchDrug && !isEditDrug)) && (
        <>
          <SectionRow row>
            <SearchBar
              label='Add your drugs'
              placeholder='Start typing drugs name'
              handleSearchClick={handleSearchClick}
              isSearching={isSearching}
              data={prescriptionStore.get().drugNames}
              minInputLengthBeforeFiltering={3}
              fetchSearchResults={fetchSearchResults}
              loading={loadingFetch}
            />
          </SectionRow>
        </>
      )}

      {((addPrescriptionState.addingDrugs && !searchDrug) || isEditDrug) && (
        <ModalComponent setOpen={handleCancel} restrictScroll={true}>
          <PrescriptionDrugCard
            handleAddDrugs={handleAddDrug}
            handleEditDrugs={handleEditDrug}
            handleCancel={handleCancel}
            buttonLoading={false}
            data={addPrescriptionState.PrescriptionDrug[0]}
            addDrugLoading={addDrugLoading}
            genericDrug={genericDrug}
          />
        </ModalComponent>
      )}

      {showSuccessfulPopUp && (
        <PrescriptionSavedPrompt
          drug={updatedDrug}
          existingDrug={existingDrug}
          handleEditDrug={handleEditDrug}
          setExistingDrug={setExistingDrug}
          setShowSuccessfulPopUp={setShowSuccessfulPopUp}
          setUpdatedDrug={setUpdatedDrug}
          key={Math.random()}
        ></PrescriptionSavedPrompt>
      )}
      {overlayLoading && <OverlayLoader />}
    </Container>
  )
}

export default observer(SearchPrompt)
