import { FormHelperText } from '@mui/material'
import Label from '../../common/Label/Label'
import RadioGroup from '../../common/RadioGroup/RadioGroup.component'
import React from 'react'
import styled from '@emotion/styled'
import theme from '../../../global/theme'

interface RadioQuestionI {
  options: any
  value: any
  question: any
  isRequired: any
  id: any
  handleResponseSelection: (id: any, responseArray: any) => void
  radioGroupName?: string
  error?: boolean
  loading?: boolean
  row?: boolean
  squared?: boolean
}

const RadioQuestion: React.FC<RadioQuestionI> = ({
  options,
  value,
  question,
  isRequired,
  id,
  handleResponseSelection,
  radioGroupName = '',
  loading = false,
  error = false,
  row = false,
  squared = true,
}) => {
  const handleRadioChange = (e: any) => {
    handleResponseSelection(id, [e.target.value])
  }
  return (
    <div>
      <div>
        <Label style={{ paddingBottom: '10px' }}>
          {question}{' '}
          {isRequired && (
            <span style={{ color: theme.colors.secondary }}>*</span>
          )}
        </Label>
        {error && <FormHelperText error>Required</FormHelperText>}
      </div>
      <RadioGroup
        row={row}
        disabledValues={
          loading ? [...options.map((option: any) => option.option)] : ['']
        }
        radioButtons={[...options.map((option: any) => option.option)]}
        onChange={handleRadioChange}
        value={value}
        radioGroupName={radioGroupName}
        radioButtonStyle={
          squared
            ? {
                borderRadius: '4px',
                background: theme.colors.backgroundColorContainer,
              }
            : {}
        }
      />
    </div>
  )
}

export default RadioQuestion
