import { MdClear } from 'react-icons/md'
import React from 'react'
import styled from '@emotion/styled'
import theme from '../../../../global/theme'

interface FilterChipProps {
  text: string
  updateFilters: (data: string) => any
}

const Chip = styled.p`
  background-color: ${theme.colors.lightBack};
  max-width: 150px;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  border-radius: 16px;
  span {
    font-size: 12px;
    color: ${theme.colors.primary};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const FilterChip = ({ text, updateFilters }: FilterChipProps) => {
  return (
    <Chip>
      <span>{text}</span>

      <MdClear
        style={{
          cursor: 'pointer',
          flexShrink: 0,
        }}
        color={`${theme.colors.primary}`}
        onClick={() => {
          updateFilters(text)
        }}
      />
    </Chip>
  )
}

export default FilterChip
