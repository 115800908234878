import React, { useEffect } from 'react'

import BouncingDotsLoader from '../../../common/Loading/BouncingDotsLoader'
import Button from '../../../common/Button/Button.component'
import RadioGroup from '../../../common/RadioGroup/RadioGroup.component'
import customerStore from '../../../../datastore/CustomerStore'
import { isEmpty } from 'lodash'
import styled from '@emotion/styled'
import theme from '../../../../global/theme'

interface DoctorDetailedSelectionProps {
  data: {
    id: number
    title: string
    specialization: string
    location: any[]
    isPrimaryPhysician: ['Yes', 'No']
  }
  handleAddDoctor: (id: number) => void
  handleCancel?: (id: number) => void
  providerAddress: any
  loading?: boolean
  isQuotesRefreshing?: boolean
}

const Container = styled.div`
  margin-top: 20px;
  width: 100%;
  min-height: 300px;
  border-radius: 16px;
  border: 1px solid ${theme.colors.borderColor};
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  h3 {
    color: ${theme.colors.primary};
    font: 20px;
    padding-bottom: 2px;
  }

  h4 {
    font-size: 14px;
    font-weight: 400;
    color: ${theme.colors.textDark};
    padding-bottom: 30px;
  }

  div {
    padding-bottom: 10px;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  position: sticky;
  bottom: 0;
  left: 0;
  background-color: ${theme.colors.white};
  padding: 10px 0px;

  @media screen and (max-width: 500px) {
    bottom: -20px;
  }
`

const DisableText = styled.div`
  font-size: 14px;
  margin-left: 5px;
  color: ${theme.colors.secondary};
`

const DoctorDetailedSelection: React.FC<DoctorDetailedSelectionProps> = ({
  data,
  handleAddDoctor,
  handleCancel,
  providerAddress,
  loading,
  isQuotesRefreshing,
}) => {
  const [close, setClose] = React.useState(false)
  const [selectedAddres, setSelectedAddress] = React.useState<any>({})
  const [addressError, setAddressError] = React.useState<boolean>(false)

  useEffect(() => {
    document.body.style.overflowY = 'hidden'
    return () => {
      document.body.style.overflowY = 'auto'
    }
  }, [])

  return (
    <div style={{ padding: '20px' }}>
      {!close && (
        <>
          <div style={{ paddingBottom: '20px' }}>
            <h3>{!isEmpty(data) && `${data.title}`}</h3>
          </div>
          {!loading ? (
            <div>
              <RadioGroup
                customRadioGroupStyle={{
                  display: 'grid',
                  maxHeight: '30rem',
                  overflowY: 'scroll',
                  paddingRight: '15px',
                  marginTop: '10px',
                }}
                label={
                  providerAddress.length > 0
                    ? `This doctor has ${providerAddress.length} office(s). Select the office location where you visit them`
                    : `This doctor does not have an office in the location provided`
                }
                radioButtons={
                  providerAddress.length
                    ? providerAddress.map(({ address1, address2, id }: any) => {
                        return `${address1} ${
                          address2 ? address2 : ''
                        }_ext_${id}`
                      })
                    : []
                }
                onChange={(e) => {
                  let value = e.target.value
                  if (providerAddress.length > 0) {
                    const address = providerAddress.filter(
                      (selectedAddres: any) => {
                        return (
                          value ===
                          selectedAddres.address1 +
                            ' ' +
                            selectedAddres.address2 +
                            '_ext_' +
                            selectedAddres.id
                        )
                      }
                    )
                    setSelectedAddress(address)
                  }
                  setAddressError(false)
                }}
                error={addressError}
                isValueModified={true}
                valueToBeRemoved={'_ext_'}
              />
            </div>
          ) : (
            <BouncingDotsLoader padding='15px' />
          )}
          <ButtonWrapper>
            <Button
              variant='contained'
              width='100%'
              onClick={() => {
                if (handleAddDoctor) {
                  if (!isEmpty(selectedAddres)) {
                    setAddressError(false)
                    handleAddDoctor(selectedAddres)
                    setClose(true)
                  } else setAddressError(true)
                }
              }}
              disabled={
                customerStore.isMAQuotesProcessing ||
                customerStore.isMSQuotesProcessing ||
                customerStore.isMPartDQuotesProcessing
              }
            >
              ADD DOCTOR
            </Button>
            <Button
              variant='outlined'
              width='100%'
              color='info'
              onClick={() => {
                if (handleCancel) {
                  handleCancel(providerAddress)
                }
              }}
            >
              CANCEL
            </Button>
          </ButtonWrapper>
        </>
      )}
    </div>
  )
}

export default DoctorDetailedSelection
