import {
  IoIosArrowDown as ArrowDown,
  IoIosArrowUp as ArrowUp,
} from 'react-icons/io'
import { Divider, Rating, Table, TableBody } from '@mui/material'
import React, { useState } from 'react'
import { getApiErrorMessage, titleCase } from '../../../../utils/StringUtils'
import { isEmpty, isUndefined, startCase } from 'lodash'

import AdditionalCost from '../../AdditionalCosts.component'
import Button from '../../../common/Button/Button.component'
import CarrierLogo from '../../../common/CarrierLogo'
import Coverage from '../../Coverage.component'
import EnrollmentRequestConfirmation from '../../Modals/EnrollmentRequestConfirmation'
import { MNonSupplementPlan } from '../../../../interface/quotes/mNonSupplementPlans/MNonSupplementPlan'
import { MedicareQuotesType } from '../../../../enums/MedicareQuoteTypeEnum'
import ModalComponent from '../../../common/Modal/Modal.component'
import MonthlyPremiumComponent from '../../MonthlyPremium.component'
import MuiTableCell from '@mui/material/TableCell'
import MuiTableRow from '@mui/material/TableRow'
import PlanCardFooter from '../../Common/PlanCardFooter'
import PrescriptionDrugCost from '../../PrescriptionDrugCost.component'
import { PrescriptionMonthlyCost } from '../../../../interface/prescriptionDrug/PrescriptionMonthlyCost'
import RemoveSpouseConfirmationPopUp from '../../Modals/RemoveSpouseConfirmation'
import StringConstants from '../../../../constants/StringConstants'
import Tooltip from '../../../common/Tooltip/Tooltip.component'
import UrlConstants from '../../../../constants/UrlConstants'
import _ from 'lodash'
import { convertToUSCurrency } from '../../../../utils/CurrencyConverterUtil'
import customerStore from '../../../../datastore/CustomerStore'
import { fetchPrescriptionMonthlyCostForPlan } from '../../../../service/prescriptions'
import { fireEvent } from '../../../../cra'
import { observer } from 'mobx-react'
import snackbarStore from '../../../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../../../global/theme'
import { updateEnrollmentRequest } from '../../../../service/quotes'
import { updatePreferredNumberForEnrollRequestedPlan } from '../../../../service/customer'
import { useNavigate } from 'react-router-dom'
import userActionStore from '../../../../datastore/UserActionStore'

interface EstimatedAnnualCostProps {
  cost: number
  title: string
  tooltipTitle: string
}

interface PlanBenefit {
  name: string
  covered: boolean
  deductible?: number
}

const Container = styled.div<{
  isRecommended?: boolean
  isGoodChoice?: boolean
}>`
  max-width: 1200px;
  width: 100%;
  overflow: hidden;
  border: ${(props) =>
    props.isRecommended
      ? `2px solid ${theme.colors.success}`
      : props.isGoodChoice
      ? `2px solid ${theme.colors.primary}`
      : `1px solid ${theme.colors.borderColor}`};
  border-radius: 16px;
  margin: 20px 0px;
  transition: box-shadow 0.4s;
  &:hover {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.4s;
  }

  .recommended-banner {
    background-color: ${theme.colors.contrastGreen};
    padding: 8px 12px;
    text-align: center;
    h2 {
      color: ${theme.colors.green};
      font-size: 18px;
    }
  }

  .good-choice-banner {
    background-color: ${theme.colors.contrastPrimary};
    padding: 8px 12px;
    text-align: center;
    h2 {
      color: ${theme.colors.primary};
      font-size: 18px;
    }
  }
`

const Wrapper = styled.div`
  padding: 0px 30px;
  @media screen and (max-width: 530px) {
    padding: 0px 15px;
  }
`

const Header = styled.div`
  padding: 20px 40px;
  border-bottom: 1px solid ${theme.colors.borderColor};
  color: ${theme.colors.textDark};
  div {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .title {
    gap: 30px;
    padding-bottom: 10px;
    img {
      max-width: 100px;
      max-height: 40px;
    }
    h2 {
      font-size: 18px;
    }
  }
  .sub_title {
    color: ${theme.colors.textGrey};
    font-size: 14px;
    display: flex;
    align-items: center;
    .star {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
  @media screen and (max-width: 600px) {
    border-bottom: none;
    padding: 10px 20px;
    .title {
      gap: 15px;
    }
    .sub_title {
      flex-direction: column;
      justify-content: center;
      gap: 5px;
    }
  }
`

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.colors.borderColor};
  padding: 16px 0px;

  @media screen and (max-width: 530px) {
    padding: 20px 0px;
  }

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`

const BenefitsWrapper = styled.div`
  flex: 1.5;
  border-right: 1px solid ${theme.colors.borderColor};
  padding: 0px 30px;
  @media screen and (max-width: 530px) {
    padding: 0px 10px;
  }

  @media screen and (max-width: 500px) {
    border-right: none;
    border-bottom: 1px solid ${theme.colors.borderColor};
    margin-bottom: 20px;
  }
`

const PricingWrapper = styled.div`
  flex: 1;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  h2 {
    font-size: 14px;
    color: ${theme.colors.textGrey};
    padding-bottom: 5px;
  }
  p {
    font-size: 24px;
    font-weight: bold;
    color: ${theme.colors.primary};
    padding-bottom: 10px;
  }

  div {
    padding: 4px 0px 0px 0px;
    p {
      font-size: 18px;
      padding-bottom: 0px;
    }
    span {
      font-size: 12px;
      color: ${theme.colors.textGrey};
      font-weight: 600;
    }
  }

  @media screen and (max-width: 530px) {
    padding: 0px 10px;
  }
`

const BenefitContent = styled.div`
  padding-bottom: 8px;
  h2 {
    font-size: 14px;
    color: ${theme.colors.textGrey};
    padding-bottom: 10px;
  }
  div {
    display: flex;
    flex-wrap: wrap;
    .rxDeductible {
      font-size: 32px;
      font-weight: 700;
      color: ${theme.colors.primary};
    }
    p {
      padding-right: 15px;
      padding-bottom: 3px;
      font-size: 14px;
      color: ${theme.colors.textGrey};
      display: flex;
      align-items: center;
      span {
        margin-right: 2px;
      }
    }
  }
`

const FooterWrapper = styled.div`
  padding: 2px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    align-items: center;
    span {
      font-size: 14px;
    }
    p {
      color: ${theme.colors.primary};
      font-size: 14px;
    }
  }

  @media screen and (max-width: 530px) {
    padding: 0px;
    padding-bottom: 10px;
    flex-wrap: wrap;
    div {
      flex-wrap: wrap;
      span {
        font-size: 12px;
      }
      p {
        font-size: 12px;
      }
    }
  }
`
const MoreDetailsWrapper = styled.div`
  padding: 10px 40px;
  .detail-header {
    display: flex;
    align-items: center;
    gap: 20px;
    padding-bottom: 10px;
    p {
      font-size: 14px;
      font-weight: 700;
      color: ${theme.colors.textGrey};
    }
    span {
      text-decoration: underline;
      font-weight: 700;
      color: ${theme.colors.primary};
      font-size: 12px;
    }
  }
  @media screen and (max-width: 600px) {
    padding: 10px 20px;
  }
`

const TableRow = styled(MuiTableRow)`
  &:nth-of-type(odd) {
    background-color: ${theme.colors.backgroundColorContainer};
  }
`

const TableCell = styled(MuiTableCell)<{ customClass?: string }>`
  font-weight: 700;
  color: ${theme.colors.textGrey};
  font-size: 12px;
  padding: 15px 10px;
  ${({ customClass }) =>
    customClass === 'plan-benefit-coverage' &&
    `
      color:${theme.colors.textGrey};
      font-weight:400;
    `}
`

const TextWrapper = styled.h1`
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  color: #2f3032;
  padding-right: 10px;
`

const AnnualCostContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0px !important;
  text-align: left;
  border-top: 1px solid ${theme.colors.borderColor};
  padding: 16px 0px 0px 0px !important;
  margin-top: 20px;
`

const MonthlyPremiumWrapper = styled.div<{ isInGrid: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isInGrid ? 'column' : 'row')};
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  border-bottom: 1px solid ${theme.colors.borderColor};
  @media screen and (max-width: 600px) {
    flex-direction: column;
    padding: 10px 20px;
  }
  .premium_details {
    display: flex;
    flex-direction: ${(props) => (props.isInGrid ? 'column' : 'row')};
    @media screen and (max-width: 600px) {
      flex-direction: column;
    }
  }
  .premium_info {
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: ${theme.colors.textGrey};
    .premium_value {
      font-size: 24px;
      font-weight: 700;
      color: ${theme.colors.primary};
    }
  }
`

const NavigationWrapper = styled.div`
  text-decoration: underline;
  font-weight: 600;
  color: ${theme.colors.primary};
  font-size: 14px;
`

const CollapsibleDetailsWrapper = styled.div<{
  isViewMorCoverageDetailsExpanded: boolean
}>`
  .collapsible-button {
    display: none;
    text-align: center;
    @media screen and (max-width: 600px) {
      display: block;
    }
  }
  .collapsible-content {
    @media screen and (max-width: 600px) {
      display: ${({ isViewMorCoverageDetailsExpanded }) =>
        isViewMorCoverageDetailsExpanded ? 'block' : 'none'};
    }
  }
`
// const AdditionalCostWrapper = styled.div<{ isRecommended: boolean }>`
//   display: flex;
//   padding: 20px 40px;
//   border-bottom: 1px solid ${theme.colors.borderColor};
//   justify-content: ${(props) => (props.isRecommended ? 'center' : 'start')};
//   @media screen and (max-width: 600px) {
//     flex-direction: column;
//     align-items: center;
//     padding: 20px;
//   }
// `

const AdditionalCostWrapper = styled.div<{ isRecommended: boolean }>`
  display: grid;
  justify-content: space-between;

  ${({ isRecommended }) =>
    isRecommended
      ? `
        grid-template-columns: 1fr 1fr;
        & > div {
          /* Add individual cell borders */
          margin-top: 10px;
          margin-bottom: 10px;
          border-right: 1px solid ${theme.colors.borderColor};
        }
        // /* Remove the last row's bottom border */
        // & > div:nth-last-child(-n + 2) {
        //   border-bottom: none;
        // }

        padding: 10px 30px;
      `
      : `
        grid-template-columns: 1fr 1fr 1fr 1fr;
        & > div {
          /* Add individual cell borders */
          border-right: 1px solid ${theme.colors.borderColor};
        }

        padding: 20px 40px;

      `}

  border-bottom: 1px solid ${theme.colors.borderColor};
  & > div:nth-child(2n) {
    border-right: none;
    border-bottom: none;
  }

  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0px;
    & > div {
      /* Add individual cell borders */
      margin-top: 0px;
      margin-bottom: 0px;
      border-right: none;
      border-bottom: 1px solid ${theme.colors.borderColor};
    }
  }
`

const MedicarePrescriptionCard: React.FC<{
  medicarePartDPlan: MNonSupplementPlan
  onEnroll: () => void
  handleRemoveFavorite?: () => void
  showCompareCheckbox?: boolean
  userOption: boolean
  handleSpouseRemove: () => void
  setShowAddSpouseConfirmation: (flag: boolean) => void
  setCompletePlanDetailModalActive: (flag: boolean) => void
  setCompleteDetailPlanId: (planId: string) => void
  isRecommended?: boolean
  isGoodChoice?: boolean
}> = ({
  medicarePartDPlan,
  onEnroll,
  handleRemoveFavorite,
  showCompareCheckbox = true,
  userOption,
  handleSpouseRemove,
  setShowAddSpouseConfirmation,
  setCompletePlanDetailModalActive,
  setCompleteDetailPlanId,
  isRecommended = false,
  isGoodChoice = false,
}) => {
  const [isExpanded, setIsExpanded] = React.useState(false)
  const [primaryEnrollmentStatus, setPrimaryEnrollmentStatus] = React.useState(
    medicarePartDPlan.enrollRequested
  )
  const [spouseEnrollmentStatus, setSpouseEnrollmentStatus] = React.useState(
    medicarePartDPlan.spouseEnrollRequested
  )

  const [spouseEnrollmentRequested, setSpouseRequestedEnrollment] =
    React.useState<boolean>(false)

  const [loading, setLoading] = React.useState(false)
  const [spouseLoading, setSpouseLoading] = React.useState(false)
  const [preferredPhoneLoading, setPreferredPhoneLoading] =
    React.useState(false)
  const [enrollmentRequestPlanId, setEnrollmentRequestedPlanId] =
    React.useState('')

  const [
    isViewMorCoverageDetailsExpanded,
    setIsViewMorCoverageDetailsExpanded,
  ] = React.useState(false)

  const [isPlanBenefitExpanded, setIsPlanBenefitExpanded] = React.useState(true)
  const [isPrescriptionExpanded, setIsPrescriptionExpanded] = React.useState(
    medicarePartDPlan.drugInformations?.length > 0
  )
  const [isPharmacyExpanded, setIsPharmacyExpanded] = React.useState(
    medicarePartDPlan.drugCosts?.length > 0
  )
  const [isDoctorCoverageExpanded, setIsDoctorCoverageExpanded] =
    React.useState(medicarePartDPlan.providerCoverage?.length > 0)

  const [drugCostDetails, setDrugCostDetails] = React.useState<
    PrescriptionMonthlyCost[]
  >([] as PrescriptionMonthlyCost[])

  const [isDrugCostDetailsLoading, setIsDrugCostDetailsLoading] =
    React.useState<boolean>(false)

  const [showRemoveSpouseConfirmation, setShowRemoveSpouseConfirmation] =
    React.useState(false)

  const handleIsPlanBenefitExpansion = (flag: boolean) => {
    setIsPlanBenefitExpanded(flag)
  }
  const handleIsPrescriptionExpanded = (flag: boolean) => {
    setIsPrescriptionExpanded(flag)
  }
  const handleIsPharmacyExpanded = (flag: boolean) => {
    setIsPharmacyExpanded(flag)
  }
  const handleIsDoctorCoverageExpanded = (flag: boolean) => {
    setIsDoctorCoverageExpanded(flag)
  }

  const [enrollmentRequestPromptActive, setEnrollmentRequestPromptActive] =
    React.useState(false)

  const handleExpandCard = (mpdPlanName: string) => {
    if (isExpanded === true) {
      customerStore.setSearchPlan('')
      customerStore.setScrollPosition('')
      setIsExpanded(false)
    }
    if (isExpanded === false) {
      setIsExpanded(true)
    }
    if (mpdPlanName === customerStore.searchPlan) {
      setIsExpanded(false)
      customerStore.setSearchPlan('')
      customerStore.setScrollPosition('')
    }
    setIsExpanded(!isExpanded)
  }
  const [preferredPhone, setPreferredPhone] = React.useState(
    customerStore.get().phone.replace('+1', '')
  )
  const [isFavorite, setIsFavorite] = React.useState(
    medicarePartDPlan.planSaved || false
  )
  const navigate = useNavigate()
  const [drugHighlights, setDrugHighlights] = useState<
    {
      key: string
      value: string
    }[]
  >([])

  let planBenefits: PlanBenefit[] = []

  let packageBenefits: any = {
    hasVision: medicarePartDPlan.hasVision,
    hasTransport: medicarePartDPlan.hasTransport,
    hasDental: medicarePartDPlan.hasDental,
    hasHearing: medicarePartDPlan.hasHearing,
    hasOTC: medicarePartDPlan.hasOTC,
    hasPreferredPharmacies: medicarePartDPlan.hasPreferredPharmacies,
    hasInsulinSavings: medicarePartDPlan.hasInsulinSavings,
    acceptsMailOrder: medicarePartDPlan.acceptsMailOrder,
    partBReimbursement: medicarePartDPlan.partBReimbursement,
  }

  Object.keys(packageBenefits).forEach((benefit) => {
    let covered = !(
      packageBenefits[benefit] === 'N' ||
      packageBenefits[benefit] === false ||
      packageBenefits[benefit] === -1
    )
    benefit = /^has/.test(benefit) ? benefit.slice(3) : benefit
    planBenefits.push({
      name: startCase(benefit).replace(/\s/g, ' '),
      covered: covered,
      deductible: undefined,
    })
  })

  const handleEnrollRequest = (forSpouse: boolean, planId: string) => {
    forSpouse ? setSpouseLoading(true) : setLoading(true)
    updateEnrollmentRequest(
      planId,
      MedicareQuotesType.MEDICARE_NON_SUPPLEMENT,
      forSpouse,
      `+1${preferredPhone.toString()}`
    )
      .then(() => {
        snackbarStore.set({
          snackbarOpen: true,
          snackbarMessage: 'Enroll request saved successfully',
          snackbarType: 'success',
        })
        setEnrollmentRequestPromptActive(true)
        setEnrollmentRequestedPlanId(planId)
        forSpouse
          ? setSpouseEnrollmentStatus(true)
          : setPrimaryEnrollmentStatus(true)
      })
      .catch((err) => {
        if (
          err.data?.message ===
          'Maximum limit reached for requesting enrollment for plans'
        ) {
          snackbarStore.set({
            snackbarOpen: true,
            snackbarMessage: getApiErrorMessage(
              'save enroll request. Maximum limit reached'
            ),
            snackbarType: 'error',
          })
        } else {
          snackbarStore.set({
            snackbarOpen: true,
            snackbarMessage: getApiErrorMessage('save enroll request'),
            snackbarType: 'error',
          })
        }
      })
      .finally(() => {
        forSpouse ? setSpouseLoading(false) : setLoading(false)
      })
  }
  const handlePreferredNumberForEnrollRequest = async (planId: string) => {
    if (preferredPhone !== customerStore.get().phone.replace('+1', '')) {
      setPreferredPhoneLoading(true)
      updatePreferredNumberForEnrollRequestedPlan({
        'plan-id': planId,
        'plan-type': MedicareQuotesType.MEDICARE_NON_SUPPLEMENT,
        'preferred-number': preferredPhone,
      })
        .then(() => {
          snackbarStore.set({
            snackbarOpen: true,
            snackbarMessage: 'Successfully saved the preferred number',
            snackbarType: 'success',
          })
          setPreferredPhoneLoading(true)
          setEnrollmentRequestPromptActive(false)
        })
        .catch((err) => {
          snackbarStore.set({
            snackbarOpen: true,
            snackbarMessage: 'Please try again',
            snackbarType: 'error',
          })
        })
        .finally(() => {
          setPreferredPhoneLoading(false)
        })
    } else {
      setEnrollmentRequestPromptActive(false)
    }

    onEnroll()
  }

  const [mPartDPlanCard, setMPartDCard] = useState<MNonSupplementPlan>()

  React.useEffect(() => {
    setMPartDCard(medicarePartDPlan)
  }, [medicarePartDPlan])

  const onAddFavPlan = (planId: string) => {
    setIsFavorite(true)
  }

  const onRemoveFavPlan = (planId: string) => {
    setIsFavorite(false)
    handleRemoveFavorite && handleRemoveFavorite()
  }

  // const [currentPlanAnnualCost, setCurrentPlanAnnualCost] =
  //   React.useState<number>(0)
  // const [currentPlanExist, setCurrentPlanExistStatus] =
  //   React.useState<boolean>(false)

  // if (medicarePartDPlan && medicarePartDPlan.customerCurrentPlanStatus) {
  //   setCurrentPlanAnnualCost(medicarePartDPlan.estimatedAnnualCost)
  //   setCurrentPlanExistStatus(true)
  // }

  const handleAddToCompare = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.checked) {
      if (
        userActionStore.comparePlanCards.planType ===
        MedicareQuotesType.MEDICARE_PRESCRIPTION
      ) {
        if (userActionStore.comparePlanCards.compareCardsData.length < 3) {
          userActionStore.addCard(MedicareQuotesType.MEDICARE_PRESCRIPTION, {
            id: medicarePartDPlan.id,
            name: medicarePartDPlan.name,
            premium: medicarePartDPlan.premium,
            carrier: medicarePartDPlan.carrierName,
            enrollRequestStatus: medicarePartDPlan.enrollRequested,
            planType: MedicareQuotesType.MEDICARE_NON_SUPPLEMENT,
            sunfirePlanId: medicarePartDPlan.sunfirePlanId,
            rating: medicarePartDPlan.fmtRating,
          })
        } else {
          snackbarStore.set({
            snackbarMessage: 'You can only add up to 3 plans for comparison',
            snackbarOpen: true,
            snackbarType: 'default',
          })
        }
      } else {
        userActionStore.addCard(MedicareQuotesType.MEDICARE_PRESCRIPTION, {
          id: medicarePartDPlan.id,
          name: medicarePartDPlan.name,
          premium: medicarePartDPlan.premium,
          carrier: medicarePartDPlan.carrierName,
          enrollRequestStatus: medicarePartDPlan.enrollRequested,
          planType: MedicareQuotesType.MEDICARE_NON_SUPPLEMENT,
          sunfirePlanId: medicarePartDPlan.sunfirePlanId,
          rating: medicarePartDPlan.fmtRating,
        })
      }
    }

    if (!e.currentTarget.checked) {
      userActionStore.removeCard(medicarePartDPlan.id)
    }
  }

  const storeScrollPosition = () => {
    const scrollPosition = window.scrollY
    customerStore.setScrollPosition(scrollPosition.toString())
  }

  React.useEffect(() => {
    if (
      mPartDPlanCard &&
      mPartDPlanCard?.drugCosts?.length > 0 &&
      isExpanded &&
      isEmpty(drugCostDetails)
    ) {
      setIsDrugCostDetailsLoading(true)
      fetchPrescriptionMonthlyCostForPlan(
        mPartDPlanCard?.sunfirePlanId!.toString(),
        mPartDPlanCard?.contractYear.toString(),
        userOption
      )
        .then((drugCostResponse) => {
          setDrugCostDetails(drugCostResponse)
        })
        .catch((err) => {})
        .finally(() => {
          setIsDrugCostDetailsLoading(false)
        })
    }
  }, [isExpanded])

  return (
    <>
      {mPartDPlanCard && (
        <Container isGoodChoice={isGoodChoice} isRecommended={isRecommended}>
          {isRecommended && (
            <div className='recommended-banner'>
              <h2>RECOMMENDATION</h2>
            </div>
          )}
          {isGoodChoice && (
            <div className='good-choice-banner'>
              <h2>GOOD CHOICE</h2>
            </div>
          )}
          {enrollmentRequestPromptActive && (
            <ModalComponent
              setOpen={() => {
                setEnrollmentRequestPromptActive(false)
              }}
              description='Popup - Request for Enrollment Confirmation'
              hideCloseButton={true}
            >
              <EnrollmentRequestConfirmation
                setOpen={() => {
                  setEnrollmentRequestPromptActive(false)
                }}
                onEnroll={() => {
                  handlePreferredNumberForEnrollRequest(enrollmentRequestPlanId)
                }}
                planName={medicarePartDPlan.name}
                planId={medicarePartDPlan.id}
                planType={MedicareQuotesType.MEDICARE_PRESCRIPTION}
                buttonLoading={preferredPhoneLoading}
                preferredPhoneNumber={preferredPhone}
                setPreferredPhoneNumber={setPreferredPhone}
              />
            </ModalComponent>
          )}

          {showRemoveSpouseConfirmation && (
            <ModalComponent
              setOpen={() => {
                setShowRemoveSpouseConfirmation(!showRemoveSpouseConfirmation)
              }}
              description='Popup - Request for Enrollment Confirmation'
              hideCloseButton={true}
            >
              <RemoveSpouseConfirmationPopUp
                onConfirm={() => {
                  handleSpouseRemove()
                }}
                onReject={() => {
                  setShowRemoveSpouseConfirmation(!showRemoveSpouseConfirmation)
                }}
              />
            </ModalComponent>
          )}

          {/* {medicarePartDPlan && ( */}
          {/* <Wrapper> */}
          <Header>
            <div className='title'>
              <CarrierLogo
                carrier={mPartDPlanCard.carrierName}
                useAltLogo={true}
              />
              <h2>{mPartDPlanCard.name}</h2>
            </div>

            <div className='sub_title'>
              <p>{`${mPartDPlanCard.contractId}-${mPartDPlanCard.planId}-${mPartDPlanCard.segmentId}`}</p>
              <Divider flexItem orientation='vertical' />
              <p className='star'>
                <span>
                  Star Rating
                  <Tooltip
                    title={
                      StringConstants.TOOLTIP_NON_ELIGIBLE_STAR_RATING_MESSAGE
                    }
                  />
                </span>
                <Rating
                  name='simple-controlled'
                  value={mPartDPlanCard.fmtRating || 0}
                  precision={0.5}
                  readOnly={true}
                  size='medium'
                />
              </p>
            </div>

            {/* {showCompareCheckbox && (
              <div>
                <Checkbox
                  checked={
                    !!userActionStore.comparePlanCards.compareCardsData.find(
                      (iterator) => iterator.id === medicarePartDPlan.id
                    )
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    handleAddToCompare(e)
                    fireEvent(
                      !!userActionStore.comparePlanCards.compareCardsData.find(
                        (iterator) => iterator.id === medicarePartDPlan.id
                      )
                        ? 'addCardForComparison'
                        : 'removeAddedPlanForComparison',
                      e,
                      {
                        description:
                          !!userActionStore.comparePlanCards.compareCardsData.find(
                            (iterator) => iterator.id === medicarePartDPlan.id
                          )
                            ? 'Add Card for Comparison'
                            : 'Remove Card from Comparison',
                        planId: medicarePartDPlan.id,
                        planType: medicarePartDPlan.type,
                        planName: medicarePartDPlan.name,
                      }
                    )
                  }}
                />
                <span>Add to Compare</span>
              </div>
            )} */}
          </Header>
          <MonthlyPremiumWrapper isInGrid={isRecommended || isGoodChoice}>
            <div className='premium_details'>
              {mPartDPlanCard.premium !== -1 && (
                <MonthlyPremiumComponent
                  btnText={
                    primaryEnrollmentStatus ? 'Enrollment Requested' : 'ENROLL'
                  }
                  buttonVariant='contained'
                  carrierName={mPartDPlanCard.carrierName}
                  color='secondary'
                  forSpouse={false}
                  isEnrolled={primaryEnrollmentStatus}
                  loaderColor={theme.colors.white}
                  loading={loading}
                  onEnroll={handleEnrollRequest}
                  premium={mPartDPlanCard.premium}
                  username={customerStore.firstName}
                  planId={mPartDPlanCard.id}
                />
              )}

              {userOption && !isUndefined(mPartDPlanCard.spousePremium) ? (
                <>
                  {mPartDPlanCard.premium !== -1 && (
                    <Divider
                      orientation='vertical'
                      flexItem
                      style={{ margin: '0px 10px' }}
                    />
                  )}
                  <MonthlyPremiumComponent
                    btnText={
                      spouseEnrollmentRequested
                        ? 'Enrollment Requested'
                        : 'ENROLL'
                    }
                    buttonVariant='outlined'
                    carrierName={mPartDPlanCard.carrierName}
                    color='primary'
                    forSpouse={true}
                    isEnrolled={spouseEnrollmentStatus}
                    loaderColor={theme.colors.primary}
                    loading={spouseLoading}
                    onEnroll={handleEnrollRequest}
                    premium={mPartDPlanCard.spousePremium}
                    username={customerStore.spouseFirstName}
                    planId={mPartDPlanCard.spousePlanId!}
                  />
                </>
              ) : (
                <></>
              )}
            </div>
            {customerStore.spouseOptIn ? (
              <NavigationWrapper
                style={{
                  cursor: 'pointer',
                  padding: '0px',
                  marginTop: '3px',
                }}
                onClick={() => {
                  setShowRemoveSpouseConfirmation(true)
                }}
              >
                - Remove Spouse/Partner
              </NavigationWrapper>
            ) : (
              <NavigationWrapper
                style={{
                  cursor: 'pointer',
                  padding: '0px',
                  marginTop: '3px',
                }}
                onClick={() => {
                  setShowAddSpouseConfirmation(true)
                }}
              >
                + Add Spouse/Partner
              </NavigationWrapper>
            )}
          </MonthlyPremiumWrapper>
          <AdditionalCostWrapper isRecommended={isRecommended || isGoodChoice}>
            {mPartDPlanCard.additionalCosts?.map((additionalCost, index) => {
              return (
                <AdditionalCost
                  name={additionalCost.name}
                  toolTip={additionalCost.toolTip}
                  value={additionalCost.value}
                />
              )
            })}
          </AdditionalCostWrapper>
          <CollapsibleDetailsWrapper
            isViewMorCoverageDetailsExpanded={isViewMorCoverageDetailsExpanded}
          >
            <div className='collapsible-button'>
              <Button
                variant='text'
                color='primary'
                bold={false}
                endIcon={
                  isViewMorCoverageDetailsExpanded ? (
                    <ArrowUp style={{ fontSize: '24px' }} />
                  ) : (
                    <ArrowDown style={{ fontSize: '24px' }} />
                  )
                }
                onClick={(e) => {
                  setIsViewMorCoverageDetailsExpanded(
                    !isViewMorCoverageDetailsExpanded
                  )
                }}
              >
                <>
                  View {isViewMorCoverageDetailsExpanded ? 'less' : 'more'}{' '}
                  details
                </>
              </Button>
              <Divider orientation='horizontal' flexItem />
            </div>
            <div className='collapsible-content'>
              {/* <Divider orientation='horizontal' flexItem /> */}

              <Coverage
                heading='Doctor Coverage'
                subtitle='Doctors'
                isSpouseSection={userOption}
                coveragesData={
                  mPartDPlanCard.providerCoverage?.length > 0
                    ? mPartDPlanCard.providerCoverage?.map((coverage) => {
                        return {
                          name: coverage.provider.name,
                          covered: coverage.isCovered,
                        }
                      })
                    : []
                }
                spouseCoverageData={
                  !isUndefined(mPartDPlanCard.spouseProviders) &&
                  mPartDPlanCard.spouseProviders.length > 0
                    ? mPartDPlanCard.spouseProviders?.map((coverage) => {
                        return {
                          name: coverage.provider.name,
                          covered: coverage.isCovered,
                        }
                      })
                    : []
                }
                isContentExpanded={isDoctorCoverageExpanded}
                setIsContentExpanded={handleIsDoctorCoverageExpanded}
                toolTipContent={StringConstants.TOOLTIP_DOCTORS_COVERAGE}
                hyperlinkDetails={{
                  label: 'Add Doctors',
                  path: '/profile/myprofile/doctors',
                }}
              ></Coverage>

              <Coverage
                heading='Pharmacy'
                subtitle='Pharmacies'
                coveragesData={
                  mPartDPlanCard.drugCosts?.length > 0
                    ? mPartDPlanCard.drugCosts?.map((drugCost) => {
                        return {
                          name: titleCase(drugCost.pharmacyName),
                          covered:
                            drugCost.pharmacyNetworkStatus === '0' ||
                            drugCost.pharmacyNetworkStatus === '-1'
                              ? false
                              : true,
                        }
                      })
                    : []
                }
                spouseCoverageData={
                  !isUndefined(mPartDPlanCard.spouseDrugCosts) &&
                  mPartDPlanCard.spouseDrugCosts?.length > 0
                    ? mPartDPlanCard.spouseDrugCosts?.map((drugCost) => {
                        return {
                          name: titleCase(drugCost.pharmacyName),
                          covered:
                            drugCost.pharmacyNetworkStatus === '0' ||
                            drugCost.pharmacyNetworkStatus === '-1'
                              ? false
                              : true,
                        }
                      })
                    : []
                }
                isSpouseSection={userOption}
                isContentExpanded={isPharmacyExpanded}
                setIsContentExpanded={handleIsPharmacyExpanded}
                toolTipContent={StringConstants.TOOLTIP_PHARMACY_COVERAGE}
                hyperlinkDetails={{
                  label: 'Add Preferred Pharmacy',
                  path: '/profile/myprofile/pharmacy',
                }}
              ></Coverage>
              <Coverage
                heading='Prescription Drug Coverage'
                subtitle='Prescriptions'
                coveragesData={
                  mPartDPlanCard.drugInformations?.length > 0
                    ? mPartDPlanCard.drugInformations?.map(
                        (coverage, index) => {
                          return {
                            name: titleCase(coverage.name),
                            covered: coverage.tier !== -1,
                          }
                        }
                      )
                    : []
                }
                spouseCoverageData={
                  !isUndefined(mPartDPlanCard.spouseDrugInformation) &&
                  mPartDPlanCard.spouseDrugInformation?.length > 0
                    ? mPartDPlanCard.spouseDrugInformation?.map(
                        (coverage, index) => {
                          return {
                            name: titleCase(coverage.name),
                            covered: coverage.tier !== -1,
                          }
                        }
                      )
                    : []
                }
                isSpouseSection={userOption}
                isContentExpanded={isPrescriptionExpanded}
                setIsContentExpanded={handleIsPrescriptionExpanded}
                toolTipContent={StringConstants.TOOLTIP_PRESCRIPTION_COVERAGE}
                hyperlinkDetails={{
                  label: 'Add Prescription Drugs',
                  path: '/profile/myprofile/prescriptions',
                }}
              ></Coverage>
            </div>
          </CollapsibleDetailsWrapper>
          {(isExpanded ||
            medicarePartDPlan.name === customerStore.searchPlan) && (
            <>
              <PlanCardFooter
                isInGrid={isGoodChoice || isRecommended}
                id={mPartDPlanCard.id}
                planId={mPartDPlanCard.planId}
                spousePlanId={mPartDPlanCard.spousePlanId}
                forSpouse={userOption}
                favourite={
                  userOption
                    ? !isUndefined(mPartDPlanCard.spousePlanSaved)
                      ? mPartDPlanCard.spousePlanSaved
                      : mPartDPlanCard.planSaved
                    : mPartDPlanCard.planSaved
                }
                handleRemoveFavourite={handleRemoveFavorite}
                showCompareCheckbox={showCompareCheckbox}
                planType={mPartDPlanCard.type}
                planName={mPartDPlanCard.name}
                handleAddToCompare={handleAddToCompare}
                isExpanded={isExpanded}
                handleExpandCard={handleExpandCard}
                medicareQuoteType={MedicareQuotesType.MEDICARE_NON_SUPPLEMENT}
              />
              <MoreDetailsWrapper>
                <>
                  <div className='detail-header'>
                    <p>Coverage </p>
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => {
                        fireEvent('viewCompletePlanDetails', e, {
                          description:
                            'View Complete Plan Details of Medicare PartD Plan Card',
                          planId: mPartDPlanCard.id,
                          planType: mPartDPlanCard.type,
                          planName: mPartDPlanCard.name,
                        })
                        // navigate({
                        //   pathname: '/plan-details',
                        //   search: `id=${mPartDPlanCard.id}&&type=mpd`,
                        // })
                        setCompletePlanDetailModalActive(true)
                        setCompleteDetailPlanId(mPartDPlanCard.id)
                        storeScrollPosition()
                      }}
                    >
                      View Complete Plan Details
                    </span>
                  </div>

                  <Table
                    style={{
                      marginBottom: '12px',
                      border: '1px solid #E5E5E5',
                    }}
                  >
                    <TableBody>
                      {/* {planBenefits.map((planBenefit, index) => ( */}
                      <TableRow>
                        <TableCell
                          sx={{ width: '50%' }}
                          style={{
                            textAlign: 'left',
                            borderRight: '1px solid #E5E5E5',
                          }}
                        >
                          Initial Coverage Limit
                        </TableCell>
                        <TableCell
                          sx={{ width: '50%' }}
                          style={{ textAlign: 'left' }}
                        >
                          {mPartDPlanCard.initialCoverageLimit === -1
                            ? 'N/A'
                            : convertToUSCurrency(
                                mPartDPlanCard.initialCoverageLimit,
                                2
                              )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{ width: '50%' }}
                          style={{
                            textAlign: 'left',
                            borderRight: '1px solid #E5E5E5',
                          }}
                        >
                          Maximum Out of Pocket
                        </TableCell>
                        <TableCell
                          sx={{ width: '50%' }}
                          style={{ textAlign: 'left' }}
                        >
                          {mPartDPlanCard.moop === -1
                            ? mPartDPlanCard.fmtMoop
                            : mPartDPlanCard.moop}
                        </TableCell>
                      </TableRow>
                      {/* ))} */}
                    </TableBody>
                  </Table>
                </>

                {medicarePartDPlan.drugCosts?.length > 0 && (
                  <PrescriptionDrugCost
                    drugCostDetails={drugCostDetails}
                    loading={isDrugCostDetailsLoading}
                    mNonSupplementPlan={mPartDPlanCard}
                  />
                )}
              </MoreDetailsWrapper>
            </>
          )}
          <PlanCardFooter
            isInGrid={isGoodChoice || isRecommended}
            planId={mPartDPlanCard.planId}
            id={mPartDPlanCard.id}
            forSpouse={userOption}
            spousePlanId={mPartDPlanCard.spousePlanId}
            favourite={
              userOption
                ? !isUndefined(mPartDPlanCard.spousePlanSaved)
                  ? mPartDPlanCard.spousePlanSaved
                  : mPartDPlanCard.planSaved
                : mPartDPlanCard.planSaved
            }
            handleRemoveFavourite={handleRemoveFavorite}
            showCompareCheckbox={showCompareCheckbox}
            planType={mPartDPlanCard.type}
            planName={mPartDPlanCard.name}
            handleAddToCompare={handleAddToCompare}
            isExpanded={isExpanded}
            handleExpandCard={handleExpandCard}
            medicareQuoteType={MedicareQuotesType.MEDICARE_NON_SUPPLEMENT}
          />
          {/* <ContentWrapper>
            <BenefitsWrapper>
              <BenefitContent>
                <h2>
                  Star Rating
                  {medicarePartDPlan.fmtRating === -1 && (
                    <Tooltip
                      title={
                        StringConstants.TOOLTIP_NON_ELIGIBLE_STAR_RATING_MESSAGE
                      }
                    />
                  )}
                </h2>
                <Rating
                  name='simple-controlled'
                  value={medicarePartDPlan.fmtRating || 0}
                  precision={0.5}
                  readOnly={true}
                  sx={{
                    marginBottom: '1.5rem',
                  }}
                />
                {medicarePartDPlan.contractYear === new Date().getFullYear() ||
                (medicarePartDPlan.contractYear ===
                  new Date().getFullYear() + 1 &&
                  customerStore.getCalculateDrugCostForAEP()) ? (
                  <>
                    <PrescriptionCoverage
                      prescribedDrugsCoverage={
                        medicarePartDPlan.drugInformations
                      }
                    />
                  </>
                ) : null}

                <ProviderCoverage
                  providerCoverages={medicarePartDPlan.providerCoverage}
                ></ProviderCoverage>

                {medicarePartDPlan.drugCosts?.length > 0 && (
                  <PharmacyCoverage
                    pharmacyCoverages={medicarePartDPlan.drugCosts}
                  ></PharmacyCoverage>
                )}
              </BenefitContent>
            </BenefitsWrapper>

            <PricingWrapper>
              <h2>Monthly Premium</h2>
              <p>{convertToUSCurrency(medicarePartDPlan.premium)}</p>
              <Button
                variant='contained'
                color='secondary'
                width='100%'
                onClick={() => {
                  setEnrollmentRequestPromptActive(true)
                }}
                disabled={enrollRequested}
                additionalPadding={true}
                description={`Attempted Enrollment in ${medicarePartDPlan.name}`}
                id={`enroll ${medicarePartDPlan.name}`}
              >
                {enrollBtnText}
              </Button>
              <div>
                {medicarePartDPlan.drugCosts?.length > 0 && (
                  <AnnualCostContainer>
                    <TextWrapper>
                      {convertToUSCurrency(medicarePartDPlan.drugCost)}
                    </TextWrapper>
                    <div style={{ width: '100%' }}>
                      <h2 style={{ paddingTop: '0px', fontSize: '14px' }}>
                        Estimated Annual Cost
                      </h2>
                    </div>
                    <div
                      style={{
                        paddingTop: '0px',
                        marginLeft: '0px!important',
                      }}
                    >
                      <Tooltip
                        title={StringConstants.TOOLTIP_ESTIMATED_ANNUAL_COST}
                        placement='top-start'
                      />
                    </div>
                  </AnnualCostContainer>
                )}
              </div>
            </PricingWrapper>
          </ContentWrapper>
          <FooterWrapper>
            <SavePlanIconButton
              planId={medicarePartDPlan.id}
              planType={MedicareQuotesType.MEDICARE_NON_SUPPLEMENT}
              saved={isFavorite}
              page='Dashboard Page'
              onAddSaved={onAddFavPlan}
              onRemoveSaved={onRemoveFavPlan}
            />
            {planBenefits.length > 0 && (
              <div>
                <Button
                  variant='text'
                  color='primary'
                  fontSize='14px'
                  borderRadius='4px'
                  bold={false}
                  endIcon={
                    isExpanded ||
                    medicarePartDPlan.name === customerStore.searchPlan ? (
                      <ArrowUp style={{ fontSize: '12px' }} />
                    ) : (
                      <ArrowDown style={{ fontSize: '12px' }} />
                    )
                  }
                  description={
                    isExpanded ||
                    medicarePartDPlan.name === customerStore.searchPlan
                      ? 'View Less Details'
                      : 'View More Details'
                  }
                  onClick={(e) => {
                    handleExpandCard(medicarePartDPlan.name)
                    fireEvent(
                      isExpanded ||
                        medicarePartDPlan.name === customerStore.searchPlan
                        ? 'viewLessCardDetails'
                        : 'viewMoreCardDetails',
                      e,
                      {
                        value:
                          !isExpanded ||
                          medicarePartDPlan.name !== customerStore.searchPlan
                            ? 'expanding'
                            : 'collapsing',
                        planId: medicarePartDPlan.id,
                        planType: MedicareQuotesType.MEDICARE_PRESCRIPTION,
                        description:
                          isExpanded ||
                          medicarePartDPlan.name === customerStore.searchPlan
                            ? 'View Less Card Details'
                            : 'View More Card Details',
                      }
                    )
                  }}
                >
                  <>
                    View{' '}
                    {isExpanded ||
                    medicarePartDPlan.name === customerStore.searchPlan
                      ? 'less'
                      : 'more'}{' '}
                    details
                  </>
                </Button>
              </div>
            )}
          </FooterWrapper>
          <MoreDetailsWrapper
            isExpanded={
              isExpanded || medicarePartDPlan.name === customerStore.searchPlan
            }
          >
            {drugHighlights.length > 0 && (
              <>
                <p>
                  Drug Coverage Highlights{' '}
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      navigate({
                        pathname: '/plan-details',
                        search: `id=${medicarePartDPlan.id}&&type=mpd`,
                      })
                      storeScrollPosition()
                    }}
                  >
                    View Complete Plan Details
                  </span>
                </p>
                <CardTable rows={drugHighlights} />
              </>
            )}

            {planBenefits?.length > 0 && (
              <>
                <p>
                  Plan Benefits{' '}
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                      fireEvent('viewCompletePlanDetails', e, {
                        description:
                          'View Complete Plan Details of Medicare Advantage Card',
                        planId: medicarePartDPlan.id,
                        planType: medicarePartDPlan.type,
                        planName: medicarePartDPlan.name,
                      })
                      navigate({
                        pathname: '/plan-details',
                        search: `id=${medicarePartDPlan.id}&&type=mpd`,
                      })
                      storeScrollPosition()
                    }}
                  >
                    View Complete Plan Details
                  </span>
                </p>
                <CardTable
                  rows={planBenefits.map((iterator) => ({
                    key: iterator.name,
                    value: iterator.covered ? 'Yes' : 'No',
                  }))}
                />
              </>
            )}

            {medicarePartDPlan.drugCosts?.length > 0 && (
              <>
                <p>
                  Drug costs for the year
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      navigate({
                        pathname: '/plan-details',
                        search: `id=${medicarePartDPlan.id}&&type=mpd`,
                      })
                      storeScrollPosition()
                    }}
                  >
                    View Complete Plan Details
                  </span>
                </p>

                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={1} style={{ textAlign: 'center' }}>
                        Pharmacy Name
                      </TableCell>
                      <TableCell colSpan={1} style={{ textAlign: 'center' }}>
                        Drug Price
                      </TableCell>
                    </TableRow>
                    {medicarePartDPlan.drugCosts.map((drugCost, index) => (
                      <TableRow key={drugCost.pharmacyId + '_' + index}>
                        <TableCell style={{ textAlign: 'center' }}>
                          {drugCost.pharmacyName}
                        </TableCell>
                        <TableCell style={{ textAlign: 'center' }}>
                          {drugCost.fmtDrugCost}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </>
            )}
            <FooterWrapper>
              <SavePlanIconButton
                planId={medicarePartDPlan.id}
                planType={MedicareQuotesType.MEDICARE_NON_SUPPLEMENT}
                saved={isFavorite}
                page='Dashboard Page'
                onAddSaved={onAddFavPlan}
                onRemoveSaved={onRemoveFavPlan}
              />
              <div>
                <Button
                  variant='text'
                  color='primary'
                  fontSize='14px'
                  bold={false}
                  endIcon={
                    isExpanded ||
                    medicarePartDPlan.name === customerStore.searchPlan ? (
                      <ArrowUp style={{ fontSize: '12px' }} />
                    ) : (
                      <ArrowDown style={{ fontSize: '12px' }} />
                    )
                  }
                  description={
                    isExpanded ||
                    medicarePartDPlan.name === customerStore.searchPlan
                      ? 'View Less Details'
                      : 'View More Details'
                  }
                  onClick={(e) => {
                    handleExpandCard(medicarePartDPlan.name)
                    fireEvent(
                      isExpanded ||
                        medicarePartDPlan.name === customerStore.searchPlan
                        ? 'viewLessCardDetails'
                        : 'viewMoreCardDetails',
                      e,
                      {
                        value:
                          !isExpanded ||
                          medicarePartDPlan.name !== customerStore.searchPlan
                            ? 'expanding'
                            : 'collapsing',
                        planId: medicarePartDPlan.id,
                        planType: MedicareQuotesType.MEDICARE_PRESCRIPTION,
                        description: isExpanded
                          ? 'View Less Card Details'
                          : 'View More Card Details',
                      }
                    )
                  }}
                >
                  <>
                    View{' '}
                    {isExpanded ||
                    medicarePartDPlan.name === customerStore.searchPlan
                      ? 'less'
                      : 'more'}{' '}
                    details
                  </>
                </Button>
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={() => {
                    setEnrollmentRequestPromptActive(true)
                  }}
                  disabled={enrollRequested}
                  additionalPadding={true}
                  description={`Attempted Enrollment in ${medicarePartDPlan.name}`}
                  id={`enroll ${medicarePartDPlan.name}`}
                >
                  {enrollBtnText}
                </Button>
              </div>
            </FooterWrapper>
          </MoreDetailsWrapper> */}
          {/* </Wrapper> */}
          {/* )} */}
        </Container>
      )}
    </>
  )
}

export default observer(MedicarePrescriptionCard)
