import React, { useState } from 'react'
import { cloneDeep, forEach, isEmpty, isUndefined } from 'lodash'
import { getMedigapChartDetails, getSavedPlans } from '../../service/quotes'

import { ActionContext } from '../../cra'
import AddSpouseConfirmationModal from '../../components/DashboardPage/Modals/AddSpouseConfirmationModal'
import BouncingDotsLoader from '../../components/common/Loading/BouncingDotsLoader'
import CompletePlanDetailsPage from '../CompletePlanDetailsPage/CompletePlanDetailsPage'
import InPageHeader from '../../components/common/InPageHeader/InPageHeader.component'
import LayoutComponent from '../../components/common/Layout/Layout.component'
import { MNonSupplementPlan } from '../../interface/quotes/mNonSupplementPlans/MNonSupplementPlan'
import { MSPlan } from '../../interface/quotes/mSupplementPlans/MSPlan'
import { MedicalCost } from '../../interface/quotes/mNonSupplementPlans/MedicalCost'
import MedicareAdvantageCard from '../../components/DashboardPage/PageSections/MedicareAdvantage/MedicareAdvantageCard.component'
import MedicarePrescriptionCardComponent from '../../components/DashboardPage/PageSections/MedicarePrescription/MedicarePrescriptionCard.component'
import MedicareSupplementCard from '../../components/DashboardPage/PageSections/MedicareSupplement/MedicareSupplementCard'
import ModalComponent from '../../components/common/Modal/Modal.component'
import NoPlanFoundCard from '../../components/DashboardPage/NoPlanFoundCard'
import PrimarySpouseToggle from '../../components/DashboardPage/Common/PrimarySpouseToggle'
import SecureComponent from '../common/SecureComponent'
import { SnackbarTypes } from '../../enums/SnackbarTypesEnum'
import StringConstants from '../../constants/StringConstants'
import ThankYouModal from '../../components/DashboardPage/Modals/ThankYouModal'
import { addSpouseDetails } from '../../service/customer'
import { convertToUSCurrency } from '../../utils/CurrencyConverterUtil'
import customerStore from '../../datastore/CustomerStore'
import { getApiErrorMessage } from '../../utils/StringUtils'
import { getPlanYear } from '../../utils/CommonUtils'
import { observer } from 'mobx-react-lite'
import { pollQuotesPullStatus } from '../../utils/quotesPullStatus'
import snackbarStore from '../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../global/theme'
import { useLocation } from 'react-router-dom'

const Container = styled.div`
  background-color: ${theme.colors.white};
  min-height: 100vh;
`
const ContentWrapper = styled.div`
  padding: 50px;
  max-width: 1100px;
  margin: 0px auto;

  .user-toggle {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }

  @media screen and (max-width: 600px) {
    padding: 20px;
    .user-toggle {
      justify-content: center;
    }
  }
`

const AddressWrapper = styled.div`
  text-align: center;
  p {
    color: ${theme.colors.textDark};
    font-weight: bold;
  }
  .address {
    font-size: 14px;
    font-weight: 600;
    color: ${theme.colors.textGrey};
    span {
      padding-left: 10px;
      color: ${theme.colors.primary};
      font-style: italic;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`

const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export interface SavedPlanResponse {
  convertedMAPlans: MNonSupplementPlan[]
  convertedMPartDPlans: MNonSupplementPlan[]
  convertedMSPlans: MSPlan[]
}

const SavedPlansPage = () => {
  const [loading, setLoading] = React.useState(true)
  const [currentTab, setCurrentTab] = React.useState(0)
  const handleTabChange = (e: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue)
  }

  const [maPlans, setMAPlans] = React.useState<MNonSupplementPlan[]>([])
  const [mPartDPlans, setMPartDPlans] = React.useState<MNonSupplementPlan[]>([])
  const [msPlans, setMSPlans] = React.useState<MSPlan[]>([])

  const [thankYouModalActive, setThankYouModalActive] = React.useState(false)

  const [fetchPlans, setFetchPlans] = React.useState(true)
  const [medigapChartDetails, setMedigapChartDetails] = React.useState<any>({})
  const [userOption, setUserOption] = useState<boolean>(false)

  const [completePlanDetailModalActive, setCompletePlanDetailModalActive] =
    React.useState<boolean>(false)
  const [completeDetailPlanId, setCompleteDetailPlanId] =
    React.useState<string>('')

  const [showAddSpouseConfirmation, setShowAddSpouseConfirmation] =
    useState<boolean>(false)
  const [addSpouseDetailsLoader, setAddSpouseDetailsLoader] =
    useState<boolean>(false)

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    getMedigapChartDetails()
      .then((response) => {
        setMedigapChartDetails(response)
      })
      .catch(() => {
        snackbarStore.set({
          snackbarOpen: true,
          snackbarMessage: getApiErrorMessage(
            'retrieve medigap chart details.'
          ),
          snackbarType: 'error',
        })
      })
  }, [])

  const handleOnEnroll = () => {
    setThankYouModalActive(true)
  }

  const handleOnReload = () => {
    setFetchPlans(true)
  }

  const { trackCurrentPage } = React.useContext(ActionContext)
  const type: any = useLocation().state

  const getPlanCards = (forSpouse: boolean) => {
    setLoading(true)
    getSavedPlans(forSpouse)
      .then((savedPlans: SavedPlanResponse | boolean) => {
        if (typeof savedPlans === 'boolean') {
          setMAPlans([])
          setMPartDPlans([])
          setMSPlans([])
          snackbarStore.set({
            snackbarMessage: 'No Plans have been saved',
            snackbarOpen: true,
            snackbarType: 'success',
          })
        } else {
          let convertedMAPlans = savedPlans.convertedMAPlans
          let convertedMPartPlans = savedPlans.convertedMPartDPlans
          if (convertedMAPlans.length > 0) {
            forEach(convertedMAPlans, (medicareAdvantagePlan, i) => {
              const additionalCost: {
                name: string
                value: string
                toolTip: string
              }[] = [
                {
                  name: 'Estimated Annual Cost',
                  value: convertToUSCurrency(medicareAdvantagePlan.drugCost, 0),
                  toolTip:
                    StringConstants.TOOLTIP_ESTIMATED_ANNUAL_COST_WITH_DRUG_COVERAGE,
                },
              ]
              medicareAdvantagePlan.medicalCosts.forEach(
                (cost: MedicalCost) => {
                  let name = ''
                  let tootTip = ''
                  let flag = false
                  switch (cost.name) {
                    case 'Doctor Office Visits':
                      flag = true
                      name = 'Primary Doctor'
                      tootTip =
                        StringConstants.TOOLTIP_ESTIMATED_ANNUAL_COST_WITH_DRUG_COVERAGE
                      break
                    case 'Doctor Specialty Visit':
                      flag = true
                      name = 'Most Specialist(s)'
                      tootTip =
                        StringConstants.TOOLTIP_ESTIMATED_ANNUAL_COST_WITH_DRUG_COVERAGE
                      break
                    case 'Inpatient Hospital Care':
                      flag = true
                      name = 'Hospital Inpatient'
                      tootTip =
                        StringConstants.TOOLTIP_ESTIMATED_ANNUAL_COST_WITH_DRUG_COVERAGE
                      break
                  }

                  if (flag) {
                    const { minCopay, minCoins } = cost.costs[0]
                    if (!isUndefined(minCopay)) {
                      additionalCost.push({
                        name: `${name} Co-Pay`,
                        value: convertToUSCurrency(minCopay, 0),
                        toolTip: tootTip,
                      })
                    } else if (!isUndefined(minCoins)) {
                      additionalCost.push({
                        name: `${name} Co-Insurance`,
                        value: `${minCoins}%`,
                        toolTip: tootTip,
                      })
                    }
                  }
                }
              )

              medicareAdvantagePlan['additionalCosts'] = additionalCost
              convertedMAPlans[i] = cloneDeep(medicareAdvantagePlan)
            })
          }
          setMAPlans(convertedMAPlans)
          if (convertedMPartPlans.length > 0) {
            forEach(convertedMPartPlans, (medicarePartDPlan, i) => {
              const additionalCost: {
                name: string
                value: string
                toolTip: string
              }[] = [
                {
                  name: 'Estimated Annual Cost',
                  value: convertToUSCurrency(medicarePartDPlan.drugCost, 0),
                  toolTip:
                    StringConstants.TOOLTIP_ESTIMATED_ANNUAL_COST_WITH_DRUG_COVERAGE,
                },
                {
                  name: 'Drug Deductible',
                  value: convertToUSCurrency(medicarePartDPlan.deductible, 2),
                  toolTip: StringConstants.TOOLTIP_MSG_PDP_PLAN_DEDUCTIBLE,
                },
              ]
              medicarePartDPlan['additionalCosts'] = additionalCost
              convertedMPartPlans[i] = cloneDeep(medicarePartDPlan)
            })
          }
          setMPartDPlans(convertedMPartPlans)
          setMSPlans(savedPlans.convertedMSPlans)
          if (savedPlans.convertedMSPlans.length > 0) {
            setCurrentTab(1)
          } else if (savedPlans.convertedMAPlans.length > 0) {
            setCurrentTab(0)
          } else if (savedPlans.convertedMPartDPlans.length > 0) {
            setCurrentTab(2)
          }
        }
      })
      .catch((err) => {
        snackbarStore.set({
          snackbarMessage: getApiErrorMessage('fetch saved plans'),
          snackbarOpen: true,
          snackbarType: 'error',
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  React.useEffect(() => {
    const currentPage =
      currentTab === 0
        ? 'Saved Plans - Medicare Advantage'
        : currentTab === 1
        ? 'Saved Plans - Medicare Supplements'
        : currentTab === 2
        ? 'Saved Plans - Medicare Prescription Drugs'
        : ''
    trackCurrentPage(currentPage)
  }, [currentTab, trackCurrentPage])

  React.useEffect(() => {
    if (fetchPlans) {
      getPlanCards(userOption)
      setFetchPlans(false)
    }
  }, [fetchPlans])

  // these functions needs to be inside the card component as it's in other 2 plan card.
  // const getMedicareSupplementPlans = (
  //   msPlansFiltersPayload?: MSPlanRequestFilter
  // ) => {
  //   ApiManager.makeApiCallWithAuthentication(
  //     UrlConstants.GET_MEDICARE_SUPPLEMENT_PLANS.USECASE,
  //     HttpMethods.POST_METHOD,
  //     msPlansFiltersPayload ? msPlansFiltersPayload : {}
  //   )
  //     .then((msPlansResponse) => {
  //       if (msPlansResponse.status === 200) {
  //         if (msPlansResponse.data && msPlansResponse.data.data) {
  //           const convertedMSPlans: MSPlan[] = []
  //           for (let msPlan of msPlansResponse.data.data) {
  //             convertedMSPlans.push(msPlan)
  //           }
  //           setMSPlans(convertedMSPlans)
  //         }
  //       } else {
  //         setMSPlans([])
  //         snackbarStore.set({
  //           snackbarOpen: true,
  //           snackbarMessage: msPlansResponse.data.message,
  //           snackbarType: 'error',
  //         })
  //       }
  //     })
  //     .catch((err: Error) => {
  //       snackbarStore.set({
  //         snackbarOpen: true,
  //         snackbarMessage: err.message,
  //         snackbarType: 'error',
  //       })
  //     })
  // }
  const handleAddSpouseDetails = (
    gender: string,
    dob: string,
    fName: string,
    lName: string
  ) => {
    setLoading(true)
    addSpouseDetails(fName, lName, dob, gender)
      .then((response) => {
        pollQuotesPullStatus(getPlanYear())
        snackbarStore.set({
          snackbarMessage: StringConstants.SNACKBAR_SAVE_SUCCESS_MSG,
          snackbarOpen: true,
          snackbarType: SnackbarTypes.SUCCESS,
        })
        customerStore.setSpouseBasicDetails({
          spouseDateOfBirth: dob,
          spouseFirstName: fName,
          spouseGender: gender,
          spouseLastName: lName,
          spouseOptIn: true,
        })
        customerStore.setForSpouse(true)
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false)
      })
  }

  const handleUserChange = () => {
    const forSpouse = userOption
    setUserOption(!userOption)
    getPlanCards(!forSpouse)
  }

  return (
    <LayoutComponent>
      <Container>
        {showAddSpouseConfirmation && (
          <ModalComponent
            width={700}
            setOpen={() => {
              setShowAddSpouseConfirmation(!showAddSpouseConfirmation)
            }}
            description='Popup - Request for Enrollment Confirmation'
            hideCloseButton={false}
          >
            <AddSpouseConfirmationModal
              loading={addSpouseDetailsLoader}
              onConfirm={(
                dob: string,
                gender: string,
                fName: string,
                lName: string
              ) => {
                // setShowAddSpouseConfirmation(!showAddSpouseConfirmation)
                handleAddSpouseDetails(gender, dob, fName, lName)
              }}
              onReject={() => {
                setShowAddSpouseConfirmation(!showAddSpouseConfirmation)
              }}
            />
          </ModalComponent>
        )}
        {thankYouModalActive && (
          <ModalComponent setOpen={() => setThankYouModalActive(false)}>
            <ThankYouModal setOpen={() => setThankYouModalActive(false)} />
          </ModalComponent>
        )}

        {completePlanDetailModalActive && !isEmpty(completeDetailPlanId) ? (
          <ModalComponent
            width={'inherit'}
            setOpen={() => setCompletePlanDetailModalActive(false)}
          >
            <CompletePlanDetailsPage
              medicarePlanId={completeDetailPlanId}
              medicarePlanType={
                currentTab === 0 ? 'ma' : currentTab === 1 ? 'ms' : 'mpd'
              }
            />
          </ModalComponent>
        ) : (
          <></>
        )}

        <InPageHeader
          isSidebarShown={false}
          onChange={handleTabChange}
          currentTab={currentTab}
          tabLabels={[
            {
              heading: 'Medicare Advantage (Part C)',
              subHeading: `${maPlans && maPlans.length} Plans Available`,
            },
            {
              heading: 'Medicare Supplement (Medigap)',
              subHeading: `${msPlans && msPlans.length} Plans Available`,
            },
            {
              heading: 'Medicare Prescription Drugs (Part D)',
              subHeading: `${
                mPartDPlans && mPartDPlans.length
              } Plans Available`,
            },
          ]}
          heading='Your Saved Plans'
          isSavedPlans={true}
          type={type}
        />
        {loading ? (
          <BouncingDotsLoader />
        ) : (
          <ContentWrapper>
            <AddressWrapper>
              <p>Showing saved plans for</p>
              <div className='address'>
                {customerStore.getPostalCodeAndCounty()}
              </div>
            </AddressWrapper>

            <div
              // style={{
              //   display: 'flex',
              //   justifyContent: 'flex-end',
              // }}
              className='user-toggle'
            >
              <PrimarySpouseToggle
                handleUserChange={handleUserChange}
                forSpouse={userOption}
              />
            </div>

            {currentTab === 0 &&
              maPlans &&
              (maPlans.length === 0 ? (
                <NoPlanFoundCard />
              ) : (
                <CardsWrapper>
                  <>
                    {maPlans.map((card, index) => {
                      const savedCardDetails = card
                      // savedCardDetails.planSaved = true
                      return (
                        <MedicareAdvantageCard
                          key={index}
                          setCompletePlanDetailModalActive={
                            setCompletePlanDetailModalActive
                          }
                          setCompleteDetailPlanId={setCompleteDetailPlanId}
                          medicareAdvantagePlan={savedCardDetails}
                          onEnroll={handleOnEnroll}
                          handleRemoveFavorite={() => getPlanCards(userOption)}
                          showCompareCheckbox={false}
                          userOption={userOption}
                          handleSpouseRemove={() => {
                            console.log('Temporary')
                          }}
                          setShowAddSpouseConfirmation={
                            setShowAddSpouseConfirmation
                          }
                        />
                      )
                    })}
                  </>
                </CardsWrapper>
              ))}
            {currentTab === 1 &&
              msPlans &&
              (msPlans.length === 0 ? (
                <NoPlanFoundCard />
              ) : (
                <CardsWrapper>
                  <>
                    {msPlans.map((card, index) => {
                      const savedCardDetails = card
                      // savedCardDetails.planSaved = true
                      return (
                        <MedicareSupplementCard
                          setCompletePlanDetailModalActive={
                            setCompletePlanDetailModalActive
                          }
                          setCompleteDetailPlanId={setCompleteDetailPlanId}
                          key={index}
                          msPlan={savedCardDetails}
                          onEnroll={handleOnEnroll}
                          handleRemoveFavorite={() => getPlanCards(userOption)}
                          showCompareCheckbox={false}
                          onReload={handleOnReload}
                          medigapChartDetails={medigapChartDetails}
                          userOption={userOption}
                          handleSpouseRemove={() => {}}
                          setShowAddSpouseConfirmation={
                            setShowAddSpouseConfirmation
                          }
                        />
                      )
                    })}
                  </>
                </CardsWrapper>
              ))}
            {currentTab === 2 &&
              mPartDPlans &&
              (mPartDPlans.length === 0 ? (
                <NoPlanFoundCard />
              ) : (
                <CardsWrapper>
                  {mPartDPlans.map((card, index) => {
                    const savedCardDetails = card
                    // savedCardDetails.planSaved = true
                    return (
                      <MedicarePrescriptionCardComponent
                        key={index}
                        setCompletePlanDetailModalActive={
                          setCompletePlanDetailModalActive
                        }
                        setCompleteDetailPlanId={setCompleteDetailPlanId}
                        medicarePartDPlan={savedCardDetails}
                        onEnroll={handleOnEnroll}
                        handleRemoveFavorite={() => getPlanCards(userOption)}
                        showCompareCheckbox={false}
                        userOption={userOption}
                        handleSpouseRemove={() => {}}
                        setShowAddSpouseConfirmation={
                          setShowAddSpouseConfirmation
                        }
                      />
                    )
                  })}
                </CardsWrapper>
              ))}
          </ContentWrapper>
        )}
      </Container>
    </LayoutComponent>
  )
}

export default SecureComponent(observer(SavedPlansPage))
