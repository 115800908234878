import ApiManager from '../../api/ApiManager'
import CarouselHeader from '../../components/ComparePlansPage/CarouselHeader'
import { HttpMethods } from '../../enums/HttpMethodsEnum'
import LayoutComponent from '../../components/common/Layout/Layout.component'
import { MNSCompletePlan } from '../../interface/quotes/mNonSupplementPlans/MNSCompletePlan'
import { MSPlan } from '../../interface/quotes/mSupplementPlans/MSPlan'
import { MSPlanGenericDetails } from '../../interface/quotes/mSupplementPlans/MSPlanGenericDetails'
import MedicareAdvantageTable from '../../components/ComparePlansPage/Tables/MedicareAdvantageTable'
import MedicarePartDTable from '../../components/ComparePlansPage/Tables/MedicarePartDTable'
import { MedicareQuotesType } from '../../enums/MedicareQuoteTypeEnum'
import MedicareSupplementTable from '../../components/ComparePlansPage/Tables/MedicareSupplementTable'
import React from 'react'
import SecureComponent from '../common/SecureComponent'
import SkeletonCard from '../../components/DashboardPage/SkeletonCard'
import StringConstants from '../../constants/StringConstants'
import { Switch } from '@mui/material'
import Tooltip from '../../components/common/Tooltip/Tooltip.component'
import UrlConstants from '../../constants/UrlConstants'
import { getApiErrorMessage } from '../../utils/StringUtils'
import { isUndefined } from 'lodash'
import { observer } from 'mobx-react'
import snackbarStore from '../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../global/theme'
import { useNavigate } from 'react-router-dom'
import userActionStore from '../../datastore/UserActionStore'

const Container = styled.div`
  padding-bottom: 50px;
  min-height: 100vh;
  background-color: ${theme.colors.white};
`

const CarouselHeaderWrapper = styled.div`
  overflow: hidden;
  position: relative;
`

const HeaderWrapper = styled.div`
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
  top: 85px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
  z-index: 1;
  @media screen and (max-width: 600px) {
    top: 84px;
  }
`

const CompareDifferenceToggleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 20px;

  h3 {
    font-size: 18px;
    color: ${theme.colors.primary};
  }

  p {
    .label {
      color: ${theme.colors.textGrey};
    }
  }
`

const ComparePlansPage = () => {
  const [cardDetailsInOrder, setCardDetailsInOrder] = React.useState<
    (MSPlan | undefined)[]
  >([])
  const [mnsCardDetailsInOrder, setMNSCardDetailsInOrder] =
    React.useState<MNSCompletePlan>()

  const [state, setState] = React.useState({
    loading: false,
  })

  const [compareDifferences, setCompareDifferences] = React.useState(false)

  const targetScroll = (event: any) => {
    const selectedTarget = event.target
    const scrollLeft = selectedTarget.scrollLeft

    const targets: any = document.getElementsByClassName('scrollTarget')

    for (let i = 0; i < targets.length; i++) {
      if (targets[i] !== selectedTarget) {
        // Force layout recalculation and immediate update
        targets[i].style.scrollBehavior = 'auto'
        targets[i].scrollLeft = scrollLeft
      }
    }
  }

  React.useEffect(() => {
    window.scroll(0, 0)
    if (userActionStore.comparePlanCards.compareCardsData.length !== 0) {
      setState({
        ...state,
        loading: true,
      })
      const medicareQuoteType = userActionStore.comparePlanCards.planType
      if (medicareQuoteType !== MedicareQuotesType.MEDICARE_SUPPLEMENT) {
        const planIds = userActionStore
          .getComparePlanCards()
          .compareCardsData.map((iterator) => iterator.sunfirePlanId)

        ApiManager.makeApiCallWithAuthentication(
          UrlConstants.RETRIEVE_MNS_COMPARE_PLAN_DETAILS.USECASE,
          HttpMethods.POST_METHOD,
          {
            planIds,
          }
        )
          .then((response) => {
            const responseData = response.data.data
            if (!isUndefined(responseData))
              setMNSCardDetailsInOrder(responseData ? responseData : {})
            else throw new Error('Response is undefined')
          })
          .catch((err) => {
            snackbarStore.set({
              snackbarMessage: getApiErrorMessage('fetch MNS plan details'),
              snackbarOpen: true,
              snackbarType: 'error',
            })
          })
          .finally(() => {
            setState({
              ...state,
              loading: false,
            })
          })
      } else {
        const planIds = userActionStore
          .getComparePlanCards()
          .compareCardsData.map((iterator) => iterator.id)
        ApiManager.makeApiCallWithAuthentication(
          UrlConstants.RETRIEVE_COMPARE_PLAN_DETAILS.USECASE,
          HttpMethods.POST_METHOD,
          {
            medicareQuoteType,
            forSpouse: false,
            planIds,
          }
        )
          .then((response) => {
            // Get plan details in the same order as userActionStore.comparePlanCards
            const responseData: {
              msPlan: MSPlan
              msPlanGenericDetails: MSPlanGenericDetails[]
            }[] = response.data.data

            if (!isUndefined(responseData)) {
              const cardsArray =
                userActionStore.comparePlanCards.compareCardsData.map(
                  (iterator) => {
                    const card = responseData.find(
                      (itr) => itr.msPlan.id === iterator.id
                    )
                    if (card)
                      card.msPlan.msPlanGenericDetails =
                        card?.msPlanGenericDetails

                    return card?.msPlan
                  }
                )
              setCardDetailsInOrder(cardsArray ? cardsArray : [])
            } else throw new Error('Response is undefined')
          })
          .catch((err) => {
            snackbarStore.set({
              snackbarMessage: getApiErrorMessage('fetch plan details'),
              snackbarOpen: true,
              snackbarType: 'error',
            })
          })
          .finally(() => {
            setState({
              ...state,
              loading: false,
            })
          })
      }
    }
  }, [userActionStore.comparePlanCards.compareCardsData])

  const navigate = useNavigate()

  const handleBack = () => {
    navigate(-1)
  }

  return (
    <LayoutComponent>
      <Container>
        <HeaderWrapper>
          <CarouselHeaderWrapper>
            <CarouselHeader
              syncScroll={targetScroll}
              className='scrollSource'
              handleBack={handleBack}
              loading={state.loading}
              cardDetailsInOrder={
                userActionStore.comparePlanCards.planType ===
                MedicareQuotesType.MEDICARE_SUPPLEMENT
                  ? cardDetailsInOrder
                  : mnsCardDetailsInOrder
              }
              planType={userActionStore.comparePlanCards.planType}
            />
          </CarouselHeaderWrapper>
          {state.loading && (
            <div style={{ padding: '20px' }}>
              <SkeletonCard quantity={20} />
            </div>
          )}
        </HeaderWrapper>

        <CompareDifferenceToggleWrapper>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <h3>Compare Differences</h3>{' '}
            <Tooltip
              title={StringConstants.TOOLTIP_COMPARE_DIFFERENCE}
              content={'?'}
            />
          </div>
          <p>
            <span className='label'>No</span>
            <Switch
              checked={compareDifferences}
              onChange={(e) => {
                setCompareDifferences(e.target.checked)
              }}
            />
            <span className='label'>Yes</span>
          </p>
        </CompareDifferenceToggleWrapper>

        {!state.loading &&
          userActionStore.comparePlanCards.planType ===
            MedicareQuotesType.MEDICARE_ADVANTAGE && (
            <MedicareAdvantageTable
              cardDetailsInOrder={mnsCardDetailsInOrder as MNSCompletePlan}
              compareDifferences={compareDifferences}
              syncScroll={targetScroll}
            />
          )}

        {!state.loading &&
          userActionStore.comparePlanCards.planType ===
            MedicareQuotesType.MEDICARE_SUPPLEMENT && (
            <MedicareSupplementTable
              compareDifferences={compareDifferences}
              cardDetailsInOrder={cardDetailsInOrder as (MSPlan | undefined)[]}
              syncScroll={targetScroll}
            />
          )}

        {!state.loading &&
          userActionStore.comparePlanCards.planType ===
            MedicareQuotesType.MEDICARE_PRESCRIPTION && (
            <MedicarePartDTable
              compareDifferences={compareDifferences}
              cardDetailsInOrder={mnsCardDetailsInOrder as MNSCompletePlan}
              syncScroll={targetScroll}
            />
          )}
      </Container>
    </LayoutComponent>
  )
}

export default SecureComponent(observer(ComparePlansPage))
