import Button from '../../../../common/Button/Button.component'
import React from 'react'
import customerStore from '../../../../../datastore/CustomerStore'
import theme from '../../../../../global/theme'

interface FallbackStepProps {
  setStep: React.Dispatch<React.SetStateAction<number>>
  completePrescriptionStep: () => void
  buttonLoading: boolean
}

const FallbackStep: React.FC<FallbackStepProps> = ({
  setStep,
  completePrescriptionStep,
  buttonLoading,
}) => {
  return (
    <div>
      <h3
        style={{
          fontSize: '20px',
          color: theme.colors.primary,
          paddingBottom: '10px',
        }}
      >
        Would you like to manually input your prescriptions?
      </h3>
      <p
        style={{
          fontWeight: 700,
          color: theme.colors.textGrey,
          maxWidth: '650px',
          paddingBottom: '10px',
        }}
      >
        {customerStore.getIsSsnProvided()
          ? 'We have nothing on file for that Social Security Number (SSN). You will need to manually input your prescriptions.'
          : 'Something went wrong while fetching your prescriptions automatically. You will need to manually input your prescriptions.'}
      </p>
      <p
        style={{
          fontSize: '14px',
          color: theme.colors.textGrey,
          paddingBottom: '10px',
        }}
      >
        Click “Yes” to confirm!
      </p>
      <div style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
        <Button
          width='100px'
          color='secondary'
          variant='contained'
          onClick={() => setStep(6)}
        >
          Yes
        </Button>
        <Button
          loading={buttonLoading}
          width='100px'
          onClick={() => {
            completePrescriptionStep()
          }}
        >
          No
        </Button>
      </div>
    </div>
  )
}

export default FallbackStep
