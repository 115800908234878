import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from 'react-icons/md'
import { isEmpty, isUndefined } from 'lodash'
import { useEffect, useState } from 'react'

import Button from '../../common/Button/Button.component'
import CarrierLogo from '../../common/CarrierLogo'
import CloseIconButton from '../../common/Button/CloseIconButton.component'
import { Divider } from '@mui/material'
import RouteConstants from '../../../constants/RouteConstants'
import StringConstants from '../../../constants/StringConstants'
import customerStore from '../../../datastore/CustomerStore'
import { fireEvent } from '../../../cra'
import { observer } from 'mobx-react'
import snackbarStore from '../../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../../global/theme'
import { useNavigate } from 'react-router-dom'
import userActionStore from '../../../datastore/UserActionStore'

const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 20px;
  background-color: ${theme.colors.primary};
  width: 100%;
  display: flex;
  z-index: 1002;
  gap: 50px;

  @media screen and (max-width: 900px) {
    flex-direction: column-reverse;
    justify-content: space-between;
    gap: 20px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  button:nth-of-type(1).Mui-disabled {
    background-color: ${theme.colors.darkGrey};
    color: ${theme.colors.white};
  }

  @media screen and (max-width: 900px) {
    font-size: 10px;
    flex-direction: row;
    gap: 5px;
  }
`

const SliderContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 15px;

  @media screen and (max-width: 900px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const Slide = styled.div<{ planTypeLength: number }>`
  background-color: white;
  overflow: hidden;
  border-radius: 16px;
  max-width: 512px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-grow: 1;

  .content {
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
    img {
      justify-content: flex-start;
      align-self: flex-start;
      max-width: 135px;
      max-height: 30px;
      padding-bottom: 6px;
    }
    h3 {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 700;
      color: ${theme.colors.textDark};
    }
    div {
      display: flex;
      justify-content: space-between;
      font-size: 16px;

      p {
        flex: 1;
        color: ${theme.colors.textDark};
        display: grid;
        width: 100%;
        span {
          font-weight: 700;
          white-space: nowrap;
        }
      }
      .border {
        margin: 0px 10px;
        width: 1px;
        height: 100%;
        background-color: ${theme.colors.borderColor};
      }
    }
  }
  .remove {
    display: block;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 900px) {
    .content {
      h3 {
        font-size: 12px;
      }
      div {
        font-size: 12px;
      }
    }
  }
`

const SubDetails = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #333;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  div {
    align-items: center;
  }

  .heading {
    margin-right: 5px;
    font-size: 12px;
    font-weight: 600;
    color: #666666;
  }

  .price {
    margin-left: 5px;
    font-size: 12px;
    font-weight: 700;
    color: #2f3032;
  }
`

const handleRemoveCompareCard = (id: string) => {
  userActionStore.removeCard(id)
}

const BottomAddToCompare = () => {
  const navigate = useNavigate()
  const [showDropdown, setShowDropdown] = useState(false)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 900)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  const handleCompare = () => {
    if (userActionStore.comparePlanCards.compareCardsData.length <= 1) {
      snackbarStore.set({
        snackbarMessage: 'A minimum of 2 plans is required for comparison',
        snackbarOpen: true,
        snackbarType: 'error',
      })
    } else {
      fireEvent(
        'comparePlans',
        {},
        {
          planList: userActionStore.comparePlanCards.compareCardsData.map(
            (plan) => {
              return {
                planId: plan.id,
                planName: plan.name,
                planType: plan.planType,
              }
            }
          ),
        }
      )
      navigate(RouteConstants.COMPARE_PLANS)
    }
  }

  const handleCancel = () => {
    userActionStore.resetComparePlanCards()
  }

  return (
    <Container>
      <ButtonContainer>
        {isMobile && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {userActionStore.comparePlanCards.compareCardsData.length === 1 && (
              <p style={{ color: 'white' }}>
                {StringConstants.MOBILE_CAROUSEL_SINGLE_PLAN_ADDITION}
              </p>
            )}
            {userActionStore.comparePlanCards.compareCardsData.length > 1 && (
              <p
                style={{ color: 'white', whiteSpace: 'nowrap' }}
              >{`${userActionStore.comparePlanCards.compareCardsData.length} plans added`}</p>
            )}
            {userActionStore.comparePlanCards.compareCardsData.length > 0 && (
              <div
                onClick={() => {
                  setShowDropdown(!showDropdown)
                  customerStore.setIsComparePlansCarouselOpen(
                    !customerStore.isComparePlansCarouselOpen
                  )
                }}
                style={{
                  cursor: 'pointer',
                  marginTop: '3px',
                }}
              >
                {showDropdown ? (
                  <MdOutlineArrowDropDown
                    style={{ color: 'white', fontSize: '20px' }}
                  />
                ) : (
                  <MdOutlineArrowDropUp
                    style={{ color: 'white', fontSize: '20px' }}
                  />
                )}
              </div>
            )}
          </div>
        )}
        <Button
          variant='contained'
          color='secondary'
          width='120px'
          onClick={handleCompare}
          description='Confirmed Comparison of Added Plans'
          id={
            `Compare ` +
            userActionStore.comparePlanCards.compareCardsData
              .map((iterator) => iterator.name)
              .join(', ')
          }
          disabled={
            userActionStore.comparePlanCards.compareCardsData.length <= 1
          }
        >
          Compare
        </Button>
        <Button
          width='120px'
          variant='contained'
          color='contrastPrimary'
          onClick={handleCancel}
          description='Cancelled Comparison of Added Plans'
        >
          Cancel
        </Button>
      </ButtonContainer>
      {(showDropdown || !isMobile) && (
        <SliderContainer>
          {userActionStore.comparePlanCards.compareCardsData.map((iterator) => (
            <Slide
              key={Math.random()}
              planTypeLength={iterator.planType.length}
            >
              <div className='content'>
                <div>
                  <CarrierLogo carrier={iterator.carrier} />
                </div>

                {window.innerWidth <= 500 && (
                  <h3>
                    {iterator.name.length > 28
                      ? iterator.name.substring(0, 25) + '...'
                      : iterator.name}
                  </h3>
                )}
                {window.innerWidth > 500 && (
                  <h3>
                    {iterator.name.length > 52
                      ? iterator.name.substring(0, 49) + '...'
                      : iterator.name}
                  </h3>
                )}
                <div>
                  <SubDetails>
                    {iterator.medicareSupplementPlanType && (
                      <>
                        <div>
                          <span className='heading'> Plan Type: </span>
                          <span>:</span>
                          <b className='price'>
                            {iterator.medicareSupplementPlanType}
                          </b>
                        </div>
                        <Divider
                          orientation='vertical'
                          flexItem
                          style={{ margin: '0px 10px' }}
                        />
                      </>
                    )}
                    {(iterator.premium || iterator.premium === 0) && (
                      <div>
                        <span className='heading'>Monthly Premium</span>
                        <span>:</span>
                        <b className='price'>${iterator.premium.toFixed(2)}</b>
                      </div>
                    )}

                    {!isUndefined(iterator.moop) && (
                      <>
                        <Divider
                          orientation='vertical'
                          flexItem
                          style={{ margin: '0px 10px' }}
                        />
                        <div>
                          <span className='heading'>
                            Maximum out of pocket:
                          </span>
                          <span>:</span>
                          <b className='price'>${iterator.moop?.toFixed(2)}</b>
                        </div>
                      </>
                    )}
                  </SubDetails>
                </div>
              </div>
              <div className='remove'>
                <CloseIconButton
                  onClick={(e) => {
                    handleRemoveCompareCard(iterator.id)
                    fireEvent('removeAddedPlanForComparison', e, {
                      label: 'X',
                      value: 'removed',
                      description: `Removed Comparison Card -  ${iterator.name}`,
                      planId: iterator.id,
                      planType: iterator.planType,
                      planName: iterator.name,
                    })
                  }}
                />
              </div>
            </Slide>
          ))}
        </SliderContainer>
      )}
    </Container>
  )
}

export default observer(BottomAddToCompare)
