import Button from '../../common/Button/Button.component'
import { DrugDataProps } from '../../InfoStepsPage/StepperComponents/Prescriptions/SearchPromptInfoSteps.Component'
import { FaRegCheckCircle } from 'react-icons/fa'
import ModalComponent from '../../common/Modal/Modal.component'
import { QuotesRefreshContext } from '../../../context/QuotesRefreshContext'
import React from 'react'
import StringConstants from '../../../constants/StringConstants'
import { isEmpty } from 'lodash'

interface PrescriptionSavedPrompt {
  setShowSuccessfulPopUp: (value: boolean) => void
  drug: { name: string; quantity: number; frequency: string }
  setUpdatedDrug: (value: any) => void
  handleEditDrug: (value: Partial<DrugDataProps>) => void
  existingDrug: any
  setExistingDrug: (value: any) => void
}
const PrescriptionSavedPrompt: React.FC<PrescriptionSavedPrompt> = ({
  setShowSuccessfulPopUp,
  setUpdatedDrug,
  drug,
  handleEditDrug,
  existingDrug,
  setExistingDrug,
}) => {
  const { isQuotesRefreshing, setIsQuotesRefreshing } =
    React.useContext(QuotesRefreshContext)

  return (
    <ModalComponent
      setOpen={() => {
        setShowSuccessfulPopUp(false)
      }}
      hideCloseButton={true}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '16px',
        }}
      >
        <FaRegCheckCircle
          style={{ height: '35px', width: '35px', color: '#459308' }}
        />
        <span
          style={{
            fontSize: '20px',
            fontWeight: '700',
            lineHeight: '28px',
          }}
        >
          Save Successful
        </span>
        <span
          style={{
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: '400',
            textAlign: 'center',
          }}
        >
          {`Your changes have been saved. You have added the prescription drug
              ${drug.name} ${drug.quantity} every ${drug.frequency}`}
        </span>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            width: '100%',
          }}
        >
          {!isEmpty(existingDrug) && existingDrug.isEditing ? (
            <Button
              variant='outlined'
              width='150px'
              onClick={() => {
                // setShowSuccessfulPopUp(false)
                // console.log('Existing drug', existingDrug)

                handleEditDrug({
                  currentDosage: existingDrug.currentDosage,
                  id: existingDrug.id,
                  isSpouse: existingDrug.isSpouse,
                  name: existingDrug.name,
                })
                setExistingDrug({})
              }}
              disabled={isQuotesRefreshing}
            >
              Undo
            </Button>
          ) : (
            <></>
          )}
          <Button
            variant='contained'
            width='150px'
            onClick={() => {
              setShowSuccessfulPopUp(false)
              setUpdatedDrug(
                {} as {
                  name: string
                  quantity: number
                  period: string
                }
              )
            }}
          >
            Add Drug
          </Button>
        </div>
      </div>
    </ModalComponent>
  )
}

export default PrescriptionSavedPrompt
