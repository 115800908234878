import React, { useEffect, useState } from 'react'
import {
  getCustomerDetails,
  updateInfoStep,
  updateSupplementalQuestions,
} from '../../../service/customer'
import { isEmpty, isNull, isUndefined } from 'lodash'

import Button from '../../common/Button/Button.component'
import { InfoStepEnum } from '../../../enums/InfoStepEnum'
import Loader from '../../common/Loader'
import { MedicareConstants } from '../../../constants/MedicareConstants'
import RadioGroup from '../../common/RadioGroup/RadioGroup.component'
import SectionRow from '../../common/SectionRow'
import SecureComponent from '../../../pages/common/SecureComponent'
import Select from '../../common/Select/Select.component'
import SelectMonthYear from '../../common/Select/SelectMonthYear.component'
import { SnackbarTypes } from '../../../enums/SnackbarTypesEnum'
import StringConstants from '../../../constants/StringConstants'
import ValidationUtils from '../../../utils/validation/ValidationUtils'
import currentPlanStore from '../../../datastore/CurrentPlanStore'
import customerStore from '../../../datastore/CustomerStore'
import { getApiErrorMessage } from '../../../utils/StringUtils'
import { observer } from 'mobx-react'
import { pollQuotesPullStatus } from '../../../utils/quotesPullStatus'
import snackbarStore from '../../../datastore/SnackbarStore'
import styled from 'styled-components'
import theme from '../../../global/theme'

interface MedicareInfoProps {
  isSpouse: boolean
}

const Container = styled.div`
  width: 100%;
  padding: 20px 0px;
`
const RadioButtonWrapper = styled.div`
  width: 100%;
  padding: 10px 0px;
  max-height: 270px;
  overflow-y: auto;
  text-align: left;
`

const SelectMonthYearWrapper = styled.div`
  display: flex;
  // padding: 10px 0px;

  @media screen and (max-width: 600px) {
    align-self: center;
  }
`
const SelectWrapper = styled.div`
  max-width: 465px;
  width: 100%;
  flex: 1;
  align-items: center;
  .MuiSelect-select {
    display: block;
    left: auto !important;
    text-align: left !important;
  }
`
const DisableText = styled.div`
  font-size: 14px;
  margin-left: 5px;
  color: ${theme.colors.secondary};
`

const options: { [key: string]: { planTypes: string[]; carriers: string[] } } =
  {
    [MedicareConstants.INSURANCE_TYPE.MS]: {
      planTypes: MedicareConstants.getPlanTypesMs(),
      carriers: MedicareConstants.CARRIER_LIST.concat(
        MedicareConstants.otherOption
      ),
    },
    [MedicareConstants.INSURANCE_TYPE.MA]: {
      planTypes: MedicareConstants.getPlanTypesMa(),
      carriers: MedicareConstants.CARRIER_LIST.concat(
        MedicareConstants.otherOption
      ),
    },
    [MedicareConstants.INSURANCE_TYPE.AB]: {
      planTypes: [],
      carriers: [],
    },
    [MedicareConstants.INSURANCE_TYPE.Group]: {
      planTypes: MedicareConstants.getPlanTypesIh(),
      carriers: MedicareConstants.CARRIER_LIST.concat(
        MedicareConstants.otherOption
      ),
    },
    [MedicareConstants.INSURANCE_TYPE.Retiree]: {
      planTypes: [],
      carriers: MedicareConstants.CARRIER_LIST.concat(
        MedicareConstants.otherOption
      ),
    },
    [MedicareConstants.INSURANCE_TYPE.Cobra]: {
      planTypes: [],
      carriers: MedicareConstants.CARRIER_LIST.concat(
        MedicareConstants.otherOption
      ),
    },
    [MedicareConstants.INSURANCE_TYPE.IH]: {
      planTypes: MedicareConstants.getPlanTypesIh(),
      carriers: MedicareConstants.CARRIER_LIST.concat(
        MedicareConstants.otherOption
      ),
    },
    [MedicareConstants.INSURANCE_TYPE.Medicaid]: {
      planTypes: [],
      carriers: [],
    },
    [MedicareConstants.INSURANCE_TYPE.Tricare]: {
      planTypes: [],
      carriers: [],
    },
    [MedicareConstants.INSURANCE_TYPE.None]: {
      planTypes: [],
      carriers: [],
    },
  }

const MedicareInfo: React.FC<MedicareInfoProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [componentLoading, setComponentLoading] = useState<boolean>(false)
  const [enrolledInMPartB, setEnrolledInMPartB] = useState<boolean | undefined>(
    undefined
  )
  const [coveredByHealthInsurance, setCoveredByHealthInsurance] = useState<
    boolean | undefined
  >(undefined)
  const [quoteType, setQuoteType] = useState<string>('')
  const [planType, setPlanType] = useState<string>('')
  const [provider, setProvider] = useState<string>('')
  const [effectiveDate, setEffectiveDate] = useState<string>('')
  const [effectiveDateError, setEffectiveDateError] = useState<{
    error: boolean
    helpertext: string
  }>({ error: false, helpertext: '' })
  const [quoteTypeError, setQuoteTypeError] = useState<{
    error: boolean
    helperText: string
  }>({ error: false, helperText: '' })
  const [planTypeError, setPlanTypeError] = useState<{
    error: boolean
    helperText: string
  }>({ error: false, helperText: '' })
  const [providerError, setProviderError] = useState<{
    error: boolean
    helperText: string
  }>({ error: false, helperText: '' })
  const isButtonDisabled =
    customerStore.isMAQuotesProcessing ||
    customerStore.isMSQuotesProcessing ||
    customerStore.isMPartDQuotesProcessing

  useEffect(() => {
    setComponentLoading(true)
    getCustomerDetails()
      .then((response) => {
        const data = response
        if (
          !props.isSpouse &&
          (data.currentMedicarePartBDateSelf ||
            data.currentPlanTypeSelf ||
            data.currentProviderSelf ||
            data.currentCoverageSelf)
        ) {
          currentPlanStore.setPlanType(data.currentPlanTypeSelf)
          currentPlanStore.setProvider(data.currentProviderSelf)
          setPlanType(data.currentPlanTypeSelf)
          setProvider(data.currentProviderSelf)

          let effectiveDateFromSupplementalForm = !isNull(
            customerStore.currentMedicarePartBDateSelf
          )
            ? customerStore.currentMedicarePartBDateSelf.substring(0, 10)
            : ''
          let effectiveDateFromBoth = !isNull(customerStore.currentPlanCustomer)
            ? !isUndefined(customerStore.currentPlanCustomer.effectiveDate)
              ? isNull(customerStore.currentPlanCustomer.effectiveDate)
                ? ''
                : customerStore.currentPlanCustomer.effectiveDate.substring(
                    0,
                    10
                  )
              : effectiveDateFromSupplementalForm
            : effectiveDateFromSupplementalForm
          setEffectiveDate(effectiveDateFromBoth)
          setEnrolledInMPartB(isEmpty(effectiveDateFromBoth) ? false : true)

          if (data.currentCoverageSelf) {
            currentPlanStore.setQuoteType(data.currentCoverageSelf)
            currentPlanStore.setCoveredByHealthInsurance(true)
            setQuoteType(data.currentCoverageSelf)
            setCoveredByHealthInsurance(true)
          } else {
            currentPlanStore.setCoveredByHealthInsurance(false)
            setCoveredByHealthInsurance(false)
          }
        } else if (
          props.isSpouse &&
          (data.currentMedicarePartBDateSpouse ||
            data.currentPlanTypeSpouse ||
            data.currentProviderSpouse ||
            data.currentCoverageSpouse)
        ) {
          let effectiveDateFromSupplementalForm = !isNull(
            customerStore.currentMedicarePartBDateSpouse
          )
            ? customerStore.currentMedicarePartBDateSpouse.substring(0, 10)
            : ''

          let effectiveDateFromBoth = !isNull(customerStore.currentPlanSpouse)
            ? !isUndefined(customerStore.currentPlanSpouse.effectiveDate)
              ? isNull(customerStore.currentPlanSpouse.effectiveDate)
                ? ''
                : customerStore.currentPlanSpouse.effectiveDate.substring(0, 10)
              : effectiveDateFromSupplementalForm
            : effectiveDateFromSupplementalForm

          setEffectiveDate(effectiveDateFromBoth)
          setEnrolledInMPartB(isEmpty(effectiveDateFromBoth) ? false : true)

          currentPlanStore.setSpousePlanType(data.currentPlanTypeSpouse)
          currentPlanStore.setSpouseProvider(data.currentProviderSpouse)
          setPlanType(data.currentPlanTypeSpouse)
          setProvider(data.currentProviderSpouse)

          if (data.currentCoverageSpouse) {
            currentPlanStore.setSpouseQuoteType(data.currentCoverageSpouse)
            currentPlanStore.setSpouseCoveredByHealthInsurance(true)
            setQuoteType(data.currentCoverageSpouse)
            setCoveredByHealthInsurance(true)
          } else {
            currentPlanStore.setSpouseCoveredByHealthInsurance(true)
            setCoveredByHealthInsurance(true)
          }
        }
        customerStore.set(data)

        // Commented by RB
        // snackbarStore.set({
        //   snackbarMessage: StringConstants.SNACKBAR_DATA_FETCH_SUCCESS_MSG,
        //   snackbarOpen: true,
        //   snackbarType: SnackbarTypes.SUCCESS,
        // })
      })
      .catch((err) => {
        snackbarStore.set({
          snackbarMessage: getApiErrorMessage('fetch customer details'),
          snackbarOpen: true,
          snackbarType: SnackbarTypes.ERROR,
        })
      })
      .finally(() => {
        setComponentLoading(false)
      })
  }, [])

  const validateEffectiveDate = (effectiveDate: string) => {
    const effectiveDateError =
      !ValidationUtils.validateEffectiveDate(effectiveDate)

    setEffectiveDateError({
      error: effectiveDateError,
      helpertext: effectiveDateError
        ? isEmpty(effectiveDate)
          ? StringConstants.UI_ERROR_MSG_FOR_EMPTY_PART_B_EFFECTIVE_DATE
          : StringConstants.UI_ERROR_MSG_FOR_INVALID_PART_B_EFFECTIVE_DATE
        : '',
    })
  }
  const handleEffectiveDateChange = (inputDate: string) => {
    validateEffectiveDate(inputDate)
    setEnrolledInMPartB(true)
    setEffectiveDate(inputDate)
  }

  const handleIsEnrolledChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let effectiveDateError
    if (e.target.value === 'Yes') {
      effectiveDateError = !ValidationUtils.validateEffectiveDate(effectiveDate)
      setEnrolledInMPartB(true)
      // setPlanName(currentPlanStore.planType)
      // setCarrier(currentPlanStore.provider)
    } else {
      setEnrolledInMPartB(false)
      setEffectiveDate('')
      // setShouldResetEffectiveDate(true)
      // setPlanName('')
      // setCarrier('')
      effectiveDateError = false
    }
    setEffectiveDateError({
      error: effectiveDateError,
      helpertext: effectiveDateError
        ? isEmpty(effectiveDate)
          ? StringConstants.UI_ERROR_MSG_FOR_EMPTY_PART_B_EFFECTIVE_DATE
          : StringConstants.UI_ERROR_MSG_FOR_INVALID_PART_B_EFFECTIVE_DATE
        : '',
    })
    // setState({
    //   ...state,
    //   effectiveDateError,
    //   effectiveDateHelperText: effectiveDateError
    //     ? isEmpty(effectiveDate)
    //       ? StringConstants.UI_ERROR_MSG_FOR_EMPTY_PART_B_EFFECTIVE_DATE
    //       : StringConstants.UI_ERROR_MSG_FOR_INVALID_PART_B_EFFECTIVE_DATE
    //     : '',
    // })
  }

  const handleSubmit = () => {
    setLoading(true)

    const payload: Partial<{
      effectiveDate: null | string
      quoteType: string
      planType: string
      provider: string
    }> = {}
    let isEffectiveDateChanged = false
    if (props.isSpouse) {
      if (
        ((isEmpty(customerStore.currentMedicarePartBDateSpouse) ||
          isEmpty(currentPlanStore)) &&
          effectiveDate !== '') ||
        (!isEmpty(customerStore.currentMedicarePartBDateSpouse) &&
          effectiveDate !==
            customerStore.currentMedicarePartBDateSpouse?.substring(0, 10)) ||
        (!isEmpty(customerStore.currentPlanSpouse?.effectiveDate) &&
          effectiveDate !==
            customerStore.currentPlanSpouse?.effectiveDate?.substring(0, 10))
      ) {
        if (effectiveDate === '') {
          payload.effectiveDate = null
        } else {
          payload.effectiveDate = effectiveDate
        }
        currentPlanStore.setEffectiveDate(payload.effectiveDate)
      }
    } else {
      if (
        ((isEmpty(customerStore.currentMedicarePartBDateSelf) ||
          isEmpty(currentPlanStore)) &&
          effectiveDate !== '') ||
        (!isEmpty(customerStore.currentMedicarePartBDateSelf) &&
          effectiveDate !==
            customerStore.currentMedicarePartBDateSelf?.substring(0, 10)) ||
        (!isEmpty(customerStore.currentPlanCustomer?.effectiveDate) &&
          effectiveDate !==
            customerStore.currentPlanCustomer?.effectiveDate?.substring(0, 10))
      ) {
        isEffectiveDateChanged = true
        if (effectiveDate === '') {
          payload.effectiveDate = null
        } else {
          payload.effectiveDate = effectiveDate
        }
        currentPlanStore.setSpouseEffectiveDate(payload.effectiveDate)
      }
    }

    const selectedOption = options[quoteType]

    try {
      if (!coveredByHealthInsurance) {
        setPlanType('')
        setQuoteType('')
        setProvider('')
        payload.quoteType = ''
        payload.provider = ''
        payload.planType = ''
      } else {
        if (!selectedOption) {
          setQuoteTypeError({ error: true, helperText: 'Required Field' })
          throw new Error('Invalid quote type selected.')
        }

        if (selectedOption.planTypes.length > 0 && isEmpty(planType)) {
          setPlanTypeError({ error: true, helperText: 'Required Field' })
          throw new Error('Plan type is required for the selected quote type.')
        }

        if (selectedOption.carriers.length > 0 && isEmpty(provider)) {
          setProviderError({ error: true, helperText: 'Required Field' })
          throw new Error('Carrier is required for the selected quote type.')
        }

        if (!isEmpty(quoteType)) {
          payload.quoteType = quoteType
        }
        if (!isEmpty(planType)) {
          payload.planType = selectedOption.planTypes.length > 0 ? planType : ''
        }
        if (!isEmpty(provider)) {
          payload.provider = selectedOption.carriers.length > 0 ? provider : ''
        }
      }

      updateSupplementalQuestions(payload, { 'for-spouse': props.isSpouse })
        .then((value) => {
          updateInfoStep(InfoStepEnum.CURRENT_PLAN).then(() => {
            if (isEffectiveDateChanged) {
              customerStore.setQuotePulledStatusMS(true)
              pollQuotesPullStatus()
            }
            if (!props.isSpouse) {
              customerStore.setSupplementalQuestionAnswers({
                currentCoverageSelf: quoteType,
                currentPlanTypeSelf: planType,
                currentProviderSelf: provider,
                currentMedicarePartBDateSelf: effectiveDate,
              })
              customerStore.setFiltersInfoStep({
                coverageSelf: quoteType,
                planTypeSelf: planType,
                providerSelf: provider,
              })
            } else if (props.isSpouse) {
              customerStore.setSpouseSupplementalQuestionAnswers({
                currentCoverageSpouse: quoteType,
                currentPlanTypeSpouse: planType,
                currentProviderSpouse: provider,
                currentMedicarePartBDateSpouse: effectiveDate,
              })
              customerStore.setSpouseFiltersInfoStep({
                coverageSpouse: quoteType,
                planTypeSpouse: planType,
                providerSpouse: provider,
              })
            }
            props.isSpouse
              ? currentPlanStore.setQuoteType(quoteType)
              : currentPlanStore.setSpouseQuoteType(quoteType)
            props.isSpouse
              ? currentPlanStore.setPlanType(planType)
              : currentPlanStore.setSpousePlanType(planType)
            props.isSpouse
              ? currentPlanStore.setQuoteType(provider)
              : currentPlanStore.setSpouseQuoteType(provider)
          })
        })
        .catch((err) => {
          snackbarStore.set({
            snackbarMessage: getApiErrorMessage(`save plan`),
            snackbarOpen: true,
            snackbarType: 'error',
          })
        })
        .finally(() => {
          setLoading(false)
        })
    } catch (err) {
      snackbarStore.set({
        snackbarMessage: 'Please fill the required fields',
        snackbarOpen: true,
        snackbarType: 'error',
      })
      setLoading(false)
    }
  }

  return componentLoading ? (
    <Loader />
  ) : (
    <Container>
      <SectionRow>
        <RadioGroup
          bottomPadding={false}
          row
          label='Currently enrolled in Medicare Part B?'
          radioButtons={['Yes', 'No']}
          onChange={(e) => handleIsEnrolledChange(e)}
          value={
            isUndefined(enrolledInMPartB) ? '' : enrolledInMPartB ? 'Yes' : 'No'
          }
          radioGroupName={`Info Steps - Are you currently covered by other Health Insurance?`}
        />
      </SectionRow>
      <SectionRow>
        <SelectMonthYear
          label=' When did you enroll into Medicare Part B?'
          value={effectiveDate}
          resetDate={!enrolledInMPartB}
          bottomPadding={false}
          handleChange={handleEffectiveDateChange}
          showValidationError={effectiveDateError.error}
          setValidationErrorTrue={() => {
            setEnrolledInMPartB(true)
            setEffectiveDateError({
              ...effectiveDateError,
              error: true,
            })
          }}
          setValidationErrorFalse={() => {
            setEffectiveDateError({
              ...effectiveDateError,
              error: false,
            })
          }}
          // disabled={currentPlanStore.getIsCurrentPlanQuestionChecked()}
          isInfoStep={true}
        />
      </SectionRow>

      <SectionRow>
        <RadioGroup
          label='Are you currently covered by other health insurance?'
          bottomPadding={false}
          radioButtons={['Yes', 'No']}
          row={true}
          onChange={(e) => {
            e.target.value === 'Yes'
              ? setCoveredByHealthInsurance(true)
              : setCoveredByHealthInsurance(false)
          }}
          value={
            isUndefined(coveredByHealthInsurance)
              ? ''
              : coveredByHealthInsurance
              ? 'Yes'
              : 'No'
          }
          radioGroupName={`Info Steps - Are you currently covered by other Health Insurance?`}
        />
      </SectionRow>

      {coveredByHealthInsurance && (
        <>
          <SectionRow row>
            <Select
              bottomPadding={false}
              label='Please choose which type of insurance'
              options={Object.keys(options)}
              onChange={(e) => {
                setQuoteType(e.target.value)
                setQuoteTypeError({ error: false, helperText: '' })
              }}
              value={quoteType}
              error={quoteTypeError.error}
              helperText={quoteTypeError ? quoteTypeError.helperText : ''}
            ></Select>
          </SectionRow>

          {!isUndefined(options[quoteType]) &&
          options[quoteType].planTypes.length > 0 ? (
            <SectionRow row>
              <Select
                bottomPadding={false}
                label='Please choose your plan type'
                options={options[quoteType].planTypes}
                onChange={(e) => {
                  setPlanType(e.target.value)
                  setPlanTypeError({ error: false, helperText: '' })
                }}
                value={planType}
                isInfoSteps={planTypeError.error}
                helperText={planTypeError.error ? planTypeError.helperText : ''}
              ></Select>
            </SectionRow>
          ) : (
            <>
              {() => {
                setPlanType('')
              }}
            </>
          )}

          {!isUndefined(options[quoteType]) &&
          options[quoteType]?.carriers.length > 0 ? (
            <SectionRow row>
              <Select
                bottomPadding={false}
                fontSize='16px'
                label='Who is your current health insurance provider?'
                options={options[quoteType].carriers}
                onChange={(e) => {
                  setProvider(e.target.value)
                  setProviderError({ error: false, helperText: '' })
                }}
                value={provider}
                isInfoSteps={providerError.error}
                helperText={providerError.error ? providerError.helperText : ''}
              ></Select>
            </SectionRow>
          ) : (
            <>{() => setProvider('')}</>
          )}
        </>
      )}

      <SectionRow row>
        <Button
          width='100%'
          color='secondary'
          variant='contained'
          onClick={handleSubmit}
          loading={loading}
          disabled={
            // customerStore.isMAQuotesProcessing ||
            // customerStore.isMSQuotesProcessing ||
            // customerStore.isMPartDQuotesProcessing ||
            loading
          }
        >
          Save Changes
        </Button>
      </SectionRow>
      <SectionRow>
        {/* {isButtonDisabled && (
          <DisableText>{StringConstants.DISABLE_BUTTON_MSG}</DisableText>
        )} */}
      </SectionRow>
    </Container>
  )
}

export default SecureComponent(observer(MedicareInfo))
