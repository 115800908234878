import CollapsibleContainer from '../CollapsibleContainer'
import InPageHeader from '../../common/InPageHeader/InPageHeader.component'
import { MNSCompletePlan } from '../../../interface/quotes/mNonSupplementPlans/MNSCompletePlan'
import React from 'react'
import TableColumn from '../TableColumn'
import TableRow from '../TableRow'
import styled from '@emotion/styled'

interface TableProps {
  cardDetailsInOrder: MNSCompletePlan
  compareDifferences: boolean
  syncScroll: (event: any) => void
}

const SectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 15px;
  margin-left: 15px;
`

const SectionHeading = styled.div`
  width: 70%;
  margin-bottom: 10px;
  align-content: center;
`

const MedicareAdvantageTable: React.FC<TableProps> = ({
  cardDetailsInOrder,
  compareDifferences,
  syncScroll,
}) => {
  const [currentTab, setCurrentTab] = React.useState<any>(0)
  const [valueType, setValueType] = React.useState<string>('30')
  let index = 0

  const getValue = (value: string, headingId: string | undefined): string => {
    if (headingId?.startsWith('COV')) {
      const match = value.match(/:placeholder:(.*?)}/)
      return match ? match[1] : value
    }
    return value
  }

  const handleChangeDaySupply = (e: any, newValue: number) => {
    setCurrentTab(newValue)
    newValue === 0
      ? setValueType('30')
      : newValue === 1
      ? setValueType('60')
      : setValueType('90')
  }

  const getBackgroundColor = () => {
    index += 1
    return index % 2 === 0 ? 'light' : 'dark'
  }

  const decideRowVisibility = (rowType: string) => {
    if (rowType === undefined) return true
    else if (rowType === `daysSupply|${valueType}`) return true
    else return false
  }

  return (
    <>
      {cardDetailsInOrder?.sections.map((section, key) =>
        section.type === 'section' ? (
          <SectionContainer>
            {/* <SectionHeading>
              {section.name !== 'Header:Documents' && section.name}
            </SectionHeading> */}
            {section.name === 'Prescription Drug Benefits' && (
              <div style={{ marginBottom: '10px' }}>
                <InPageHeader
                  onChange={handleChangeDaySupply}
                  currentTab={currentTab}
                  showBackButton={false}
                  tabLabels={[
                    {
                      heading: '30 Days Supply',
                    },
                    { heading: '60 Days Supply' },
                    { heading: '90 Days Supply' },
                  ]}
                  justifyContent='right'
                  bgColor='white'
                  showBottomNavigator={false}
                />
              </div>
            )}
          </SectionContainer>
        ) : (
          (section.type === 'group' || section.name === 'Summary-Info') && (
            <>
              <CollapsibleContainer
                header={
                  section.name === 'Summary-Info' ? 'Summary' : section.name
                }
                className='scrollTarget'
                isCollapsed={false}
                index={key}
                syncScroll={syncScroll}
              >
                {section.rows.map(
                  (row, index) =>
                    decideRowVisibility(row.type) && (
                      <TableRow
                        header={row.cols[0]}
                        background={
                          (compareDifferences &&
                            row.cols
                              .slice(1)
                              .every(
                                (element) =>
                                  getValue(
                                    element,
                                    section.headingId
                                  ).toLowerCase() ===
                                  getValue(
                                    row.cols[1],
                                    section.headingId
                                  ).toLowerCase()
                              )) ||
                          !compareDifferences
                            ? getBackgroundColor()
                            : 'highlight'
                        }
                      >
                        {row.cols.slice(1).map((value, index) => (
                          <TableColumn key={index} alignItems='self-start'>
                            <div
                              style={{
                                alignItems: 'self-start',
                                textAlign: 'left',
                                float: 'left',
                              }}
                              dangerouslySetInnerHTML={{
                                __html: getValue(value, section.headingId),
                              }}
                            ></div>
                          </TableColumn>
                        ))}
                      </TableRow>
                    )
                )}
              </CollapsibleContainer>
            </>
          )
        )
      )}
    </>
  )
}

export default MedicareAdvantageTable
