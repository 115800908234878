import React from 'react'
import styled from '@emotion/styled'
import theme from '../../../global/theme'

interface LabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
  required?: boolean
}

const LabelC = styled.label`
  font-size: 14px;
  color: ${theme.colors.textDark};
  font-weight: 600;
  display: block;
`

const SubLabelC = styled.label`
  font-size: 12px;
  color: ${theme.colors.textDark};
  font-weight: 400;
  display: block;
`

const Label: React.FC<LabelProps> = ({
  children,
  required = false,
  ...props
}) => {
  return (
    <LabelC {...props}>
      {children}{' '}
      {required && <span style={{ color: theme.colors.secondary }}>*</span>}
    </LabelC>
  )
}

export const SubLabel: React.FC<LabelProps> = ({ children, ...props }) => {
  return <SubLabelC {...props}>{children}</SubLabelC>
}

export default Label
