import ApiManager from '../api/ApiManager'
import { HttpMethods } from '../enums/HttpMethodsEnum'
import UrlConstants from '../constants/UrlConstants'

export const getCitiesOfProviders = async (payload: any) => {
  const usecase = UrlConstants.GET_PROVIDERS_CITIES.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpMethods.GET_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          resolve(response)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in  ${usecase}:`, error)
        reject(error)
      })
  })
}

export const lookupProviders = async (payload: any) => {
  const usecase = UrlConstants.LOOKUP_PROVIDERS.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpMethods.POST_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          resolve(response)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in  ${usecase}:`, error)
        reject(error)
      })
  })
}

export const getProviders = async (payload: any) => {
  const usecase = UrlConstants.GET_PROVIDERS.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpMethods.GET_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          resolve(response)
        } else {
          reject(response)
        }
      })
      .catch((error) => {
        console.log(`Error in  ${usecase}:`, error)
        reject(error)
      })
  })
}

export const getCityDetails = async (payload: any) => {
  const usecase = UrlConstants.GET_CITY_DETAILS.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpMethods.GET_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          resolve(response)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in  ${usecase}:`, error)
        reject(error)
      })
  })
}

export const getProviderAddress = async (payload: any) => {
  const usecase = UrlConstants.GET_PROVIDER_ADDRESS.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpMethods.POST_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          resolve(response)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in  ${usecase}:`, error)
        reject(error)
      })
  })
}

export const addProvider = async (id: any, npi: any, forSpouse: boolean) => {
  const usecase = UrlConstants.ADD_PROVIDER.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(usecase, HttpMethods.POST_METHOD, {
      $id: id,
      $npi: npi,
      $forSpouse: forSpouse,
    })
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          resolve(response)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in  ${usecase}:`, error)
        reject(error)
      })
  })
}

export const updateProviderAddress = async (
  id: any,
  npi: any,
  externalId: any,
  forSpouse: boolean
) => {
  const usecase = UrlConstants.UPDATE_PROVIDER_ADDRESS.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpMethods.PATCH_METHOD,
      {
        $id: id,
        $npi: npi,
        $externalId: externalId,
        $forSpouse: forSpouse,
      }
    )
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          resolve(response)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in  ${usecase}:`, error)
        reject(error)
      })
  })
}

export const deleteProvider = async (id: any, forSpouse: boolean) => {
  const usecase = UrlConstants.DELETE_PROVIDER.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpMethods.DELETE_METHOD,
      { $id: id, $forSpouse: forSpouse }
    )
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          resolve(response)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in  ${usecase}:`, error)
        reject(error)
      })
  })
}
