import { Table, TableBody } from '@mui/material'

import { MNonSupplementPlan } from '../../interface/quotes/mNonSupplementPlans/MNonSupplementPlan'
import MuiTableCell from '@mui/material/TableCell'
import MuiTableRow from '@mui/material/TableRow'
import { PrescriptionMonthlyCost } from '../../interface/prescriptionDrug/PrescriptionMonthlyCost'
import { ThreeDots } from 'react-loader-spinner'
import customerStore from '../../datastore/CustomerStore'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import theme from '../../global/theme'
import { useNavigate } from 'react-router-dom'

const ThreeDotsWrapper = styled.div`
  display: flex;
  margin: 20px;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media screen and (max-width: 640px) {
    width: 100%;
    margin: 20px 0px;
  }
`

const Container = styled.div``

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding-bottom: 10px;
  p {
    font-size: 14px;
    font-weight: 700;
    color: ${theme.colors.textGrey};
  }
  span {
    text-decoration: underline;
    font-weight: 700;
    color: ${theme.colors.primary};
    font-size: 12px;
  }
`

const ContentWrapper = styled.div`
  border: 1px solid #e5e5e5;
  overflow: auto;

  @media screen and (max-width: 500px) {
    overflow: auto;
    max-width: 347px;
  }

  @media screen and (max-width: 400px) {
    overflow: auto;
    max-width: 308px;
  }

  @media screen and (max-width: 375px) {
    overflow: auto;
    max-width: 290px;
  }
`

const TableRow = styled(MuiTableRow)`
  &:nth-of-type(odd) {
    background-color: ${theme.colors.backgroundColorContainer};
  }
`

const TableCell = styled(MuiTableCell)<{ customClass?: string }>`
  font-weight: 700;
  color: ${theme.colors.textGrey};
  font-size: 12px;
  padding: 15px 10px;
  ${({ customClass }) =>
    customClass === 'plan-benefit-coverage' &&
    `
      font-size: 10px;
      color:${theme.colors.textGrey};
      font-weight:400;
    `}
  @media screen and (max-width: 600px) {
    width: 25%;
    padding: 15px 10px;
  }
`

interface PrescriptionDrugCostProps {
  mNonSupplementPlan: MNonSupplementPlan
  drugCostDetails: PrescriptionMonthlyCost[]
  loading: boolean
  isHeadRequired?: boolean
}

const storeScrollPosition = () => {
  const scrollPosition = window.scrollY
  customerStore.setScrollPosition(scrollPosition.toString())
}

const PrescriptionCoverageComparison: React.FC<PrescriptionDrugCostProps> = ({
  mNonSupplementPlan,
  drugCostDetails,
  loading,
  isHeadRequired = true,
}) => {
  const navigate = useNavigate()
  return loading ? (
    <ThreeDotsWrapper>
      <ThreeDots color='#222C69' height={80} width={80} />
    </ThreeDotsWrapper>
  ) : !isEmpty(drugCostDetails) ? (
    <Container>
      {isHeadRequired && (
        <HeaderWrapper>
          <p>Drug Price Comparison</p>
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => {
              navigate({
                pathname: '/plan-details',
                search: `id=${mNonSupplementPlan.id}&&type=mpd`,
              })
              storeScrollPosition()
            }}
          >
            View Complete Plan Details
          </span>
        </HeaderWrapper>
      )}
      <ContentWrapper>
        <Table
          style={{
            width: '100%',
          }}
        >
          <TableBody>
            <TableRow>
              <TableCell
                sx={{
                  borderRight: '1px solid #E5E5E5',
                  textAlign: 'center',
                }}
              >
                Drug Name
              </TableCell>
              <>
                {drugCostDetails.map((drugCost) => (
                  <>
                    <TableCell
                      sx={{
                        borderRight: '1px solid #E5E5E5',
                        textAlign: 'center',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <span>{drugCost.pharmacyName}</span>
                        <span style={{ fontWeight: '400' }}>
                          {(() => {
                            const drugCostData =
                              mNonSupplementPlan.drugCosts.find(
                                (maPlanDrugCost) =>
                                  maPlanDrugCost.pharmacyId ===
                                  drugCost.pharmacyId
                              )
                            const address1 = drugCostData?.address1 || ''
                            const address2 = drugCostData?.address2 || ''
                            return address1 || address2
                              ? `${address1} ${address2}`
                              : null
                          })()}
                        </span>
                      </div>
                    </TableCell>
                  </>
                ))}
              </>
            </TableRow>
            {mNonSupplementPlan.drugInformations.map(
              (drugInformation, index) => (
                <TableRow>
                  <TableCell
                    sx={{
                      width: '25%',
                      borderRight: '1px solid #E5E5E5',
                      textAlign: 'center',
                    }}
                  >
                    {drugInformation.name}
                  </TableCell>
                  {drugCostDetails.map((pharmacy, colIndex) => {
                    // Find the drug in the current pharmacy's drugDetails
                    const drug = pharmacy.drugDetails.find(
                      (d) => d.name === drugInformation.name
                    )
                    return (
                      <TableCell
                        sx={{
                          width: '25%',
                          borderRight: '1px solid #E5E5E5',
                          textAlign: 'center',
                          fontWeight: '600',
                        }}
                        key={colIndex}
                      >
                        {drug?.tier !== -1 ? 'Covered' : 'Not Covered'}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </ContentWrapper>
    </Container>
  ) : (
    <></>
  )
}

export default observer(PrescriptionCoverageComparison)
