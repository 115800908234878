import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
} from 'react-router-dom'

import InPageHeader from '../../components/common/InPageHeader/InPageHeader.component'
import LayoutComponent from '../../components/common/Layout/Layout.component'
import React from 'react'
import RouteConstants from '../../constants/RouteConstants'
import SecureComponent from '../common/SecureComponent'
import customerStore from '../../datastore/CustomerStore'
import { observer } from 'mobx-react'
import styled from '@emotion/styled'
import theme from '../../global/theme'
import { withRouter } from '../../components/common/WithRouter/WithRouter.component'

type CurrentSubRouteContextType = {
  setCurrentSubRoute: React.Dispatch<React.SetStateAction<string>>
  currentSubRoute: string
}

const Container = styled.div`
  min-height: 100vh;
  background-color: ${theme.colors.white};
  position: relative;
`
interface ProfilePageProps {
  location: {
    state: {
      from: string
    }
  }
}

const ProfilePage: React.FC<ProfilePageProps> = ({ location }) => {
  const currentRoute = useLocation().pathname
  const [currentTab, setCurrentTab] = React.useState(0)
  const [currentSubRoute, setCurrentSubRoute] = React.useState<string>(
    currentRoute.split('/')[3]
  )

  const navigate = useNavigate()
  const currentLocation = useLocation().pathname

  const handleTabChange = (e: React.SyntheticEvent, newValue: number) => {
    navigate(
      newValue === 0
        ? `myprofile/${currentSubRoute}`
        : `spouseprofile/${currentSubRoute}`,
      {
        state: {
          from:
            (location && location.state && location.state.from) ||
            RouteConstants.DASHBOARD,
        },
      }
    )
    setCurrentTab(newValue)
  }

  React.useEffect(() => {
    currentTab === 0
      ? customerStore.setForSpouse(false)
      : customerStore.get().spouseOptIn
      ? customerStore.setForSpouse(true)
      : customerStore.setForSpouse(false)
  }, [currentTab])

  React.useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
    return () => {
      customerStore.setForSpouse(false)
    }
  }, [])

  React.useEffect(() => {
    if (currentLocation.split('/')[2] === 'myprofile') {
      setCurrentTab(0)
    } else {
      setCurrentTab(1)
    }
  }, [currentLocation])

  return (
    <LayoutComponent>
      <Container>
        <InPageHeader
          showBackButton={true}
          onChange={handleTabChange}
          currentTab={currentTab}
          tabLabels={[
            {
              heading: 'Me',
            },
            {
              heading: 'My Spouse/Partner',
            },
          ]}
          heading='Profile'
          locationState={
            (location && location.state && location.state.from) ||
            RouteConstants.DASHBOARD
          }
          isSidebarShown={
            currentTab === 0 || (currentTab !== 0 && customerStore.spouseOptIn)
          }
        />
        <Outlet context={{ setCurrentSubRoute, currentSubRoute, location }} />
      </Container>
    </LayoutComponent>
  )
}

export default SecureComponent(withRouter(observer(ProfilePage)))

export function useCurrentSubrouteContext() {
  return useOutletContext<CurrentSubRouteContextType>()
}
