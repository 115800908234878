import React from 'react'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'
import styled from '@emotion/styled'
import theme from '../../global/theme'
import { useLocation } from 'react-router-dom'

interface InfoStepsStepperProps {
  stepDetails: {
    order: number
    label: string
    route: string
  }[]
}

const StepperLabelStyled = styled.span`
  @media screen and (max-width: 470px) {
    display: none;
  }
`

const StepStyled = styled(Step)<{ activeStep: number }>`
  .MuiSvgIcon-root {
    fill: ${(props) =>
      props.activeStep > (props.index || 0)
        ? theme.colors.success
        : props.activeStep === (props.index || 0)
        ? theme.colors.primary
        : theme.colors.disabled};
  }
`

const InfoStepsStepper: React.FC<InfoStepsStepperProps> = ({ stepDetails }) => {
  const location = useLocation().pathname
  const getActiveStep = () => {
    const currentStep = stepDetails.find((iterator) => {
      return iterator.route.split('/')[2] === location.split('/')[2]
    })
    return currentStep
  }

  return (
    <Stepper
      activeStep={getActiveStep()?.order || 0}
      sx={{ width: '100%' }}
      alternativeLabel={window.innerWidth < 600}
      orientation='vertical'
    >
      {stepDetails.map((iterator) => {
        return (
          <StepStyled
            key={iterator.order}
            activeStep={getActiveStep()?.order || 0}
            index={iterator.order}
          >
            <StepLabel style={{ width: 'max-content' }}>
              <StepperLabelStyled>{iterator.label}</StepperLabelStyled>
            </StepLabel>
          </StepStyled>
        )
      })}
    </Stepper>
  )
}

export default InfoStepsStepper
