import FilterChip from '../FilterChip.component.tsx/FilterChip'
import { Link } from '@mui/material'
import mPartDPlansFilterStore from '../../../../datastore/medicareQuotes/MPartPlanFilterQuotesStore'
import medicareAdvantageFilterQuoteStore from '../../../../datastore/medicareQuotes/MedicareAdvantageFilterQuoteStore'
import msPlanFiltersStore from '../../../../datastore/medicareQuotes/MSupplementPlanFiltersStore'
import styled from '@emotion/styled'
import theme from '../../../../global/theme'

const Container = styled.div<ContainerProps>`
  opacity: ${({ greyOut }) => (greyOut ? 0.5 : 1)};
  pointer-events: ${({ greyOut }) => (greyOut ? 'none' : 'auto')};
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  gap: 10px;

  .filter-wrapper {
    display: flex;
    flex: 1;
    gap: 10px;
    flex-wrap: wrap;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column-reverse;
  }
`

interface ContainerProps {
  greyOut: boolean
}

interface FilterBoxProps {
  handleClearFilters: () => void
  selectedFilters: string[]
  updateFilters: (data: string) => any
  // style: React.CSSProperties
}

const FilterBox = ({
  handleClearFilters: clearButtonState,
  selectedFilters,
  updateFilters,
}: FilterBoxProps) => {
  if (selectedFilters.length === 0) {
    return null
  }
  return (
    <Container
      greyOut={
        msPlanFiltersStore.filterApplied ||
        mPartDPlansFilterStore.filterApplied ||
        medicareAdvantageFilterQuoteStore.filterApplied
      }
    >
      <div className='filter-wrapper'>
        {selectedFilters.map((filter, index) => (
          <FilterChip text={filter} updateFilters={updateFilters} key={index} />
        ))}
      </div>
      <p
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Link
          onClick={clearButtonState}
          style={{
            whiteSpace: 'nowrap',
            cursor: 'pointer',
            color: theme.colors.primary,
          }}
        >
          Clear Filters
        </Link>
      </p>
    </Container>
  )
}

export default FilterBox
