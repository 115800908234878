import { MedicareConstants } from '../../../../constants/MedicareConstants'
import React from 'react'
import Select from '../../../common/Select/Select.component'
import currentPlanStore from '../../../../datastore/CurrentPlanStore'
import { isEmpty } from 'lodash'
import styled from '@emotion/styled'
import theme from '../../../../global/theme'

interface SelectGroupOrIHPlanTypeProps extends ContainerProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
  disableMainNext?: () => void
  enableMainNext?: () => void
  handleHasNext?: (value: boolean) => void
  forSpouse: boolean
}

interface ContainerProps {
  width?: string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 600px) {
    padding: 10px 5px;
    width: 100%;
  }
`

const Heading = styled.h3`
  font-size: 20px;
  font-weight: 700px;
  padding-bottom: 20px;
  color: ${theme.colors.primary};
`

const Description = styled.p`
  font-weight: 600;
  text-align: left;
`

const SelectWrapper = styled.div<{ screenWidth: number }>`
  max-width: 465px;
  width: 97.5%;
  margin-top: 10px;
  .MuiSelect-select {
    display: block;
    left: auto !important;
    text-align: left !important;
  }
  @media screen and (min-width: 601px) and (max-width: 750px) {
    .MuiPopover-paper {
      min-width: ${(props) =>
        ((750 - props.screenWidth) / 50) * 4 + 60}% !important;
    }
  }

  @media screen and (max-width: 600px) {
    .MuiPopover-paper {
      min-width: ${(props) =>
        90 - ((600 - props.screenWidth) / 50) * 1.75}% !important;
    }
  }
`

const SelectGroupOrIHPlanType: React.FC<SelectGroupOrIHPlanTypeProps> = ({
  onClick,
  enableMainNext,
  disableMainNext,
  handleHasNext,
  forSpouse,
}) => {
  const [planType] = React.useState<string[]>(
    MedicareConstants.getPlanTypesIh()
  )

  const [selectedPlanType, setSelectedPlanType] = React.useState<string>(
    forSpouse
      ? currentPlanStore.spousePlanType
        ? currentPlanStore.spousePlanType
        : ''
      : currentPlanStore.planType
      ? currentPlanStore.planType
      : ''
  )
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  })

  const debounce = <A extends unknown[]>(
    callback: (...args: A) => unknown,
    msDelay: number
  ) => {
    let timer: NodeJS.Timeout | undefined

    return (...args: A) => {
      clearTimeout(timer)

      timer = setTimeout(() => {
        timer = undefined
        callback(...args)
      }, msDelay)
    }
  }

  React.useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      })
    }, 100)

    window.addEventListener('resize', debouncedHandleResize)

    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  })

  React.useEffect(() => {
    handleHasNext && handleHasNext(true)
    if (!isEmpty(selectedPlanType)) {
      enableMainNext && enableMainNext()
    } else {
      disableMainNext && disableMainNext()
    }
  }, [])

  const handleSearchItemClick = (item: any) => {
    setSelectedPlanType(item)
    if (forSpouse) currentPlanStore.setSpousePlanType(item)
    else currentPlanStore.setPlanType(item)
    enableMainNext && enableMainNext()
  }
  return (
    <Container>
      <Heading>
        Please choose your {forSpouse ? `spouse's` : ''}{' '}
        {forSpouse
          ? currentPlanStore.spouseQuoteType
            ? currentPlanStore.spouseQuoteType
            : ''
          : currentPlanStore.quoteType
          ? currentPlanStore.quoteType
          : ''}{' '}
        Plan type
      </Heading>
      <Description>
        Select your {forSpouse ? `spouse's` : ''} plan type
      </Description>
      <SelectWrapper screenWidth={dimensions.width}>
        <Select
          formLabel='Select your plan type'
          options={planType ? planType : ([] as string[])}
          onChange={(e) => {
            handleSearchItemClick(e.target.value)
          }}
          height='39px'
          value={selectedPlanType}
          isInfoSteps={true}
        ></Select>
      </SelectWrapper>
    </Container>
  )
}

export default SelectGroupOrIHPlanType
