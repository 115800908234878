import ApiManager from '../api/ApiManager'
import { HttpMethods } from '../enums/HttpMethodsEnum'
import UrlConstants from '../constants/UrlConstants'

export const fetchPharmacies = (forSpouse: boolean) => {
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_SELECTED_PHARMACY_DETAILS_SF.USECASE,
      HttpMethods.GET_METHOD,
      { $forSpouse: forSpouse }
    )
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          resolve(response.data.data)
        } else {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const deletePharmacy = (
  id: string,
  forSpouse: boolean
): Promise<string> => {
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.DELETE_PHARMACY_DETAIL_SF.USECASE,
      HttpMethods.DELETE_METHOD,
      {
        $pharmacyId: id,
        $forSpouse: forSpouse,
      }
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data.message)
        } else {
          reject(response)
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}
