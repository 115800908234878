import {
  addResponsesForQuestionnaires,
  getQuestionnaires,
} from '../../../../service/questionnaire'
import customerStore, { CustomerDTO } from '../../../../datastore/CustomerStore'
import {
  getCustomerDetails,
  updateCustomerDetails,
  updateInfoStep,
} from '../../../../service/customer'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import BottomStepNavigator from '../../BottomStepNavigator.component'
import { InfoStepEnum } from '../../../../enums/InfoStepEnum'
import { MedicareQuotesType } from '../../../../enums/MedicareQuoteTypeEnum'
import { PlanRecommendationScoresType } from '../../../../interface/questionnaire/PlanRecommendationScores'
import Question from '../Common/Question'
import { QuestionnaireI } from '../../../../interface/questionnaire/QuestionnaireInterface'
import QuestionnaireRadioStep from './QuestionnaireRadioStep'
import RouteConstants from '../../../../constants/RouteConstants'
import { SaveQuestionnaireRequestI } from '../../../../interface/questionnaire/SaveQuestionnaireRequestInterface'
import SkeletonCard from '../../../DashboardPage/SkeletonCard'
import SpouseInfoQuestion from './SpouseInfoQuestion'
import StringConstants from '../../../../constants/StringConstants'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react'
import snackbarStore from '../../../../datastore/SnackbarStore'
import styled from '@emotion/styled'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const QuestionnaireScreen = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [loading, setLoading] = useState<boolean>(false)
  const [activeStep, setActiveStep] = useState(0)
  const [questionnaires, setQuestionnaires] = useState<QuestionnaireI[]>([])
  const [responses, setResponses] = useState<SaveQuestionnaireRequestI[]>([])
  const [forSpouse, setForSpouse] = useState(false)
  const [spouseQuestionStep, setSpouseQuestionStep] = useState<
    'null' | 'step1' | 'step2'
  >('null')

  const getRecommendedPlanFromScore = (score: PlanRecommendationScoresType) => {
    // Store values in an array of objects for easier comparison
    const plans = [
      { value: score.ms, name: MedicareQuotesType.MEDICARE_SUPPLEMENT },
      { value: score.ma, name: MedicareQuotesType.MEDICARE_ADVANTAGE },
      { value: score.pdp, name: MedicareQuotesType.MEDICARE_PRESCRIPTION },
    ]
    // Find the highest value
    const maxPlan = plans.reduce((max, current) =>
      current.value > max.value ? current : max
    )
    return maxPlan.name
  }

  const handleRecommendationScore = async (
    questionnaires: QuestionnaireI[],
    responses: SaveQuestionnaireRequestI[]
  ) => {
    let ms = 0,
      ma = 0,
      pdp = 0

    for (let i = 0; i <= activeStep && i < responses.length; i++) {
      const response = responses.find(
        (response) =>
          response.id === questionnaires[i].id && response.response.length > 0
      )
      if (response) {
        const selectedOption = response.options.findIndex(
          (option) => option.option === response.response[0]
        )

        switch (i) {
          case 0:
            if (selectedOption === 0) ms += 65
            else if (selectedOption === 1) ma += 50
            else if (selectedOption === 2) pdp += 50
            break
          case 1:
          case 2:
          case 3:
            if (selectedOption === 0) ms += 25
            else if (selectedOption === 1) ma += 25
            break
          case 4:
            if (selectedOption === 0) ms += 15
            else if (selectedOption === 1) {
              ms += 10
              ma += 10
            } else if (selectedOption === 2) {
              ma += 10
            }
            break
          default:
            break
        }
      }
    }

    const scoreObject: PlanRecommendationScoresType = {
      ms,
      ma,
      pdp,
    }

    const recommendedPlanType: MedicareQuotesType =
      getRecommendedPlanFromScore(scoreObject)

    await updateCustomerDetails({
      recommendedPlanType,
    })

    const updatedCustomerDetails: CustomerDTO = await getCustomerDetails()
    customerStore.set(updatedCustomerDetails)

    sessionStorage.setItem(
      StringConstants.PLAN_RECOMMENDATION_SCORES,
      JSON.stringify(scoreObject)
    )
  }

  const fetchQuestionnaires = (forSpouse: boolean) => {
    getQuestionnaires(forSpouse, 'PLAN_TYPE')
      .then((res: QuestionnaireI[]) => {
        const response = res.sort(function (a: any, b: any) {
          if (a.displayOrder > b.displayOrder) {
            return 1
          } else {
            return -1
          }
        })
        setQuestionnaires(response)
        const resultArr = [
          ...response.map((data: any) => {
            return {
              id: data.id,
              inputType: data.inputType,
              isRequired: data.isRequired,
              options: data.options,
              question: data.question,
              response:
                data.responses.length > 0 ? data.responses[0].response : [],
            }
          }),
        ]
        setResponses(resultArr)
      })
      .catch((err) => {})
      .finally(() => {})
  }

  const resetSteps = () => {
    setActiveStep(0)
    setSpouseQuestionStep('null')
    fetchQuestionnaires(forSpouse)
  }

  const handleNextQuestion = async () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    if (
      activeStep < questionnaires.length - 1 &&
      spouseQuestionStep === 'null'
    ) {
      if (
        activeStep === 0 &&
        responses[0].options[2].option === responses[0].response[0]
      ) {
        await handleSaveResponse()
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
      }
    } else if (
      activeStep === questionnaires.length - 1 &&
      spouseQuestionStep === 'null'
    )
      await handleSaveResponse()
  }

  const handleBackQuestion = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    if (activeStep !== 0 && spouseQuestionStep === 'null')
      setActiveStep((prevActiveStep) => prevActiveStep - 1)
    else if (spouseQuestionStep === 'step1') {
      setSpouseQuestionStep('null')
    } else if (
      spouseQuestionStep === 'step2' &&
      isEmpty(customerStore.getSpouseFullName())
    ) {
      setSpouseQuestionStep('step1')
    } else if (
      spouseQuestionStep === 'step2' &&
      !isEmpty(customerStore.getSpouseFullName())
    ) {
      setSpouseQuestionStep('null')
    }
  }

  const handleSaveResponse = async () => {
    try {
      setLoading(true)
      if (activeStep === 0) {
        await addResponsesForQuestionnaires(forSpouse, responses.slice(0, 1))
      } else await addResponsesForQuestionnaires(forSpouse, responses)
      await handleRecommendationScore(questionnaires, responses)
      if (forSpouse) {
        await updateInfoStep(InfoStepEnum.QUESTIONNAIRE)
        snackbarStore.set({
          snackbarMessage: StringConstants.SNACKBAR_SAVE_SUCCESS_MSG,
          snackbarOpen: true,
          snackbarType: 'success',
        })
        navigate(RouteConstants.INFO_STEPS_HEALTH)
      } else if (isEmpty(customerStore.spouseFirstName)) {
        setSpouseQuestionStep('step1')
      } else {
        setSpouseQuestionStep('step2')
      }
    } catch (error) {
      snackbarStore.set({
        snackbarMessage: 'Something went wrong',
        snackbarOpen: true,
        snackbarType: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const handleSpouseQuestion = async (response: boolean) => {
    try {
      setLoading(true)
      if (!response) {
        customerStore.setSpouseOptIn(false)
        await updateInfoStep(InfoStepEnum.QUESTIONNAIRE)
        snackbarStore.set({
          snackbarMessage: StringConstants.SNACKBAR_SAVE_SUCCESS_MSG,
          snackbarOpen: true,
          snackbarType: 'success',
        })
        navigate(RouteConstants.INFO_STEPS_HEALTH)
      } else {
        setSpouseQuestionStep('step2')
      }
    } catch (error) {
      snackbarStore.set({
        snackbarMessage: 'Something went wrong',
        snackbarOpen: true,
        snackbarType: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchQuestionnaires(forSpouse)
    if (!forSpouse) {
      sessionStorage.removeItem(StringConstants.PLAN_RECOMMENDATION_SCORES)
    }
  }, [forSpouse])

  useEffect(() => {
    setForSpouse(searchParams.get('forSpouse') === 'true')
  }, [searchParams])

  return questionnaires.length === 0 ? (
    <SkeletonCard />
  ) : (
    <Container>
      {spouseQuestionStep === 'step1' && (
        <Question
          heading='Would you like to add a spouse?'
          description='Household discounts up to 15% may be available for each member. Would you like to view rates for a Spouse/Partner?'
          handleNo={() => {
            handleSpouseQuestion(false)
          }}
          handleYes={() => {
            handleSpouseQuestion(true)
          }}
        />
      )}

      {spouseQuestionStep === 'step2' && (
        <SpouseInfoQuestion
          handleBack={handleBackQuestion}
          loading={loading}
          setLoading={setLoading}
          resetSteps={resetSteps}
        />
      )}

      {spouseQuestionStep === 'null' && (
        <QuestionnaireRadioStep
          id={questionnaires[activeStep].id}
          question={questionnaires[activeStep].question}
          description={questionnaires[activeStep].description}
          options={questionnaires[activeStep].options}
          value={
            (responses[activeStep] &&
              responses[activeStep].response &&
              responses[activeStep].response.length > 0 &&
              responses[activeStep].response[0]) ||
            (questionnaires[activeStep].responses &&
              questionnaires[activeStep].responses.length > 0 &&
              questionnaires[activeStep].responses[0].response &&
              questionnaires[activeStep].responses[0].response.length > 0 &&
              questionnaires[activeStep].responses[0].response[0])
          }
          handleResponseSelection={(id: any, responseArray: any) => {
            const updatedResponse = responses.map((item: any) => {
              if (item.id === id) {
                return {
                  ...item,
                  response: responseArray,
                }
              } else {
                return item
              }
            })
            setResponses(updatedResponse)
          }}
        />
      )}
      {spouseQuestionStep !== 'step2' && (
        <BottomStepNavigator
          handleNext={handleNextQuestion}
          handleBack={handleBackQuestion}
          handleViewPlans={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' })
            navigate(RouteConstants.DASHBOARD)
          }}
          disableNext={
            (questionnaires[activeStep].isRequired &&
              responses[activeStep]?.response.length === 0) ||
            spouseQuestionStep === 'step1'
          }
          isLoading={loading}
          currentInfoStep='Questionnaires'
          currentSectionCount={1}
          currentQuestionCount={
            spouseQuestionStep === 'step1' ? 7 : activeStep + 1
          }
          totalQuestionCount={9}
        />
      )}
    </Container>
  )
}

export default observer(QuestionnaireScreen)
