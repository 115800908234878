import Button from '../../common/Button/Button.component'
import { Link } from 'react-router-dom'
import React from 'react'
import RouteConstants from '../../../constants/RouteConstants'
import styled from '@emotion/styled'
import theme from '../../../global/theme'

interface ModelProps {
  open?: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const Container = styled.div`
  min-height: 220px;

  h2 {
    color: ${theme.colors.primary};
    padding-bottom: 20px;
  }
  p {
    padding-bottom: 5px;
    font-weight: 600;
    color: ${theme.colors.textDark};
    span {
      color: ${theme.colors.secondary};
      font-weight: bold;
    }
  }
  .highlight {
    padding-top: 20px;
    color: ${theme.colors.primary};
    font-weight: 600;
    padding-bottom: 20px;
  }

  div {
    display: flex;
    justify-content: space-between;
  }

  @media screen and (max-width: 750px) {
    div {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`
const ModalButton = styled(Link)`
  width: 220px;
  text-decoration: none;

  @media screen and (max-width: 750px) {
    width: 100%;
  }
`

const NewPasswordModal: React.FC<ModelProps> = ({ open, setOpen }) => {
  React.useEffect(() => {
    document.body.style.overflowY = 'hidden'
    return () => {
      document.body.style.overflowY = 'auto'
    }
  }, [])
  return (
    <Container>
      <h2>Hey there!</h2>
      <p>It looks like you have logged in with your temporary password.</p>
      <p>
        Your password will expire in <span>24 hours</span> if you do not set a
        new password.
      </p>
      <p className='highlight'>Set a new password now to save your quote.</p>
      <div>
        <ModalButton to={RouteConstants.RESET_PASSWORD}>
          <Button
            color='secondary'
            variant='contained'
            bold={false}
            width='100%'
            description='Dashboard Set New Password Prompt - CTA'
          >
            Set new password
          </Button>
        </ModalButton>
        <ModalButton to=''>
          <Button
            color='info'
            bold={false}
            width='100%'
            onClick={(e) => setOpen(false)}
            description='Dashboard Set New Password Prompt - Skip'
          >
            Skip for now
          </Button>
        </ModalButton>
      </div>
    </Container>
  )
}

export default NewPasswordModal
