import React, { useEffect, useState } from 'react'

import { MedicareConstants } from '../../../../constants/MedicareConstants'
import Select from '../../../common/Select/Select.component'
import currentPlanStore from '../../../../datastore/CurrentPlanStore'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react'
import styled from '@emotion/styled'
import theme from '../../../../global/theme'

interface SelectAdvantagePlanProps extends ContainerProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
  question?: string
  description?: string
  disableMainNext?: () => void
  enableMainNext?: () => void
  handleHasNext?: (value: boolean) => void
  forSpouse: boolean
}

interface ContainerProps {
  width?: string
}

const Container = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  @media screen and (max-width: 600px) {
    padding: 10px 5px;
    width: 100%;
  }
`

const Heading = styled.h3`
  font-size: 20px;
  font-weight: 700px;
  padding-bottom: 10px;
  color: ${theme.colors.primary};
`

const Description = styled.p`
  padding-top: 20px;
  font-weight: 600;
`

const RadioButtonWrapper = styled.div`
  width: 100%;
  padding: 10px 0px;
  max-height: 200px;
  overflow-y: auto;
`
const SelectWrapper = styled.div<{ screenWidth: number }>`
  max-width: 465px;
  width: 100%;
  flex: 1;
  margin-top: 10px;
  .MuiSelect-select {
    display: block;
    left: auto !important;
    text-align: left !important;
  }
  @media screen and (min-width: 601px) and (max-width: 750px) {
    .MuiPopover-paper {
      min-width: ${(props) =>
        ((750 - props.screenWidth) / 50) * 4 + 60}% !important;
    }
  }

  @media screen and (max-width: 600px) {
    .MuiPopover-paper {
      min-width: ${(props) =>
        90 - ((600 - props.screenWidth) / 50) * 1.75}% !important;
    }
  }
`

const SelectAdvantagePlan: React.FC<SelectAdvantagePlanProps> = ({
  onClick,
  disableMainNext,
  enableMainNext,
  handleHasNext,
  forSpouse,
}) => {
  const [planTypes] = React.useState<string[]>(
    MedicareConstants.getPlanTypesMa().map((planType) => planType)
  )

  const [selectedPlanType, setSelectedPlanType] = useState<string>(
    forSpouse
      ? currentPlanStore.spousePlanType
        ? currentPlanStore.spousePlanType
        : ''
      : currentPlanStore.planType
      ? currentPlanStore.planType
      : ''
  )
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  })

  useEffect(() => {
    handleHasNext && handleHasNext(true)
    if (!isEmpty(selectedPlanType)) {
      enableMainNext && enableMainNext()
    } else {
      disableMainNext && disableMainNext()
    }
  }, [])

  const handlePlanSelection = (e: any) => {
    if (forSpouse) {
      currentPlanStore.setSpousePlanType(e)
    } else {
      currentPlanStore.setPlanType(e)
    }

    setSelectedPlanType(e)
    enableMainNext && enableMainNext()
  }

  useEffect(() => {
    if (selectedPlanType !== '') enableMainNext && enableMainNext()
  }, [])

  return (
    <Container>
      <Heading>
        Please select your {forSpouse ? `spouse's` : ''}{' '}
        {forSpouse
          ? currentPlanStore.spouseQuoteType
            ? currentPlanStore.spouseQuoteType
            : ''
          : currentPlanStore.quoteType
          ? currentPlanStore.quoteType
          : ''}{' '}
        Plan
      </Heading>
      <Description>Select your {forSpouse ? `spouse's` : ''} plan</Description>
      <SelectWrapper screenWidth={dimensions.width}>
        <Select
          formLabel='Select your plan type'
          options={planTypes ? planTypes : ([] as string[])}
          onChange={(e) => {
            handlePlanSelection(e.target.value)
          }}
          height='39px'
          value={selectedPlanType}
          isInfoSteps={true}
        ></Select>
      </SelectWrapper>
    </Container>
  )
}

export default observer(SelectAdvantagePlan)
