import {
  isMillimanResponseProcessing,
  makeMillimanPrescriptionRequest,
} from '../../../../../service/prescriptions'

import BottomStepNavigator from '../../../BottomStepNavigator.component'
import CheckboxGroup from '../../../../common/CheckboxGroup/CheckboxGroup.component'
import DateField from '../../../../common/DateField'
import Filter from 'bad-words'
import InputField from '../../../../common/InputField/InputField.component'
import React from 'react'
import RouteConstants from '../../../../../constants/RouteConstants'
import { SnackbarTypes } from '../../../../../enums/SnackbarTypesEnum'
import StringConstants from '../../../../../constants/StringConstants'
import ValidationUtils from '../../../../../utils/validation/ValidationUtils'
import customerStore from '../../../../../datastore/CustomerStore'
import dayjs from 'dayjs'
import { getDateWithinApprovedDOBRange } from '../../../../../utils/CommonUtils'
import { isEmpty } from 'lodash'
import moment from 'moment'
import snackbarStore from '../../../../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../../../../global/theme'
import { useNavigate } from 'react-router-dom'

interface DisclosureStepProps {
  setStep: React.Dispatch<React.SetStateAction<number>>
}

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  .loader {
    width: 50px;
    aspect-ratio: 1;
    display: grid;
    border-radius: 50%;
    background: linear-gradient(
          0deg,
          rgb(0 0 0/50%) 30%,
          #0000 0 70%,
          rgb(0 0 0/100%) 0
        )
        50%/8% 100%,
      linear-gradient(90deg, rgb(0 0 0/25%) 30%, #0000 0 70%, rgb(0 0 0/75%) 0)
        50%/100% 8%;
    background-repeat: no-repeat;
    animation: l23 1s infinite steps(12);
  }
  .loader::before,
  .loader::after {
    content: '';
    grid-area: 1/1;
    border-radius: 50%;
    background: inherit;
    opacity: 0.915;
    transform: rotate(30deg);
  }
  .loader::after {
    opacity: 0.83;
    transform: rotate(60deg);
  }
  @keyframes l23 {
    100% {
      transform: rotate(1turn);
    }
  }
`

const DisclosureStepWrapper = styled.div`
  width: 100%;
  h3 {
    color: ${theme.colors.primary};
  }
  p {
    color: ${theme.colors.textGrey};
    font-size: 14px;
    padding: 5px 0px;
  }
`
const Label = styled.h4`
  font-size: 14px;
  color: ${theme.colors.textGrey};
  padding: 20px 0px 0px 0px;
  font-weight: 600;
`

const DisclosureStep: React.FC<DisclosureStepProps> = ({ setStep }) => {
  const filter = new Filter()
  const navigate = useNavigate()
  const [fullNameState, setFullNameState] = React.useState({
    value: '',
    error: false,
    helperText: '',
  })
  const [dobState, setDobState] = React.useState({
    value: '',
    error: false,
    helperText: '',
  })
  const [ssnState, setSsnState] = React.useState({
    value: '',
    error: false,
    helperText: '',
  })
  const [fraudConsentState, setFraudConsentState] = React.useState({
    value: false,
    error: false,
    helperText: '',
  })
  const [loading, setLoading] = React.useState(false)

  const yearsTo = dayjs().year()
  const defaultYear = yearsTo - 67
  const currentDate = dayjs(dobState.value || new Date())
  const defaultDate = currentDate.year(defaultYear).format('YYYY-MM-DD')

  const validateFullName = (fullName: string) => {
    fullName = fullName.trim()
    const fullNameError = !ValidationUtils.validateFullName(fullName)
    const fullNameChunks = fullName.split(' ')

    let helperText = ''
    if (isEmpty(fullName)) {
      helperText = StringConstants.UI_ERROR_MSG_FOR_EMPTY_FULL_NAME
    } else if (fullNameError && fullNameChunks[0].length < 2) {
      helperText = StringConstants.UI_ERROR_MSG_FOR_INVALID_FIRST_NAME
    } else if (
      fullNameError &&
      (fullNameChunks.length < 2 ||
        fullNameChunks[fullNameChunks.length - 1].length < 2)
    ) {
      helperText = StringConstants.UI_ERROR_MSG_FOR_INVALID_LAST_NAME
    } else if (fullNameError) {
      helperText = StringConstants.UI_ERROR_MSG_FOR_INVALID_FULL_NAME
    }

    setFullNameState((prev) => ({
      ...prev,
      error: fullNameError,
      helperText,
    }))
  }

  const handleFullNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fullName = isEmpty(e.target.value)
      ? ''
      : filter.clean(e.target.value).replaceAll('*', '')
    validateFullName(fullName)
    setFullNameState((prev) => ({ ...prev, value: fullName }))
  }

  const validateSsn = (ssn: string) => {
    const ssnError = !ValidationUtils.validateSsn(ssn)
    setSsnState((prev) => ({
      ...prev,
      error: ssnError,
      helperText:
        isEmpty(ssn) || ssnError
          ? StringConstants.UI_ERROR_MSG_FOR_INVALID_SSN
          : '',
    }))
  }

  const handleSsnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputSsn = e.target.value.replace(/\D/g, '')
    let maskedSsn = inputSsn
      .slice(0, 9)
      .replace(/(\d{3})(\d{0,2})(\d{0,4})/, (match, p1, p2, p3) => {
        // Apply the mask XXX-XX-XXXX
        let masked = p1
        if (p2) masked += '-' + p2
        if (p3) masked += '-' + p3
        return masked
      })
    e.target.value = maskedSsn
    validateSsn(maskedSsn)
    setSsnState((prev) => ({ ...prev, value: maskedSsn }))
  }

  const validateDOB = (dob: string) => {
    const dobError = !ValidationUtils.validateDOB(dob)
    setDobState((prev) => ({
      ...prev,
      error: dobError,
      helperText: isEmpty(dob)
        ? StringConstants.UI_ERROR_MSG_FOR_EMPTY_DOB
        : dobError
        ? StringConstants.UI_ERROR_MSG_FOR_INVALID_DOB
        : '',
    }))
    return !dobError
  }

  const handleDOBChange = (e: any) => {
    if (e) {
      const givenDOB = dayjs(e.$d).format('YYYY-MM-DD')
      if (validateDOB(givenDOB))
        setDobState((prev) => ({ ...prev, value: givenDOB }))
    }
  }

  const handleInputDOB = (e: any) => {
    if (e) {
      const givenDOB = dayjs(e.$d).format('YYYY-MM-DD')
      if (e.$y >= 1000) {
        const finalDate = getDateWithinApprovedDOBRange(e)
        validateDOB(finalDate)
        setDobState((prev) => ({ ...prev, value: finalDate }))
      } else validateDOB(givenDOB)
    }
  }

  const handleAgreeToDisclosure = async () => {
    if (isEmpty(fullNameState.value)) {
      setFullNameState((prev) => ({
        ...prev,
        error: true,
        helperText: StringConstants.UI_ERROR_MSG_FOR_EMPTY_FULL_NAME,
      }))
    }

    if (isEmpty(dobState.value)) {
      setDobState((prev) => ({
        ...prev,
        error: true,
        helperText: StringConstants.UI_ERROR_MSG_FOR_EMPTY_DOB,
      }))
    }

    if (!isEmpty(dobState.value)) {
      validateDOB(dobState.value)
    }

    if (isEmpty(ssnState.value)) {
      setSsnState((prev) => ({
        ...prev,
        error: true,
        helperText: StringConstants.UI_ERROR_MSG_FOR_INVALID_SSN,
      }))
    }

    if (!isEmpty(ssnState.value)) {
      validateSsn(ssnState.value)
    }

    if (!isEmpty(fullNameState.value)) {
      validateFullName(fullNameState.value)
    }

    if (!fraudConsentState.value) {
      setFraudConsentState((prev) => ({
        ...prev,
        error: true,
        helperText: 'You must agree to Fraud Statement Consent',
      }))
    }

    if (
      !(
        fullNameState.error ||
        dobState.error ||
        ssnState.error ||
        fraudConsentState.error ||
        isEmpty(ssnState.value) ||
        isEmpty(dobState.value) ||
        isEmpty(fullNameState.value) ||
        fraudConsentState.value === false
      )
    ) {
      try {
        setLoading(true)
        const nameChunks = fullNameState.value.trim().split(' ')
        await makeMillimanPrescriptionRequest({
          ssn: ssnState.value,
          firstName: nameChunks[0],
          lastName: nameChunks[nameChunks.length - 1],
          dateOfBirth: dobState.value,
        })

        customerStore.setIsSsnProvided(true)

        const isStillProcessing = await isMillimanResponseProcessing()

        if (!isStillProcessing) {
          setStep(4)
        }
      } catch (error) {
        snackbarStore.set({
          snackbarOpen: true,
          snackbarMessage: 'Something went wrong',
          snackbarType: SnackbarTypes.ERROR,
        })
      } finally {
        setLoading(false)
      }
    }
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  React.useEffect(() => {
    setFullNameState((prev) => ({
      ...prev,
      value: customerStore.getFullName(),
    }))
    setDobState((prev) => ({
      ...prev,
      value: customerStore.dateOfBirth.substring(0, 10),
    }))
  }, [])

  return loading ? (
    <DisclosureStepWrapper style={{ textAlign: 'center' }}>
      <h3>We're creating a list of your current prescriptions</h3>
      <h3 style={{ padding: '20px 0px', color: theme.colors.textGrey }}>
        Please wait... loading may take a few moments. Do not close this window.
      </h3>
      <LoaderWrapper>
        <div className='loader' />
      </LoaderWrapper>
    </DisclosureStepWrapper>
  ) : (
    <div>
      <DisclosureStepWrapper>
        <h3>Add your prescription drugs</h3>
        <p>
          We must validate your Social Security Number in order to look up a
          list of your current prescriptions. This may take a few moments.
        </p>
        <p style={{ fontSize: '16px', padding: '20px 0px' }}>
          <strong>Disclosure</strong>
        </p>
        <p>
          {customerStore.getFullName()}, for the purposes in selecting a plan,
          do you authorize any pharmacy or pharmacy benefit manager that
          possesses any prescription information to supply this information to
          Elite Insurance Partners? This may include information on mental
          health, substance use, HIV, AIDS, and communicable diseases, unless
          otherwise restricted by state law. Any information Elite Insurance
          Partners obtains will not be re-disclosed without your authorization
          unless permitted by law, in which case it may not be protected under
          federal privacy rules. This authorization shall be valid for 30 days
          from this date and may be revoked by sending written notice to{' '}
          <strong>
            Elite Insurance Partners at 8745 Henderson Rd, Ste 220 Tampa, FL
            33634
          </strong>
          . An insurance company may not condition treatment, payment,
          enrollment, or eligibility for benefits on whether you agree to this
          authorization.
        </p>
        <p>
          <strong>
            If you agree to the above, please provide your e-signature by
            clicking on agree.
          </strong>
        </p>
        <CheckboxGroup
          checkboxState={[
            {
              id: 1,
              checked: fraudConsentState.value,
              label: (
                <span style={{ textDecoration: 'underline' }}>
                  Fraud Statement Consent
                </span>
              ),
            },
          ]}
          onChange={(e) => {
            setFraudConsentState((prev) => ({
              ...prev,
              value: e.target.checked,
              error: !e.target.checked,
              helperText: e.target.checked
                ? ''
                : 'You must agree to Fraud Statement Consent',
            }))
          }}
        />
        {fraudConsentState.error && (
          <label
            style={{
              color: theme.colors.secondary,
              fontSize: '14px',
              display: 'block',
              paddingTop: '10px',
            }}
          >
            {fraudConsentState.helperText}
          </label>
        )}
        <form>
          <div style={{ display: 'flex', columnGap: '20px', flexWrap: 'wrap' }}>
            <div>
              <Label>
                Enter your Full Name
                <span style={{ color: `${theme.colors.secondary}` }}>*</span>
              </Label>
              <InputField
                placeholder='Jhon Doe'
                error={fullNameState.error}
                helperText={fullNameState.helperText}
                width='250px'
                value={fullNameState.value}
                onChange={handleFullNameChange}
              />
            </div>
            <div>
              <Label>
                Enter your Social Security Number (SSN)
                <span style={{ color: `${theme.colors.secondary}` }}>*</span>
              </Label>
              <InputField
                placeholder='### - ## - ####'
                error={ssnState.error}
                helperText={ssnState.helperText}
                width='250px'
                value={ssnState.value}
                onChange={handleSsnChange}
              />
            </div>
          </div>
          <div>
            <Label>
              Enter your Date of Birth
              <span style={{ color: `${theme.colors.secondary}` }}>*</span>
            </Label>
            <DateField
              format='MM/DD/YYYY'
              value={dayjs(dayjs(dobState.value).format(`YYYY-MM-DD`))}
              minDate={dayjs(
                (moment().year() - 120).toString().substring(0, 2) + '00-01-01'
              )}
              maxDate={dayjs(moment().format('YYYY-MM-DD'))}
              defaultCalendarMonth={dayjs(
                moment(defaultDate).startOf('day').format('YYYY-MM-DD')
              )}
              error={dobState.error}
              errorText={dobState.helperText}
              readonly={false}
              onAccept={handleDOBChange}
              onChange={handleInputDOB}
            />
          </div>
        </form>
      </DisclosureStepWrapper>
      <BottomStepNavigator
        buttonText3='Agree'
        button3Variant='secondary'
        handleNext={handleAgreeToDisclosure}
        handleBack={() => {
          setStep(2)
        }}
        handleViewPlans={() => {
          window.scrollTo({ top: 0, behavior: 'smooth' })
          navigate(RouteConstants.DASHBOARD)
        }}
        currentInfoStep='Prescriptions'
      />
    </div>
  )
}

export default DisclosureStep
