import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import AddSpouse from './AddSpouse.component'
import { CgProfile } from 'react-icons/cg'
import { GiHeartPlus } from 'react-icons/gi'
import { ImFileText2 } from 'react-icons/im'
import LeftNavbar from './LeftNavbar.component'
import PharmacyIcon from '../common/Icon/PharmacyIcon.component'
import PrescriptionIcon from '../common/Icon/PrescriptionIcon.component'
import ProviderIcon from '../common/Icon/ProviderIcon.component'
import React from 'react'
import RouteConstants from '../../constants/RouteConstants'
import SecureComponent from '../../pages/common/SecureComponent'
import customerStore from '../../datastore/CustomerStore'
import { observer } from 'mobx-react'
import styled from '@emotion/styled'
import theme from '../../global/theme'
import { useBurgerMenu } from '../../context/SidebarContext'
import { useCurrentSubrouteContext } from '../../pages/ProfilePage/ProfilePage'
import { withRouter } from '../common/WithRouter/WithRouter.component'

interface SpouseSectionContainerProps {
  display?: 'block' | 'none'
  location?: {
    state: {
      from: {
        pathname: string
      }
    }
  }
}

interface SpouseSectionProps extends SpouseSectionContainerProps {}

const Container = styled.div<SpouseSectionContainerProps>`
  display: ${(props) => props.display};
`

const ContentContainer = styled.div`
  display: flex;
`

const Wrapper = styled.div`
  width: 100%;
  padding: 20px 80px;

  @media screen and (max-width: 950px) {
    padding: 20px 40px;
  }

  @media screen and (max-width: 600px) {
    padding: 10px 20px;
  }
`

const SectionHeading = styled.div`
  color: ${theme.colors.textDark};
  display: flex;
  align-items: center;
  z-index: 500;
  background-color: ${theme.colors.white};
  border-bottom: 1px solid ${theme.colors.borderColor};
  padding: 10px 0px;
  h2 {
    font-size: 24px;
    font-weight: 600;
  }
  @media screen and (max-width: 600px) {
    top: 95px;
    h2 {
      font-size: 18px;
    }
  }
  @media screen and (max-width: 400px) {
    top: 85px;
  }
`

const SpouseSection: React.FC<SpouseSectionProps> = (
  props: SpouseSectionProps
) => {
  const { setShowBurgerMenu } = useBurgerMenu()
  const { setCurrentSubRoute, currentSubRoute } = useCurrentSubrouteContext()
  const [activeNav, setActiveNav] = React.useState(currentSubRoute)

  const navigate = useNavigate()
  const location = useLocation().pathname

  const navbarProps = {
    navLinks: [
      {
        label: 'Profile',
        icon: <CgProfile />,
        link: 'profile',
      },
      {
        label: 'Medicare Info',
        link: 'medicare-info',
        icon: <ImFileText2 />,
      },
      {
        label: 'Health',
        icon: <GiHeartPlus />,
        link: 'health',
      },
      {
        label: 'Prescriptions',
        link: 'prescriptions',
        icon: (
          <PrescriptionIcon
            color={activeNav === 'prescriptions' ? '#FFFFFF' : undefined}
          />
        ),
      },
      {
        label: 'Pharmacy',
        link: 'pharmacy',
        icon: (
          <PharmacyIcon
            color={activeNav === 'pharmacy' ? '#FFFFFF' : undefined}
          />
        ),
      },
      {
        label: 'Doctors',
        link: 'doctors',
        icon: (
          <ProviderIcon
            color={activeNav === 'doctors' ? '#FFFFFF' : undefined}
          ></ProviderIcon>
        ),
      },
    ],
  }

  React.useEffect(() => {
    if (customerStore.spouseOptIn) setShowBurgerMenu(true)
    else setShowBurgerMenu(false)
  }, [customerStore.spouseOptIn])

  const activeNavHandler = (link: string) => {
    toggleSidebar()
    setActiveNav(link)
    setCurrentSubRoute(link)
    navigate(link, {
      state: {
        from:
          (props.location &&
            props.location.state &&
            props.location.state.from) ||
          RouteConstants.DASHBOARD,
      },
    })
  }

  React.useEffect(() => {
    if (currentSubRoute !== location.split('/')[3]) {
      setActiveNav(location.split('/')[3])
      setCurrentSubRoute(location.split('/')[3])
    }
  }, [currentSubRoute, location, setCurrentSubRoute])

  React.useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
  }, [])

  const { toggleSidebar, isOpen } = useBurgerMenu()

  return (
    <Container display={props.display}>
      {customerStore.spouseOptIn ? (
        <ContentContainer>
          <LeftNavbar
            {...navbarProps}
            activeNav={activeNav}
            onClick={activeNavHandler}
            showLeftNavHandler={toggleSidebar}
            showLeftNav={isOpen}
            isSpouse={true}
          />
          <Wrapper>
            <SectionHeading>
              <h2>
                {
                  navbarProps.navLinks.find(
                    (iterator) => iterator.link === activeNav
                  )?.label
                }
              </h2>
            </SectionHeading>
            <Outlet />
          </Wrapper>
        </ContentContainer>
      ) : (
        <AddSpouse />
      )}
    </Container>
  )
}

export default SecureComponent(withRouter(observer(SpouseSection)))
