import { AiFillCloseCircle } from 'react-icons/ai'
import BouncingDotsLoader from '../../../common/Loading/BouncingDotsLoader'
import Card from '../../../common/Card/Card.component'
import { MdOutlineDone } from 'react-icons/md'
import { QuotesRefreshContext } from '../../../../context/QuotesRefreshContext'
import React from 'react'
import { SnackbarTypes } from '../../../../enums/SnackbarTypesEnum'
import StringConstants from '../../../../constants/StringConstants'
import { deleteProvider } from '../../../../service/providers'
import mPartDPlansFilterStore from '../../../../datastore/medicareQuotes/MPartPlanFilterQuotesStore'
import medicareAdvantageFilterQuoteStore from '../../../../datastore/medicareQuotes/MedicareAdvantageFilterQuoteStore'
import { reject } from 'lodash'
import snackbarStore from '../../../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../../../global/theme'

interface AddedDoctorProps extends AddressWrapperProps {
  addDoctorState?: {
    AddedDoctor: any[]
    Doctor: any[]
    addingDoctors: boolean
  }
  setAddDoctorState?: React.Dispatch<
    React.SetStateAction<{
      AddedDoctor: any[]
      Doctor: any[]
      addingDoctors: boolean
    }>
  >
  data: any
  borderColor?: any
  children?: JSX.Element | string | undefined
  type?: 'highlighted' | 'normal'
  setIsEditProvider?: (flag: boolean) => void
  forSpouse?: boolean
  setDeleteProviderLoading?: (flag: boolean) => void
}

interface AddressWrapperProps {
  showSpecialization?: boolean
}

const Container = styled.div`
  font-size: 12px;
  p {
    padding-bottom: 10px;
  }
`

const Header = styled.div`
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  h2 {
    font-size: 20px;
    color: ${theme.colors.primary};
  }
  span {
    color: red;
  }
`
const PostalCodeWrapper = styled.div`
  padding: 5px 10px;
  background-color: ${theme.colors.contrastPrimary};
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 0px 0px 15px 15px;
  h4 {
    font-size: 14px;
  }
`
const AddressWrapper = styled.p<AddressWrapperProps>`
  margin-bottom: ${(props) => (props.showSpecialization ? '24px' : '0px')};
`

const AddedDoctor: React.FC<AddedDoctorProps> = ({
  addDoctorState,
  setAddDoctorState,
  data,
  borderColor = `${theme.colors.primary}`,
  children,
  showSpecialization = true,
  type = 'normal',
  setIsEditProvider = () => {},
  forSpouse,
  setDeleteProviderLoading = () => {},
}) => {
  const { isQuotesRefreshing } = React.useContext(QuotesRefreshContext)

  const handleEdit = () => {
    if (addDoctorState) {
      setIsEditProvider(true)
      const tempAddedDoctor = addDoctorState.AddedDoctor
      const tempDoctor = addDoctorState.AddedDoctor.filter(
        (item) => item.id === data.id
      )

      const filteredDoctor = tempDoctor[0]
      const doctorForEditing = tempDoctor[0]

      doctorForEditing.id = filteredDoctor.id
      doctorForEditing.name = filteredDoctor.name
      doctorForEditing.address1 = filteredDoctor.address1
      doctorForEditing.address2 = filteredDoctor.address2
      doctorForEditing.city = filteredDoctor.city
      doctorForEditing.externalId = filteredDoctor.externalId
      doctorForEditing.npi = filteredDoctor.npi

      if (setAddDoctorState) {
        setAddDoctorState({
          ...addDoctorState,
          AddedDoctor: tempAddedDoctor,
          addingDoctors: true,
          Doctor: [doctorForEditing],
        })
      }
    }
  }

  const handleDelete = () => {
    setDeleteProviderLoading(true)
    deleteProvider(data.id, forSpouse ? forSpouse : false)
      .then(() => {
        mPartDPlansFilterStore.clearStore()
        medicareAdvantageFilterQuoteStore.clearStore()
        snackbarStore.set({
          snackbarMessage: StringConstants.SNACKBAR_DELETE_SUCCESS_MSG,
          snackbarOpen: true,
          snackbarType: SnackbarTypes.SUCCESS,
        })
        if (addDoctorState) {
          const temp = addDoctorState.AddedDoctor.filter(
            (item) => item.id !== data.id
          )
          if (setAddDoctorState) {
            setAddDoctorState({
              ...addDoctorState,
              AddedDoctor: temp,
            })
          }
        }
      })
      .catch((err: any) => {
        reject(err)
      })
      .finally(() => {
        setDeleteProviderLoading(false)
      })
  }
  return (
    <Card
      isScrollRequired={false}
      cardType='edit'
      borderColor={borderColor}
      deleteCard={handleDelete}
      editCard={handleEdit}
      isQuotesRefreshing={isQuotesRefreshing}
    >
      {data ? (
        <Container>
          <Header>
            <div>
              <h2>{data.name}</h2>
              {type === 'highlighted' &&
                (children ? (
                  <span style={{ color: 'red', marginLeft: '8px' }}>
                    <AiFillCloseCircle />
                  </span>
                ) : (
                  <span style={{ color: 'green', marginLeft: '8px' }}>
                    <MdOutlineDone />
                  </span>
                ))}
            </div>
          </Header>
          <AddressWrapper showSpecialization={showSpecialization}>
            {data.address1}
            {data.address2 ? ',' : ''} {data.address2} <br /> {data.city}
          </AddressWrapper>
          {children
            ? children
            : showSpecialization && (
                <PostalCodeWrapper>
                  <h4>
                    {' '}
                    {data.state} {data.zip}
                  </h4>
                </PostalCodeWrapper>
              )}
        </Container>
      ) : (
        <BouncingDotsLoader />
      )}
    </Card>
  )
}

export default AddedDoctor
