import React, { useCallback, useEffect, useRef } from 'react'

import BouncingDotsLoader from '../Loading/BouncingDotsLoader'
import InputField from '../InputField/InputField.component'
import { debounce } from 'lodash'
import styled from '@emotion/styled'
import theme from '../../../global/theme'

interface SearchBarProps {
  handleSearchClick?: (item: object) => void
  placeholder: string
  data: any[]
  variant?: 'default' | 'outlined'
  type?: 'default' | 'checkbox' | 'radio'
  isSearching?: boolean
  minInputLengthBeforeFiltering?: number
  disabled?: boolean
  fetchSearchResults?: (searchWord: string) => void
  loading?: boolean
  physicianDropdown?: boolean
  isInfoSteps?: boolean
  label?: string
}

const Container = styled.div<{
  variant: 'default' | 'outlined'
}>`
  width: 100%;
  border: ${(props) =>
    props.variant === 'outlined' && `1px solid ${theme.colors.borderColor}`};
  padding: 10px 0px;
  border-radius: 4px;
  position: relative;
`

const SearchBox = styled.div<{
  physicianDropdown: boolean
  isInfoSteps: boolean
}>`
  border-radius: 8px;
  box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  width: 100%;
  max-height: ${(props) => (props.isInfoSteps === true ? `320px` : `180px`)};
  @media screen and (max-width: 900px) {
    max-height: ${(props) => (props.physicianDropdown ? `280px` : `200px`)};
  }

  @media screen and (max-width: 700px) {
    position: ${(props) => props.physicianDropdown && `relative`};
  }

  overflow-y: auto;
  border: 1px solid ${theme.colors.borderColor};
  position: absolute;
  z-index: 1000;
  background-color: white;
`

const SearchItem = styled.div`
  border-bottom: 1px solid ${theme.colors.borderColor};
  text-align: left;
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: ${theme.colors.contrastPrimary};
  }
`

const SearchBar: React.FC<SearchBarProps> = ({
  handleSearchClick,
  placeholder,
  variant = 'default',
  data,
  type = 'default',
  isSearching = false,
  minInputLengthBeforeFiltering = 0,
  disabled = false,
  fetchSearchResults,
  loading = false,
  physicianDropdown = false,
  isInfoSteps = false,
  label,
}) => {
  const [filteredData, setFilteredData] = React.useState<any[]>([])
  const [searchTerm, setSearchTerm] = React.useState('')
  const [searchValue, setSearchValue] = React.useState('')
  const [isBlurred, setIsBlurred] = React.useState(false)
  const dropdownRef: any = useRef(null)

  React.useEffect(() => {
    if (disabled) {
      setSearchValue('')
      setSearchTerm('')
      setFilteredData([])
    }
  }, [disabled])

  useEffect(() => {
    if (searchValue.length > 2) {
      setFilteredData(data)
    }
  }, [data])

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsBlurred(true)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const debouncedFilterHandler = useCallback(
    debounce((value) => {
      let searchWord = ''
      if (
        minInputLengthBeforeFiltering === 0 ||
        value.length >= minInputLengthBeforeFiltering
      )
        searchWord = value
      const newFilter = data
        ? data.filter((value) => {
            return value.title.toLowerCase().includes(searchWord.toLowerCase())
          })
        : []
      if (searchWord === '' || searchWord.length < 3) {
        setFilteredData([])
        setSearchTerm('')
      } else {
        setFilteredData(newFilter)
        setSearchTerm(searchWord)

        if (searchWord.length >= 3 && fetchSearchResults) {
          fetchSearchResults(searchWord)
        }
      }
    }, 300),
    []
  )

  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const regex = /^[a-zA-Z0-9 ]*$/
    if (regex.test(value)) {
      setSearchValue(value)
      debouncedFilterHandler(value)
    }
  }

  return (
    <Container variant={variant} ref={dropdownRef}>
      <InputField
        placeholder={placeholder}
        label={label}
        width='100%'
        onChange={handleFilter}
        disabled={disabled}
        value={searchValue}
        onFocus={(e) => {
          handleFilter(e)
          setIsBlurred(false)
        }}
      />
      {type === 'default' &&
        (filteredData.length !== 0 || variant === 'outlined') &&
        !isBlurred && (
          <SearchBox
            physicianDropdown={physicianDropdown}
            isInfoSteps={isInfoSteps}
            onBlur={(e) => {
              e.preventDefault()
            }}
          >
            {!isSearching &&
              !disabled &&
              filteredData.slice(0, 15).map((item, key) => {
                return (
                  <SearchItem
                    onClick={() => {
                      if (handleSearchClick) {
                        handleSearchClick(item)
                      }
                    }}
                    key={key}
                  >
                    <p
                      style={{
                        fontSize: '14px',
                        color: theme.colors.textDark,
                        paddingBottom: '5px',
                        fontWeight: '700',
                      }}
                    >
                      {item.title}
                    </p>
                    <p
                      style={{
                        fontSize: '12px',
                        color: theme.colors.textGrey,
                      }}
                    >
                      {item?.subtitle}
                    </p>
                  </SearchItem>
                )
              })}

            {isSearching && <BouncingDotsLoader padding='15px' />}
          </SearchBox>
        )}
      {!loading ? (
        type === 'default' &&
        (filteredData.length === 0 || variant === 'outlined') &&
        searchTerm !== '' &&
        ((physicianDropdown && searchValue.length >= 3) ||
          !physicianDropdown) && (
          <SearchBox
            physicianDropdown={physicianDropdown}
            isInfoSteps={isInfoSteps}
          >
            <SearchItem>
              <p
                style={{
                  fontSize: '14px',
                  color: theme.colors.textDark,
                  paddingBottom: '5px',
                }}
              >
                No results found
              </p>
            </SearchItem>
          </SearchBox>
        )
      ) : (
        <BouncingDotsLoader padding='15px' />
      )}
    </Container>
  )
}

export default SearchBar
