import Button from '../common/Button/Button.component'
import Tooltip from '../common/Tooltip/Tooltip.component'
import { convertToUSCurrency } from '../../utils/CurrencyConverterUtil'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import theme from '../../global/theme'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  align-items: center;
  color: ${theme.colors.textGrey};
`

const PremiumWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 700;
  color: ${theme.colors.primary};
`
interface MonthlyPremiumProps {
  username: string
  premium: number
  loading: boolean
  loaderColor: string
  buttonVariant: 'text' | 'outlined' | 'contained' | undefined
  color:
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'contrastPrimary'
    | undefined
  onEnroll: (forSpouse: boolean, planId: string) => void
  carrierName: string
  planName?: string
  isEnrolled: boolean
  btnText: string
  tooltipContent?: string
  forSpouse: boolean
  enrollmentRedirectURL?: string
  planId: string
}

const MonthlyPremium: React.FC<MonthlyPremiumProps> = ({
  buttonVariant,
  loading,
  loaderColor,
  onEnroll,
  premium,
  username,
  color,
  carrierName,
  planName = undefined,
  isEnrolled,
  btnText,
  tooltipContent = undefined,
  forSpouse,
  enrollmentRedirectURL = undefined,
  planId,
}) => {
  return (
    <>
      <Container>
        <p>{isEmpty(username) ? `Spouse` : `${username}'s`} Monthly Premium</p>
        <PremiumWrapper>
          {convertToUSCurrency(premium)}{' '}
          {tooltipContent && <Tooltip title={tooltipContent}></Tooltip>}
        </PremiumWrapper>
        <Button
          loading={loading}
          loaderColor={loaderColor}
          variant={buttonVariant}
          color={color}
          onClick={() => {
            if (!isEmpty(enrollmentRedirectURL))
              window.open(enrollmentRedirectURL, '_blank')
            onEnroll(forSpouse, planId)
          }}
          disabled={isEnrolled}
          description={`Attempted Enrollment in  ${carrierName} plan ${planName}`}
          id={`enroll  ${carrierName} plan ${planName}`}
          width='250px'
        >
          {btnText}
        </Button>
      </Container>
    </>
  )
}

export default observer(MonthlyPremium)
