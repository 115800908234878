import { deletePharmacy, fetchPharmacies } from '../../../../service/pharmacy'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { ActionContext } from '../../../../cra'
import ApiManager from '../../../../api/ApiManager'
import BottomStepNavigator from '../../BottomStepNavigator.component'
import BouncingDotsLoader from '../../../common/Loading/BouncingDotsLoader'
import Button from '../../../common/Button/Button.component'
import Card from '../../../common/Card/Card.component'
import { Checkbox } from '@mui/material'
import { County } from '../../../../interface/CountyInterface'
import { HttpMethods } from '../../../../enums/HttpMethodsEnum'
import { MdLocationPin } from 'react-icons/md'
import ModalComponent from '../../../common/Modal/Modal.component'
import OverlayLoader from '../../../common/OverlayLoader/OverlayLoader'
import PharmacyListSearchInfoSteps from '../../../ProfilePage/Modals/PharmacyListSearchInfoSteps'
import { PharmacyObj } from '../../../../interface/PharmacyInterface'
import { PharmacyRadiusEnum } from '../../../../enums/PharmacyRadiusEnum'
import PostalCodeSelection from '../../../common/PostalCodeSelection/PostalCodeSelection'
import React from 'react'
import RouteConstants from '../../../../constants/RouteConstants'
import SecureComponent from '../../../../pages/common/SecureComponent'
import Select from '../../../common/Select/Select.component'
import { SelectChangeEvent } from '@mui/material'
import SkeletonCard from '../../../DashboardPage/SkeletonCard'
import { SnackbarTypes } from '../../../../enums/SnackbarTypesEnum'
import StringConstants from '../../../../constants/StringConstants'
import Tooltip from '../../../common/Tooltip/Tooltip.component'
import UrlConstants from '../../../../constants/UrlConstants'
import currentPlanStore from '../../../../datastore/CurrentPlanStore'
import customerStore from '../../../../datastore/CustomerStore'
import { deleteInfoStepStatus } from '../../../../service/customer'
import { getApiErrorMessage } from '../../../../utils/StringUtils'
import { getPlanYear } from '../../../../utils/CommonUtils'
import { isEmpty } from 'lodash'
import mPartDPlansFilterStore from '../../../../datastore/medicareQuotes/MPartPlanFilterQuotesStore'
import medicareAdvantageFilterQuoteStore from '../../../../datastore/medicareQuotes/MedicareAdvantageFilterQuoteStore'
import { observer } from 'mobx-react'
import { pollQuotesPullStatus } from '../../../../utils/quotesPullStatus'
import snackbarStore from '../../../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../../../global/theme'
import userActionStore from '../../../../datastore/UserActionStore'

interface PharmacyScreenProps {
  showBottomStepNavigator?: boolean
  redirect?: boolean
  isSpouse?: boolean
}

type PharmacyType = 'COMMUNITY_PHARMACY' | 'MAIL_ORDER_PHARMACY'

const Container = styled.div`
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const Heading = styled.h3`
  font-size: 20px;
  font-weight: 700px;
  padding-bottom: 20px;
  color: ${theme.colors.primary};
`
const Description = styled.p`
  padding-bottom: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #515151;
`

const AddressWrapper = styled.div`
  width: 100%;
  padding: 10px 0px;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 1110px) {
    flex-direction: column;
    gap: 20px;
  }
`

const AddressContainer = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 900px) {
    flex-direction: column;
    align-items: baseline;
  }
`

const SearchMilesWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;

  h2 {
    color: black;
    margin-bottom: 40px;
  }

  @media screen and (max-width: 1110px) {
    margin-left: initial;
  }
`

const SearchPromptWrapper = styled.div`
  padding: 10px 0px 20px 0px;

  @media screen and (max-width: 700px) {
    padding: 10px 0px 10px 0px;
  }
`
const DisableText = styled.div`
  font-size: 14px;
  margin-left: 5px;
  color: ${theme.colors.secondary};
`
const Address = styled.div`
  font-weight: 700;
  padding-left: 4px;
  span {
    margin-left: 15px;
    font-weight: 700px;
    color: ${theme.colors.primary};
    text-decoration: underline;
    cursor: pointer;
    line-height: 18px;
    font-size: 12px;
  }

  @media screen and (max-width: 900px) {
    padding-left: 0px;
  }
`

const SelectedPharmacyWrapper = styled.div`
  display: flex;
  column-gap: 10px;

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }

  h2 {
    color: ${theme.colors.primary};
    padding-top: 20px;
    font-size: 18px;
  }
`

const SelectedPharmacyCard = styled.div`
  h3 {
    font-weight: 600;
    color: ${theme.colors.textDark};
    font-size: 16px;
    padding-bottom: 10px;
    max-width: 65%;
  }
  p {
    font-weight: 400;
    color: ${theme.colors.textGrey};
    font-size: 14px;
  }
`

const SavedPharmacies = styled.div`
  color: #2f3032;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  border-top: 1px solid ${theme.colors.borderColor};
  padding: 20px 0px 10px 0px;
`

const CardWrapper = styled.div`
  width: 50%;
  @media screen and (max-width: 900px) {
    width: 100%;
  }
`

const PharmacyScreen: React.FC<PharmacyScreenProps> = ({
  showBottomStepNavigator = true,
  redirect = true,
  isSpouse = false,
}) => {
  const [zipcodeInfo, setZipcodeInfo] = React.useState<{
    code: string
    county?: County
    state?: string
    optCounties?: County[]
  }>({
    code: customerStore.pharmacyPostalCode || customerStore.postalCode,
    county: userActionStore.zipCodeCache.find(
      (iterator) => iterator.code === customerStore.pharmacyPostalCode
    )?.county || {
      countyName: customerStore.county,
      countyFips: '',
    },
    state:
      userActionStore.zipCodeCache.find(
        (iterator) => iterator.code === customerStore.pharmacyPostalCode
      )?.state || customerStore.state,
    optCounties: [],
  })
  const [showPostalCodeSelection, setShowPostalCodeSelection] =
    React.useState(false)
  const [pharmacyList, setPharmacyList] = React.useState<PharmacyObj[]>([])

  const [addedPharmacyList, setAddedPharmacyList] = React.useState<
    PharmacyObj[]
  >([])

  const [isPharmacySelected, setIsPharmacySelected] = React.useState(false)
  const [radioButtonOption, setRadioButtonOption] = React.useState('Retail')
  const [state, setState] = React.useState({
    loading: true,
    buttonLoading: false,
    nextButtonLoading: false,
    addedPharmaciesLoading: false,
  })
  const [overlayLoading, setOverlayLoading] = React.useState(false)
  const [isOpenPharmacySearch, setOpenPharmacySearch] = React.useState(false)
  const [searchInputLoading, setSearchInputLoading] = React.useState(false)
  const radiusOptions = Object.values(PharmacyRadiusEnum)
  const isButtonDisabled =
    customerStore.isMAQuotesProcessing ||
    customerStore.isMSQuotesProcessing ||
    customerStore.isMPartDQuotesProcessing
  const [radiusDisplayValue, setRadiusDisplayValue] = React.useState(
    radiusOptions[0].toString()
  )
  const [radius, setRadius] = React.useState<number>(
    +radiusOptions[0].toString().replace(' miles', '')
  )
  const [forSpouse, setForSpouse] = React.useState(isSpouse)

  const navigate = useNavigate()
  const { trackCurrentPage } = React.useContext(ActionContext)
  const [searchParams] = useSearchParams()

  React.useEffect(() => {
    setForSpouse(searchParams.get('forSpouse') === 'true' || isSpouse || false)
  }, [searchParams, isSpouse])

  const getPharmacyInfo = React.useCallback(() => {
    fetchPharmacies(forSpouse)
      .then((response: any) => {
        if (response?.message === 'No pharmacy exists for the customer') {
          setState((s) => ({
            ...s,
            loading: false,
          }))
        } else {
          const pharmacyData = response as PharmacyObj[]
          setAddedPharmacyList([...pharmacyData])

          setState((s) => ({
            ...s,
            loading: false,
          }))
          if (pharmacyData.length !== 0) {
            setSearchInputLoading(false)
            setIsPharmacySelected(true)
          } else setIsPharmacySelected(false)
        }
      })
      .catch((err) => {
        setRadioButtonOption('Retail')
        setIsPharmacySelected(false)
        setAddedPharmacyList([])
        snackbarStore.set({
          snackbarMessage: getApiErrorMessage(
            'fetch selected pharmacy details'
          ),
          snackbarOpen: true,
          snackbarType: 'error',
        })

        setState((s) => ({
          ...s,
          loading: false,
        }))
      })
  }, [forSpouse])

  React.useEffect(() => {
    trackCurrentPage(
      `${showBottomStepNavigator ? 'Info Steps -' : 'Profile -'} ${
        forSpouse ? 'Spouse/Partner’s Pharmacy' : 'Pharmacy'
      }`
    )
    setRadioButtonOption('Retail')
    setAddedPharmacyList([])
    setIsPharmacySelected(false)
    getPharmacyInfo()
  }, [forSpouse])

  React.useEffect(() => {
    if (customerStore.isMSQuotesProcessing) pollQuotesPullStatus()
  }, [customerStore.isMSQuotesProcessing])

  React.useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
  }, [])

  const changeRadius = (selectedRadiusDisplayValue: string) => {
    setRadius(+selectedRadiusDisplayValue.replace(' miles', ''))
    setRadiusDisplayValue(selectedRadiusDisplayValue)
  }

  const handleRadiusChange = (e: SelectChangeEvent<string>) => {
    changeRadius(e.target.value)
  }

  const handlePharmacySelection = (item: any[]) => {
    handleSavePharmacy(item, 'COMMUNITY_PHARMACY')
  }

  const handleSelectedPharmacyDelete = (id: string): void => {
    setState({
      ...state,
      addedPharmaciesLoading: true,
    })

    const addedPharmacies = addedPharmacyList

    deletePharmacy(id, forSpouse)
      .then((res) => {
        snackbarStore.set({
          snackbarMessage: StringConstants.SNACKBAR_DELETE_SUCCESS_MSG,
          snackbarOpen: true,
          snackbarType: SnackbarTypes.SUCCESS,
        })
        if (!forSpouse) {
          medicareAdvantageFilterQuoteStore.clearStore()
          mPartDPlansFilterStore.clearStore()
          pollQuotesPullStatus(getPlanYear())
        }
        if (addedPharmacies.length === 1) {
          setIsPharmacySelected(false)
          setAddedPharmacyList([])
          setRadioButtonOption('Retail')

          if (customerStore.get().forSpouse === false) {
            deleteInfoStepStatus('pharmacy')
              .then((res) => {
                customerStore.setInfoSteps([
                  ...customerStore.infoSteps.filter((iterator: string) => {
                    if (iterator !== 'pharmacy') return iterator
                  }),
                ])
              })
              .catch((err) => {
                snackbarStore.set({
                  snackbarMessage: 'Something went wrong',
                  snackbarOpen: true,
                  snackbarType: 'error',
                })
              })
          }
        } else if (addedPharmacies.length === 3) {
          const filteredPharmacies = addedPharmacies.filter(
            (iterator) => iterator.id !== id
          )
          setAddedPharmacyList(filteredPharmacies)
        }
        getPharmacyInfo()
      })
      .catch((err) => {})
      .finally(() => {
        setState({
          ...state,
          addedPharmaciesLoading: false,
        })
      })
  }

  const getDetailsByIndex = (index: number) => {
    return pharmacyList[index]
  }

  const handleSavePharmacy = (
    items: object[] | null,
    type: 'COMMUNITY_PHARMACY' | 'MAIL_ORDER_PHARMACY'
  ) => {
    setState({
      ...state,
      // loading: true,
      buttonLoading: true,
      nextButtonLoading: true,
      addedPharmaciesLoading: true,
    })
    setOverlayLoading(() => true)
    setOpenPharmacySearch(false)
    setSearchInputLoading(true)
    setIsPharmacySelected(true)

    let pharmacyDetails: any[] = []

    if (items && items.length > 0) {
      pharmacyDetails = items.map((item) => {
        return getDetailsByIndex((item as any).index)
      })
    }

    const pharmacyIds: string[] = pharmacyDetails.map((pharmacy: any) => {
      return pharmacy.id.toString()
    })

    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.POST_PHARMACY_DETAILS_INFO_STEPS_SF.USECASE,
      HttpMethods.POST_METHOD,
      { pharmacyIds, $forSpouse: forSpouse }
    )
      .then((res) => {
        if (res.data.status === 'success') {
          snackbarStore.set({
            snackbarOpen: true,
            snackbarMessage: StringConstants.SNACKBAR_SAVE_SUCCESS_MSG,
            snackbarType: SnackbarTypes.SUCCESS,
          })

          if (customerStore.getSpouseOptIn() === false || !forSpouse) {
            ApiManager.makeApiCallWithAuthentication(
              UrlConstants.PATCH_INFO_STEPS.USECASE,
              HttpMethods.PATCH_METHOD,
              {
                $infoStep: 'pharmacy',
              }
            )
              .then((res) => {
                if (res.status === 200) {
                  if (!forSpouse) {
                    medicareAdvantageFilterQuoteStore.clearStore()
                    mPartDPlansFilterStore.clearStore()
                    pollQuotesPullStatus(getPlanYear())
                  }
                  customerStore.setInfoSteps([
                    ...customerStore.infoSteps,
                    'pharmacy',
                  ])
                } else throw res
              })
              .catch((err) => {
                snackbarStore.set({
                  snackbarMessage: 'Something went wrong',
                  snackbarOpen: true,
                  snackbarType: 'error',
                })
              })
          }
          getPharmacyInfo()
        } else throw res
      })
      .catch((err) => {
        if (
          /Pharmacy/.test(err?.data?.message) &&
          /already exists/.test(err?.data?.message)
        ) {
          if (isEmpty(pharmacyDetails)) {
            getPharmacyInfo()
          }
          if (!customerStore.get().infoSteps.includes('pharmacy'))
            customerStore.setInfoSteps('pharmacy')
          snackbarStore.set({
            snackbarOpen: true,
            snackbarMessage: getApiErrorMessage(
              'save pharmacy details. Added pharmacy exists'
            ),
            snackbarType: SnackbarTypes.ERROR,
          })
        } else {
          snackbarStore.set({
            snackbarOpen: true,
            snackbarMessage: getApiErrorMessage('save pharmacy details'),
            snackbarType: SnackbarTypes.ERROR,
          })
        }
        setSearchInputLoading(false)
        setRadioButtonOption('Retail')
        setIsPharmacySelected(false)
      })
      .finally(() => {
        setState({
          ...state,
          loading: false,
          buttonLoading: false,
          nextButtonLoading: false,
          addedPharmaciesLoading: false,
        })
        setOverlayLoading(() => false)
      })
  }

  return state.loading ? (
    <SkeletonCard height={100} />
  ) : (
    <Container>
      {isOpenPharmacySearch && (
        <ModalComponent
          setOpen={() => {
            setOpenPharmacySearch(false)
          }}
          hideCloseButton={false}
          description='Popup - Pharmacy Search'
          restrictScroll={true}
        >
          <PharmacyListSearchInfoSteps
            zipCode={zipcodeInfo.code}
            radius={radius}
            setOpen={() => {
              setOpenPharmacySearch(false)
            }}
            handleSearchClick={handlePharmacySelection}
            setPharmacyList={setPharmacyList}
            radiusOptions={radiusOptions}
            selectNextRadiusOption={changeRadius}
            addedPharmacyList={addedPharmacyList}
            radiusDisplayValue={radiusDisplayValue}
            handleRadiusChange={handleRadiusChange}
            forSpouse={forSpouse}
          />
        </ModalComponent>
      )}
      {overlayLoading && <OverlayLoader />}
      <Heading>Choose your {forSpouse && "spouse/partner's"} pharmacy</Heading>
      <Description>
        Drug costs vary based on the pharmacy you use. Choosing pharmacies lets
        us show you your estimated drug costs, helping you pick the lowest cost
        plan. You don’t have to choose the pharmacies you currently use.
      </Description>

      <AddressWrapper>
        <AddressContainer>
          <p style={{ fontWeight: '600', color: '#2F3032' }}>
            Search for a pharmacy near you:
          </p>
          <Address>
            {zipcodeInfo.code && (
              <p style={{ display: 'inline' }}>
                {zipcodeInfo.code} - {zipcodeInfo.county?.countyName},{' '}
                {zipcodeInfo.state}
              </p>
            )}
            <span
              onClick={() => {
                setShowPostalCodeSelection(!showPostalCodeSelection)
              }}
            >
              {zipcodeInfo.code ? 'Change' : 'Add your Postal code'}
            </span>
          </Address>
        </AddressContainer>
        <SearchMilesWrapper>
          {showPostalCodeSelection && (
            <ModalComponent
              setOpen={() => {
                setShowPostalCodeSelection(false)
              }}
              description='Popup - Select Zipcode'
            >
              <>
                <h2 style={{ fontWeight: 'bold' }}>Change Zip Code</h2>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <p style={{ alignContent: 'center' }}>Search within: </p>
                  &nbsp;&nbsp;
                  <Select
                    bottomPadding={false}
                    formLabel=''
                    options={radiusOptions}
                    height='30px'
                    value={radiusDisplayValue}
                    onChange={handleRadiusChange}
                  />
                </div>
                <PostalCodeSelection
                  onSubmit={(zipInfo: {
                    code: string
                    county?: County
                    state?: string
                    optCounties?: County[]
                  }) => {
                    if (zipInfo.code) {
                      setZipcodeInfo({ ...zipcodeInfo, ...zipInfo })
                      customerStore.setPharmacyPostalCode(zipInfo.code)
                      setShowPostalCodeSelection(false)
                    }
                  }}
                  // onCancel={() => setShowPostalCodeSelection(false)}
                />
              </>
            </ModalComponent>
          )}
        </SearchMilesWrapper>
      </AddressWrapper>
      {/* {addedPharmacyList.length == 0 && ( */}
      <div>
        <Checkbox
          checked={currentPlanStore.getIsPharmacyChecked()}
          onClick={() => {
            currentPlanStore.setIsPharmacyChecked(
              !currentPlanStore.getIsPharmacyChecked()
            )
          }}
        />
        I don’t have a preferred pharmacy
      </div>
      {/* )} */}

      {!isPharmacySelected && radioButtonOption === 'Mail Order' && (
        <BouncingDotsLoader />
      )}

      <SearchPromptWrapper>
        {searchInputLoading ? (
          <BouncingDotsLoader />
        ) : (
          <>
            <Button
              color='primary'
              variant='contained'
              onClick={() => {
                setOpenPharmacySearch(true)
              }}
              disabled={
                currentPlanStore.getIsPharmacyChecked() ||
                addedPharmacyList.length >= 3
              }
            >
              Find Pharmacy
            </Button>
            {addedPharmacyList.length >= 3 && (
              <DisableText>*3 pharmacies have been Added</DisableText>
            )}
          </>
        )}
      </SearchPromptWrapper>

      <SavedPharmacies>
        Saved Pharmacies : {addedPharmacyList.length}
      </SavedPharmacies>

      {isPharmacySelected && (
        <SelectedPharmacyWrapper>
          {isPharmacySelected && state.addedPharmaciesLoading && (
            <BouncingDotsLoader />
          )}

          {isPharmacySelected &&
            !state.addedPharmaciesLoading &&
            addedPharmacyList.length !== 0 &&
            addedPharmacyList.map((iterator: any) => {
              if (iterator.defaultMailOrder === false) {
                return (
                  <CardWrapper>
                    <Card
                      cardType={
                        iterator && iterator.isDefaultPharmacy
                          ? 'readOnly'
                          : 'deleteOnly'
                      }
                      deleteCard={() =>
                        handleSelectedPharmacyDelete(iterator.id)
                      }
                      height='100%'
                      onlyDeleteButton
                      key={iterator.id}
                    >
                      <SelectedPharmacyCard>
                        <h3>
                          {iterator && iterator.name}
                          {iterator && iterator.isDefaultPharmacy && (
                            <Tooltip
                              title={
                                StringConstants.TOOLTIP_DEFAULT_PHARMACY_MSG
                              }
                              content={'i'}
                            />
                          )}
                        </h3>
                        <p style={{ paddingBottom: '10px' }}>
                          {iterator.address1 +
                            ', ' +
                            iterator.city +
                            ', ' +
                            iterator.state +
                            ', ' +
                            iterator.zip}
                        </p>
                        <p>
                          <MdLocationPin
                            style={{
                              fontSize: '14px',
                            }}
                          />
                          {iterator.distance
                            ? ' ' + iterator.distance + ' mi'
                            : 'N/A'}
                        </p>
                      </SelectedPharmacyCard>
                    </Card>
                  </CardWrapper>
                )
              } else if (iterator.defaultMailOrder === true) {
                return (
                  <CardWrapper>
                    <Card
                      cardType='deleteOnly'
                      deleteCard={() =>
                        handleSelectedPharmacyDelete(iterator.id)
                      }
                      height='100%'
                      onlyDeleteButton
                      key={iterator.id}
                    >
                      <>
                        <h3
                          style={{
                            color: theme.colors.textGrey,
                            maxWidth: '65%',
                          }}
                        >
                          Mail Order Pharmacy
                        </h3>
                        <SelectedPharmacyCard>
                          <p style={{ paddingTop: '10px' }}>
                            Add both mail-order and retail pharmacies to find
                            the lowest cost.
                          </p>
                        </SelectedPharmacyCard>
                      </>
                    </Card>
                  </CardWrapper>
                )
              }
              return <></>
            })}
        </SelectedPharmacyWrapper>
      )}

      {showBottomStepNavigator && (
        <BottomStepNavigator
          handleNext={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' })
            if (customerStore.getSpouseOptIn() === false || forSpouse) {
              navigate(RouteConstants.INFO_STEPS_PRESCRIPTION)
            } else if (customerStore.getSpouseOptIn() && !forSpouse) {
              navigate(`${RouteConstants.INFO_STEPS_PHARMACY}/?forSpouse=true`)
            }
          }}
          handleBack={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' })
            if (forSpouse) {
              navigate(RouteConstants.INFO_STEPS_PHARMACY)
            } else {
              navigate(RouteConstants.INFO_STEPS_DOCTORS)
            }
          }}
          handleViewPlans={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' })
            navigate(RouteConstants.DASHBOARD)
          }}
          isLoading={state.nextButtonLoading}
          currentInfoStep='Pharmacy'
        />
      )}
    </Container>
  )
}

export default SecureComponent(observer(PharmacyScreen))
