const MedicalShieldIcon: React.FC = () => {
  return (
    <svg
      width='76'
      height='75'
      viewBox='0 0 76 75'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_2052_105406)'>
        <path
          d='M56.2084 73.9601C45.7039 71.0299 38.043 60.342 38.043 47.7569V35.6039L56.2084 30.1733L74.4593 35.6039V47.7569C74.4593 60.342 66.7107 71.0299 56.2084 73.9601Z'
          stroke='#222C69'
          stroke-width='2.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M65.3352 47.3272H59.1354V41.1201H53.2818V47.3272H47.082V53.1876H53.2818V59.3946H59.1354V53.1876H65.3352V47.3272Z'
          stroke='#222C69'
          stroke-width='2.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M16.2601 1.04004H45.5326V5.43536C45.5326 7.93574 43.4668 9.91849 41.0547 9.91849H20.7357C18.2382 9.91849 16.2578 7.93574 16.2578 5.43536V1.04004H16.2601Z'
          stroke='#222C69'
          stroke-width='2.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M50.355 1.04004H56.2962C58.4475 1.04004 60.1694 2.76396 60.1694 4.91772V26.8111M46.3941 73.9602H5.4122C3.34638 73.9602 1.53906 72.2363 1.53906 70.0826V4.91772C1.53906 2.76165 3.34638 1.04004 5.4122 1.04004H11.4389'
          stroke='#222C69'
          stroke-width='2.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M11.4385 6.03857H6.53125V68.9619H41.1402M55.1763 25.9515V6.03857H50.3545'
          stroke='#222C69'
          stroke-width='2.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M22.3729 35.1743V43.7939H13.7656V35.1743H17.6388'
          stroke='#222C69'
          stroke-width='2.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M28.6572 42.4995H33.6522'
          stroke='#222C69'
          stroke-width='2.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M28.6572 36.4658H33.6522'
          stroke='#222C69'
          stroke-width='2.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M22.3729 18.106V26.7256H13.7656V18.106H17.6388'
          stroke='#222C69'
          stroke-width='2.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M28.6572 25.4336H38.3862'
          stroke='#222C69'
          stroke-width='2.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M28.6572 19.3999H48.0299'
          stroke='#222C69'
          stroke-width='2.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M22.3729 52.2402V60.8599H13.7656V52.2402H17.6388'
          stroke='#222C69'
          stroke-width='2.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M28.6572 59.5654H35.718'
          stroke='#222C69'
          stroke-width='2.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M28.6572 53.5317H34.0815'
          stroke='#222C69'
          stroke-width='2.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_2052_105406'>
          <rect
            width='75'
            height='75'
            fill='white'
            transform='translate(0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default MedicalShieldIcon
