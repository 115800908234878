import { SyntheticEvent, useEffect, useRef, useState } from 'react'
import { isEmpty, isNil, isNull } from 'lodash'

import AddMNSPlanDetails from '../../components/DashboardPage/AddMNSPlanDetails'
import AddSpouseConfirmationModal from '../../components/DashboardPage/Modals/AddSpouseConfirmationModal'
import ApiManager from '../../api/ApiManager'
import CompleteYourProfileNotification from '../../components/DashboardPage/Common/TopNotificationChipComponents/CompleteYourProfile'
import ContactCard from '../../components/common/ContactContainer'
import GeneralConstants from '../../constants/GeneralConstants'
import { HttpMethods } from '../../enums/HttpMethodsEnum'
import InPageHeader from '../../components/common/InPageHeader/InPageHeader.component'
import { InfoStepEnum } from '../../enums/InfoStepEnum'
import InfoStepsPrompt from '../../components/DashboardPage/Modals/InfoStepsPrompt'
import LayoutComponent from '../../components/common/Layout/Layout.component'
import { MNonSupplementPlan } from '../../interface/quotes/mNonSupplementPlans/MNonSupplementPlan'
import { MSPlan } from '../../interface/quotes/mSupplementPlans/MSPlan'
import MedicareAdvantageSection from '../../components/DashboardPage/PageSections/MedicareAdvantage/MedicareAdvantageSection'
import MedicarePrescription from '../../components/DashboardPage/PageSections/MedicarePrescription/MedicarePrescription'
import { MedicareQuotesType } from '../../enums/MedicareQuoteTypeEnum'
import MedicareSupplement from '../../components/DashboardPage/PageSections/MedicareSupplement/MedicareSupplement'
import ModalComponent from '../../components/common/Modal/Modal.component'
import NewPasswordModal from '../../components/DashboardPage/Modals/NewPasswordModal'
import NewPasswordNotification from '../../components/DashboardPage/Common/TopNotificationChipComponents/NewPasswordNotification'
import OverlayLoader from '../../components/common/OverlayLoader/OverlayLoader'
import PaginationConstants from '../../constants/PaginationConstants'
import { PreferenceCoverageShortNameEnum } from '../../enums/PreferenceCoverageShortNameEnum'
import { QuotesPullStatus } from '../../interface/quotes/QuotesPullStatus'
import RouteConstants from '../../constants/RouteConstants'
import { SFLeadInterface } from '../../interface/salesforce/SFLeadInterface'
import SecureComponent from '../common/SecureComponent'
import { SnackbarTypes } from '../../enums/SnackbarTypesEnum'
import StringConstants from '../../constants/StringConstants'
import ThankYouModal from '../../components/DashboardPage/Modals/ThankYouModal'
import TopNotificationChip from '../../components/common/Notification/TopNotificationChip.component'
import UrlConstants from '../../constants/UrlConstants'
import { addSpouseDetails } from '../../service/customer'
import customerStore from '../../datastore/CustomerStore'
import { getApiErrorMessage } from '../../utils/StringUtils'
import { getPlanCount } from '../../service/quotes'
import { getPlanYear } from '../../utils/CommonUtils'
import mPartDPlansFilterStore from '../../datastore/medicareQuotes/MPartPlanFilterQuotesStore'
import medicareAdvantageFilterQuoteStore from '../../datastore/medicareQuotes/MedicareAdvantageFilterQuoteStore'
import msPlanFiltersStore from '../../datastore/medicareQuotes/MSupplementPlanFiltersStore'
import { observer } from 'mobx-react'
import snackbarStore from '../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../global/theme'
import userActionStore from '../../datastore/UserActionStore'

const Container = styled.div`
  min-height: 100vh;
  background-color: ${theme.colors.white};
  position: relative;
`

const ThreeDotsWrapper = styled.div`
  display: flex;
  margin: 20px;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media screen and (max-width: 640px) {
    width: 100%;
    margin: 20px 0px;
  }
`

const DashboardPage = () => {
  const [resPassModalActive, setResPassModalActive] = useState<boolean>(false)
  const [effectiveDateChanged, setEffectiveDateChanged] =
    useState<boolean>(false)
  const [infoStepsModal, setInfoStepsModal] = useState<{
    active: boolean
    redirect: string | null
  }>({
    active: false,
    redirect: null,
  })

  const [resPassChipActive, setResPassChipActive] = useState<boolean>(false)
  const [completeProfileChipActive, setCompleteProfileChipActive] =
    useState<boolean>(false)
  const [
    openEffectiveDateFilterConfirmationPopup,
    setOpenEffectiveDateFilterConfirmationPopup,
  ] = useState<boolean>(false)

  const [maPlans, setMAPlans] = useState<MNonSupplementPlan[]>([])
  const [maPlanTotalCount, setMATotalPlanCount] = useState<number | null>(null)
  const [msPlans, setMSPlans] = useState<MSPlan[]>([])
  const [msPlanTotalCount, setMSTotalPlanCount] = useState<number | null>(null)
  const [mPartDPlans, setMPartDPlans] = useState<MNonSupplementPlan[]>([])
  const [mPartDPlanTotalCount, setMPartDPlanCount] = useState<number | null>(
    null
  )
  const [showAddSpouseConfirmation, setShowAddSpouseConfirmation] =
    useState<boolean>(false)
  const [addSpouseDetailsLoader, setAddSpouseDetailsLoader] =
    useState<boolean>(false)
  const [thankYouModalActive, setThankYouModalActive] = useState(false)

  const handleMAPlansData = (data: MNonSupplementPlan[]) => {
    setMAPlans(data)
  }

  const handleMSPlansData = (data: MSPlan[]) => {
    setMSPlans(data)
  }

  const handleMPartDData = (data: MNonSupplementPlan[]) => {
    setMPartDPlans(data)
  }

  const [maPlanMessage, setMAPlanErrorMessage] = useState<string>('')
  const [msPlanMessage, setMSPlanErrorMessage] = useState<string>('')
  const [mPartDPlanMessage, setMPatDPlanErrorMessage] = useState<string>('')
  const [type, setType] = useState<string>('')
  const [isQuotesStatusPulled, setIsQuotesStatusPulled] =
    useState<boolean>(false)

  const defaultPaginationDetail: { page: number; size: number } = {
    page: PaginationConstants.DEFAULT_PAGE,
    size: PaginationConstants.DEFAULT_PAGE_SIZE,
  }

  const timerRef: { current: NodeJS.Timeout | null } = useRef(null)
  const isMounted = useRef(true)
  const initiateTimer = () => {
    timerRef.current = setTimeout(() => {
      fetchQuotesPullStatus(getPlanYear())
    }, 15000)
  }

  const [currentTab, setCurrentTab] = useState<number>(-1)

  const updatePriorityCallback = (message: string) => {
    const requestBody: SFLeadInterface = {
      callbackLogic: message,
      leadAppStage: 'Quoting Tool',
      leadAppSubstage: 'Dashboard',
    }

    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.SF_CALLBACK_LEAD_INFO_UPDATE.USECASE,
      HttpMethods.PATCH_METHOD,
      requestBody
    ).catch((err: Error) => {})
  }

  const fetchQuotesPullStatus = (planYear?: number) => {
    getPlanCount(planYear)
      .then((quotesPullStatus: QuotesPullStatus) => {
        // if (response.status === 200) {
        !medicareAdvantageFilterQuoteStore.isPlanFetched() &&
          !isNil(quotesPullStatus.maPlan) &&
          !medicareAdvantageFilterQuoteStore.isFiltersActive() &&
          setMATotalPlanCount(quotesPullStatus.maPlan)
        !msPlanFiltersStore.isPlanFetched() &&
          !isNil(quotesPullStatus.msPlan) &&
          !msPlanFiltersStore.isFiltersActive() &&
          setMSTotalPlanCount(quotesPullStatus.msPlan)

        !mPartDPlansFilterStore.isPlanFetched() &&
          !isNil(quotesPullStatus.maPlan) &&
          !mPartDPlansFilterStore.isFiltersActive() &&
          setMPartDPlanCount(quotesPullStatus.mPartD)

        // TODO Remove the plans fetched from store
        // !medicareAdvantageFilterQuoteStore.isPlanFetched() &&
        //   !isNil(response.maPlan) &&
        //   medicareAdvantageFilterQuoteStore.setTotalCount(
        //     response.maPlan
        //   )
        // !msPlanFiltersStore.isPlanFetched() &&
        //   !isNil(response.msPlan) &&
        //   msPlanFiltersStore.setTotalCount(response.msPlan)

        // !mPartDPlansFilterStore.isPlanFetched() &&
        //   !isNil(response.maPlan) &&
        //   mPartDPlansFilterStore.setTotalCount(response.mPartD)

        customerStore.setQuotePulledStatusMA(
          quotesPullStatus.isMAQuotesProcessing
        )
        customerStore.setQuotePulledStatusMPartD(
          quotesPullStatus.isMPartDQuotesProcessing
        )
        customerStore.setQuotePulledStatusMS(
          quotesPullStatus.isMSQuotesProcessing
        )

        if (
          quotesPullStatus.isMAQuotesProcessing ||
          quotesPullStatus.isMPartDQuotesProcessing ||
          quotesPullStatus.isMSQuotesProcessing
        ) {
          timerRef.current = setTimeout(() => {
            fetchQuotesPullStatus(getPlanYear())
          }, 15000)
        }

        // if (!customerStore.getCurrentTabStatus(currentTab)) {
        //   if (
        //     currentTab === 0 &&
        //     !medicareAdvantageFilterQuoteStore.isPlanFetched()
        //   )
        //     showQuotesOnTabChange(currentTab)
        //   else if (currentTab === 1 && !msPlanFiltersStore.isPlanFetched())
        //     showQuotesOnTabChange(currentTab)
        //   else if (
        //     currentTab === 2 &&
        //     !mPartDPlansFilterStore.isPlanFetched()
        //   )
        //     showQuotesOnTabChange(currentTab)
        //   else if (effectiveDateChanged && currentTab === 1) {
        //     showQuotesOnTabChange(currentTab)
        //   } else if (
        //     msPlanFiltersStore.getIsTobaccoChanged() &&
        //     currentTab === 1
        //   ) {
        //     showQuotesOnTabChange(currentTab)
        //   }
        // }
        setIsQuotesStatusPulled(true)
        // } else {
        //   if (isMounted.current)
        //     snackbarStore.set({
        //       snackbarOpen: true,
        //       snackbarMessage: 'Unable to fetch Quotes Pull Status',
        //       snackbarType: 'error',
        //     })
        // }
      })
      .catch((err) => {
        if (isMounted.current)
          snackbarStore.set({
            snackbarOpen: true,
            snackbarMessage: 'Unable to fetch Quotes Pull Status',
            snackbarType: 'error',
          })
      })
    if (msPlanFiltersStore.isQuotesPullStatusFetchTriggered)
      msPlanFiltersStore.setIsQuotesPullStatusFetchTriggered(false)
  }

  const handleAddSpouseDetails = (
    gender: string,
    dob: string,
    fName: string,
    lName: string
  ) => {
    setAddSpouseDetailsLoader(true)
    addSpouseDetails(fName, lName, dob, gender)
      .then((response) => {
        customerStore.isMAQuotesProcessing = true
        customerStore.isMSQuotesProcessing = true
        customerStore.isMPartDQuotesProcessing = true
        fetchQuotesPullStatus(getPlanYear())
        snackbarStore.set({
          snackbarMessage: StringConstants.SNACKBAR_SAVE_SUCCESS_MSG,
          snackbarOpen: true,
          snackbarType: SnackbarTypes.SUCCESS,
        })
        customerStore.setSpouseBasicDetails({
          spouseDateOfBirth: dob,
          spouseFirstName: fName,
          spouseGender: gender,
          spouseLastName: lName,
          spouseOptIn: true,
        })
        customerStore.setForSpouse(true)
        setAddSpouseDetailsLoader(false)
        setShowAddSpouseConfirmation(false)
        setThankYouModalActive(true)
        snackbarStore.set({
          snackbarMessage: 'Spouse details added successfully',
          snackbarOpen: true,
          snackbarType: SnackbarTypes.SUCCESS,
        })
      })
      .catch((err) => {
        setAddSpouseDetailsLoader(false)
        snackbarStore.set({
          snackbarMessage: 'Unable to add spouse details, try again later',
          snackbarOpen: true,
          snackbarType: SnackbarTypes.ERROR,
        })
      })
      .finally(() => {
        setAddSpouseDetailsLoader(false)
      })
  }

  const getCustomerDetails = (preferenceCoverage: string) => {
    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_CUSTOMER_DETAILS.USECASE,
      HttpMethods.GET_METHOD
    )
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.data

          customerStore.set(data)
          customerStore.setPreferenceCoverage(preferenceCoverage)
          msPlanFiltersStore.setEffectiveDate(
            data.msEffectiveDateFilterSelf
              ? data.msEffectiveDateFilterSelf.substring(0, 10)
              : data.msEffectiveDateFilterSelf
          )
        } else throw response
      })
      .catch((err) => {
        if (isMounted.current)
          snackbarStore.set({
            snackbarMessage: getApiErrorMessage('fetch customer details'),
            snackbarOpen: true,
            snackbarType: 'error',
          })
      })
  }

  useEffect(() => {
    if (!customerStore.scrollPosition)
      window.scroll({ top: 0, behavior: 'smooth' })

    medicareAdvantageFilterQuoteStore.setPlansFetched(false)
    msPlanFiltersStore.setPlansFetched(false)
    mPartDPlansFilterStore.setPlansFetched(false)
    let preferenceCoverage = window.location.pathname.split('/')[2]
    if (preferenceCoverage !== undefined) setType(preferenceCoverage)

    if (isEmpty(preferenceCoverage)) {
      preferenceCoverage = customerStore.preferenceCoverage
    } else {
      customerStore.setPreferenceCoverage(preferenceCoverage)
    }

    switch (preferenceCoverage) {
      case PreferenceCoverageShortNameEnum.ma:
        setCurrentTab(0)
        break
      case PreferenceCoverageShortNameEnum.ms:
        setCurrentTab(1)
        break
      case PreferenceCoverageShortNameEnum.pdp:
        setCurrentTab(2)
        break
    }
    getCustomerDetails(preferenceCoverage)
    fetchQuotesPullStatus(getPlanYear())
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current)
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    const customerDetails = customerStore.get()
    if (customerDetails.isAutoLoggedIn) setResPassModalActive(false)
    else if (!customerDetails.status || customerDetails.status === 'new')
      setResPassModalActive(true)
    else setResPassModalActive(false)
  }, [customerStore.status, customerStore.isAutoLoggedIn])

  useEffect(() => {
    if (resPassModalActive === false) {
      ApiManager.makeApiCallWithAuthentication(
        UrlConstants.GET_INFO_STEPS.USECASE,
        HttpMethods.GET_METHOD
      )
        .then((res) => {
          const infoStepsArray = res.data.data as string[]
          let redirectURL: string | null = null

          if (infoStepsArray && infoStepsArray.length !== 0) {
            if (infoStepsArray.includes('currentPlan')) {
              redirectURL = null
            } else if (infoStepsArray.includes('doctor')) {
              redirectURL = RouteConstants.INFO_STEPS_DOCTORS
            } else if (infoStepsArray.includes('pharmacy')) {
              redirectURL = RouteConstants.INFO_STEPS_CURRENT_PLAN
            } else if (infoStepsArray.includes('healthQuestion')) {
              redirectURL = RouteConstants.INFO_STEPS_PHARMACY
            } else if (infoStepsArray.includes('prescription')) {
              redirectURL = RouteConstants.INFO_STEPS_HEALTH
            } else if (infoStepsArray.includes(InfoStepEnum.QUESTIONNAIRE)) {
              redirectURL = RouteConstants.INFO_STEPS_PRESCRIPTION
            }
          } else {
            redirectURL = RouteConstants.INFO_STEPS_QUESTIONNAIRE
          }

          customerStore.setInfoSteps(infoStepsArray)

          setInfoStepsModal({
            active: redirectURL ? true : false,
            redirect: redirectURL,
          })
        })
        .catch((err) => {})
    }
  }, [resPassModalActive])

  const isPDPInitialRender = useRef(true)

  const isMAInitialRender = useRef(true)

  const getStepValue = (percentage: number, total: number): number => {
    return Math.round((percentage / 100) * total)
  }

  useEffect(() => {
    if (!isEmpty(maPlanMessage)) updatePriorityCallback(maPlanMessage)
  }, [maPlanMessage])

  useEffect(() => {
    if (!isEmpty(maPlanMessage)) updatePriorityCallback(msPlanMessage)
  }, [msPlanMessage])

  useEffect(() => {
    if (!isEmpty(maPlanMessage)) updatePriorityCallback(mPartDPlanMessage)
  }, [mPartDPlanMessage])

  useEffect(() => {
    let compareCardsPlanType = userActionStore.getComparePlanCards().planType
    if (
      (compareCardsPlanType === MedicareQuotesType.MEDICARE_ADVANTAGE &&
        customerStore.isMAQuotesProcessing) ||
      (compareCardsPlanType === MedicareQuotesType.MEDICARE_SUPPLEMENT &&
        customerStore.isMSQuotesProcessing) ||
      (compareCardsPlanType === MedicareQuotesType.MEDICARE_PRESCRIPTION &&
        customerStore.isMPartDQuotesProcessing)
    )
      userActionStore.resetComparePlanCards()
  }, [
    customerStore.isMAQuotesProcessing,
    customerStore.isMSQuotesProcessing,
    customerStore.isMPartDQuotesProcessing,
  ])

  const handleTabChange = (e: SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue)
  }

  const handleResPassModalClose = () => {
    customerStore.setShowResetPasswordPrompt(false)
    setResPassModalActive(false)
  }

  const toggleEffectiveDateFilterPopupOpen = () => {
    setOpenEffectiveDateFilterConfirmationPopup(
      !openEffectiveDateFilterConfirmationPopup
    )
  }

  const scrollToStoredPosition = () => {
    const storedPosition = parseInt(customerStore.scrollPosition)
    if (storedPosition) {
      setTimeout(() => {
        window.scrollTo({ top: storedPosition, behavior: 'smooth' })
      }, 500)
      customerStore.setScrollPosition('')
    } else {
      if (storedPosition) {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }
    }
  }

  useEffect(() => {
    if (msPlans.length > 0 && currentTab === 1) {
      scrollToStoredPosition()
    }
    if (maPlans.length > 0 && currentTab === 0) {
      scrollToStoredPosition()
    }
    if (mPartDPlans.length > 0 && currentTab === 2) {
      scrollToStoredPosition()
    }
  }, [msPlans, maPlans, mPartDPlans])

  // useEffect(() => {
  //   let pathname = window.location.pathname.replace(/\//g, '')
  //   const requestBody: SFLeadInterface = {
  //     leadAppStage: 'Quoting Tool',
  //     leadAppSubstage: pathname,
  //   }

  //   ApiManager.makeApiCallWithAuthentication(
  //     UrlConstants.SF_CALLBACK_LEAD_INFO_UPDATE.USECASE,
  //     HttpMethods.PATCH_METHOD,
  //     requestBody
  //   )
  //     .then((response) => {
  //       if (response.status === 200)
  //         console.log(
  //           'Successfully updated the Salesforce for leadAppStage as Quoting Tool'
  //         )
  //       else
  //         throw new Error(
  //           'Something went wrong while updating the Salesforce for leadAppStage as Quoting Tool'
  //         )
  //     })
  //     .catch((err: Error) => {
  //       console.log(
  //         `Error occurred when trying to update SF callback fields,Err - ${err.message}`
  //       )
  //     })
  // }, [])
  return (
    <LayoutComponent showDiscountsDisclaimer={currentTab === 1} type={type}>
      <Container>
        <TopNotificationChip
          open={resPassChipActive}
          setOpen={() => setResPassChipActive(false)}
        >
          <NewPasswordNotification />
        </TopNotificationChip>

        <TopNotificationChip
          open={completeProfileChipActive}
          setOpen={() => setCompleteProfileChipActive(false)}
        >
          <CompleteYourProfileNotification />
        </TopNotificationChip>

        {showAddSpouseConfirmation && (
          <ModalComponent
            width={600}
            setOpen={() => {
              setShowAddSpouseConfirmation(!showAddSpouseConfirmation)
            }}
            description='Popup - Request for Enrollment Confirmation'
            hideCloseButton={false}
          >
            <AddSpouseConfirmationModal
              loading={addSpouseDetailsLoader}
              onConfirm={(
                dob: string,
                gender: string,
                fName: string,
                lName: string
              ) => {
                handleAddSpouseDetails(gender, dob, fName, lName)
              }}
              onReject={() => {
                setShowAddSpouseConfirmation(!showAddSpouseConfirmation)
              }}
            />
          </ModalComponent>
        )}

        {thankYouModalActive && (
          <ModalComponent setOpen={() => setThankYouModalActive(false)}>
            <ThankYouModal setOpen={() => setThankYouModalActive(false)} />
          </ModalComponent>
        )}

        {infoStepsModal.active &&
          !customerStore.getCurrentTabStatus(currentTab) && (
            <ModalComponent
              width={600}
              setOpen={() =>
                setInfoStepsModal({
                  ...infoStepsModal,
                  active: false,
                })
              }
              restrictScroll={true}
              padding='20px 30px 30px 30px'
              hideCloseButton={true}
              description='Popup - Info Steps Prompt'
            >
              <InfoStepsPrompt
                setOpen={() =>
                  setInfoStepsModal({
                    ...infoStepsModal,
                    active: false,
                  })
                }
                redirect={infoStepsModal.redirect}
              />
            </ModalComponent>
          )}

        {resPassModalActive && customerStore.getShowResetPasswordPrompt() && (
          <ModalComponent
            width={600}
            setOpen={handleResPassModalClose}
            description='Popup - Reset Password Prompt'
          >
            <NewPasswordModal
              setOpen={handleResPassModalClose}
              open={resPassModalActive}
            />
          </ModalComponent>
        )}

        {/* {openEffectiveDateFilterConfirmationPopup && (
          <ModalComponent
            width={450}
            setOpen={handleRejectEffectiveDateFilterChange}
            hideCloseButton={true}
            description='Popup - Confirm Effective Date Filter Change'
          >
            <EffectiveDateFilterChangeConfirmation
              onConfirm={handleConfirmEffectiveDateFilterChange}
              onReject={handleRejectEffectiveDateFilterChange}
            />
          </ModalComponent>
        )} */}

        <InPageHeader
          onChange={handleTabChange}
          currentTab={currentTab}
          showBackButton={false}
          tabLabels={[
            {
              heading: 'Medicare Advantage (Part C)',
              subHeading: `${
                !customerStore.getCurrentTabStatus(0)
                  ? isNull(maPlanTotalCount)
                    ? ''
                    : `${maPlanTotalCount} plans Available`
                  : GeneralConstants.quotesRefreshMessage
              }`,
              subHeading2: `${
                isEmpty(customerStore.county) && maPlanTotalCount === 0
                  ? StringConstants.MS_TAB_SUBHEADING_FOR_MA_LEAD
                  : ''
              }`,
            },
            {
              heading: 'Medicare Supplement (Medigap)',
              subHeading: `${
                !customerStore.getCurrentTabStatus(1)
                  ? isNull(msPlanTotalCount)
                    ? ''
                    : `${msPlanTotalCount} plans Available`
                  : GeneralConstants.quotesRefreshMessage
              }`,
              subHeading2: `${
                (isEmpty(customerStore.gender) ||
                  isEmpty(customerStore.dateOfBirth)) &&
                msPlanTotalCount === 0
                  ? StringConstants.MS_TAB_SUBHEADING_FOR_MA_LEAD
                  : ''
              }`,
            },
            {
              heading: 'Medicare Prescription Drugs (Part D)',
              subHeading: `${
                !customerStore.getCurrentTabStatus(2)
                  ? isNull(mPartDPlanTotalCount)
                    ? ''
                    : `${mPartDPlanTotalCount} plans Available`
                  : GeneralConstants.quotesRefreshMessage
              }`,
              subHeading2: `${
                isEmpty(customerStore.county) && mPartDPlanTotalCount === 0
                  ? StringConstants.MS_TAB_SUBHEADING_FOR_MA_LEAD
                  : ''
              }`,
            },
          ]}
          isDashboard={true}
          isSidebarShown={!customerStore.getCurrentTabStatus(currentTab)}
        />

        {currentTab === 0 && (
          <>
            {!customerStore.getCurrentTabStatus(currentTab) ? (
              <MedicareAdvantageSection
                plans={maPlans as MNonSupplementPlan[]}
                onDataChange={handleMAPlansData}
                setMATotalPlanCount={setMATotalPlanCount}
                maPlanTotalCount={maPlanTotalCount}
                setShowAddSpouseConfirmation={setShowAddSpouseConfirmation}
              />
            ) : isEmpty(customerStore.county) ? (
              <AddMNSPlanDetails initiateTimer={initiateTimer} tabNumber={2} />
            ) : (
              <ContactCard />
            )}
          </>
        )}

        {currentTab === 1 && (
          <>
            {!customerStore.getCurrentTabStatus(currentTab) ? (
              <MedicareSupplement
                plans={msPlans}
                msPlanTotalCount={msPlanTotalCount}
                onDataChange={handleMSPlansData}
                setMSPlanTotalCount={setMSTotalPlanCount}
                setShowAddSpouseConfirmation={setShowAddSpouseConfirmation}
                fetchQuotesPullStatus={fetchQuotesPullStatus}
              />
            ) : (
              <ContactCard />
            )}
          </>
        )}

        {currentTab === 2 && (
          <>
            {!customerStore.getCurrentTabStatus(currentTab) ? (
              <MedicarePrescription
                plans={mPartDPlans as MNonSupplementPlan[]}
                onDataChange={handleMPartDData}
                setMPartDPlanCount={setMPartDPlanCount}
                mPartDTotalCount={mPartDPlanTotalCount}
                setShowAddSpouseConfirmation={setShowAddSpouseConfirmation}
              />
            ) : isEmpty(customerStore.county) ? (
              <AddMNSPlanDetails initiateTimer={initiateTimer} tabNumber={2} />
            ) : (
              <ContactCard />
            )}
          </>
        )}

        {msPlanFiltersStore.getIsOverLayLoading() && <OverlayLoader />}
      </Container>
    </LayoutComponent>
  )
}
export default SecureComponent(observer(DashboardPage))
