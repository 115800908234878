import { QuotesPullStatus } from '../interface/quotes/QuotesPullStatus'
import RouteConstants from '../constants/RouteConstants'
import customerStore from '../datastore/CustomerStore'
import { getPlanCount } from '../service/quotes'
import snackbarStore from '../datastore/SnackbarStore'

export const pollQuotesPullStatus = (
  planYear?: number,
  pollFromDashboard = false
) => {
  if (
    RouteConstants.DASHBOARD !== '/' + window.location.pathname.split('/')[1] ||
    pollFromDashboard
  ) {
    getPlanCount(planYear)
      .then((quotesPullStatus: QuotesPullStatus) => {
        customerStore.setQuotePulledStatusMA(
          quotesPullStatus.isMAQuotesProcessing
        )
        customerStore.setQuotePulledStatusMPartD(
          quotesPullStatus.isMPartDQuotesProcessing
        )
        customerStore.setQuotePulledStatusMS(
          quotesPullStatus.isMSQuotesProcessing
        )
        if (
          quotesPullStatus.isMAQuotesProcessing ||
          quotesPullStatus.isMPartDQuotesProcessing ||
          quotesPullStatus.isMSQuotesProcessing
        ) {
          setTimeout(() => {
            pollQuotesPullStatus(planYear, pollFromDashboard)
          }, 15000)
        }
      })
      .catch((err) => {
        snackbarStore.set({
          snackbarOpen: true,
          snackbarMessage: 'Unable to fetch Quotes Pull Status',
          snackbarType: 'error',
        })
      })
  }
}
