const theme = {
  colors: {
    primary: '#222C69',
    secondary: '#BA0000',
    grey: '#212121',
    success: '#2e7d32',
    warning: '#ff9800',
    danger: '#d32f2f',
    textLight: '#fff',
    textDark: '#2f3032',
    textGrey: '#515151',
    white: '#fff',
    lightBack: '#e3eeff',
    borderColor: '#e5e5e5',
    contrastPrimary: '#F2F7FE',
    backgroundColorContainer: '#FBFBFB',
    backgroundColorContainerDark: '#F3F3F3',
    green: '#459308',
    contrastGreen: '#4593081A',
    vLightBlue: '#f2f7fe',
    disabled: '#00000043',
    darkGrey: '#aaaaaa',
    highlight: '#FFF7CF',
  },
}

export default theme
