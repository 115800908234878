import React, { useEffect, useState } from 'react'

import { Checkbox } from '@mui/material'
import SelectMonthYear from '../../../common/Select/SelectMonthYear.component'
import StringConstants from '../../../../constants/StringConstants'
import ValidationUtils from '../../../../utils/validation/ValidationUtils'
import currentPlanStore from '../../../../datastore/CurrentPlanStore'
import { observer } from 'mobx-react'
import styled from '@emotion/styled'
import theme from '../../../../global/theme'

interface QuestionProps extends ContainerProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
  secondaryFunction?: (val: string) => void
  forSpouse: boolean
  question?: string
  description?: string
  compareValue?: boolean
  dateFieldInputProps?: { min?: string; max?: string }
  disableMainNext?: () => void
  enableMainNext?: () => void
  handleHasNext?: (value: boolean) => void
}

interface ContainerProps {
  width?: string
}

const Container = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  @media screen and (max-width: 600px) {
    margin: 0;
  }
`

const Heading = styled.h3`
  font-size: 20px;
  font-weight: 700px;
  padding-bottom: 20px;
  color: ${theme.colors.primary};
`

const Description = styled.p`
  padding-bottom: 20px;
  font-weight: 600;
`

const SelectMonthYearWrapper = styled.div`
  display: flex;
  padding: 10px;

  @media screen and (max-width: 600px) {
    align-self: center;
  }
`

const DateQuestion: React.FC<QuestionProps> = ({
  onClick,
  secondaryFunction,
  question = '',
  description = '',
  width,
  compareValue = false,
  dateFieldInputProps,
  disableMainNext,
  enableMainNext,
  handleHasNext,
  forSpouse,
}) => {
  const [date, setDate] = React.useState<string>(
    !forSpouse
      ? currentPlanStore.effectiveDate
        ? currentPlanStore.effectiveDate.substring(0, 10)
        : ''
      : currentPlanStore.spouseEffectiveDate
      ? currentPlanStore.spouseEffectiveDate.substring(0, 10)
      : ''
  )
  const [isCurrentPlanQuestionChecked, setIsCurrentPlanQuestionChecked] =
    useState(
      forSpouse
        ? currentPlanStore.getSpouseIsCurrentPlanQuestionChecked()
        : currentPlanStore.getIsCurrentPlanQuestionChecked()
    )
  const [state, setState] = React.useState({
    error: false,
    helperText: '',
  })

  useEffect(() => {
    handleHasNext && handleHasNext(true)
    if (date.length === 0 && !isCurrentPlanQuestionChecked)
      disableMainNext && disableMainNext()
    else enableMainNext && enableMainNext()
  }, [date])

  useEffect(() => {
    if (isCurrentPlanQuestionChecked) {
      setDate('')
      secondaryFunction && secondaryFunction('')
      enableMainNext && enableMainNext()
    } else if (!isCurrentPlanQuestionChecked && date.length === 0) {
      disableMainNext && disableMainNext()
    }
  }, [isCurrentPlanQuestionChecked])

  const handleOnChange = (inputDate: string) => {
    secondaryFunction && secondaryFunction(inputDate)
    setDate(inputDate)

    if (inputDate) {
      if (compareValue) {
        if (
          !ValidationUtils.validateAnticipatedEndDate(
            inputDate,
            currentPlanStore.get().effectiveDate
          )
        ) {
          setState({
            ...state,
            error: true,
            helperText:
              StringConstants.UI_ERROR_MSG_FOR_INVALID_ANTICIPATED_END_DATE,
          })
        } else {
          setState({
            ...state,
            error: false,
            helperText: '',
          })
        }
      }
    }
  }

  return (
    <Container>
      <Heading>{question}</Heading>
      <Description>{description}</Description>
      <SelectMonthYearWrapper>
        <SelectMonthYear
          value={date}
          handleChange={handleOnChange}
          showValidationError={state.error}
          setValidationErrorTrue={() => {}}
          setValidationErrorFalse={() => {
            setState({
              ...state,
              error: false,
            })
          }}
          disabled={isCurrentPlanQuestionChecked}
          isInfoStep={true}
        />
      </SelectMonthYearWrapper>

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Checkbox
          checked={isCurrentPlanQuestionChecked}
          onClick={() => {
            if (forSpouse)
              currentPlanStore.setSpouseIsCurrentPlanQuestionChecked(
                !isCurrentPlanQuestionChecked
              )
            else
              currentPlanStore.setIsCurrentPlanQuestionChecked(
                !isCurrentPlanQuestionChecked
              )
            setIsCurrentPlanQuestionChecked(!isCurrentPlanQuestionChecked)
          }}
        />
        I don’t know
      </div>
    </Container>
  )
}

export default observer(DateQuestion)
