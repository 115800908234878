import React, { useEffect, useRef, useState } from 'react'
import { cloneDeep, isEmpty, isNull, startCase } from 'lodash'
import {
  getCustomerDetails,
  removeSpouseDetails,
} from '../../../../service/customer'
import { getMNSPlansFilters, getMPartDPPlans } from '../../../../service/quotes'

import { ActionContext } from '../../../../cra'
import BottomAddToCompare from '../../BottomAddToCompare/BottomAddToCompare.component'
import Button from '../../../common/Button/Button.component'
import CallUsNow from '../../Common/Recommendation/CallUsNow.component'
import CompletePlanDetailsPage from '../../../../pages/CompletePlanDetailsPage/CompletePlanDetailsPage'
import FilterBox from '../../Common/FilterBox.component.tsx/FilterBox'
import FilterPrescription from './FilterPrescription.component'
import { Left } from '../MedicareSupplement/MedicareSupplement'
import { MNonSupplementPlan } from '../../../../interface/quotes/mNonSupplementPlans/MNonSupplementPlan'
import { MParDPlanFiltersRequest } from '../../../../interface/quotes/mNonSupplementPlans/medicarePartD/request/MPartDPlanFiltersRequest'
import { MPartDPlanDisplayFilter } from '../../../../interface/quotes/mNonSupplementPlans/medicarePartD/MPartDPlanDisplayFilters'
import { MedicarePartDFilters } from '../../../../interface/quotes/mNonSupplementPlans/medicarePartD/MedicarePartDFilters'
import MedicarePrescriptionCard from './MedicarePrescriptionCard.component'
import { MedicareQuotesType } from '../../../../enums/MedicareQuoteTypeEnum'
import ModalComponent from '../../../common/Modal/Modal.component'
import NoPlanFoundCard from '../../NoPlanFoundCard'
import PaginationConstants from '../../../../constants/PaginationConstants'
import PrimarySpouseToggle from '../../Common/PrimarySpouseToggle'
import QuotesConstants from '../../../../constants/QuotesConstants'
import RecommendedPrescriptionCards from './RecommendedPrescriptionCards'
import { RiFilter2Fill } from 'react-icons/ri'
import SortBy from '../../Common/SortByDropdown.component.tsx/SortBy'
import StringConstants from '../../../../constants/StringConstants'
import ThankYouModal from '../../Modals/ThankYouModal'
import { ThreeDots } from 'react-loader-spinner'
import { convertToUSCurrency } from '../../../../utils/CurrencyConverterUtil'
import customerStore from '../../../../datastore/CustomerStore'
import { getContact } from '../../../../utils/getContact'
import { getPlanYear } from '../../../../utils/CommonUtils'
import { isUndefined } from 'lodash'
import mPartDPlansFilterStore from '../../../../datastore/medicareQuotes/MPartPlanFilterQuotesStore'
import { observer } from 'mobx-react'
import snackbarStore from '../../../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../../../global/theme'
import { useBurgerMenu } from '../../../../context/SidebarContext'
import { useReactToPrint } from 'react-to-print'
import userActionStore from '../../../../datastore/UserActionStore'

const Container = styled.div`
  display: flex;
  justify-content: center;
`
const CardsWrapper = styled.div`
  padding: 20px 60px;
  flex: 1;
  max-width: 1200px;

  .recommendation-header {
    text-align: center;
    max-width: 600px;
    margin: auto;
    padding-bottom: 20px;
    h2 {
      color: ${theme.colors.primary};
      font-weight: 700;
      font-size: 28px;
    }

    p {
      font-size: 14px;
      font-weight: 600;
      color: ${theme.colors.textGrey};
    }
  }

  @media screen and (max-width: 950px) {
    padding: 20px;
  }
`
const UtilityContainer = styled.div`
  display: flex;
  gap: 10px;
  .filter-toggle {
    display: none;
    border: 1px solid #a9a9a9;
    align-items: center;
    color: ${theme.colors.textGrey};
    font-weight: 600;
    cursor: pointer;
    border-radius: 32px;
    padding: 5px 10px;
    transition: box-shadow 0.4s;
    &:hover {
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      transition: box-shadow 0.4s;
    }

    @media screen and (max-width: 950px) {
      display: flex;
      font-size: 14px;
    }
  }

  @media screen and (max-width: 600px) {
    justify-content: space-between;
  }
`

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.25em 0;
`
const ThreeDotsWrapper = styled.div`
  display: flex;
  margin: 20px;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  width: 900px;

  @media screen and (max-width: 1250px) {
    width: 700px;
  }

  @media screen and (max-width: 1050px) {
    width: 600px;
  }

  @media screen and (max-width: 640px) {
    width: 100%;
    margin: 20px 0px;
  }
`

const FilterContainer = styled.div`
  .bottom-wrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    @media screen and (max-width: 600px) {
      flex-direction: column;
      align-items: initial;
    }
  }
`

const MedicarePrescription: React.FC<{
  plans: MNonSupplementPlan[]
  onDataChange: (data: MNonSupplementPlan[]) => void
  setMPartDPlanCount: (data: number) => void
  mPartDTotalCount: number | null
  setShowAddSpouseConfirmation: (flag: boolean) => void
}> = ({
  plans,
  onDataChange,
  setMPartDPlanCount,
  mPartDTotalCount,
  setShowAddSpouseConfirmation,
}) => {
  const { isOpen, toggleSidebar, setShowBurgerMenu } = useBurgerMenu()
  const [sortPlanOptions, setSortPlanOptions] = useState<
    {
      title: string
      value: string
    }[]
  >(
    mPartDPlansFilterStore.isPrimaryAndSpouseOption
      ? mPartDPlansFilterStore.spouseMPartDPlanFilters?.sortOptions?.map(
          (value) => ({ title: value, value })
        )
      : mPartDPlansFilterStore.mPartDPlanFilters?.sortOptions?.map((value) => ({
          title: value,
          value,
        }))
  )

  const printRef = useRef(null)

  const reactToPrint = useReactToPrint({
    content: () => printRef.current,
  })
  const [completePlanDetailModalActive, setCompletePlanDetailModalActive] =
    React.useState<boolean>(false)
  const [completeDetailPlanId, setCompleteDetailPlanId] =
    React.useState<string>('')

  const [currentSortOption, setCurrentSortOption] = useState<{
    label: string
    index: number
  }>(
    !mPartDPlansFilterStore.getIsPrimaryAndSpouseOption()
      ? {
          index: mPartDPlansFilterStore
            .getMPartDPlansFilters()
            .sortOptions?.findIndex((option) => {
              return (
                option ===
                mPartDPlansFilterStore.getMPartDPlansFilters()
                  ?.selectSortOption!
              )
            }),
          label:
            mPartDPlansFilterStore.getMPartDPlansFilters()?.selectSortOption!,
        }
      : {
          index:
            mPartDPlansFilterStore.spouseMPartDPlanFilters?.sortOptions?.findIndex(
              (option) => {
                return (
                  option ===
                  mPartDPlansFilterStore.spouseMPartDPlanFilters
                    ?.selectSortOption!
                )
              }
            ),
          label:
            mPartDPlansFilterStore.spouseMPartDPlanFilters?.selectSortOption!,
        }
  )

  useEffect(() => {
    setShowBurgerMenu(true)
  })

  const [mPartDRequestBody, setMPartDRequestBody] =
    useState<MParDPlanFiltersRequest>(
      !mPartDPlansFilterStore.getIsPrimaryAndSpouseOption()
        ? mPartDPlansFilterStore.mPartDPlanRequestFilter
        : mPartDPlansFilterStore.spouseMPartDPlanRequestFilter
    )

  const [sectionLoader, setSectionLoader] = useState<boolean>(true)
  const [plansLoader, setPlansLoader] = useState<boolean>(true)
  const [paginationLoader, setPaginationLoader] = useState<boolean>(true)
  const [userOption, setUserOption] = useState<boolean>(
    mPartDPlansFilterStore.getIsPrimaryAndSpouseOption()
  )
  const [mPartDPlansFilters, setMPartDPlansFilters] =
    useState<MPartDPlanDisplayFilter>(
      mPartDPlansFilterStore.getIsPrimaryAndSpouseOption()
        ? mPartDPlansFilterStore.spouseMPartDPlanFilters
        : mPartDPlansFilterStore.mPartDPlanFilters
    )

  useEffect(() => {
    if (!isUndefined(mPartDPlansFilterStore.getMPartDPlansFilters())) {
      setMPartDPlansFilters(mPartDPlansFilterStore.getMPartDPlansFilters())
    }
  }, [mPartDPlansFilterStore.getMPartDPlansFilters()])

  const { trackCurrentPage } = React.useContext(ActionContext)

  const filterPrescriptionRef = useRef<any>(null)

  const [clearFilterState, setClearFilterState] = React.useState(false)

  const getPlans = (payload: MParDPlanFiltersRequest) => {
    getMPartDPPlans(payload)
      .then((medicarePartDPlans: any) => {
        // const convertedPlans: MNonSupplementPlan[] = []
        // if (response.status === 200) {

        const convertedMPartDPlans: MNonSupplementPlan[] = []
        for (let medicarePartDPlan of medicarePartDPlans.data) {
          const additionalCost: {
            name: string
            value: string
            toolTip: string
          }[] = [
            {
              name: 'Estimated Annual Cost',
              value: convertToUSCurrency(medicarePartDPlan.drugCost, 0),
              toolTip: StringConstants.TOOLTIP_ESTIMATED_ANNUAL_COST,
            },
            {
              name: 'Drug Deductible',
              value: convertToUSCurrency(medicarePartDPlan.deductible, 2),
              toolTip: StringConstants.TOOLTIP_MSG_PDP_PLAN_DEDUCTIBLE,
            },
          ]
          medicarePartDPlan['additionalCosts'] = additionalCost
          convertedMPartDPlans.push(medicarePartDPlan)
        }
        // if (
        //   (mPartDPlansFilterStore.prevSortType !== null &&
        //     mPartDPlansFilterStore.prevSortType !==
        //       mPartDPlansFilterStore.getMPartDPlansFilters()
        //         .selectSortOption) ||
        //   customerStore.mpdPageNumber === PaginationConstants.DEFAULT_PAGE
        // ) {
        //   mPartDPlansFilterStore.setPrevSortType(
        //     mPartDPlansFilterStore.getMPartDPlansFilters().selectSortOption
        //   )
        //   if (!isClearFilters)
        //     customerStore.setMPDPageNumber(
        //       customerStore.mpdPageSize / PaginationConstants.DEFAULT_PAGE_SIZE
        //     )
        //   setMPartDPlans(convertedMPartDPlans)
        // } else {
        //   setMPartDPlans(cloneDeep(mPartDPlans.concat(convertedMPartDPlans)))
        // }
        if (
          payload.paginationDetail?.page === PaginationConstants.DEFAULT_PAGE
        ) {
          onDataChange(cloneDeep(convertedMPartDPlans))
        } else {
          onDataChange(cloneDeep(plans.concat(convertedMPartDPlans)))
        }
        setMPartDPlanCount(
          !isUndefined(medicarePartDPlans.totalCount)
            ? medicarePartDPlans.totalCount
            : medicarePartDPlans.data.length
        )
        // } else {
        //   setMPartDPlans([])
        //   if (isMounted.current)
        //     snackbarStore.set({
        //       snackbarOpen: true,
        //       snackbarMessage: 'No plans present for the applied filters',
        //       snackbarType: 'success',
        //     })
        //   mPartDPlansFilterStore.setTotalCount(0)
        //   mPartDPlansFilterStore.setFilterApplied(false)
        // }
        // mPartDPlansFilterStore.setFilterApplied(false)
        // mPartDPlansFilterStore.setPlansFetched(true)
        // } else {
        //   throw response
        // }
      })
      .catch((err: Error) => {
        // if (isMounted.current)
        //   snackbarStore.set({
        //     snackbarOpen: true,
        //     snackbarMessage: getApiErrorMessage('fetch Medicare part D plans'),
        //     snackbarType: 'error',
        //   })
      })
      .finally(() => {
        setSectionLoader(false)
        setPlansLoader(false)
        setPaginationLoader(false)
      })
  }
  const getFilters = async (forSpouse: boolean) => {
    getMNSPlansFilters<MedicarePartDFilters>(
      MedicareQuotesType.MEDICARE_PRESCRIPTION,
      customerStore.mPartDFilterYear === ''
        ? getPlanYear().toString()
        : customerStore.mPartDFilterYear,
      forSpouse
    )
      .then((mPartDPlanFilter: MedicarePartDFilters) => {
        // if (medicarePartDPlansFilter.status === 200) {
        if (!isEmpty(mPartDPlanFilter)) {
          const mPartDPlanDisplayFilter: MPartDPlanDisplayFilter = {
            carriers: mPartDPlanFilter.carriers.map(
              (carrier, index: number) => {
                return {
                  id: parseInt(carrier.id),
                  checked: false,
                  label: carrier.name,
                  value: carrier.name,
                }
              }
            ),
            selectSortOption: QuotesConstants.initialMPartDPlanSortOption,
            deductible: {
              minValue: mPartDPlanFilter.deductible.start,
              maxValue: mPartDPlanFilter.deductible.end,
              currentValue: mPartDPlanFilter.deductible.end,
              stepValue: getStepValue(
                10,
                mPartDPlanFilter.deductible.end -
                  mPartDPlanFilter.deductible.start
              ),
            },
            minStarRating: {
              minValue: 0,
              maxValue: mPartDPlanFilter.minStarRating.end,
              currentValue: 0,
              stepValue: getStepValue(
                10,
                mPartDPlanFilter.minStarRating.end -
                  mPartDPlanFilter.minStarRating.start
              ),
            },
            monthlyPremium: {
              minValue: mPartDPlanFilter.monthlyPremium.start,
              maxValue: mPartDPlanFilter.monthlyPremium.end,
              currentValue: mPartDPlanFilter.monthlyPremium.end,
              stepValue: getStepValue(
                10,
                mPartDPlanFilter.monthlyPremium.end -
                  mPartDPlanFilter.monthlyPremium.start
              ),
            },
            planYear: mPartDPlanFilter.planYear
              ? mPartDPlanFilter.planYear
              : { planYears: [], defaultPlanYear: '' },
            currentPlanYear: mPartDPlanFilter.planYear.defaultPlanYear,
            drugCheckBoxes: mPartDPlanFilter.prescriptionDrugs
              ? mPartDPlanFilter.prescriptionDrugs?.map(
                  (prescriptionDrug, index) => {
                    return {
                      id: index,
                      checked: false,
                      label: startCase(prescriptionDrug.name),
                      value: prescriptionDrug.name,
                      optionalValue: prescriptionDrug.currentDosage.id,
                    }
                  }
                )
              : [],
            providers: mPartDPlanFilter.providers
              ? mPartDPlanFilter.providers?.map((provider, index) => {
                  return {
                    id: index,
                    checked: false,
                    label: provider.name,
                    value: provider.name,
                    optionalValue: provider.id,
                  }
                })
              : [],
            sortOptions: mPartDPlanFilter.sortOptions,
            clearFiltersApplied: false,
            forSpouse: mPartDPlanFilter.forSpouse,
          }

          const planOptions = mPartDPlanFilter.sortOptions.map((option) => {
            return { title: option, value: option }
          })

          setSortPlanOptions(planOptions)

          setCurrentSortOption({
            label: mPartDPlanDisplayFilter.selectSortOption!,
            index: planOptions.findIndex((option) => {
              return option.title === mPartDPlanDisplayFilter.selectSortOption!
            }),
          })

          mPartDPlansFilterStore.setSelectedFilters(
            [],
            mPartDPlansFilterStore.isPrimaryAndSpouseOption
          )
          setSelectedFilters(
            mPartDPlansFilterStore.getSelectedFilters(
              mPartDPlansFilterStore.isPrimaryAndSpouseOption
            )
          )
          if (!forSpouse) {
            mPartDPlansFilterStore.setPartDPlansFilters(mPartDPlanDisplayFilter)
            handleRequestBodyChange(
              {
                // carriers: carriers,
                // planTypes: planTypes,
                paginationDetail: {
                  page: PaginationConstants.DEFAULT_PAGE,
                  size: PaginationConstants.DEFAULT_PAGE_SIZE,
                },
                sortDetail: {
                  order: 'ASC',
                  type: QuotesConstants.initialMAPlanSortOption,
                },
                planYear:
                  customerStore.maFilterYear === ''
                    ? getPlanYear().toString()
                    : customerStore.maFilterYear,
                forSpouse: false,
              },
              false
            )
          } else {
            handleRequestBodyChange(
              {
                // carriers: carriers,
                // planTypes: planTypes,
                paginationDetail: {
                  page: PaginationConstants.DEFAULT_PAGE,
                  size: PaginationConstants.DEFAULT_PAGE_SIZE,
                },
                sortDetail: {
                  order: 'ASC',
                  type: QuotesConstants.initialMAPlanSortOption,
                },
                planYear:
                  customerStore.maFilterYear === ''
                    ? getPlanYear().toString()
                    : customerStore.maFilterYear,
                forSpouse: true,
              },
              true
            )
          }
          setMPartDPlansFilters(mPartDPlanDisplayFilter)
          customerStore.setMAPageSize(PaginationConstants.DEFAULT_PAGE_SIZE)
        } else {
          snackbarStore.set({
            snackbarOpen: true,
            snackbarMessage: 'No Medicare Part D Plans Present',
            snackbarType: 'success',
          })
          setPlansLoader(false)
          setSectionLoader(false)
          setPaginationLoader(false)
        }
      })
      .catch((err: Error) => {
        snackbarStore.set({
          snackbarOpen: true,
          snackbarMessage: StringConstants.INTERNAL_SERVER_ERROR,
          snackbarType: 'error',
        })
        setPlansLoader(false)
        setSectionLoader(false)
        setPaginationLoader(false)
      })
    // } else {
    //   if (!isTabChanged) {
    //     const currentDate = new Date()
    //     const currentMonth = currentDate.getMonth() + 1
    //     if (isEqual(mPartDPlansFilterStore.getMPartDPlanRequestFilters(), {})) {
    //       mPartDPlansFilterStore.setMPartDPlanRequestFilters({
    //         paginationDetail: {
    //           page: PaginationConstants.DEFAULT_PAGE,
    //           size: PaginationConstants.DEFAULT_PAGE_SIZE,
    //         },
    //         sortDetail: {
    //           order: 'ASC',
    //           type: QuotesConstants.initialMPartDPlanSortOption,
    //         },
    //         planYear:
    //           customerStore.mPartDFilterYear === ''
    //             ? currentMonth >= 10 && currentMonth <= 12
    //               ? customerStore.currentMedicarePartBDateSelf
    //                 ? Number(
    //                     customerStore.currentMedicarePartBDateSelf.substring(
    //                       0,
    //                       4
    //                     )
    //                   ) <
    //                   currentDate.getFullYear() + 1
    //                   ? currentDate.getFullYear().toString()
    //                   : (currentDate.getFullYear() + 1).toString()
    //                 : (currentDate.getFullYear() + 1).toString()
    //               : currentDate.getFullYear().toString()
    //             : customerStore.mPartDFilterYear,
    //       })
    //       customerStore.setMPDPageNumber(PaginationConstants.DEFAULT_PAGE)
    //       customerStore.setMPDPageSize(
    //         customerStore.mpdPageSize + PaginationConstants.DEFAULT_PAGE_SIZE
    //       )
    //     } else {
    //       if (
    //         mPartDPlansFilterStore.prevSortType ===
    //         mPartDPlansFilterStore.getMPartDPlansFilters().selectSortOption
    //       ) {
    //         customerStore.setMPDPageNumber(PaginationConstants.DEFAULT_PAGE)
    //         mPartDPlansFilterStore.setPaginationDetails({
    //           page: PaginationConstants.DEFAULT_PAGE,
    //           size: customerStore.mpdPageSize,
    //         })
    //       }
    //     }
    //     getMedicarePartDPlans(
    //       mPartDPlansFilterStore.getMPartDPlanRequestFilters(),
    //       true
    //     )
    //   }
    // }
  }

  const getStepValue = (percentage: number, total: number): number => {
    return Math.round((percentage / 100) * total)
  }

  useEffect(() => {
    trackCurrentPage('Dashboard - Medicare Prescription Drugs')
  }, [trackCurrentPage])

  useEffect(() => {
    if (!mPartDPlansFilterStore.isPrimaryAndSpouseOption) {
      mPartDPlansFilterStore.setPartDPlansFilters(mPartDPlansFilters)
    } else {
      mPartDPlansFilterStore.setSpousePartDPlansFilters(mPartDPlansFilters)
    }
  }, [mPartDPlansFilters])

  useEffect(() => {
    setSectionLoader(true)

    if (isEmpty(mPartDPlansFilterStore.mPartDPlanRequestFilter))
      mPartDPlansFilterStore.setMPartDPlanRequestFilters({
        paginationDetail: {
          page: PaginationConstants.DEFAULT_PAGE,
          size: PaginationConstants.DEFAULT_PAGE_SIZE,
        },
        sortDetail: {
          order: 'ASC',
          type: QuotesConstants.initialMAPlanSortOption,
        },
        planYear:
          customerStore.maFilterYear === ''
            ? getPlanYear().toString()
            : customerStore.maFilterYear,
        forSpouse: false,
      })
    if (
      customerStore.spouseOptIn &&
      isEmpty(mPartDPlansFilterStore.spouseMPartDPlanRequestFilter)
    )
      mPartDPlansFilterStore.spouseMPartDPlanRequestFilter = {
        paginationDetail: {
          page: PaginationConstants.DEFAULT_PAGE,
          size: PaginationConstants.DEFAULT_PAGE_SIZE,
        },
        sortDetail: {
          order: 'ASC',
          type: QuotesConstants.initialMAPlanSortOption,
        },
        planYear:
          customerStore.maFilterYear === ''
            ? getPlanYear().toString()
            : customerStore.maFilterYear,
        forSpouse: true,
      }
    if (isEmpty(mPartDPlansFilters)) getFilters(userOption)
  }, [])

  useEffect(() => {
    if (!isEmpty(mPartDRequestBody)) getPlans(mPartDRequestBody)
  }, [mPartDRequestBody])

  // useEffect(() => {
  //   if (!isEqual(mPartDPlansFilterStore.getMPartDPlansFilters(), {})) {
  //     const planOptions = mPartDPlansFilterStore
  //       .getMPartDPlansFilters()
  //       .sortOptions.map((option) => {
  //         return { title: option, value: option }
  //       })

  //     setSortPlanOptions(planOptions)

  //     setCurrentSortOption({
  //       label: mPartDPlansFilterStore.getMPartDPlansFilters().selectSortOption!,
  //       index: planOptions.findIndex((option) => {
  //         return (
  //           option.title ===
  //           mPartDPlansFilterStore.getMPartDPlansFilters().selectSortOption!
  //         )
  //       }),
  //     })
  //   }
  // }, [
  //   JSON.stringify(
  //     mPartDPlansFilterStore.getMPartDPlansFilters().selectSortOption
  //   ),
  // ])

  // useEffect(() => {
  //   if (!isEmpty(medicarePartDPlans)) {
  //     mPartDPlansFilterStore.setPlansFetched(true)
  //     mPartDPlansFilterStore.setPaginationApplied(false)
  //     mPartDPlansFilterStore.setPaginationLoader(false)
  //   }
  //   mPartDPlansFilterStore.setClearFilters(false)
  // }, [medicarePartDPlans])

  const [thankYouModalActive, setThankYouModalActive] = React.useState(false)

  const handleSortChange = (e: any) => {
    const sortOption: string = e.target.innerText
      .split()[0]
      .split(' (High to Low)')[0]

    if (currentSortOption?.label !== sortOption) {
      if (sortOption === 'Overall Rating') {
        handleRequestBodyChange(
          {
            sortDetail: { order: 'DESC', type: sortOption },
            paginationDetail: {
              page: PaginationConstants.DEFAULT_PAGE,
              size: PaginationConstants.DEFAULT_PAGE_SIZE,
            },
          },
          userOption
        )
      } else {
        handleRequestBodyChange(
          {
            sortDetail: { order: 'ASC', type: sortOption },
            paginationDetail: {
              page: PaginationConstants.DEFAULT_PAGE,
              size: PaginationConstants.DEFAULT_PAGE_SIZE,
            },
          },
          userOption
        )
      }

      setCurrentSortOption({
        label: sortOption,
        index: sortPlanOptions.findIndex((option) => {
          return option.title === sortOption
        }),
      })

      if (mPartDPlansFilterStore.isPrimaryAndSpouseOption) {
        mPartDPlansFilterStore.spouseMPartDPlanFilters.selectSortOption =
          sortOption
      } else
        mPartDPlansFilterStore.mPartDPlanFilters.selectSortOption = sortOption

      setPlansLoader(true)
    }
  }

  const handlePaginationChange = () => {
    setPaginationLoader(true)
    handleRequestBodyChange(
      {
        paginationDetail: {
          page:
            mPartDRequestBody.paginationDetail?.page! +
            PaginationConstants.DEFAULT_PAGE,
          size: PaginationConstants.DEFAULT_PAGE_SIZE,
        },
      },
      userOption
    )
    customerStore.setMPDPageNumber(
      customerStore.mpdPageNumber + PaginationConstants.DEFAULT_PAGE
    )
    customerStore.setMPDPageSize(
      customerStore.mpdPageSize + PaginationConstants.DEFAULT_PAGE_SIZE
    )
  }

  const handleOnEnroll = () => {
    setThankYouModalActive(true)
  }

  const [selectedFilters, setSelectedFilters] = useState<string[]>(
    mPartDPlansFilterStore.isPrimaryAndSpouseOption
      ? mPartDPlansFilterStore.spouseSelectedFilters
      : mPartDPlansFilterStore.selectedFilters
  )

  const handleClearFilters = () => {
    setSectionLoader(true)
    mPartDPlansFilterStore.clearRequestFilters(
      mPartDPlansFilterStore.isPrimaryAndSpouseOption
    )
    setSelectedFilters([])
    setMPartDPlansFilters({} as MPartDPlanDisplayFilter)
    getFilters(mPartDPlansFilterStore.isPrimaryAndSpouseOption)
  }

  const updateFilters = (unFilter: string) => {
    setPlansLoader(true)
    const tempSelectedFilters = selectedFilters.filter(
      (filter) => filter !== unFilter
    )

    setSelectedFilters(tempSelectedFilters)
    mPartDPlansFilterStore.setSelectedFilters(
      tempSelectedFilters,
      mPartDPlansFilterStore.isPrimaryAndSpouseOption
    )

    if (mPartDPlansFilters) {
      let fieldsChanged = mPartDRequestBody
      const checkedCarriers = mPartDPlansFilters.carriers?.map((item) =>
        item.value === unFilter ? { ...item, checked: !item.checked } : item
      )

      fieldsChanged['carriers'] = checkedCarriers
        .filter((element) => element.checked)
        .map((element) => element.id)

      const checkedDrugs = mPartDPlansFilters.drugCheckBoxes?.map((item) =>
        item.value === unFilter ? { ...item, checked: !item.checked } : item
      )
      fieldsChanged['prescriptions'] = checkedDrugs
        .filter((element) => element.checked)
        .map((element) => element.optionalValue!)

      const checkedProviders = mPartDPlansFilters.providers?.map((item) =>
        item.value === unFilter ? { ...item, checked: !item.checked } : item
      )

      fieldsChanged['providers'] = checkedProviders
        .filter((element) => element.checked)
        .map((element) => element.optionalValue!)

      const tempFilters: MPartDPlanDisplayFilter = {
        ...mPartDPlansFilters,
        carriers: checkedCarriers,
        drugCheckBoxes: checkedDrugs,
        providers: checkedProviders,
      }
      setMPartDPlansFilters(tempFilters)

      if (mPartDPlansFilterStore.isPrimaryAndSpouseOption)
        mPartDPlansFilterStore.setSpousePartDPlansFilters(tempFilters)
      else mPartDPlansFilterStore.setPartDPlansFilters(tempFilters)

      handleRequestBodyChange(
        fieldsChanged,
        mPartDPlansFilterStore.isPrimaryAndSpouseOption
      )

      mPartDPlansFilterStore.setIsFilterChanged(true)
    }
  }

  const handleRequestBodyChange = (
    fieldsChanged: MParDPlanFiltersRequest,
    forSpouse: boolean
  ) => {
    let requestBody: MParDPlanFiltersRequest = {}
    if (forSpouse) {
      requestBody = mPartDPlansFilterStore.spouseMPartDPlanRequestFilter
      setMPartDRequestBody({ ...requestBody, ...fieldsChanged })
      mPartDPlansFilterStore.spouseMPartDPlanRequestFilter = {
        ...requestBody,
        ...fieldsChanged,
      }
    } else {
      requestBody = mPartDPlansFilterStore.mPartDPlanRequestFilter
      setMPartDRequestBody({ ...requestBody, ...fieldsChanged })
      mPartDPlansFilterStore.mPartDPlanRequestFilter = {
        ...requestBody,
        ...fieldsChanged,
      }
    }
  }

  const handleUserChange = (forSpouse: boolean) => {
    setSectionLoader(true)
    setUserOption(forSpouse)

    let isFiltersCalled = false
    mPartDPlansFilterStore.setIsPrimaryAndSpouseOption(forSpouse)

    if (forSpouse) {
      if (isEmpty(mPartDPlansFilterStore.spouseMPartDPlanFilters)) {
        isFiltersCalled = true
        getFilters(forSpouse)
      } else {
        setMPartDPlansFilters(mPartDPlansFilterStore.spouseMPartDPlanFilters)
        setCurrentSortOption({
          index:
            mPartDPlansFilterStore.spouseMPartDPlanFilters?.sortOptions?.findIndex(
              (option) => {
                return (
                  option ===
                  mPartDPlansFilterStore.spouseMPartDPlanFilters
                    ?.selectSortOption!
                )
              }
            ),
          label:
            mPartDPlansFilterStore.spouseMPartDPlanFilters.selectSortOption!,
        })
      }
    } else {
      if (isEmpty(mPartDPlansFilterStore.mPartDPlanFilters)) {
        isFiltersCalled = true
        getFilters(forSpouse)
      } else {
        // setMAPlansFilters({
        //   ...maPlansFilters,
        //   forSpouse,
        // })
        setMPartDPlansFilters(mPartDPlansFilterStore.mPartDPlanFilters)
        setCurrentSortOption({
          index: mPartDPlansFilterStore
            .getMPartDPlansFilters()
            ?.sortOptions?.findIndex((option) => {
              return (
                option ===
                mPartDPlansFilterStore.getMPartDPlansFilters()
                  ?.selectSortOption!
              )
            }),
          label: mPartDPlansFilterStore.mPartDPlanFilters.selectSortOption!,
        })
      }
    }
    // setMAPlansRequestBody({
    //   ...maPlansRequestBody,
    //   forSpouse: forSpouse,
    // })
    if (!isFiltersCalled)
      handleRequestBodyChange({ forSpouse: forSpouse }, forSpouse)
  }

  const handleSpouseRemove = () => {
    setSectionLoader(false)
    removeSpouseDetails()
      .then(() => {
        getCustomerDetails()
          .then((customerDetails: any) => {
            customerStore.set(customerDetails)
            getFilters(mPartDPlansFilterStore.isPrimaryAndSpouseOption)
          })
          .catch((err) => {
            throw err
          })
      })
      .catch(() => {
        setSectionLoader(false)
      })
  }

  return (
    <Container>
      {thankYouModalActive && (
        <ModalComponent setOpen={() => setThankYouModalActive(false)}>
          <ThankYouModal setOpen={() => setThankYouModalActive(false)} />
        </ModalComponent>
      )}

      {completePlanDetailModalActive && !isEmpty(completeDetailPlanId) ? (
        <ModalComponent
          setOpen={() => setCompletePlanDetailModalActive(false)}
          maxWidth={'none'}
          restrictScroll={true}
        >
          <CompletePlanDetailsPage
            medicarePlanId={completeDetailPlanId}
            medicarePlanType='mpd'
            printComponentRef={printRef}
            reactToPrint={reactToPrint}
            mnsPlan={plans.find((plan) => plan.id === completeDetailPlanId)}
          />
        </ModalComponent>
      ) : (
        <></>
      )}
      {/* Hidden Printable Content */}
      {/* <div style={{ display: 'none' }}>
        <div ref={printRef}>
          <CompletePlanDetailsPage
            medicarePlanId={completeDetailPlanId}
            medicarePlanType='ms'
            printComponentRef={printRef}
            reactToPrint={reactToPrint}
          />
        </div>
      </div> */}

      {!sectionLoader ? (
        <>
          {!isEmpty(mPartDPlansFilters) && (
            <FilterPrescription
              showFilter={isOpen}
              handleShowFilter={toggleSidebar}
              filterRef={filterPrescriptionRef}
              clearFilterState={clearFilterState}
              clearButtonState={handleClearFilters}
              selectedFilters={selectedFilters}
              setSelectedFilters={(Value: string[]) => {
                setSelectedFilters(Value)
              }}
              mPartDPlansFilters={mPartDPlansFilters}
              setMPartDPlansFilters={setMPartDPlansFilters}
              mPartDPlansRequestBody={mPartDRequestBody}
              handleRequestBodyChange={handleRequestBodyChange}
              setPlanLoader={setPlansLoader}
            />
          )}

          <CardsWrapper>
            {/* {currentSortOption.label === 'Popularity' */}
            {customerStore.recommendedPlanType ===
              MedicareQuotesType.MEDICARE_PRESCRIPTION &&
              plans.length > 0 && (
                <div className='recommendation-header'>
                  <h2>Here are the Best Plans for You</h2>
                  <p>
                    Make changes to your drugs, doctors, pharmacy, and/or your
                    desired benefits and see how it affects your recommendation.
                  </p>
                </div>
              )}

            {!isEmpty(mPartDPlansFilters) && (
              <FilterContainer>
                <div className='bottom-wrapper'>
                  <PrimarySpouseToggle
                    handleUserChange={handleUserChange}
                    forSpouse={userOption}
                  />
                  <UtilityContainer>
                    <div
                      className='filter-toggle'
                      onClick={() => {
                        toggleSidebar()
                      }}
                    >
                      <RiFilter2Fill style={{ color: '#666666' }} />
                      <Left>Filters</Left>
                    </div>
                    {sortPlanOptions && (
                      <SortBy
                        options={sortPlanOptions}
                        value={currentSortOption}
                        onChange={handleSortChange}
                      />
                    )}
                  </UtilityContainer>
                </div>
                <FilterBox
                  handleClearFilters={handleClearFilters}
                  selectedFilters={selectedFilters}
                  updateFilters={updateFilters}
                  key={'MA_FILTER_BOX'}
                />
              </FilterContainer>
            )}

            <>
              {plansLoader ? (
                <ThreeDotsWrapper>
                  <ThreeDots color='#222C69' height={80} width={80} />
                </ThreeDotsWrapper>
              ) : plans.length > 0 ? (
                <>
                  {/* {currentSortOption.label === 'Popularity' */}
                  {customerStore.recommendedPlanType ===
                    MedicareQuotesType.MEDICARE_PRESCRIPTION && (
                    <RecommendedPrescriptionCards
                      recommendedPlan={plans[0]}
                      goodChoicePlan={plans.length > 1 ? plans[1] : undefined}
                      setCompleteDetailPlanId={setCompleteDetailPlanId}
                      setCompletePlanDetailModalActive={
                        setCompletePlanDetailModalActive
                      }
                      onEnroll={handleOnEnroll}
                      userOption={userOption}
                      handleSpouseRemove={handleSpouseRemove}
                      setShowAddSpouseConfirmation={
                        setShowAddSpouseConfirmation
                      }
                    />
                  )}
                  {(customerStore.recommendedPlanType !==
                  MedicareQuotesType.MEDICARE_PRESCRIPTION
                    ? plans
                    : plans.slice(2)
                  ).map((cardData: MNonSupplementPlan, index) => (
                    <div key={cardData.id}>
                      <MedicarePrescriptionCard
                        setCompleteDetailPlanId={setCompleteDetailPlanId}
                        setCompletePlanDetailModalActive={
                          setCompletePlanDetailModalActive
                        }
                        medicarePartDPlan={cardData}
                        onEnroll={handleOnEnroll}
                        userOption={userOption}
                        handleSpouseRemove={handleSpouseRemove}
                        setShowAddSpouseConfirmation={
                          setShowAddSpouseConfirmation
                        }
                      />
                      {index % 2 !== 0 && (
                        <CallUsNow
                          heading='Not sure which Medicare plan is right for you?'
                          message='Our licensed insurance agents are here to help'
                          phoneNumber={getContact(customerStore.getBrandName())}
                        />
                      )}
                    </div>
                  ))}

                  {!paginationLoader ? (
                    <PaginationWrapper>
                      {!isNull(mPartDTotalCount) &&
                        Math.ceil(
                          mPartDTotalCount /
                            PaginationConstants.DEFAULT_PAGE_SIZE
                        ) >
                          (mPartDRequestBody.paginationDetail
                            ? mPartDRequestBody.paginationDetail.page
                            : 0) && (
                          <Button
                            color='primary'
                            variant='contained'
                            onClick={handlePaginationChange}
                          >
                            Load More Plans
                          </Button>
                        )}
                    </PaginationWrapper>
                  ) : (
                    <ThreeDotsWrapper>
                      <ThreeDots color='#222C69' height={80} width={80} />
                    </ThreeDotsWrapper>
                  )}
                </>
              ) : (
                <NoPlanFoundCard />
              )}
            </>
          </CardsWrapper>
        </>
      ) : (
        <ThreeDotsWrapper>
          <ThreeDots color='#222C69' height={80} width={80} />
        </ThreeDotsWrapper>
      )}

      {userActionStore.comparePlanCards.planType ===
        MedicareQuotesType.MEDICARE_PRESCRIPTION &&
        userActionStore.comparePlanCards.compareCardsData.length !== 0 && (
          <BottomAddToCompare />
        )}
    </Container>
  )
}

export default observer(MedicarePrescription)
