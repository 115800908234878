import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import React, { useRef } from 'react'

import styled from '@emotion/styled'
import theme from '../../global/theme'

interface CollapsibleContainerProps {
  header: string
  className?: string
  isCollapsed?: boolean
  index: number
  syncScroll: (event: any) => void
  sourceScroll?: (event: any) => void
}

const Container = styled.div`
  border: 1px solid ${theme.colors.borderColor};
  margin-bottom: 10px;
`

const Header = styled.div`
  background-color: ${theme.colors.primary};
  padding: 10px 40px;
  color: ${theme.colors.textLight};
  font-weight: 700;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    padding-right: 10px;
  }

  @media screen and (max-width: 1200px) {
    padding: 10px 10px;
  }
`

const ContentWrapper = styled.div<{ collapsed: boolean }>`
  display: ${({ collapsed }) => (collapsed ? 'none' : 'block')} !important;
  overflow-x: scroll;
  display: flex;
  flex-direction: column;
`
const ContentMainWrapper = styled.div`
  overflow: hidden;
  position: relative;
`

const CollapsibleContainer: React.FC<CollapsibleContainerProps> = ({
  header,
  children,
  className,
  index,
  isCollapsed = true,
  syncScroll,
  sourceScroll,
}) => {
  const [collapsed, setCollapsed] = React.useState(isCollapsed)
  const divRef = useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (divRef.current) {
      divRef.current.addEventListener('scroll', (event: any) => {
        syncScroll(event)
      })
    }

    // Cleanup function
    return () => {
      if (divRef.current) {
        divRef.current.removeEventListener('scroll', syncScroll)
      }
    }
  }, [])

  return (
    <Container>
      <Header
        onClick={() => {
          setCollapsed(!collapsed)
        }}
      >
        <span>{header}</span>
        {collapsed ? <BsChevronDown /> : <BsChevronUp />}
      </Header>
      <ContentMainWrapper>
        <ContentWrapper
          ref={divRef}
          collapsed={collapsed}
          className={'scrollTarget'}
        >
          {children}
        </ContentWrapper>
      </ContentMainWrapper>
    </Container>
  )
}

export default CollapsibleContainer
