import { BurgerMenuProvider } from '../../../context/SidebarContext'
import Footer from '../Footer/Footer.component'
import Helmet from 'react-helmet'
import Navbar from '../Navbar/Navbar.component'
import React from 'react'
import ScrollToTopButton from '../ScrollToTopButton'
import Snackbar from '../Snackbar/Snackbar'
import aetnaFavicon from '../../../assets/images/aetna-favicon.png'
import customerStore from '../../../datastore/CustomerStore'
import defaultFavicon from '../../../assets/images/flag-favicon.png'
import devotedFavicon from '../../../assets/images/devoted-favicon.png'
import { isNavBannerShown } from '../../../utils/CommonUtils'
import mutualOfOmahaFavicon from '../../../assets/images/mutual-of-omaha-favicon.ico'
import { observer } from 'mobx-react'
import snackbarStore from '../../../datastore/SnackbarStore'
import styled from '@emotion/styled'

type SnackbarType = 'success' | 'error' | 'warning' | 'default'

interface LayoutProps {
  children?: JSX.Element | string
  showDiscountsDisclaimer?: boolean
  type?: string
}

interface BannerProps {
  isBanner?: boolean
}

const Container = styled.div<BannerProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;

  .content_wrapper {
    display: flex;
    flex-direction: column;
    margin-top: ${(props) => (props.isBanner ? '105.5px' : '85.5px')};
    flex: 1;

    @media screen and (max-width: 885px) {
      margin-top: ${(props) => (props.isBanner ? '105.5px' : '85.5px')};
    }

    @media screen and (max-width: 750px) {
      margin-top: ${(props) => (props.isBanner ? '125.5px' : '95.5px')};
    }

    @media screen and (max-width: 600px) {
      margin-top: ${(props) => (props.isBanner ? '125.5px' : '85.5px')};
    }

    @media screen and (max-width: 400px) {
      margin-top: ${(props) => (props.isBanner ? '115.5px' : '85.5px')};
    }
  }
`

const Wrapper = styled.div`
  padding-top: 0px;
  flex: 1;
`
const NavbarWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000; /* Ensure it stays on top */
`

const Layout: React.FC<LayoutProps> = ({
  children,
  showDiscountsDisclaimer = false,
  type,
}) => {
  const getFavicon = () => {
    const leadBrand = customerStore.getBrandName()

    if (leadBrand?.includes('aetna')) {
      return aetnaFavicon
    } else if (leadBrand?.includes('omaha')) {
      return mutualOfOmahaFavicon
    } else if (leadBrand?.includes('devoted')) {
      return devotedFavicon
    }
    return defaultFavicon
  }

  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false)
  const [showBurgerMenu, setShowBurgerMenu] = React.useState(false)

  return (
    <BurgerMenuProvider
      isOpen={isSidebarOpen}
      toggleSidebar={() => {
        setIsSidebarOpen(!isSidebarOpen)
      }}
      setShowBurgerMenu={(value: boolean) => {
        setShowBurgerMenu(value)
      }}
      showBurgerMenu={showBurgerMenu}
    >
      <Container isBanner={isNavBannerShown(customerStore.getBrandName())}>
        <Helmet>
          <title>Quoting Tool</title>
          <link rel='icon' href={getFavicon()} />
        </Helmet>
        <NavbarWrapper>
          <Navbar type={type} />
        </NavbarWrapper>
        <div className='content_wrapper'>
          <Wrapper>{children}</Wrapper>
        </div>

        <Footer showDiscountsDisclaimer={showDiscountsDisclaimer} />
        <Snackbar
          open={snackbarStore.get().snackBarOpen}
          message={snackbarStore.get().snackbarMessage}
          type={snackbarStore.get().snackbarType as SnackbarType}
          onClose={() => {
            snackbarStore.reset()
          }}
        />
        <ScrollToTopButton />
      </Container>
    </BurgerMenuProvider>
  )
}

export default observer(Layout)
