import { Box, Chip } from '@mui/material'
import { forEach, isUndefined } from 'lodash'
import { useEffect, useState } from 'react'

import CheckboxGroup from '../../../common/CheckboxGroup/CheckboxGroup.component'
import InputField from '../../../common/InputField/InputField.component'
import { QuestionnaireI } from '../../../../interface/questionnaire/QuestionnaireInterface'
import { SaveQuestionnaireRequestI } from '../../../../interface/questionnaire/SaveQuestionnaireRequestInterface'
import SkeletonCard from '../../../DashboardPage/SkeletonCard'
import { SnackbarTypes } from '../../../../enums/SnackbarTypesEnum'
import { getQuestionnaires } from '../../../../service/questionnaire'
import { observer } from 'mobx-react'
import snackbarStore from '../../../../datastore/SnackbarStore'
import styled from 'styled-components'

const Description = styled.p`
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: 600;
  text-align: left;
`

const RadioButtonWrapper = styled.div`
  width: 100%;
  padding: 10px 0px;
  max-height: 400px;
  overflow-y: auto;
  text-align: left;
`

const ChipInputWrapper = styled(Box)`
  margin-top: 16px;
`

const ChipContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
`

interface OptionalQuestionProps {
  forSpouse: boolean
  disableMainNext?: () => void
  enableMainNext?: () => void
  handleHasNext?: (value: boolean) => void
  setOptionalQuotes: (value: any) => void
  optionalQuotes?: SaveQuestionnaireRequestI
}

const OptionalQuoteQuestion: React.FC<OptionalQuestionProps> = ({
  forSpouse,
  disableMainNext,
  enableMainNext,
  handleHasNext,
  optionalQuotes,
  setOptionalQuotes,
}) => {
  const [optionsLoader, setOptionsLoader] = useState<boolean>(true)
  const [customOptions, setCustomOptions] = useState<string[]>([])
  const [inputValue, setInputValue] = useState<string>('')

  useEffect(() => {
    getQuestionnaires(forSpouse, 'CURRENT_PLAN')
      .then((questionnaires: QuestionnaireI[]) => {
        setOptionsLoader(false)
        forEach(questionnaires, (questionnaire: QuestionnaireI) => {
          if (
            questionnaire.question ===
            'Would you also like to see a quote for anything listed below?*'
          ) {
            const tempResponse = {
              id: questionnaire.id,
              inputType: questionnaire.inputType,
              isRequired: questionnaire.isRequired,
              options: questionnaire.options,
              question: questionnaire.question,
              response:
                questionnaire.responses.length > 0
                  ? questionnaire.responses[0].response
                  : [],
            }

            // Find custom options from existing responses
            const standardOptions = new Set(
              questionnaire.options.map((opt) => opt.option)
            )
            const existingCustomOptions = tempResponse.response.filter(
              (resp: string) => !standardOptions.has(resp)
            )

            if (existingCustomOptions.length > 0) {
              setCustomOptions(existingCustomOptions)
              // Add 'Others' to response if it's not already there
              if (!tempResponse.response.includes('Others')) {
                tempResponse.response.push('Others')
              }
            }

            if (questionnaire.responses.length > 0)
              enableMainNext && enableMainNext()
            setOptionalQuotes(tempResponse)
          }
        })
      })
      .catch((err) => {
        snackbarStore.set({
          snackbarOpen: true,
          snackbarMessage: 'Unable to fetch the questionnaire',
          snackbarType: SnackbarTypes.ERROR,
        })
      })
  }, [])

  useEffect(() => {
    if (optionalQuotes && optionalQuotes.response.length > 0) {
      enableMainNext && enableMainNext()
    } else {
      disableMainNext && disableMainNext()
    }
  }, [optionalQuotes])

  const getStandardOptions = () => {
    return optionalQuotes?.options.map((opt) => opt.option) || []
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    const standardOptions = getStandardOptions()

    setOptionalQuotes((prevResponse: any) => {
      if (!prevResponse) return prevResponse

      let newResponse: string[]

      if (value === 'None of the above') {
        // If "None of the above" is selected, clear all other selections
        newResponse = e.target.checked ? ['None of the above'] : []
        setCustomOptions([])
      } else if (value === 'Others') {
        // Handle "Others" selection
        const isChecked = !prevResponse.response.includes(value)
        if (!isChecked) {
          // If unchecking "Others", remove all custom options
          newResponse = prevResponse.response.filter(
            (item: string) =>
              standardOptions.includes(item) && item !== 'Others'
          )
          setCustomOptions([])
        } else {
          // When checking "Others", keep standard options and add back all custom options
          const standardResponses = prevResponse.response.filter(
            (item: string) =>
              standardOptions.includes(item) && item !== 'None of the above'
          )
          newResponse = [...standardResponses, value, ...customOptions]
        }
      } else {
        // Handle other options
        const isChecked = !prevResponse.response.includes(value)
        if (isChecked) {
          const standardResponses = prevResponse.response.filter(
            (item: string) =>
              (standardOptions.includes(item) ||
                customOptions.includes(item)) &&
              item !== 'None of the above'
          )
          newResponse = [...standardResponses, value]
        } else {
          newResponse = prevResponse.response.filter(
            (item: string) => item !== value
          )
        }
      }

      return {
        ...prevResponse,
        response: newResponse,
      }
    })
  }

  const handleChipInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && inputValue.trim()) {
      e.preventDefault()
      const newOption = inputValue.trim()
      if (!customOptions.includes(newOption)) {
        const newCustomOptions = [...customOptions, newOption]
        setCustomOptions(newCustomOptions)

        setOptionalQuotes((prevResponse: any) => {
          const standardOptions = getStandardOptions()
          // Keep all standard options (except 'None of the above')
          const standardResponses = prevResponse.response.filter(
            (item: string) =>
              standardOptions.includes(item) &&
              item !== 'None of the above' &&
              item !== 'Others' // Remove any existing 'Others'
          )

          return {
            ...prevResponse,
            response: [...standardResponses, 'Others', ...newCustomOptions],
          }
        })
      }
      setInputValue('')
    }
  }

  const handleChipDelete = (optionToDelete: string) => {
    const newCustomOptions = customOptions.filter(
      (option) => option !== optionToDelete
    )
    setCustomOptions(newCustomOptions)

    setOptionalQuotes((prevResponse: any) => {
      const standardOptions = getStandardOptions()
      // Keep standard options (except 'None of the above')
      const standardResponses = prevResponse.response.filter(
        (item: string) =>
          standardOptions.includes(item) &&
          item !== 'None of the above' &&
          item !== 'Others' // Remove any existing 'Others'
      )

      // If no custom options left, just return standard responses
      if (newCustomOptions.length === 0) {
        return {
          ...prevResponse,
          response: standardResponses,
        }
      }

      return {
        ...prevResponse,
        response: [...standardResponses, 'Others', ...newCustomOptions],
      }
    })
  }

  const isNoneSelected = optionalQuotes?.response.includes('None of the above')
  const isOthersSelected = optionalQuotes?.response.includes('Others')

  return optionsLoader ? (
    <SkeletonCard />
  ) : (
    <>
      <Description>{optionalQuotes?.question}</Description>
      <RadioButtonWrapper>
        <CheckboxGroup
          checkboxStyle={{
            borderRadius: '5px',
            border: 'solid #E5E5E5 1px',
            background: '#FBFBFB',
          }}
          checkboxState={
            !isUndefined(optionalQuotes)
              ? optionalQuotes?.options.map((option) => ({
                  id: option.option,
                  checked: optionalQuotes.response.includes(option.option),
                  label: option.option,
                  disabled:
                    isNoneSelected && option.option !== 'None of the above',
                }))
              : []
          }
          width='100%'
          onChange={handleCheckboxChange}
        />
      </RadioButtonWrapper>

      {isOthersSelected && (
        <ChipInputWrapper>
          <InputField
            fullWidth
            size='small'
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleChipInput}
            placeholder='Type custom option and press Enter'
          />
          <ChipContainer>
            {customOptions.map((option) => (
              <Chip
                key={option}
                label={option}
                onDelete={() => handleChipDelete(option)}
                color='primary'
                variant='outlined'
                size='medium'
              />
            ))}
          </ChipContainer>
        </ChipInputWrapper>
      )}
    </>
  )
}

export default observer(OptionalQuoteQuestion)
