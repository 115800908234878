import React, { useState } from 'react'
import {
  getCustomerDetails,
  updateInfoStep,
  updateSupplementalQuestions,
} from '../../../../service/customer'
import { useNavigate, useSearchParams } from 'react-router-dom'

import BottomStepNavigator from '../../BottomStepNavigator.component'
import BouncingDotsLoader from '../../../common/Loading/BouncingDotsLoader'
import { CurrentPlanISComponentEnum } from '../../../../enums/CurrentPlanISComponentEnum'
import DateQuestion from './DateQuestion'
import DisplayCard from './DisplayCard'
import { InfoStepEnum } from '../../../../enums/InfoStepEnum'
import { MedicareConstants } from '../../../../constants/MedicareConstants'
import MuiTableCell from '@mui/material/TableCell'
import MuiTableRow from '@mui/material/TableRow'
import OptionalQuoteQuestion from './OptionalQuoteQuestion'
import Question from '../Common/Question'
import RecommendedPlan from './RecommendedPlan'
import RouteConstants from '../../../../constants/RouteConstants'
import SecureComponent from '../../../../pages/common/SecureComponent'
import SelectAdvantagePlan from './SelectAdvantagePlan'
import SelectAdvantagePlanProvider from './SelectAdvantagePlanProvider'
import SelectGroupOrIHPlanProvider from './SelectGroupOrIHPlanProvider'
import SelectGroupOrIHPlanType from './SelectGroupOrIHPlanType'
import SelectMABenefits from './SelectMABenefits'
import SelectPlanType from './SelectPlanType'
import SelectSupplementPlan from './SelectSupplementPlan'
import SelectSupplementPlanType from './SelectSupplementPlanType'
import { SnackbarTypes } from '../../../../enums/SnackbarTypesEnum'
import StringConstants from '../../../../constants/StringConstants'
import { addResponsesForQuestionnaires } from '../../../../service/questionnaire'
import currentPlanStore from '../../../../datastore/CurrentPlanStore'
import customerStore from '../../../../datastore/CustomerStore'
import { getApiErrorMessage } from '../../../../utils/StringUtils'
import { isEmpty } from 'lodash'
import mPartDPlansFilterStore from '../../../../datastore/medicareQuotes/MPartPlanFilterQuotesStore'
import medicareAdvantageFilterQuoteStore from '../../../../datastore/medicareQuotes/MedicareAdvantageFilterQuoteStore'
import moment from 'moment'
import msPlanFiltersStore from '../../../../datastore/medicareQuotes/MSupplementPlanFiltersStore'
import { observer } from 'mobx-react'
import snackbarStore from '../../../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../../../global/theme'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`
const SectionWrapper = styled.div`
  width: 100%;
  padding: 10px 0px 30px 0px;
  border-top: 1px solid ${theme.colors.borderColor};
`
const Heading = styled.h3`
  font-size: 20px;
  font-weight: 700px;
  color: ${theme.colors.primary};
`
const Description = styled.p`
  padding: 20px 40px;
  width: 100%;
  font-weight: 600;
  @media screen and (max-width: 600px) {
    padding: 20px 0px;
  }
`

const TableRow = styled(MuiTableRow)`
  &:nth-of-type(odd) {
    background-color: ${theme.colors.contrastPrimary};
  }
`

const DisableText = styled.div`
  font-size: 14px;
  margin-left: 5px;
  color: ${theme.colors.secondary};
`

const TableCell = styled(MuiTableCell)`
  color: ${theme.colors.textGrey};
  font-size: 14px;
  border-bottom: 0px;
  &.MuiTableCell-root {
    width: ${(props) => (props.width ? props.width : '50%')};
  }
`

const CurrentPlan: React.FC = () => {
  const navigate = useNavigate()
  const [disableNext, setDisableNext] = React.useState(true)
  const [currentPlanStep, setCurrentPlanStep] =
    React.useState<CurrentPlanISComponentEnum>(
      CurrentPlanISComponentEnum.OPTIONAL_QUOTE_QUESTION
    )

  const [optionalQuotes, setOptionalQuotes] = useState()
  const [loading, setLoading] = React.useState(true)

  const [state, setState] = React.useState({
    nextButtonLoading: false,
  })

  const [displayCard, setDisplayCard] = React.useState<boolean>(false)

  const [effectiveDate, setEffectiveDate] = React.useState('')
  const [hasNext, setHasNext] = React.useState<boolean>(true)
  const [forSpouse, setForSpouse] = React.useState(false)
  const [searchParams] = useSearchParams()
  const [sectionNumber, setSectionNumber] = useState<number>(1)

  React.useEffect(() => {
    if (searchParams.get('forSpouse') === 'true') {
      setForSpouse(true)
      setCurrentPlanStep(CurrentPlanISComponentEnum.QUESTION_1)
    } else {
      setForSpouse(false)
    }
  }, [searchParams])

  const handleQuestionChange = (
    question: CurrentPlanISComponentEnum,
    isNext: boolean
  ) => {
    if (isNext) setSectionNumber((prevState) => prevState + 1)
    else setSectionNumber((prevState) => prevState - 1)
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setCurrentPlanStep(question)
  }

  const disableMainNext = () => {
    setDisableNext(true)
  }
  const enableMainNext = () => {
    setDisableNext(false)
  }

  const handleHasNext = (value: boolean) => {
    setHasNext(value)
  }

  const updateCurrentPlanStep = async () => {
    try {
      customerStore.setIsProfileUpdating(true)
      setState({
        ...state,
        nextButtonLoading: true,
      })
      if (customerStore.getSpouseOptIn() === false || forSpouse) {
        await updateInfoStep(InfoStepEnum.CURRENT_PLAN)
        snackbarStore.set({
          snackbarMessage: StringConstants.SNACKBAR_SAVE_SUCCESS_MSG,
          snackbarOpen: true,
          snackbarType: SnackbarTypes.SUCCESS,
        })
        setDisplayCard(false)
        setCurrentPlanStep(CurrentPlanISComponentEnum.FINISHED)
      } else if (customerStore.getSpouseOptIn() && !forSpouse) {
        navigate(`${RouteConstants.INFO_STEPS_CURRENT_PLAN}/?forSpouse=true`)
      }
    } catch (error) {
      snackbarStore.set({
        snackbarMessage: 'Something went wrong',
        snackbarOpen: true,
        snackbarType: 'error',
      })
    } finally {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      customerStore.setIsProfileUpdating(false)
      setState({
        ...state,
        nextButtonLoading: false,
      })
    }
  }

  const handleEdit = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    handleQuestionChange(CurrentPlanISComponentEnum.QUESTION_1, true)
  }

  const getHandleNext = (componentName: CurrentPlanISComponentEnum) => {
    const quoteType = !forSpouse
      ? currentPlanStore.get().quoteType
      : currentPlanStore.spouseQuoteType
    if (
      componentName === CurrentPlanISComponentEnum.QUESTION_4 &&
      (quoteType === MedicareConstants.INSURANCE_TYPE.AB ||
        quoteType === MedicareConstants.INSURANCE_TYPE.Medicaid ||
        quoteType === MedicareConstants.INSURANCE_TYPE.Tricare ||
        quoteType === MedicareConstants.INSURANCE_TYPE.None ||
        isEmpty(quoteType))
    ) {
      return handleCurrentPlanFinish()
    }

    const handlers: Record<CurrentPlanISComponentEnum, () => void> = {
      [CurrentPlanISComponentEnum.OPTIONAL_QUOTE_QUESTION]: () => {
        if (displayCard) {
          handleQuestionChange(CurrentPlanISComponentEnum.DISPLAY_CARD, true)
        } else handleQuestionChange(CurrentPlanISComponentEnum.QUESTION_1, true)
      },
      [CurrentPlanISComponentEnum.DISPLAY_CARD]: () => {
        updateCurrentPlanStep()
      },
      [CurrentPlanISComponentEnum.QUESTION_1]: () => {},
      [CurrentPlanISComponentEnum.QUESTION_2]: () =>
        handleQuestionChange(CurrentPlanISComponentEnum.QUESTION_4, true),
      [CurrentPlanISComponentEnum.QUESTION_3]: () =>
        handleQuestionChange(CurrentPlanISComponentEnum.QUESTION_4, true),
      [CurrentPlanISComponentEnum.QUESTION_4]: () => {
        // const quoteType = currentPlanStore.get().quoteType
        switch (quoteType) {
          case MedicareConstants.INSURANCE_TYPE.MA:
            return handleQuestionChange(
              CurrentPlanISComponentEnum.QUESTION_5,
              true
            )
          case MedicareConstants.INSURANCE_TYPE.MS:
            return handleQuestionChange(
              CurrentPlanISComponentEnum.QUESTION_7,
              true
            )
          case MedicareConstants.INSURANCE_TYPE.IH:
          case MedicareConstants.INSURANCE_TYPE.Group:
            return handleQuestionChange(
              CurrentPlanISComponentEnum.QUESTION_9,
              true
            )
          default:
            return handleQuestionChange(
              CurrentPlanISComponentEnum.QUESTION_10,
              true
            )
        }
      },
      [CurrentPlanISComponentEnum.QUESTION_5]: () =>
        handleQuestionChange(CurrentPlanISComponentEnum.QUESTION_6, true),
      [CurrentPlanISComponentEnum.QUESTION_6]: () =>
        handleQuestionChange(CurrentPlanISComponentEnum.QUESTION_11, true),
      [CurrentPlanISComponentEnum.QUESTION_7]: () =>
        handleQuestionChange(CurrentPlanISComponentEnum.QUESTION_8, true),
      [CurrentPlanISComponentEnum.QUESTION_8]: () => handleCurrentPlanFinish(),
      [CurrentPlanISComponentEnum.QUESTION_9]: () =>
        handleQuestionChange(CurrentPlanISComponentEnum.QUESTION_10, true),
      [CurrentPlanISComponentEnum.QUESTION_10]: () => handleCurrentPlanFinish(),
      [CurrentPlanISComponentEnum.QUESTION_11]: () => handleCurrentPlanFinish(),
      [CurrentPlanISComponentEnum.FINISHED]: () => {},
    }

    const handler = handlers[componentName]
    if (handler) {
      return handler()
    }
  }

  const updateOptionalQuotes = () => {
    setState({ ...state, nextButtonLoading: true })
    if (optionalQuotes)
      addResponsesForQuestionnaires(forSpouse, optionalQuotes)
        .then(() => {
          getHandleNext(currentPlanStep)
          setState({ ...state, nextButtonLoading: false })
          snackbarStore.set({
            snackbarOpen: true,
            snackbarMessage: 'Saved Successfully',
            snackbarType: SnackbarTypes.SUCCESS,
          })
        })
        .catch((err) => {
          snackbarStore.set({
            snackbarOpen: true,
            snackbarMessage: 'Unable to fetch the questionnaire',
            snackbarType: SnackbarTypes.ERROR,
          })
        })
    else {
      setState({ ...state, nextButtonLoading: false })
      snackbarStore.set({
        snackbarOpen: true,
        snackbarMessage: 'Invalid Input',
        snackbarType: SnackbarTypes.ERROR,
      })
    }
  }

  const getHandleBack = (componentName: CurrentPlanISComponentEnum) => {
    const handlers: Record<CurrentPlanISComponentEnum, () => void> = {
      [CurrentPlanISComponentEnum.OPTIONAL_QUOTE_QUESTION]: () =>
        navigate(RouteConstants.INFO_STEPS_PRESCRIPTION),
      [CurrentPlanISComponentEnum.QUESTION_1]: () => {
        if (displayCard)
          handleQuestionChange(CurrentPlanISComponentEnum.DISPLAY_CARD, false)
        else
          handleQuestionChange(
            CurrentPlanISComponentEnum.OPTIONAL_QUOTE_QUESTION,
            false
          )
      },
      [CurrentPlanISComponentEnum.DISPLAY_CARD]: () =>
        handleQuestionChange(
          CurrentPlanISComponentEnum.OPTIONAL_QUOTE_QUESTION,
          false
        ),
      [CurrentPlanISComponentEnum.QUESTION_2]: () =>
        handleQuestionChange(CurrentPlanISComponentEnum.QUESTION_1, false),
      [CurrentPlanISComponentEnum.QUESTION_3]: () =>
        handleQuestionChange(CurrentPlanISComponentEnum.QUESTION_1, false),
      [CurrentPlanISComponentEnum.QUESTION_4]: () =>
        handleQuestionChange(CurrentPlanISComponentEnum.QUESTION_2, false),
      [CurrentPlanISComponentEnum.QUESTION_5]: () =>
        handleQuestionChange(CurrentPlanISComponentEnum.QUESTION_4, false),
      [CurrentPlanISComponentEnum.QUESTION_6]: () =>
        handleQuestionChange(CurrentPlanISComponentEnum.QUESTION_5, false),
      [CurrentPlanISComponentEnum.QUESTION_7]: () =>
        handleQuestionChange(CurrentPlanISComponentEnum.QUESTION_4, false),
      [CurrentPlanISComponentEnum.QUESTION_8]: () =>
        handleQuestionChange(CurrentPlanISComponentEnum.QUESTION_7, false),
      [CurrentPlanISComponentEnum.QUESTION_9]: () =>
        handleQuestionChange(CurrentPlanISComponentEnum.QUESTION_4, false),
      [CurrentPlanISComponentEnum.QUESTION_10]: () => {
        const quoteType = !forSpouse
          ? currentPlanStore.get().quoteType
          : currentPlanStore.spouseQuoteType
        return quoteType === MedicareConstants.INSURANCE_TYPE.IH ||
          quoteType === MedicareConstants.INSURANCE_TYPE.Group
          ? handleQuestionChange(CurrentPlanISComponentEnum.QUESTION_9, false)
          : handleQuestionChange(CurrentPlanISComponentEnum.QUESTION_4, false)
      },
      [CurrentPlanISComponentEnum.QUESTION_11]: () =>
        handleQuestionChange(CurrentPlanISComponentEnum.QUESTION_6, false),
      [CurrentPlanISComponentEnum.FINISHED]: () => {},
    }

    const handler = handlers[componentName]

    if (handler) {
      return handler()
    }
  }

  const changeComponent = (componentName: CurrentPlanISComponentEnum) => {
    switch (componentName) {
      case CurrentPlanISComponentEnum.DISPLAY_CARD:
        return (
          <DisplayCard
            forSpouse={forSpouse}
            effectiveDate={effectiveDate}
            handleEdit={handleEdit}
          />
        )
      case CurrentPlanISComponentEnum.OPTIONAL_QUOTE_QUESTION:
        return (
          <OptionalQuoteQuestion
            forSpouse={forSpouse}
            enableMainNext={enableMainNext}
            disableMainNext={disableMainNext}
            handleHasNext={handleHasNext}
            optionalQuotes={optionalQuotes}
            setOptionalQuotes={setOptionalQuotes}
          />
        )
      case CurrentPlanISComponentEnum.QUESTION_1:
        return (
          <Question
            key={CurrentPlanISComponentEnum.QUESTION_1}
            heading={`${
              forSpouse ? 'Is your spouse/partner' : 'Are you'
            } currently enrolled in Medicare Part B?`}
            isRequired={true}
            description='Filters may be applied based on your current coverage to showcase plans most suitable for you. You can modify this anytime by removing the filters.'
            handleYes={() =>
              handleQuestionChange(CurrentPlanISComponentEnum.QUESTION_2, true)
            }
            handleNo={() =>
              handleQuestionChange(CurrentPlanISComponentEnum.QUESTION_3, true)
            }
            disableMainNext={disableMainNext}
            enableMainNext={enableMainNext}
            question={CurrentPlanISComponentEnum.QUESTION_1}
          />
        )
      case CurrentPlanISComponentEnum.QUESTION_2:
        return (
          <DateQuestion
            key={CurrentPlanISComponentEnum.QUESTION_2}
            forSpouse={forSpouse}
            question={`When did ${
              forSpouse ? 'your spouse/partner' : 'you'
            } enroll into Medicare Part B?`}
            description='If you aren’t sure, please make an estimate.'
            secondaryFunction={(date: string) => {
              if (!forSpouse)
                currentPlanStore.setEffectiveDate(
                  date ? (date === '' ? null : date) : null
                )
              else
                currentPlanStore.setSpouseEffectiveDate(
                  date ? (date === '' ? null : date) : null
                )
            }}
            dateFieldInputProps={{
              max: moment().add(1, 'year').year().toString() + '-12-31',
            }}
            disableMainNext={disableMainNext}
            enableMainNext={enableMainNext}
            handleHasNext={handleHasNext}
          />
        )
      case CurrentPlanISComponentEnum.QUESTION_3:
        return (
          <DateQuestion
            key={CurrentPlanISComponentEnum.QUESTION_3}
            forSpouse={forSpouse}
            question={`When does your ${
              forSpouse ? "spouse/partner's" : ''
            } Medicare Part B take effect?`}
            description='If you aren’t sure, please make an estimate.'
            secondaryFunction={(date: string) => {
              if (!forSpouse)
                currentPlanStore.setEffectiveDate(
                  date ? (date === '' ? null : date) : null
                )
              else
                currentPlanStore.setSpouseEffectiveDate(
                  date ? (date === '' ? null : date) : null
                )
            }}
            dateFieldInputProps={{
              max: moment().add(1, 'year').year().toString() + '-12-31',
            }}
            disableMainNext={disableMainNext}
            enableMainNext={enableMainNext}
            handleHasNext={handleHasNext}
          />
        )
      case CurrentPlanISComponentEnum.QUESTION_4:
        return (
          <SelectPlanType
            disableMainNext={disableMainNext}
            enableMainNext={enableMainNext}
            handleHasNext={handleHasNext}
            forSpouse={forSpouse}
          />
        )
      case CurrentPlanISComponentEnum.QUESTION_5:
        return (
          <SelectAdvantagePlanProvider
            disableMainNext={disableMainNext}
            enableMainNext={enableMainNext}
            handleHasNext={handleHasNext}
            forSpouse={forSpouse}
          />
        )
      case CurrentPlanISComponentEnum.QUESTION_6:
        return (
          <SelectAdvantagePlan
            disableMainNext={disableMainNext}
            enableMainNext={enableMainNext}
            handleHasNext={handleHasNext}
            forSpouse={forSpouse}
          />
        )
      case CurrentPlanISComponentEnum.QUESTION_7:
        return (
          <SelectSupplementPlanType
            disableMainNext={disableMainNext}
            enableMainNext={enableMainNext}
            handleHasNext={handleHasNext}
            forSpouse={forSpouse}
          />
        )
      case CurrentPlanISComponentEnum.QUESTION_8:
        return (
          <SelectSupplementPlan
            disableMainNext={disableMainNext}
            enableMainNext={enableMainNext}
            handleHasNext={handleHasNext}
            forSpouse={forSpouse}
          />
        )
      case CurrentPlanISComponentEnum.QUESTION_9:
        return (
          <SelectGroupOrIHPlanType
            disableMainNext={disableMainNext}
            enableMainNext={enableMainNext}
            handleHasNext={handleHasNext}
            forSpouse={forSpouse}
          />
        )
      case CurrentPlanISComponentEnum.QUESTION_10:
        return (
          <SelectGroupOrIHPlanProvider
            disableMainNext={disableMainNext}
            enableMainNext={enableMainNext}
            handleHasNext={handleHasNext}
            forSpouse={forSpouse}
          />
        )
      case CurrentPlanISComponentEnum.QUESTION_11:
        return (
          <SelectMABenefits
            forSpouse={forSpouse}
            disableMainNext={disableMainNext}
            enableMainNext={enableMainNext}
            handleHasNext={handleHasNext}
          />
        )
    }
  }

  React.useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
    currentPlanStore.reset()
    if (customerStore.infoSteps.includes('currentPlan')) {
      setCurrentPlanStep(CurrentPlanISComponentEnum.FINISHED)
      setLoading(false)
    } else {
      getCustomerDetails()
        .then((response) => {
          const data = response
          if (
            !forSpouse &&
            (data.currentMedicarePartBDateSelf ||
              data.currentPlanTypeSelf ||
              data.currentProviderSelf ||
              data.currentCoverageSelf ||
              !isEmpty(data.maPlanBenefits))
          ) {
            setDisplayCard(true)

            if (data.currentMedicarePartBDateSelf) {
              const parts = data.currentMedicarePartBDateSelf
                .substring(0, 10)
                .split('-')
              currentPlanStore.setEffectiveDate(
                data.currentMedicarePartBDateSelf
              )
              setEffectiveDate(`${parts[1]}-${parts[2]}-${parts[0]}`)
            }
            currentPlanStore.setPlanType(data.currentPlanTypeSelf)
            currentPlanStore.setProvider(data.currentProviderSelf)

            if (data.currentCoverageSelf) {
              currentPlanStore.setQuoteType(data.currentCoverageSelf)
              currentPlanStore.setCoveredByHealthInsurance(true)
            } else {
              currentPlanStore.setCoveredByHealthInsurance(false)
            }
            currentPlanStore.setMABenefits(data.maPlanBenefits)
            enableMainNext && enableMainNext()
          } else if (
            forSpouse &&
            (data.currentMedicarePartBDateSpouse ||
              data.currentPlanTypeSpouse ||
              data.currentProviderSpouse ||
              data.currentCoverageSpouse ||
              !isEmpty(data.spouseMAPlanBenefits))
          ) {
            setDisplayCard(true)

            if (data.currentMedicarePartBDateSpouse) {
              const parts = data.currentMedicarePartBDateSpouse
                .substring(0, 10)
                .split('-')
              currentPlanStore.setSpouseEffectiveDate(
                data.currentMedicarePartBDateSpouse
              )
              setEffectiveDate(`${parts[1]}-${parts[2]}-${parts[0]}`)
            }
            currentPlanStore.setSpousePlanType(data.currentPlanTypeSpouse)
            currentPlanStore.setSpouseProvider(data.currentProviderSpouse)
            currentPlanStore.setSpouseMABenefits(data.spouseMAPlanBenefits)

            if (data.currentCoverageSpouse) {
              currentPlanStore.setSpouseQuoteType(data.currentCoverageSelf)
              currentPlanStore.setSpouseCoveredByHealthInsurance(true)
            } else {
              currentPlanStore.setSpouseCoveredByHealthInsurance(true)
            }
            enableMainNext && enableMainNext()
          } else {
            setDisplayCard(false)
          }

          customerStore.set(data)
        })
        .catch((err) => {
          snackbarStore.set({
            snackbarMessage: getApiErrorMessage('fetch customer details'),
            snackbarOpen: true,
            snackbarType: SnackbarTypes.ERROR,
          })
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [forSpouse])

  const handleCurrentPlanFinish = () => {
    customerStore.setIsProfileUpdating(true)
    setState({
      ...state,
      nextButtonLoading: true,
    })
    let currentPlan = {} as {
      effectiveDate: null | string
      quoteType: string
      planType: string
      provider: string
      maPlanBenefits?: string[]
      spouseMAPlanBenefits?: string[]
    }

    if (!forSpouse)
      currentPlan = {
        effectiveDate: isEmpty(currentPlanStore.get().effectiveDate)
          ? null
          : currentPlanStore.effectiveDate!.substring(0, 10),
        //anticipatedEndDate: currentPlanStore.get().anticipatedEndDate,
        quoteType: currentPlanStore.get().quoteType,
        planType: currentPlanStore.get().planType,
        provider: currentPlanStore.get().provider,
        maPlanBenefits: currentPlanStore.getMABenefits(),
      }
    else
      currentPlan = {
        effectiveDate: isEmpty(currentPlanStore.spouseEffectiveDate)
          ? null
          : currentPlanStore.spouseEffectiveDate!.substring(0, 10),
        //anticipatedEndDate: currentPlanStore.get().anticipatedEndDate,
        quoteType: currentPlanStore.spouseQuoteType,
        planType: currentPlanStore.spousePlanType,
        provider: currentPlanStore.spouseProvider,
        spouseMAPlanBenefits: currentPlanStore.getSpouseMABenefits(),
      }

    updateSupplementalQuestions(currentPlan, { 'for-spouse': forSpouse })
      .then(() => {
        updateInfoStep(InfoStepEnum.CURRENT_PLAN)
          .then(() => {
            if (!forSpouse) {
              customerStore.setSupplementalQuestionAnswers({
                currentCoverageSelf: currentPlan.quoteType,
                currentPlanTypeSelf: currentPlan.planType,
                currentProviderSelf: currentPlan.provider,
                currentMedicarePartBDateSelf: currentPlan.effectiveDate,
              })
              customerStore.setFiltersInfoStep({
                coverageSelf: currentPlan.quoteType,
                planTypeSelf: currentPlan.planType,
                providerSelf: currentPlan.provider,
              })
            } else if (forSpouse) {
              customerStore.setSpouseSupplementalQuestionAnswers({
                currentCoverageSpouse: currentPlan.quoteType,
                currentPlanTypeSpouse: currentPlan.planType,
                currentProviderSpouse: currentPlan.provider,
                currentMedicarePartBDateSpouse: currentPlan.effectiveDate,
              })
              customerStore.setSpouseFiltersInfoStep({
                coverageSpouse: currentPlan.quoteType,
                planTypeSpouse: currentPlan.planType,
                providerSpouse: currentPlan.provider,
              })
            }
            medicareAdvantageFilterQuoteStore.clearStore()
            msPlanFiltersStore.clearMSPlanFilterStore()
            mPartDPlansFilterStore.clearStore()
            snackbarStore.set({
              snackbarMessage: StringConstants.SNACKBAR_SAVE_SUCCESS_MSG,
              snackbarOpen: true,
              snackbarType: SnackbarTypes.SUCCESS,
            })
            if (customerStore.getSpouseOptIn() && !forSpouse) {
              navigate(
                `${RouteConstants.INFO_STEPS_CURRENT_PLAN}/?forSpouse=true`
              )
            } else {
              setCurrentPlanStep(CurrentPlanISComponentEnum.FINISHED)
            }
          })
          .catch((err) => {
            snackbarStore.set({
              snackbarMessage: 'Something went wrong',
              snackbarOpen: true,
              snackbarType: 'error',
            })
          })
      })
      .catch((err) => {
        snackbarStore.set({
          snackbarMessage: getApiErrorMessage(`save plan`),
          snackbarOpen: true,
          snackbarType: 'error',
        })
      })
      .finally(() => {
        customerStore.setIsProfileUpdating(false)
        setState({
          ...state,
          nextButtonLoading: false,
        })
      })
    // }
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return loading ? (
    <BouncingDotsLoader />
  ) : (
    <Container>
      {currentPlanStep ===
      CurrentPlanISComponentEnum.OPTIONAL_QUOTE_QUESTION ? (
        changeComponent(currentPlanStep)
      ) : currentPlanStep !== CurrentPlanISComponentEnum.FINISHED ? (
        changeComponent(currentPlanStep)
      ) : (
        <RecommendedPlan />
      )}

      {currentPlanStep !== CurrentPlanISComponentEnum.FINISHED && (
        <BottomStepNavigator
          handleNext={() => {
            if (
              currentPlanStep ===
              CurrentPlanISComponentEnum.OPTIONAL_QUOTE_QUESTION
            )
              updateOptionalQuotes()
            else getHandleNext(currentPlanStep)
          }}
          handleBack={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' })
            getHandleBack(currentPlanStep)
          }}
          handleViewPlans={() => {
            navigate(RouteConstants.DASHBOARD)
          }}
          disableNext={disableNext}
          isLoading={state.nextButtonLoading}
          currentInfoStep='Current Plan'
          buttonText3={hasNext ? 'Next' : 'Finish'}
          currentSectionCount={6}
          currentQuestionCount={sectionNumber}
          totalQuestionCount={8}
        />
      )}
    </Container>
  )
}

export default SecureComponent(observer(CurrentPlan))
