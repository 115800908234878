import { SyncLoader } from 'react-spinners'
import styled from '@emotion/styled'
import theme from '../../../global/theme'

const Container = styled.div<{ padding?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.padding ? `padding: ${props.padding};` : `padding-top: 15px;`}
`

interface BouncingDotsLoaderProps {
  padding?: string
}

const BouncingDotsLoader: React.FC<BouncingDotsLoaderProps> = ({
  padding = undefined,
}) => {
  return (
    <Container padding={padding}>
      <SyncLoader size='12px' color={theme.colors.primary} />
    </Container>
  )
}

export default BouncingDotsLoader
