import React, { useEffect, useRef, useState } from 'react'
import { cloneDeep, isEmpty, isNull } from 'lodash'
import {
  getCustomerDetails,
  removeSpouseDetails,
} from '../../../../service/customer'
import { getMAPlans, getMNSPlansFilters } from '../../../../service/quotes'

import { ActionContext } from '../../../../cra'
import BottomAddToCompare from '../../BottomAddToCompare/BottomAddToCompare.component'
import Button from '../../../common/Button/Button.component'
import CallUsNow from '../../Common/Recommendation/CallUsNow.component'
import CompletePlanDetailsPage from '../../../../pages/CompletePlanDetailsPage/CompletePlanDetailsPage'
import FilterAdvantage from './FilterAdvantage.component'
import FilterBox from '../../Common/FilterBox.component.tsx/FilterBox'
import { Left } from '../MedicareSupplement/MedicareSupplement'
import { MAPlanFiltersRequest } from '../../../../interface/quotes/mNonSupplementPlans/medicareAdvantage/request/MAPlanFiltersRequest'
import { MNonSupplementPlan } from '../../../../interface/quotes/mNonSupplementPlans/MNonSupplementPlan'
import { MedicalCost } from '../../../../interface/quotes/mNonSupplementPlans/MedicalCost'
import MedicareAdvantageCard from './MedicareAdvantageCard.component'
import { MedicareAdvantageDisplayFilter } from '../../../../interface/quotes/mNonSupplementPlans/medicareAdvantage/MedicareAdvantageDisplayFilters'
import { MedicareAdvantageFilters } from '../../../../interface/quotes/mNonSupplementPlans/medicareAdvantage/MedicareAdvantageFilters'
import { MedicareQuotesType } from '../../../../enums/MedicareQuoteTypeEnum'
import ModalComponent from '../../../common/Modal/Modal.component'
import NoPlanFoundCard from '../../NoPlanFoundCard'
import PaginationConstants from '../../../../constants/PaginationConstants'
import PrimarySpouseToggle from '../../Common/PrimarySpouseToggle'
import QuotesConstants from '../../../../constants/QuotesConstants'
import RecommendedMACards from './RecommendedMACards.component'
import { RiFilter2Fill } from 'react-icons/ri'
import SortBy from '../../Common/SortByDropdown.component.tsx/SortBy'
import StringConstants from '../../../../constants/StringConstants'
import ThankYouModal from '../../Modals/ThankYouModal'
import { ThreeDots } from 'react-loader-spinner'
import { convertToUSCurrency } from '../../../../utils/CurrencyConverterUtil'
import customerStore from '../../../../datastore/CustomerStore'
import { getContact } from '../../../../utils/getContact'
import { getPlanYear } from '../../../../utils/CommonUtils'
import { isUndefined } from 'lodash'
import medicareAdvantageFilterQuoteStore from '../../../../datastore/medicareQuotes/MedicareAdvantageFilterQuoteStore'
import { observer } from 'mobx-react'
import snackbarStore from '../../../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../../../global/theme'
import { useBurgerMenu } from '../../../../context/SidebarContext'
import { useReactToPrint } from 'react-to-print'
import userActionStore from '../../../../datastore/UserActionStore'

const Container = styled.div`
  display: flex;
  justify-content: center;
`
const CardsWrapper = styled.div`
  flex: 1;
  padding: 20px 60px;
  max-width: 1200px;

  .recommendation-header {
    text-align: center;
    max-width: 600px;
    margin: auto;
    padding-bottom: 20px;
    h2 {
      color: ${theme.colors.primary};
      font-weight: 700;
      font-size: 28px;
    }

    p {
      font-size: 14px;
      font-weight: 600;
      color: ${theme.colors.textGrey};
    }
  }

  @media screen and (max-width: 950px) {
    padding: 20px;
  }
`
const UtilityContainer = styled.div`
  display: flex;
  gap: 10px;
  .filter-toggle {
    display: none;
    border: 1px solid #a9a9a9;
    align-items: center;
    color: #515151;
    font-weight: 600;
    cursor: pointer;
    border-radius: 32px;
    padding: 5px 10px;
    transition: box-shadow 0.4s;
    &:hover {
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      transition: box-shadow 0.4s;
    }

    @media screen and (max-width: 950px) {
      display: flex;
      font-size: 14px;
    }
  }

  @media screen and (max-width: 600px) {
    justify-content: space-between;
  }
`

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.25em 0;
`
const ThreeDotsWrapper = styled.div`
  display: flex;
  margin: 20px;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  width: 900px;

  @media screen and (max-width: 1250px) {
    width: 700px;
  }

  @media screen and (max-width: 1050px) {
    width: 600px;
  }

  @media screen and (max-width: 640px) {
    width: 100%;
    margin: 20px 0px;
  }
`

const FilterContainer = styled.div`
  display: block;

  .bottom-wrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    @media screen and (max-width: 600px) {
      flex-direction: column;
      align-items: initial;
    }
  }

  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column-reverse;
  }
`

const MedicareAdvantage: React.FC<{
  plans: MNonSupplementPlan[]
  onDataChange: (data: MNonSupplementPlan[]) => void
  setMATotalPlanCount: (data: number) => void
  maPlanTotalCount: number | null
  setShowAddSpouseConfirmation: (flag: boolean) => void
}> = ({
  plans,
  onDataChange,
  setShowAddSpouseConfirmation,
  setMATotalPlanCount,
  maPlanTotalCount,
}) => {
  const { toggleSidebar, isOpen, setShowBurgerMenu } = useBurgerMenu()

  const [thankYouModalActive, setThankYouModalActive] = React.useState(false)
  const [sortPlanOptions, setSortPlanOptions] = useState<
    {
      title: string
      value: string
    }[]
  >(
    medicareAdvantageFilterQuoteStore.isPrimaryAndSpouseOption
      ? medicareAdvantageFilterQuoteStore.spouseMedicareAdvantageFilters?.sortOptions?.map(
          (value) => ({ title: value, value })
        )
      : medicareAdvantageFilterQuoteStore.medicareAdvantageFilters?.sortOptions?.map(
          (value) => ({ title: value, value })
        )
  )
  const printRef = useRef(null)

  const reactToPrint = useReactToPrint({
    content: () => printRef.current,
  })
  const [completePlanDetailModalActive, setCompletePlanDetailModalActive] =
    React.useState<boolean>(false)
  const [completeDetailPlanId, setCompleteDetailPlanId] =
    React.useState<string>('')
  const [currentSortOption, setCurrentSortOption] = useState<{
    label: string
    index: number
  }>(
    !medicareAdvantageFilterQuoteStore.getIsPrimaryAndSpouseOption()
      ? {
          index: medicareAdvantageFilterQuoteStore
            .getMedicareAdvantageFilters()
            .sortOptions?.findIndex((option) => {
              return (
                option ===
                medicareAdvantageFilterQuoteStore.getMedicareAdvantageFilters()
                  ?.selectSortOption!
              )
            }),
          label:
            medicareAdvantageFilterQuoteStore.getMedicareAdvantageFilters()
              ?.selectSortOption!,
        }
      : {
          index:
            medicareAdvantageFilterQuoteStore.spouseMedicareAdvantageFilters?.sortOptions?.findIndex(
              (option) => {
                return (
                  option ===
                  medicareAdvantageFilterQuoteStore
                    .spouseMedicareAdvantageFilters?.selectSortOption!
                )
              }
            ),
          label:
            medicareAdvantageFilterQuoteStore.spouseMedicareAdvantageFilters
              ?.selectSortOption!,
        }
  )

  const [maPlansRequestBody, setMAPlansRequestBody] =
    useState<MAPlanFiltersRequest>(
      !medicareAdvantageFilterQuoteStore.isPrimaryAndSpouseOption
        ? medicareAdvantageFilterQuoteStore.maPlansRequestFilter
        : medicareAdvantageFilterQuoteStore.spouseMAPlansRequestFilter
    )

  const [sectionLoader, setSectionLoader] = useState<boolean>(true)
  const [plansLoader, setPlansLoader] = useState<boolean>(true)
  const [paginationLoader, setPaginationLoader] = useState<boolean>(true)
  const [userOption, setUserOption] = useState<boolean>(
    medicareAdvantageFilterQuoteStore.getIsPrimaryAndSpouseOption()
  )

  const getStepValue = (percentage: number, total: number): number => {
    return Math.round((percentage / 100) * total)
  }

  const [maPlansFilters, setMAPlansFilters] =
    useState<MedicareAdvantageDisplayFilter>(
      medicareAdvantageFilterQuoteStore.getIsPrimaryAndSpouseOption()
        ? medicareAdvantageFilterQuoteStore.spouseMedicareAdvantageFilters
        : medicareAdvantageFilterQuoteStore.medicareAdvantageFilters
    )

  const { trackCurrentPage } = React.useContext(ActionContext)

  const [selectedFilters, setSelectedFilters] = useState<string[]>(
    medicareAdvantageFilterQuoteStore.isPrimaryAndSpouseOption
      ? medicareAdvantageFilterQuoteStore.spouseSelectedFilters
      : medicareAdvantageFilterQuoteStore.selectedFilters
  )
  const filterAdvantageRef = useRef<any>(null)

  const [clearFilterState, setClearFilterState] = React.useState(false)

  const getFilters = async (forSpouse: boolean) => {
    getMNSPlansFilters<MedicareAdvantageFilters>(
      MedicareQuotesType.MEDICARE_ADVANTAGE,
      customerStore.maFilterYear === ''
        ? getPlanYear().toString()
        : customerStore.maFilterYear,
      forSpouse
    )
      .then((maPlanFilter: MedicareAdvantageFilters) => {
        if (!isEmpty(maPlanFilter)) {
          const maPlanDisplayFilters: MedicareAdvantageDisplayFilter = {
            moop: {
              minValue: maPlanFilter.moop.start,
              maxValue: maPlanFilter.moop.end,
              currentValue: maPlanFilter.moop.end,
              stepValue: getStepValue(
                10,
                maPlanFilter.moop.end - maPlanFilter.moop.start
              ),
            },
            monthlyPremium: {
              minValue: maPlanFilter.monthlyPremium.start,
              maxValue: maPlanFilter.monthlyPremium.end,
              currentValue: maPlanFilter.monthlyPremium.end,
              stepValue: getStepValue(
                10,
                maPlanFilter.monthlyPremium.end -
                  maPlanFilter.monthlyPremium.start
              ),
            },
            planYear: maPlanFilter.planYear
              ? maPlanFilter.planYear
              : { planYears: [], defaultPlanYear: '' },
            currentPlanYear: maPlanFilter.planYear.defaultPlanYear,
            ratingValue: {
              minValue: 0,
              maxValue: maPlanFilter.minStarRating.end,
              currentValue: 0,
              stepValue: getStepValue(
                10,
                maPlanFilter.minStarRating.end -
                  maPlanFilter.minStarRating.start
              ),
            },
            rxIncluded: maPlanFilter.rxIncluded,
            planTypeCheckbox: maPlanFilter.policyTypes?.map(
              (policyType, index) => {
                return {
                  id: index,
                  checked: false,
                  label: policyType,
                  value: policyType,
                }
              }
            ),
            planBenefitsCheckbox: maPlanFilter.benefits?.map(
              (benefit, index) => {
                return {
                  id: index,
                  checked: false,
                  label:
                    benefit === 'Medicare Part B Get Back'
                      ? 'Medicare Part B Give Back'
                      : benefit,
                  value: benefit,
                }
              }
            ),
            drugsCheckbox: maPlanFilter.prescriptionDrugs
              ? maPlanFilter.prescriptionDrugs.map(
                  (prescriptionDrug, index) => {
                    return {
                      id: index,
                      checked: false,
                      label: prescriptionDrug.currentDosage?.name,
                      value: prescriptionDrug.currentDosage?.name,
                      optionalValue: prescriptionDrug.currentDosage?.id,
                    }
                  }
                )
              : [],
            carriers: maPlanFilter.carriers?.map((carrier) => {
              return {
                id: parseInt(carrier.id),
                checked: false,
                label: carrier.name,
                value: carrier.name,
              }
            }),
            providers: maPlanFilter.providers?.map((provider) => {
              return {
                id: parseInt(provider.id),
                checked: false,
                label: provider.name,
                value: provider.name,
                optionalValue: provider.id,
              }
            }),

            sortOptions: maPlanFilter.sortOptions,
            selectSortOption: QuotesConstants.initialMAPlanSortOption,
            clearFiltersApplied: false,
            forSpouse: maPlanFilter.forSpouse,
          }

          const planOptions = maPlanFilter.sortOptions.map((option) => {
            return { title: option, value: option }
          })

          setSortPlanOptions(planOptions)

          setCurrentSortOption({
            label: maPlanDisplayFilters.selectSortOption!,
            index: planOptions.findIndex((option) => {
              return option.title === maPlanDisplayFilters.selectSortOption!
            }),
          })

          medicareAdvantageFilterQuoteStore.setSelectedFilters(
            [],
            medicareAdvantageFilterQuoteStore.isPrimaryAndSpouseOption
          )
          setSelectedFilters(
            medicareAdvantageFilterQuoteStore.getSelectedFilters(
              medicareAdvantageFilterQuoteStore.isPrimaryAndSpouseOption
            )
          )
          if (!forSpouse) {
            handleRequestBodyChange(
              {
                // carriers: carriers,
                // planTypes: planTypes,
                paginationDetail: {
                  page: PaginationConstants.DEFAULT_PAGE,
                  size: PaginationConstants.DEFAULT_PAGE_SIZE,
                },
                sortDetail: {
                  order: 'ASC',
                  type: QuotesConstants.initialMAPlanSortOption,
                },
                planYear:
                  customerStore.maFilterYear === ''
                    ? getPlanYear().toString()
                    : customerStore.maFilterYear,
                forSpouse: false,
              },
              false
            )
            medicareAdvantageFilterQuoteStore.setMedicareAdvantageFilters(
              maPlanDisplayFilters
            )
          } else {
            handleRequestBodyChange(
              {
                // carriers: carriers,
                // planTypes: planTypes,
                paginationDetail: {
                  page: PaginationConstants.DEFAULT_PAGE,
                  size: PaginationConstants.DEFAULT_PAGE_SIZE,
                },
                sortDetail: {
                  order: 'ASC',
                  type: QuotesConstants.initialMAPlanSortOption,
                },
                planYear:
                  customerStore.maFilterYear === ''
                    ? getPlanYear().toString()
                    : customerStore.maFilterYear,
                forSpouse: true,
              },
              true
            )
            medicareAdvantageFilterQuoteStore.spouseMedicareAdvantageFilters = {
              ...maPlanDisplayFilters,
              forSpouse: true,
            }
          }

          setMAPlansFilters(maPlanDisplayFilters)
          customerStore.setMAPageSize(PaginationConstants.DEFAULT_PAGE_SIZE)
        } else {
          snackbarStore.set({
            snackbarOpen: true,
            snackbarMessage: 'No Medicare Advantage Plans Present',
            snackbarType: 'success',
          })
          setPlansLoader(false)
          setSectionLoader(false)
          setPaginationLoader(false)
        }
      })
      .catch((err: Error) => {
        setSectionLoader(false)
        setPaginationLoader(false)
        setPlansLoader(false)
        snackbarStore.set({
          snackbarOpen: true,
          snackbarMessage: StringConstants.INTERNAL_SERVER_ERROR,
          snackbarType: 'error',
        })
      })
  }

  const getPlans = (payload: MAPlanFiltersRequest) => {
    getMAPlans(payload)
      .then((medicareAdvantagePlans: any) => {
        const convertedMAPlans: MNonSupplementPlan[] = []
        for (let medicareAdvantagePlan of medicareAdvantagePlans.data) {
          const additionalCost: {
            name: string
            value: string
            toolTip: string
          }[] = [
            {
              name: 'Estimated Annual Cost',
              value: convertToUSCurrency(medicareAdvantagePlan.drugCost, 0),
              toolTip: StringConstants.TOOLTIP_ESTIMATED_ANNUAL_COST,
            },
          ]
          medicareAdvantagePlan.medicalCosts.forEach((cost: MedicalCost) => {
            let name = ''
            let tootTip = ''
            let flag = false
            switch (cost.name) {
              case 'Doctor Office Visits':
                flag = true
                name = 'Primary Doctor'
                tootTip = StringConstants.TOOLTIP_PRIMARY_PRIMARY_DOCTOR_CO_PAY
                break
              case 'Doctor Specialty Visit':
                flag = true
                name = 'Most Specialist(s)'
                tootTip = StringConstants.TOOLTIP_SPECIALIST_CO_PAY
                break
              case 'Inpatient Hospital Care':
                flag = true
                name = 'Hospital Inpatient'
                tootTip = StringConstants.TOOLTIP_HOSPITAL_IN_PATIENT_CO_PAY
                break
            }

            if (flag) {
              const { minCopay, minCoins } = cost.costs[0]
              if (!isUndefined(minCopay)) {
                additionalCost.push({
                  name: `${name} Co-Pay`,
                  value: convertToUSCurrency(minCopay, 0),
                  toolTip: tootTip,
                })
              } else if (!isUndefined(minCoins)) {
                additionalCost.push({
                  name: `${name} Co-Insurance`,
                  value: `${minCoins}%`,
                  toolTip: tootTip,
                })
              }
            }
          })

          medicareAdvantagePlan['additionalCosts'] = additionalCost
          convertedMAPlans.push(medicareAdvantagePlan)
        }
        if (
          payload.paginationDetail?.page === PaginationConstants.DEFAULT_PAGE
        ) {
          onDataChange(cloneDeep(convertedMAPlans))
        } else {
          onDataChange(cloneDeep(plans.concat(convertedMAPlans)))
        }
        setMATotalPlanCount(
          !isUndefined(medicareAdvantagePlans.totalCount)
            ? medicareAdvantagePlans.totalCount
            : medicareAdvantagePlans.data.length
        )
      })
      .catch((err: Error) => {})
      .finally(() => {
        setSectionLoader(false)
        setPlansLoader(false)
        setPaginationLoader(false)
      })
  }

  useEffect(() => {
    if (!medicareAdvantageFilterQuoteStore.isPrimaryAndSpouseOption) {
      medicareAdvantageFilterQuoteStore.setMedicareAdvantageFilters(
        maPlansFilters
      )
    } else {
      medicareAdvantageFilterQuoteStore.setSpouseMAPlanDisplayFilters(
        maPlansFilters
      )
    }
  }, [maPlansFilters])

  useEffect(() => {
    setSectionLoader(true)
    setShowBurgerMenu(true)
    // //Initializing the Request body on component mount
    if (isEmpty(medicareAdvantageFilterQuoteStore.maPlansRequestFilter))
      medicareAdvantageFilterQuoteStore.setMAPlanRequestFilter({
        paginationDetail: {
          page: PaginationConstants.DEFAULT_PAGE,
          size: PaginationConstants.DEFAULT_PAGE_SIZE,
        },
        sortDetail: {
          order: 'ASC',
          type: QuotesConstants.initialMAPlanSortOption,
        },
        planYear:
          customerStore.maFilterYear === ''
            ? getPlanYear().toString()
            : customerStore.maFilterYear,
        forSpouse: false,
      })
    if (
      customerStore.spouseOptIn &&
      isEmpty(medicareAdvantageFilterQuoteStore.spouseMAPlansRequestFilter)
    )
      medicareAdvantageFilterQuoteStore.spouseMAPlansRequestFilter = {
        // carriers: carriers,
        // planTypes: planTypes,
        paginationDetail: {
          page: PaginationConstants.DEFAULT_PAGE,
          size: PaginationConstants.DEFAULT_PAGE_SIZE,
        },
        sortDetail: {
          order: 'ASC',
          type: QuotesConstants.initialMAPlanSortOption,
        },
        planYear:
          customerStore.maFilterYear === ''
            ? getPlanYear().toString()
            : customerStore.maFilterYear,
        forSpouse: true,
      }
    if (isEmpty(maPlansFilters)) getFilters(userOption)
  }, [])

  useEffect(() => {
    if (!isEmpty(maPlansRequestBody)) getPlans(maPlansRequestBody)
  }, [maPlansRequestBody])

  const handleFilterChange = () => {
    // getPlans(medicareAdvantageFilterQuoteStore.getMAPlanRequestFilter())
  }

  useEffect(() => {
    trackCurrentPage('Dashboard - Medicare Advantage')
  }, [trackCurrentPage])

  const handleSortChange = (e: any) => {
    const sortOption: string = e.target.innerText
      .split()[0]
      .split(' (High to Low)')[0]

    if (currentSortOption?.label !== sortOption) {
      if (sortOption === 'Overall Rating') {
        handleRequestBodyChange(
          {
            sortDetail: { order: 'DESC', type: sortOption },
            paginationDetail: {
              page: PaginationConstants.DEFAULT_PAGE,
              size: PaginationConstants.DEFAULT_PAGE_SIZE,
            },
          },
          userOption
        )
      } else {
        handleRequestBodyChange(
          {
            sortDetail: { order: 'ASC', type: sortOption },
            paginationDetail: {
              page: PaginationConstants.DEFAULT_PAGE,
              size: PaginationConstants.DEFAULT_PAGE_SIZE,
            },
          },
          userOption
        )
      }
      setCurrentSortOption({
        label: sortOption,
        index: sortPlanOptions.findIndex((option) => {
          return option.title === sortOption
        }),
      })

      if (medicareAdvantageFilterQuoteStore.isPrimaryAndSpouseOption) {
        medicareAdvantageFilterQuoteStore.spouseMedicareAdvantageFilters.selectSortOption =
          sortOption
      } else
        medicareAdvantageFilterQuoteStore.medicareAdvantageFilters.selectSortOption =
          sortOption
      setPlansLoader(true)
    }
  }

  const handlePaginationChange = () => {
    setPaginationLoader(true)
    handleRequestBodyChange(
      {
        paginationDetail: {
          page:
            maPlansRequestBody.paginationDetail?.page! +
            PaginationConstants.DEFAULT_PAGE,
          size: PaginationConstants.DEFAULT_PAGE_SIZE,
        },
      },
      userOption
    )
    customerStore.setMAPageSize(
      customerStore.maPageSize + PaginationConstants.DEFAULT_PAGE_SIZE
    )
    customerStore.setMAPageNumber(
      customerStore.maPageNumber + PaginationConstants.DEFAULT_PAGE
    )
  }

  const handleOnEnroll = () => {
    setThankYouModalActive(true)
  }

  const handleSpouseRemove = () => {
    setSectionLoader(true)
    removeSpouseDetails()
      .then(() => {
        getCustomerDetails()
          .then((customerDetails: any) => {
            customerStore.set(customerDetails)
            getFilters(
              medicareAdvantageFilterQuoteStore.isPrimaryAndSpouseOption
            )
          })
          .catch((err) => {
            throw err
          })
      })
      .catch((err) => {
        setSectionLoader(false)
      })
  }

  const handleClearFilters = () => {
    setSectionLoader(true)
    medicareAdvantageFilterQuoteStore.clearRequestFilters(
      medicareAdvantageFilterQuoteStore.isPrimaryAndSpouseOption
    )
    setSelectedFilters([])
    setMAPlansFilters({} as MedicareAdvantageDisplayFilter)
    getFilters(medicareAdvantageFilterQuoteStore.isPrimaryAndSpouseOption)
  }

  const handleRequestBodyChange = (fieldsChanged: any, forSpouse: boolean) => {
    let requestBody = {}
    if (forSpouse) {
      requestBody = medicareAdvantageFilterQuoteStore.spouseMAPlansRequestFilter
      setMAPlansRequestBody({ ...requestBody, ...fieldsChanged })
      medicareAdvantageFilterQuoteStore.spouseMAPlansRequestFilter = {
        ...requestBody,
        ...fieldsChanged,
      }
    } else {
      requestBody = medicareAdvantageFilterQuoteStore.maPlansRequestFilter
      setMAPlansRequestBody({ ...requestBody, ...fieldsChanged })
      medicareAdvantageFilterQuoteStore.maPlansRequestFilter = {
        ...requestBody,
        ...fieldsChanged,
      }
    }
  }

  const updateFilters = (unFilter: string) => {
    setPlansLoader(true)
    const tempSelectedFilters = selectedFilters.filter(
      (filter) => filter !== unFilter
    )

    setSelectedFilters(tempSelectedFilters)
    medicareAdvantageFilterQuoteStore.setSelectedFilters(
      tempSelectedFilters,
      medicareAdvantageFilterQuoteStore.isPrimaryAndSpouseOption
    )

    if (maPlansFilters) {
      let fieldsChanged = maPlansRequestBody

      const checkedCarriers = maPlansFilters.carriers?.map((item) =>
        item.value === unFilter ? { ...item, checked: !item.checked } : item
      )

      fieldsChanged['carriers'] = checkedCarriers
        .filter((checkedCarrier) => checkedCarrier.checked)
        .map((checkedCarrier) => Number(checkedCarrier.id))

      const checkedPlanBenefits = maPlansFilters.planBenefitsCheckbox?.map(
        (item) =>
          item.value === unFilter ? { ...item, checked: !item.checked } : item
      )

      fieldsChanged['planBenefits'] = checkedPlanBenefits
        .filter((element) => element.checked)
        .map((element) => element.value)

      const checkedPlanTypes = maPlansFilters.planTypeCheckbox?.map((item) =>
        item.value === unFilter ? { ...item, checked: !item.checked } : item
      )

      fieldsChanged['policyTypes'] = checkedPlanTypes
        .filter((element) => element.checked)
        .map((element) => element.value)

      const checkedDrugs = maPlansFilters.drugsCheckbox?.map((item) =>
        item.value === unFilter ? { ...item, checked: !item.checked } : item
      )

      fieldsChanged['prescriptions'] = checkedDrugs
        .filter((element) => element.checked)
        .map((element) => element.optionalValue!)

      const checkedProviders = maPlansFilters.providers?.map((item) =>
        item.value === unFilter ? { ...item, checked: !item.checked } : item
      )

      fieldsChanged['providers'] = checkedProviders
        .filter((element) => element.checked)
        .map((element) => element.optionalValue!)

      const tempFilters: MedicareAdvantageDisplayFilter = {
        ...maPlansFilters,
        carriers: checkedCarriers,
        planBenefitsCheckbox: checkedPlanBenefits,
        planTypeCheckbox: checkedPlanTypes,
        drugsCheckbox: checkedDrugs,
        providers: checkedProviders,
      }

      setMAPlansFilters(tempFilters)
      if (medicareAdvantageFilterQuoteStore.isPrimaryAndSpouseOption)
        medicareAdvantageFilterQuoteStore.setSpouseMAPlanDisplayFilters(
          tempFilters
        )
      else
        medicareAdvantageFilterQuoteStore.setMedicareAdvantageFilters(
          tempFilters
        )

      handleRequestBodyChange(
        fieldsChanged,
        medicareAdvantageFilterQuoteStore.isPrimaryAndSpouseOption
      )
    }
  }

  const handleUserChange = (forSpouse: boolean) => {
    setSectionLoader(true)
    setUserOption(forSpouse)

    let isFiltersCalled = false
    medicareAdvantageFilterQuoteStore.setIsPrimaryAndSpouseOption(forSpouse)

    if (forSpouse) {
      if (
        isEmpty(
          medicareAdvantageFilterQuoteStore.spouseMedicareAdvantageFilters
        )
      ) {
        getFilters(forSpouse)
        isFiltersCalled = true
      } else {
        setMAPlansFilters(
          medicareAdvantageFilterQuoteStore.spouseMedicareAdvantageFilters
        )
        setCurrentSortOption({
          index:
            medicareAdvantageFilterQuoteStore.spouseMedicareAdvantageFilters?.sortOptions?.findIndex(
              (option) => {
                return (
                  option ===
                  medicareAdvantageFilterQuoteStore
                    .spouseMedicareAdvantageFilters?.selectSortOption!
                )
              }
            ),
          label:
            medicareAdvantageFilterQuoteStore.spouseMedicareAdvantageFilters
              .selectSortOption!,
        })
        setSelectedFilters(
          medicareAdvantageFilterQuoteStore.spouseSelectedFilters
        )
      }
    } else {
      if (isEmpty(medicareAdvantageFilterQuoteStore.medicareAdvantageFilters)) {
        isFiltersCalled = true
        getFilters(forSpouse)
      } else {
        setMAPlansFilters(
          medicareAdvantageFilterQuoteStore.medicareAdvantageFilters
        )
        setCurrentSortOption({
          index: medicareAdvantageFilterQuoteStore
            .getMedicareAdvantageFilters()
            ?.sortOptions?.findIndex((option) => {
              return (
                option ===
                medicareAdvantageFilterQuoteStore.getMedicareAdvantageFilters()
                  ?.selectSortOption!
              )
            }),
          label:
            medicareAdvantageFilterQuoteStore.medicareAdvantageFilters
              .selectSortOption!,
        })
      }
      setSelectedFilters(medicareAdvantageFilterQuoteStore.selectedFilters)
    }

    if (!isFiltersCalled)
      handleRequestBodyChange({ forSpouse: forSpouse }, forSpouse)
  }

  return (
    <Container>
      {thankYouModalActive && (
        <ModalComponent setOpen={() => setThankYouModalActive(false)}>
          <ThankYouModal setOpen={() => setThankYouModalActive(false)} />
        </ModalComponent>
      )}
      {completePlanDetailModalActive && !isEmpty(completeDetailPlanId) ? (
        <ModalComponent
          maxWidth={'none'}
          restrictScroll={true}
          setOpen={() => setCompletePlanDetailModalActive(false)}
        >
          <CompletePlanDetailsPage
            medicarePlanId={completeDetailPlanId}
            medicarePlanType='ma'
            printComponentRef={printRef}
            reactToPrint={reactToPrint}
            mnsPlan={plans.find((plan) => plan.id === completeDetailPlanId)}
          />
        </ModalComponent>
      ) : (
        <></>
      )}

      {/* Hidden Printable Content */}
      {/* <div style={{ display: 'none' }}>
          <div ref={printRef}>
            <CompletePlanDetailsPage
              medicarePlanId={completeDetailPlanId}
              medicarePlanType='ms'
              printComponentRef={printRef}
              reactToPrint={reactToPrint}
            />
          </div>
        </div> */}

      {!sectionLoader ? (
        <>
          {!isEmpty(maPlansFilters) && (
            <FilterAdvantage
              maFiltersChanged={handleFilterChange}
              // planYearChange={planYearChange}
              filterRef={filterAdvantageRef}
              showFilter={isOpen}
              handleShowFilter={toggleSidebar}
              setSelectedFilters={(Value: string[]) => {
                setSelectedFilters(Value)
              }}
              selectedFilters={selectedFilters}
              maPlansFilters={maPlansFilters}
              setMAPlansFilters={setMAPlansFilters}
              maPlansRequestBody={maPlansRequestBody}
              // setMAPlansRequestBody={setMAPlansRequestBody}
              handleRequestBodyChange={handleRequestBodyChange}
              setPlanLoader={setPlansLoader}
            />
          )}

          <CardsWrapper>
            {/* {currentSortOption.label === 'Popularity' */}
            {customerStore.recommendedPlanType ===
              MedicareQuotesType.MEDICARE_ADVANTAGE &&
              plans.length > 0 && (
                <div className='recommendation-header'>
                  <h2>Here are the Best Plans for You</h2>
                  <p>
                    Make changes to your drugs, doctors, pharmacy, and/or your
                    desired benefits and see how it affects your recommendation.
                  </p>
                </div>
              )}
            {!isEmpty(maPlansFilters) && (
              <>
                <FilterContainer>
                  <div className='bottom-wrapper'>
                    <PrimarySpouseToggle
                      handleUserChange={handleUserChange}
                      forSpouse={userOption}
                    />
                    <UtilityContainer>
                      <div
                        className='filter-toggle'
                        onClick={() => {
                          toggleSidebar()
                        }}
                      >
                        <RiFilter2Fill style={{ color: '#666666' }} />
                        <Left>Filters</Left>
                      </div>
                      {sortPlanOptions && (
                        <SortBy
                          options={sortPlanOptions}
                          value={currentSortOption}
                          onChange={handleSortChange}
                        />
                      )}
                    </UtilityContainer>
                  </div>
                </FilterContainer>

                <FilterBox
                  handleClearFilters={handleClearFilters}
                  selectedFilters={selectedFilters}
                  updateFilters={updateFilters}
                  key={'MA_FILTER_BOX'}
                />
              </>
            )}

            <>
              {plansLoader ? (
                <ThreeDotsWrapper>
                  <ThreeDots color='#222C69' height={80} width={80} />
                </ThreeDotsWrapper>
              ) : plans.length > 0 ? (
                <>
                  {/* {currentSortOption.label === 'Popularity' */}
                  {customerStore.recommendedPlanType ===
                    MedicareQuotesType.MEDICARE_ADVANTAGE && (
                    <RecommendedMACards
                      recommendedPlan={plans[0]} // First plan
                      goodChoicePlan={plans.length > 1 ? plans[1] : undefined} // Second plan, if available
                      userOption={userOption}
                      onEnroll={handleOnEnroll}
                      handleSpouseRemove={handleSpouseRemove}
                      setShowAddSpouseConfirmation={
                        setShowAddSpouseConfirmation
                      }
                      setCompleteDetailPlanId={setCompleteDetailPlanId}
                      setCompletePlanDetailModalActive={
                        setCompletePlanDetailModalActive
                      }
                    />
                  )}

                  {(customerStore.recommendedPlanType !==
                  MedicareQuotesType.MEDICARE_ADVANTAGE
                    ? plans
                    : plans.slice(2)
                  ).map((cardData: MNonSupplementPlan, index) => (
                    <div key={cardData.id}>
                      <MedicareAdvantageCard
                        userOption={userOption}
                        medicareAdvantagePlan={cardData}
                        onEnroll={handleOnEnroll}
                        handleSpouseRemove={handleSpouseRemove}
                        setShowAddSpouseConfirmation={
                          setShowAddSpouseConfirmation
                        }
                        setCompleteDetailPlanId={setCompleteDetailPlanId}
                        setCompletePlanDetailModalActive={
                          setCompletePlanDetailModalActive
                        }
                      />
                      {index % 2 !== 0 && (
                        <CallUsNow
                          heading='Not sure which Medicare plan is right for you?'
                          message='Our licensed insurance agents are here to help'
                          phoneNumber={getContact(customerStore.getBrandName())}
                        />
                      )}
                    </div>
                  ))}

                  {!paginationLoader ? (
                    <PaginationWrapper>
                      {!isNull(maPlanTotalCount) &&
                        Math.ceil(
                          maPlanTotalCount /
                            PaginationConstants.DEFAULT_PAGE_SIZE
                        ) >
                          (maPlansRequestBody.paginationDetail
                            ? maPlansRequestBody.paginationDetail.page
                            : 0) && (
                          <Button
                            color='primary'
                            variant='contained'
                            onClick={handlePaginationChange}
                          >
                            Load More Plans
                          </Button>
                        )}
                    </PaginationWrapper>
                  ) : (
                    <ThreeDotsWrapper>
                      <ThreeDots color='#222C69' height={80} width={80} />
                    </ThreeDotsWrapper>
                  )}
                </>
              ) : (
                <NoPlanFoundCard />
              )}
            </>
          </CardsWrapper>
        </>
      ) : (
        <ThreeDotsWrapper>
          <ThreeDots color='#222C69' height={80} width={80} />
        </ThreeDotsWrapper>
      )}

      {userActionStore.comparePlanCards.planType ===
        MedicareQuotesType.MEDICARE_ADVANTAGE &&
        userActionStore.comparePlanCards.compareCardsData.length !== 0 && (
          <BottomAddToCompare />
        )}
    </Container>
  )
}

export default observer(MedicareAdvantage)
