import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import React, { useEffect, useState } from 'react'

import Label from '../Label/Label'
import { isEmpty } from 'lodash'
import styled from '@emotion/styled'

interface YearMonthDaySelectorProps {
  label: string
  isFullWidth?: boolean
  onYearChange: (year: number | '') => void
  onMonthChange: (month: number | '') => void
  onDayChange: (day: number | '') => void
  errors: { year: string; month: string; day: string }
  setErrors: (error: { day: string; month: string; year: string }) => void
  loading?: boolean
  year?: number | ''
  month?: number | ''
  day?: number | ''
  isRequired?: boolean
}

const Container = styled.div`
  padding: 10px 0px;
`

const DateWrapper = styled.div`
  display: flex;
  gap: 16px;

  @media screen and (max-width: 600px) {
    gap: 5px;
  }
`

const YearMonthDaySelector: React.FC<YearMonthDaySelectorProps> = ({
  label,
  onYearChange,
  onMonthChange,
  onDayChange,
  errors,
  setErrors,
  loading = false,
  isFullWidth = true,
  year = '',
  month = '',
  day = '',
  isRequired = false,
}) => {
  const years: number[] = []
  const startYear = new Date().getFullYear() + 1
  const currentYear = 1920

  for (let i = currentYear; i < startYear; i++) {
    years.push(i)
  }

  const months = [
    { value: 1, label: 'January' },
    { value: 2, label: 'February' },
    { value: 3, label: 'March' },
    { value: 4, label: 'April' },
    { value: 5, label: 'May' },
    { value: 6, label: 'June' },
    { value: 7, label: 'July' },
    { value: 8, label: 'August' },
    { value: 9, label: 'September' },
    { value: 10, label: 'October' },
    { value: 11, label: 'November' },
    { value: 12, label: 'December' },
  ]

  const [selectedYear, setSelectedYear] = useState<number | ''>(year)
  const [selectedMonth, setSelectedMonth] = useState<number | ''>(month)
  const [selectedDay, setSelectedDay] = useState<number | ''>(day)
  const [daysInMonth, setDaysInMonth] = useState<number>(31)

  const handleYearChange = (event: SelectChangeEvent<number | ''>) => {
    const year = event.target.value === '' ? '' : (event.target.value as number)
    setSelectedYear(year)
    setErrors({ ...errors, year: '' })
    onYearChange(year)
    calculateDaysInMonth(year, selectedMonth)
  }

  const handleMonthChange = (event: SelectChangeEvent<number | ''>) => {
    const month =
      event.target.value === '' ? '' : (event.target.value as number)
    setSelectedMonth(month)
    setErrors({ ...errors, month: '' })
    onMonthChange(month)
    calculateDaysInMonth(selectedYear, month)
  }

  const handleDayChange = (event: SelectChangeEvent<number | ''>) => {
    const day = event.target.value === '' ? '' : (event.target.value as number)
    setSelectedDay(day)
    setErrors({ ...errors, day: '' })
    onDayChange(day)
  }

  const calculateDaysInMonth = (year: number | '', month: number | '') => {
    if (year && month) {
      const daysInMonth = new Date(year, month, 0).getDate()
      setDaysInMonth(daysInMonth)
      if (selectedDay !== '' && selectedDay > daysInMonth) {
        setSelectedDay(daysInMonth)
      }
    }
  }

  useEffect(() => {
    calculateDaysInMonth(selectedYear, selectedMonth)
  }, [selectedYear, selectedMonth])

  return (
    <div>
      {label && (
        <Label
          required={isRequired}
          style={{
            fontSize: '14px',
            fontWeight: '600',
            paddingBottom: '10px',
          }}
        >
          {label}{' '}
        </Label>
      )}
      <DateWrapper>
        <FormControl size='small' variant='outlined' fullWidth={isFullWidth}>
          <InputLabel
            id='month-select-label'
            sx={{
              color: !isEmpty(errors.month) ? 'red' : 'grey',
            }}
          >
            Month
          </InputLabel>
          <Select
            disabled={loading}
            error={!isEmpty(errors.month)}
            labelId='month-select-label'
            value={selectedMonth}
            onChange={handleMonthChange}
            label='Month'
            displayEmpty
            MenuProps={{
              PaperProps: {
                style: {
                  zIndex: 2200,
                },
              },
            }}
            sx={{
              borderRadius: '36px',
              minWidth: 'max-content',
            }}
          >
            {months.map((month) => (
              <MenuItem key={month.value} value={month.value}>
                {month.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText error>{errors.month}</FormHelperText>
        </FormControl>

        <FormControl size='small' variant='outlined' fullWidth={isFullWidth}>
          <InputLabel
            id='day-select-label'
            sx={{
              color: !isEmpty(errors.day) ? 'red' : 'grey',
            }}
          >
            Day
          </InputLabel>
          <Select
            disabled={loading}
            error={!isEmpty(errors.day)}
            labelId='day-select-label'
            value={selectedDay}
            onChange={handleDayChange}
            label='Day'
            displayEmpty
            MenuProps={{
              PaperProps: {
                style: {
                  zIndex: 2200,
                },
              },
            }}
            sx={{
              borderRadius: '36px',
              minWidth: 'max-content',
            }}
          >
            {Array.from({ length: daysInMonth }, (_, index) => index + 1).map(
              (day) => (
                <MenuItem key={day} value={day}>
                  {day}
                </MenuItem>
              )
            )}
          </Select>
          <FormHelperText error>{errors.day}</FormHelperText>
        </FormControl>

        <FormControl size='small' variant='outlined' fullWidth={isFullWidth}>
          <InputLabel
            id='year-select-label'
            sx={{
              color: !isEmpty(errors.year) ? 'red' : 'grey',
            }}
          >
            Year
          </InputLabel>
          <Select
            disabled={loading}
            error={!isEmpty(errors.month)}
            labelId='year-select-label'
            sx={{
              borderRadius: '36px',
              minWidth: 'max-content',
            }}
            value={selectedYear}
            onChange={handleYearChange}
            onMouseDown={() => {
              if (selectedYear === '') {
                const defaultYear = new Date().getFullYear() - 65
                setSelectedYear(defaultYear)
                setErrors({ ...errors, year: '' })
                onYearChange(defaultYear)
                calculateDaysInMonth(defaultYear, selectedMonth)
              }
            }}
            label='Year'
            MenuProps={{
              PaperProps: {
                style: {
                  zIndex: 2200,
                },
              },
            }}
          >
            {years.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText error>{errors.year}</FormHelperText>
        </FormControl>
      </DateWrapper>
    </div>
  )
}

export default YearMonthDaySelector
