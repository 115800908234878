import React, { useEffect, useState } from 'react'

import { MedicareConstants } from '../../../../constants/MedicareConstants'
import RadioGroup from '../../../common/RadioGroup/RadioGroup.component'
import currentPlanStore from '../../../../datastore/CurrentPlanStore'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react'
import styled from '@emotion/styled'
import theme from '../../../../global/theme'

interface SelectPlanTypeProps extends ContainerProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
  disableMainNext?: () => void
  enableMainNext?: () => void
  handleHasNext?: (value: boolean) => void
  forSpouse: boolean
}

interface ContainerProps {
  width?: string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 600px) {
    padding: 10px 5px;
    width: 100%;
  }
`

const Heading = styled.h3`
  font-size: 20px;
  font-weight: 700px;
  padding-bottom: 20px;
  color: ${theme.colors.primary};
`

const Description = styled.p`
  padding-top: 20px;
  font-weight: 600;
  text-align: left;
`

const RadioButtonWrapper = styled.div`
  width: 100%;
  padding: 10px 0px;
  max-height: 270px;
  overflow-y: auto;
  text-align: left;
`

const SelectPlanType: React.FC<SelectPlanTypeProps> = ({
  disableMainNext,
  enableMainNext,
  handleHasNext,
  forSpouse,
}) => {
  const [showOptions, setShowOptions] = useState<boolean>(false)

  const handlePlanType = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) enableMainNext && enableMainNext()
    if (!forSpouse) {
      currentPlanStore.setQuoteType(e.target.value)
      currentPlanStore.setPlanType('')
      currentPlanStore.setProvider('')
    } else {
      currentPlanStore.setSpouseQuoteType(e.target.value)
      currentPlanStore.setSpousePlanType('')
      currentPlanStore.setSpouseProvider('')
    }

    showNavigatedButton(
      forSpouse
        ? currentPlanStore.spouseQuoteType
        : currentPlanStore.get().quoteType
    )
  }

  const showNavigatedButton = (quoteType: string) => {
    if (
      ![
        MedicareConstants.INSURANCE_TYPE.MA,
        MedicareConstants.INSURANCE_TYPE.MS,
        MedicareConstants.INSURANCE_TYPE.Group,
        MedicareConstants.INSURANCE_TYPE.IH,
        MedicareConstants.INSURANCE_TYPE.Retiree,
        MedicareConstants.INSURANCE_TYPE.Cobra,
      ].includes(quoteType)
    ) {
      handleHasNext && handleHasNext(false)
    } else {
      handleHasNext && handleHasNext(true)
    }
  }

  useEffect(() => {
    if (!forSpouse) {
      setShowOptions(
        currentPlanStore.get().coveredByHealthInsurance === true ? true : false
      )
      if (!isEmpty(currentPlanStore.quoteType)) {
        showNavigatedButton(currentPlanStore.quoteType)
        setShowOptions(true)
        enableMainNext && enableMainNext()
      } else {
        disableMainNext && disableMainNext()
      }
    } else {
      setShowOptions(
        currentPlanStore.spouseCoveredByHealthInsurance === true ? true : false
      )
      if (!isEmpty(currentPlanStore.spouseQuoteType)) {
        showNavigatedButton(currentPlanStore.spouseQuoteType)
        setShowOptions(true)
        enableMainNext && enableMainNext()
      } else {
        disableMainNext && disableMainNext()
      }
    }
  }, [])

  const handleCoveredByHealthInsurance = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!forSpouse) {
      if (e.target.value === 'Yes') {
        currentPlanStore.setCoveredByHealthInsurance(true)
        if (
          (currentPlanStore.quoteType &&
            ![
              MedicareConstants.INSURANCE_TYPE.AB,
              MedicareConstants.INSURANCE_TYPE.Medicaid,
              MedicareConstants.INSURANCE_TYPE.Tricare,
              MedicareConstants.INSURANCE_TYPE.None,
            ].includes(currentPlanStore.quoteType)) ||
          isEmpty(currentPlanStore.quoteType)
        ) {
          disableMainNext && disableMainNext()
        }
        setShowOptions(true)
      } else {
        currentPlanStore.setCoveredByHealthInsurance(false)
        currentPlanStore.setPlanType('')
        currentPlanStore.setProvider('')
        currentPlanStore.setQuoteType('')
        setShowOptions(false)
        enableMainNext && enableMainNext()
      }
    } else {
      if (e.target.value === 'Yes') {
        currentPlanStore.setSpouseCoveredByHealthInsurance(true)
        if (
          (currentPlanStore.spouseQuoteType &&
            ![
              MedicareConstants.INSURANCE_TYPE.AB,
              MedicareConstants.INSURANCE_TYPE.Medicaid,
              MedicareConstants.INSURANCE_TYPE.Tricare,
              MedicareConstants.INSURANCE_TYPE.None,
            ].includes(currentPlanStore.spouseQuoteType)) ||
          isEmpty(currentPlanStore.spouseQuoteType)
        ) {
          disableMainNext && disableMainNext()
        }
        setShowOptions(true)
      } else {
        currentPlanStore.setSpouseCoveredByHealthInsurance(false)
        currentPlanStore.setSpousePlanType('')
        currentPlanStore.setSpouseProvider('')
        currentPlanStore.setSpouseQuoteType('')
        setShowOptions(false)
        enableMainNext && enableMainNext()
      }
    }
  }

  React.useEffect(() => {
    if (forSpouse) {
      if (!currentPlanStore.spouseCoveredByHealthInsurance) {
        enableMainNext && enableMainNext()
      }
    } else {
      if (!currentPlanStore.coveredByHealthInsurance) {
        enableMainNext && enableMainNext()
      }
    }
  }, [
    currentPlanStore.coveredByHealthInsurance,
    currentPlanStore.spouseCoveredByHealthInsurance,
    enableMainNext,
  ])

  return (
    <Container>
      <Heading>
        {forSpouse
          ? 'Is your spouse covered by other health insurance?'
          : 'Are you currently covered by other Health Insurance?'}
      </Heading>
      <RadioButtonWrapper>
        <RadioGroup
          alignItems='center'
          justifyContent='center'
          radioButtons={['Yes', 'No']}
          row={true}
          onChange={handleCoveredByHealthInsurance}
          value={(() => {
            if (forSpouse) {
              const result =
                currentPlanStore.spouseCoveredByHealthInsurance === true
                  ? 'Yes'
                  : currentPlanStore.spouseCoveredByHealthInsurance === false
                  ? 'No'
                  : ''
              return result
            } else {
              const result =
                currentPlanStore.get().coveredByHealthInsurance === true
                  ? 'Yes'
                  : currentPlanStore.get().coveredByHealthInsurance === false
                  ? 'No'
                  : ''
              return result
            }
          })()}
          radioGroupName={`Info Steps - Are you currently covered by other Health Insurance?`}
          radioButtonWidth='130px'
        />
      </RadioButtonWrapper>
      {showOptions && (
        <>
          <Description>
            Please choose which type of insurance you currently have
          </Description>
          <RadioButtonWrapper>
            <RadioGroup
              radioButtons={MedicareConstants.getInsuranceTypes()}
              width='100%'
              onChange={handlePlanType}
              value={(() => {
                let result = ''
                if (forSpouse) {
                  result = currentPlanStore.spouseQuoteType
                } else {
                  result = currentPlanStore.quoteType
                }
                return result || 'Medicare Advantage'
              })()}
            />
          </RadioButtonWrapper>
        </>
      )}
    </Container>
  )
}

export default observer(SelectPlanType)
