import React, { useEffect } from 'react'
import { addProvider, getProviderAddress } from '../../../../service/providers'
import { isEmpty, reject } from 'lodash'

import BouncingDotsLoader from '../../../common/Loading/BouncingDotsLoader'
import Button from '../../../common/Button/Button.component'
import { DoctorData } from '../../../../mock_data/MockData'
import DoctorDetailedSelection from './DoctorDetailedSelection.component'
import ModalComponent from '../../../common/Modal/Modal.component'
import { QuotesRefreshContext } from '../../../../context/QuotesRefreshContext'
import RouteConstants from '../../../../constants/RouteConstants'
import SearchBar from '../../../common/Search/Search.component'
import SectionRow from '../../../common/SectionRow'
import { SnackbarTypes } from '../../../../enums/SnackbarTypesEnum'
import customerStore from '../../../../datastore/CustomerStore'
import mPartDPlansFilterStore from '../../../../datastore/medicareQuotes/MPartPlanFilterQuotesStore'
import medicareAdvantageFilterQuoteStore from '../../../../datastore/medicareQuotes/MedicareAdvantageFilterQuoteStore'
import { observer } from 'mobx-react'
import snackbarStore from '../../../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../../../global/theme'
import { updateInfoStepStatus } from '../../../../service/customer'
import { useNavigate } from 'react-router-dom'

interface SearchPromptProps {
  addDoctorState: {
    AddedDoctor: any[]
    Doctor: any[]
    addingDoctors: boolean
  }
  setAddDoctorState: React.Dispatch<
    React.SetStateAction<{
      AddedDoctor: any[]
      Doctor: any[]
      addingDoctors: boolean
    }>
  >
  handleNext?: () => void
  searchDoctor: boolean
  setSearchDoctor: React.Dispatch<React.SetStateAction<boolean>>
  selectedCity: any
  fetchLookUpProviders?: any
  loadingFetch?: boolean
  providersLookUpData?: any
  forSpouse: boolean
  setAddProviderLoading?: (flag: boolean) => void
  isInfoSteps?: boolean
}

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;

  @media screen and (max-width: 700px) {
    width: 100%;
  }
`

const Container = styled.div`
  width: 100%;
`

const SearchPrompt: React.FC<SearchPromptProps> = ({
  addDoctorState,
  setAddDoctorState,
  handleNext,
  setSearchDoctor,
  searchDoctor,
  fetchLookUpProviders,
  loadingFetch,
  providersLookUpData,
  selectedCity,
  forSpouse,
  isInfoSteps,
  setAddProviderLoading = () => {},
}) => {
  const [showButtonGroup, setShowButtonGroup] = React.useState(false)
  const [providerAddress, setProviderAddress] = React.useState<any>({})
  const [loading, setLoading] = React.useState<boolean>(false)
  const navigate = useNavigate()
  const { isQuotesRefreshing } = React.useContext(QuotesRefreshContext)

  const handleSearchClick = (item: any) => {
    const providersWithSameTitle = providersLookUpData.filter(
      (provider: any) => {
        return provider.title === item.title
      }
    )
    setSearchDoctor(false)
    setAddDoctorState({
      ...addDoctorState,
      addingDoctors: true,
      Doctor:
        providersWithSameTitle.length === 1 ? [item] : providersWithSameTitle,
    })
    setLoading(true)
    if (providersWithSameTitle.length > 1) {
      const promises = providersWithSameTitle.map((provider: any) => {
        return getProviderAddress({
          id: provider.id,
          searchType: 'cloudsearch',
          type: 'location',
          city: selectedCity.city,
          state: selectedCity.state,
          lat: selectedCity.latitude,
          lng: selectedCity.longitude,
        }).then((response: any) => {
          const providerAddress: any[] = []
          response.data.data.forEach((address: any) => {
            if (address.zip && address.zip.length === 5) {
              providerAddress.push(address)
            }
          })
          return providerAddress
        })
      })
      Promise.all(promises)
        .then((addressesArray: any[]) => {
          setProviderAddress(addressesArray)
        })
        .catch((error: any) => {
          snackbarStore.set({
            snackbarMessage: error.data.message,
            snackbarOpen: true,
            snackbarType: SnackbarTypes.ERROR,
          })
        })
        .finally(() => setLoading(false))
    } else {
      getProviderAddress({
        id: item.id,
        searchType: 'cloudsearch',
        type: 'location',
        city: selectedCity.city,
        state: selectedCity.state,
        lat: selectedCity.latitude,
        lng: selectedCity.longitude,
      })
        .then((response: any) => {
          const providerAddress: any[] = []
          response.data.data.map((address: any) => {
            if (address.zip.length === 5) providerAddress.push(address)
          })
          setProviderAddress([providerAddress])
        })
        .catch((err) => {
          snackbarStore.set({
            snackbarMessage: err.data.message,
            snackbarOpen: true,
            snackbarType: SnackbarTypes.ERROR,
          })
        })
        .finally(() => setLoading(false))
    }
  }

  const fetchSearchResults = (searchWord: any) => {
    fetchLookUpProviders(searchWord)
  }

  const handleAddDoctor = async (doctor: any) => {
    setAddProviderLoading(true)
    setShowButtonGroup(true)
    await addProvider(doctor[0].id, doctor[0].npi, forSpouse)
      .then((response: any) => {
        updateInfoStepStatus('doctor').then(() => {
          mPartDPlansFilterStore.clearStore()
          medicareAdvantageFilterQuoteStore.clearStore()
          setAddDoctorState({
            addingDoctors: false,
            Doctor: [],
            AddedDoctor: [...addDoctorState.AddedDoctor, response.data.data],
          })
          snackbarStore.set({
            snackbarMessage: 'Doctor added successfully',
            snackbarOpen: true,
            snackbarType: SnackbarTypes.SUCCESS,
          })
          setProviderAddress({})
          setSearchDoctor(false)
        })
      })
      .catch((err) => {
        snackbarStore.set({
          snackbarMessage: err.data.message,
          snackbarOpen: true,
          snackbarType: SnackbarTypes.ERROR,
        })
        reject(err)
        setSearchDoctor(true)
        setProviderAddress({})
        setAddDoctorState({
          ...addDoctorState,
          Doctor: [],
          addingDoctors: false,
        })
      })
      .finally(() => {
        setAddProviderLoading(false)
      })
  }
  const handleCancel = async (item: any) => {
    const updatedDoctor =
      addDoctorState.Doctor.length !== 1
        ? await addDoctorState.Doctor.find(
            (doctor: any) => doctor.id !== item.npi
          )
        : {}
    const updatedProviderAddress = await providerAddress
      .map((addressGroup: any) =>
        addressGroup.filter(
          (address: any) =>
            !item.some((itemObj: any) => itemObj.npi === address.npi)
        )
      )
      .filter((addressGroup: any) => addressGroup.length > 0)

    setSearchDoctor(isEmpty(updatedDoctor) ? true : false)
    setProviderAddress(updatedProviderAddress)
    setAddDoctorState({
      ...addDoctorState,
      Doctor: [updatedDoctor],
      addingDoctors: isEmpty(updatedDoctor) ? false : true,
    })
  }
  return (
    <Container>
      {(!addDoctorState.addingDoctors ||
        (searchDoctor && !showButtonGroup)) && (
        <SectionRow row>
          <SearchBar
            label="What is your doctor's name?"
            placeholder='Search Doctor'
            handleSearchClick={handleSearchClick}
            data={providersLookUpData}
            fetchSearchResults={fetchSearchResults}
            loading={loadingFetch}
            physicianDropdown
            isInfoSteps={isInfoSteps}
          />
        </SectionRow>
      )}

      {!loading ? (
        addDoctorState.addingDoctors &&
        !searchDoctor &&
        (providerAddress.length < 2 ? (
          <ModalComponent
            setOpen={handleCancel}
            hideCloseButton
            padding='0px'
            restrictScroll={true}
          >
            <DoctorDetailedSelection
              handleAddDoctor={handleAddDoctor}
              handleCancel={handleCancel}
              data={addDoctorState.Doctor[0]}
              loading={loading}
              providerAddress={providerAddress[0]}
              isQuotesRefreshing={isQuotesRefreshing}
            />
          </ModalComponent>
        ) : (
          !isEmpty(providerAddress) &&
          providerAddress.map((innerArray: any, index: any) => {
            return (
              <ModalComponent
                padding='0px'
                setOpen={handleCancel}
                hideCloseButton
              >
                <DoctorDetailedSelection
                  key={index}
                  handleAddDoctor={handleAddDoctor}
                  handleCancel={handleCancel}
                  data={addDoctorState.Doctor[0]}
                  loading={loading}
                  providerAddress={providerAddress[index]}
                  isQuotesRefreshing={isQuotesRefreshing}
                />
              </ModalComponent>
            )
          })
        ))
      ) : (
        <BouncingDotsLoader padding='15px' />
      )}

      {addDoctorState.AddedDoctor.length > 0 &&
        !addDoctorState.addingDoctors &&
        showButtonGroup && (
          <ButtonWrapper>
            <Button
              width='100%'
              variant='contained'
              bold={false}
              onClick={() => {
                setSearchDoctor(true)
                setShowButtonGroup(false)
              }}
            >
              + Add another doctor
            </Button>
            <Button
              width='100%'
              bold={false}
              onClick={() => {
                navigate(RouteConstants.DASHBOARD)
              }}
            >
              I'm done adding doctor
            </Button>
          </ButtonWrapper>
        )}
    </Container>
  )
}

export default SearchPrompt
