import Tooltip from '../common/Tooltip/Tooltip.component'
import styled from '@emotion/styled'
import theme from '../../global/theme'

interface AdditionalCostProps {
  name: string
  value: string
  toolTip: string
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${theme.colors.textGrey};
  gap: 5px;
  font-weight: 600;
  font-size: 14px;
  padding: 10px;
  .value {
    font-weight: 700;
    font-size: 24px;
    color: ${theme.colors.primary};
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    padding: 10px 0px;
    gap: 0;
  }
`

const AdditionalCost: React.FC<AdditionalCostProps> = ({
  name,
  value,
  toolTip,
}) => (
  <Content>
    <p className='value'>{value}</p>
    <p>
      {name}
      <Tooltip title={toolTip} />
    </p>
  </Content>
)

export default AdditionalCost
