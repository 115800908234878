import { AiOutlineMail, AiOutlinePrinter } from 'react-icons/ai'
import React, { useRef, useState } from 'react'

import ApiManager from '../../api/ApiManager'
import Button from '../common/Button/Button.component'
import CarrierLogo from '../common/CarrierLogo'
import EnrollmentRequestConfirmation from '../DashboardPage/Modals/EnrollmentRequestConfirmation'
import { FiChevronLeft } from 'react-icons/fi'
import { HttpMethods } from '../../enums/HttpMethodsEnum'
import Icon from '../../components/common/Icon/Icon.component'
import { MedicareQuotesType } from '../../enums/MedicareQuoteTypeEnum'
import ModalComponent from '../common/Modal/Modal.component'
import RouteConstants from '../../constants/RouteConstants'
import SavePlanIconButton from '../common/SavePlan/SavePlanIconButton'
import { ThreeDots } from 'react-loader-spinner'
import UrlConstants from '../../constants/UrlConstants'
import customerStore from '../../datastore/CustomerStore'
import eipLogo from '../../assets/vectors/eipLogo.svg'
import { fireEvent } from '../../cra'
import { getApiErrorMessage } from '../../utils/StringUtils'
import { isEmpty } from 'lodash'
import snackbarStore from '../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../global/theme'
import { updateEnrollmentRequest } from '../../service/quotes'
import { updatePreferredNumberForEnrollRequestedPlan } from '../../service/customer'
import { useNavigate } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import userActionStore from '../../datastore/UserActionStore'

const Container = styled.div`
  border-bottom: 1px solid ${theme.colors.borderColor};
  display: flex;
  gap: 50px;
  padding: 20px 50px;

  .print-logo {
    display: none;
    position: absolute;
    top: 0px;
    right: 20px;
    img {
      max-width: 100px;
    }
    @media print {
      display: initial;
    }
  }

  @media screen and (max-width: 920px) {
    padding: 20px;
    gap: 20px;
    flex-direction: column;
  }
`

const BackButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  p {
    display: flex;
    font-weight: 600;
    color: ${theme.colors.primary};
    cursor: pointer;
    span {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  @media print {
    display: none;
  }
`

const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;

    .plan-head {
      max-width: 600px;
      display: flex;
      align-items: center;
      gap: 20px;
      h2 {
        font-size: 18px;
      }

      img {
        max-height: 40px;
      }

      @media screen and (max-width: 1150px) {
        max-width: 400px;
      }

      @media screen and (max-width: 920px) {
        flex-direction: column;
        justify-content: center;
        margin: auto;
        text-align: center;
      }

      @media print {
        h2 {
          font-size: 32px;
          color: ${theme.colors.primary};
        }
      }
    }

    .utility {
      display: flex;
      align-items: center;
      gap: 20px;
      font-weight: 600;
      color: ${theme.colors.primary};
      text-decoration: underline;
      cursor: pointer;
      p {
        font-size: 14px;
        display: flex;
        align-items: end;
        white-space: nowrap;
      }

      @media screen and (max-width: 920px) {
        justify-content: center;
        width: 100%;
      }

      @media print {
        display: none;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    @media print {
      display: none;
    }
  }
`

const HeaderSection: React.FC<{
  carrierLogoName: string
  planId: string
  planName: string
  isFavorite: boolean
  planType: MedicareQuotesType
  handleOnEnroll: () => void
  onAddSaved: () => void
  onRemoveSaved: () => void
  enrollRequestedStatus: boolean
  ridersApplied?: string[] | undefined
  sunfirePlanType?: MedicareQuotesType
  enrollmentRedirectURL?: string
  handleOnPrintClicked: any
  printLoader: boolean
}> = ({
  carrierLogoName,
  planId,
  planName,
  planType,
  isFavorite,
  onAddSaved,
  onRemoveSaved,
  handleOnEnroll,
  enrollRequestedStatus,
  ridersApplied,
  sunfirePlanType,
  enrollmentRedirectURL,
  handleOnPrintClicked,
  printLoader,
}) => {
  const navigate = useNavigate()
  const [emailLoader, setEmailLoader] = React.useState<boolean>(false)
  // const [printLoader, setPrintLoader] = React.useState<boolean>(false)
  const timerRef: { current: NodeJS.Timeout | null } = useRef(null)
  const [enrollRequested, setEnrollRequested] = React.useState(false)
  const [enrollmentRequestPromptActive, setEnrollmentRequestPromptActive] =
    React.useState(false)
  const enrollBtnText =
    planType === MedicareQuotesType.MEDICARE_SUPPLEMENT
      ? enrollRequested
        ? 'Application Requested'
        : 'APPLY'
      : enrollRequested
      ? 'Enrollment Requested'
      : 'ENROLL'
  const [loading, setLoading] = React.useState(false)
  const [preferredPhone, setPreferredPhone] = React.useState(
    customerStore.get().phone.replace('+1', '')
  )
  const [preferredPhoneLoading, setPreferredPhoneLoading] = useState(false)

  React.useEffect(() => {
    // Clear the interval when the component unmounts
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current)
    }
  }, [])

  React.useEffect(() => {
    if (enrollRequestedStatus !== undefined) {
      setEnrollRequested(enrollRequestedStatus)
    }
  }, [enrollRequestedStatus])

  const handleEnrollRequest = () => {
    if (
      planId &&
      planType === MedicareQuotesType.MEDICARE_SUPPLEMENT &&
      isEmpty(userActionStore.getRidersApplied(planId)) &&
      !isEmpty(ridersApplied)
    ) {
      userActionStore.setRidersApplied(planId, ridersApplied!)
    }
    setLoading(true)
    const riders = planId
      ? userActionStore.getRidersAppliedWithFullNames(planId).length > 0
        ? userActionStore.getRidersAppliedWithFullNames(planId)
        : undefined
      : undefined

    updateEnrollmentRequest(
      planId,
      planType,
      false,
      `+1${preferredPhone.toString()}`,
      riders?.toString()
    )
      .then((response) => {
        setLoading(false)
        snackbarStore.set({
          snackbarOpen: true,
          snackbarMessage: `${
            planType === MedicareQuotesType.MEDICARE_SUPPLEMENT
              ? 'Apply'
              : 'Enroll'
          } request saved successfully`,
          snackbarType: 'success',
        })
        setEnrollRequested(true)
        setEnrollmentRequestPromptActive(true)
      })
      .catch((err) => {
        if (
          err.data?.message ===
          'Maximum limit reached for requesting enrollment for plans'
        ) {
          setLoading(false)
          snackbarStore.set({
            snackbarOpen: true,
            snackbarMessage: getApiErrorMessage(
              `save ${
                planType === MedicareQuotesType.MEDICARE_SUPPLEMENT
                  ? 'apply'
                  : 'enroll'
              } request. Maximum limit reached`
            ),
            snackbarType: 'error',
          })
          setEnrollRequested(false)
          setEnrollmentRequestPromptActive(false)
        } else {
          setLoading(false)
          snackbarStore.set({
            snackbarOpen: true,
            snackbarMessage: getApiErrorMessage(
              `save ${
                planType === MedicareQuotesType.MEDICARE_SUPPLEMENT
                  ? 'apply'
                  : 'enroll'
              } request`
            ),
            snackbarType: 'error',
          })
          setEnrollRequested(false)
          setEnrollmentRequestPromptActive(false)
        }
      })
  }

  const handlePreferredNumberForEnrollRequest = async () => {
    if (preferredPhone !== customerStore.get().phone.replace('+1', '')) {
      setPreferredPhoneLoading(true)
      updatePreferredNumberForEnrollRequestedPlan({
        'plan-id': planId!,
        'plan-type': planType,
        'preferred-number': preferredPhone,
      })
        .then(() => {
          snackbarStore.set({
            snackbarOpen: true,
            snackbarMessage: 'Successfully saved the preferred number',
            snackbarType: 'success',
          })
          setEnrollmentRequestPromptActive(false)
        })
        .catch((err) => {
          snackbarStore.set({
            snackbarOpen: true,
            snackbarMessage: 'Please try again',
            snackbarType: 'error',
          })
        })
        .finally(() => {
          setPreferredPhoneLoading(false)
        })
    } else {
      setEnrollmentRequestPromptActive(false)
    }

    handleOnEnroll()
  }

  const handleOnEmailClicked = () => {
    setEmailLoader(true)
    //TODO: Handle MS Plans for spouse
    let emailPlanDetailsUsecase
    if (sunfirePlanType === MedicareQuotesType.MEDICARE_SUPPLEMENT)
      emailPlanDetailsUsecase = UrlConstants.POST_EMAIL_PLAN_DETAILS.USECASE
    else
      emailPlanDetailsUsecase = UrlConstants.POST_EMAIL_MNS_PLAN_DETAILS.USECASE

    ApiManager.makeApiCallWithAuthentication(
      emailPlanDetailsUsecase,
      HttpMethods.POST_METHOD,
      {
        planId: planId,
        medicareQuoteType: sunfirePlanType,
        forSpouse: false,
      }
    )
      .then((response) => {
        if (response.status === 200) {
          snackbarStore.set({
            snackbarOpen: true,
            snackbarMessage: 'Plan details will be sent soon.',
            snackbarType: 'success',
          })
        } else throw response
      })
      .catch((err) => {
        snackbarStore.set({
          snackbarOpen: true,
          snackbarMessage: getApiErrorMessage('send plan details'),
          snackbarType: 'error',
        })
      })
      .finally(() => setEmailLoader(false))
  }

  return (
    <Container>
      {enrollmentRequestPromptActive && (
        <ModalComponent
          width={550}
          setOpen={() => {
            setEnrollmentRequestPromptActive(false)
          }}
          description='Popup - Request for Enrollment Confirmation'
          hideCloseButton={true}
        >
          <EnrollmentRequestConfirmation
            setOpen={() => {
              setEnrollmentRequestPromptActive(false)
            }}
            onEnroll={() => {
              handlePreferredNumberForEnrollRequest()
            }}
            planName={planName}
            planId={planId ? planId : ''}
            planType={planType}
            buttonLoading={preferredPhoneLoading}
            preferredPhoneNumber={preferredPhone}
            setPreferredPhoneNumber={setPreferredPhone}
          />
        </ModalComponent>
      )}

      <div className='print-logo'>
        <img src={eipLogo} alt='eip logo' />
      </div>

      {/* <BackButtonWrapper>
        <p
          onClick={() => {
            if (customerStore.currentTab === 0) {
              navigate(RouteConstants.DASHBOARD_MA)
            } else if (customerStore.currentTab === 1) {
              navigate(RouteConstants.DASHBOARD_MS)
            } else {
              navigate(RouteConstants.DASHBOARD_PDP)
            }
          }}
        >
          <Icon childComponent={<FiChevronLeft />} />
          <span>Back</span>
        </p>
      </BackButtonWrapper> */}
      <ContentWrapper>
        <div className='header'>
          <div className='plan-head'>
            <div>
              <CarrierLogo carrier={carrierLogoName} useAltLogo={true} />
            </div>
            <h2>{planName}</h2>
          </div>
          <div className='utility'>
            <p
              onClick={(e) => {
                fireEvent('click', e, {
                  description:
                    'Print Plan Details in Complete Plan Details Page',
                })
                handleOnPrintClicked()
              }}
            >
              <AiOutlinePrinter
                style={{
                  fontSize: '18px',
                }}
              />{' '}
              Print Plan Details
              {printLoader && (
                <ThreeDots color='#222C69' height={20} width={40} />
              )}
            </p>
            <p
              onClick={(e) => {
                fireEvent('click', e, {
                  description:
                    'Email Plan Details in Complete Plan Details Page',
                })
                handleOnEmailClicked()
              }}
            >
              <AiOutlineMail
                style={{
                  fontSize: '18px',
                }}
              />{' '}
              Email Plan Details
              {emailLoader && (
                <ThreeDots color='#222C69' height={20} width={40} />
              )}
            </p>
          </div>
        </div>
        <div className='footer'>
          {planId && (
            <SavePlanIconButton
              planId={planId}
              planType={planType}
              saved={isFavorite}
              page='Complete Plan Details Page'
              onAddSaved={onAddSaved}
              onRemoveSaved={onRemoveSaved}
              forSpouse={false}
            />
          )}
          <Button
            color='secondary'
            variant='contained'
            width='200px'
            bold={false}
            onClick={() => {
              handleEnrollRequest()
            }}
            loading={loading}
            disabled={enrollRequested}
            additionalPadding={enrollBtnText !== 'ENROLL'}
            description={`Complete Plan Details Page - Attempted Enrollment in ${planName}`}
            id={`Enroll ${planName}`}
          >
            {enrollBtnText}
          </Button>
        </div>
      </ContentWrapper>
    </Container>
  )
}

export default HeaderSection
