import CloseIconButton from '../Button/CloseIconButton.component'
import React from 'react'
import { fireEvent } from '../../../cra'
import styled from '@emotion/styled'
import theme from '../../../global/theme'

interface ModalProps extends ContainerProps {
  children?: JSX.Element | string | undefined
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  description?: string
  hideCloseButton?: boolean
}

interface ContainerProps {
  width?: number | string
  padding?: string
  maxWidth?: number | string
  restrictScroll?: boolean
}

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1000;
  top: 0px;
  left: 0px;
  background: rgb(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
`

const Modal = styled.div<ContainerProps>`
  max-width: ${(p) => {
    if (p.maxWidth) {
      return `${p.width}`
    } else if (typeof p.width === 'number') {
      return `${p.width}px`
    } else {
      return p.width
    }
  }};
  width: 100%;
  background-color: ${theme.colors.white};
  max-height: 90vh;
  border-radius: 16px;
  position: relative;
  padding: ${(p: ContainerProps) => (p.padding ? p.padding : '40px 50px')};
  overflow-y: ${(p: ContainerProps) =>
    p.restrictScroll ? 'none' : 'scroll !important'};
  margin: 10px;

  @media screen and (max-width: 500px) {
    padding: 20px 20px;
  }
`
const CloseButtonContainer = styled.div`
  position: absolute;
  z-index: 10;
  top: 20px;
  right: 20px;
`

const ModalComponent: React.FC<ModalProps> = ({
  children,
  setOpen,
  width = 500,
  description,
  hideCloseButton = false,
  padding,
  maxWidth,
  restrictScroll = true,
}) => {
  React.useEffect(() => {
    fireEvent('popupOpen', null, {
      description,
    })

    return () => {
      fireEvent('popupClose', null, {
        description,
      })
    }
  }, [description])

  return (
    <Container>
      <Modal
        restrictScroll={restrictScroll}
        maxWidth={maxWidth}
        width={width}
        style={{ overflowY: 'hidden' }}
        padding={padding}
      >
        {!hideCloseButton && (
          <CloseButtonContainer>
            <CloseIconButton
              onClick={(e) => setOpen(false)}
              fontSize='14px'
              textColor={theme.colors.primary}
              iconType='bold'
            />
          </CloseButtonContainer>
        )}
        {children}
      </Modal>
    </Container>
  )
}

export default ModalComponent
