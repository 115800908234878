import DisclosureStep from './DisclosureStep'
import FallbackStep from './FallbackStep'
import ModalComponent from '../../../common/Modal/Modal.component'
import React from 'react'
import ReviewPrescriptionStep from './ReviewPrescriptionStep'
import styled from '@emotion/styled'

interface MillimanStepperProps {
  setShowAddDrugComponent: React.Dispatch<React.SetStateAction<boolean>>
}

const ContentWrapper = styled.div`
  max-height: 700px;
  overflow-y: auto;
  @media screen and (max-width: 750px) {
    max-height: 600px;
  }
`

const MillimanStepper: React.FC<MillimanStepperProps> = ({
  setShowAddDrugComponent,
}) => {
  const [step, setStep] = React.useState(1)
  return (
    <>
      <ContentWrapper>
        {step === 1 && (
          <DisclosureStep
            setStep={setStep}
            setShowAddDrugComponent={setShowAddDrugComponent}
          />
        )}
        {step === 2 && (
          <ReviewPrescriptionStep
            setShowAddDrugComponent={setShowAddDrugComponent}
            setStep={setStep}
          />
        )}
        {step === 3 && (
          <FallbackStep setShowAddDrugComponent={setShowAddDrugComponent} />
        )}
      </ContentWrapper>
    </>
  )
}

export default MillimanStepper
