import Label, { SubLabel } from '../Label/Label'

import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import MuiRadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import React from 'react'
import StringConstants from '../../../constants/StringConstants'
import Tooltip from '../Tooltip/Tooltip.component'
import { fireEvent } from '../../../cra'
import theme from '../../../global/theme'

const radioStyles = {
  backgroundColor: `${theme.colors.white}`,
  border: `1px solid ${theme.colors.borderColor}`,
  borderRadius: '36px',
  padding: '0px 20px 0px 5px',
  margin: 0,
  flex: 1,
}

const errorRadioStyles = {
  backgroundColor: `${theme.colors.backgroundColorContainer}`,
  border: `1px solid ${theme.colors.secondary}`,
  borderRadius: '36px',
  padding: '0px 20px 0px 5px',
  margin: 0,
  flex: 1,
}

const unstyledRadioStyles = {}

interface RadioGroupProps {
  allUncheck?: boolean | undefined
  formLabel?: string
  radioButtons: Array<string>
  subDetails?: Array<string>
  buttonDescription?: Array<string>
  row?: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  width?: string
  type?: 'default' | 'unstyled'
  value?: string
  error?: boolean
  fontSize?: string
  radioGroupName?: string
  disabledValues?: Array<string>
  radioButtonWidth?: string
  alignItems?: string
  justifyContent?: string
  isValueModified?: boolean
  valueToBeRemoved?: string
  showToolTipForDosage?: boolean
  label?: string
  subLabel?: string
  bottomPadding?: boolean
  radioButtonStyle?: React.CSSProperties
  // !TODO to be removed
  extraPaddingBottom?: string
  customerLabel?: React.ReactNode
  customRadioButtons?: {
    customLabel: string
    customLabelComponent: React.ReactNode
    value: string
  }[]
  customRadioGroupStyle?: React.CSSProperties
}

const RadioGroup: React.FC<RadioGroupProps> = ({
  allUncheck,
  formLabel,
  radioButtons,
  row = false,
  onChange,
  width = '100%',
  type = 'default',
  value,
  error = false,
  fontSize = '14px',
  radioGroupName = '',
  disabledValues = [],
  extraPaddingBottom = '0px',
  radioButtonWidth,
  alignItems,
  justifyContent,
  isValueModified = false,
  valueToBeRemoved = '',
  showToolTipForDosage = false,
  label,
  subLabel,
  bottomPadding = true,
  radioButtonStyle,
  customRadioButtons,
  customRadioGroupStyle = {},
}) => {
  const [radioValue, setRadioValue] = React.useState(value)
  const disable =
    disabledValues.length > 0
      ? disabledValues.every((x) => radioButtons.includes(x))
      : false

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValue((event.target as HTMLInputElement).value)
    onChange && onChange(event)
  }

  React.useEffect(() => {
    setRadioValue(value)
  }, [value])

  const getLabel = (
    line: string | number | JSX.Element
  ): JSX.Element | string | number => {
    if (typeof line === 'string') {
      if (showToolTipForDosage && valueToBeRemoved !== '') {
        return (
          <>
            {line.includes(valueToBeRemoved)
              ? line.split(valueToBeRemoved)[0]
              : line}
            {line.includes(valueToBeRemoved) && (
              <Tooltip
                title={StringConstants.TOOLTIP_COMMON_DOSAGE_MSG}
                content={'i'}
              />
            )}
          </>
        )
      }
      if (line.includes('\n')) {
        const label = (
          <p>
            {line.split('\n').map((iterator) => (
              <React.Fragment key={iterator}>
                <span>{iterator}</span>
                <br />
              </React.Fragment>
            ))}
          </p>
        )
        return label
      } else {
        return line
      }
    } else {
      return line
    }
  }

  return (
    <FormControl
      sx={{
        width: `${width}`,
        alignItems: `${alignItems}`,
        paddingBottom: bottomPadding ? '20px' : 0,
      }}
    >
      {label && <Label style={{ paddingBottom: '5px' }}>{label}</Label>}
      {subLabel && (
        <SubLabel style={{ paddingBottom: '5px' }}>{subLabel}</SubLabel>
      )}
      <FormLabel
        sx={
          error === false
            ? { fontStyle: 'italic', fontSize: '14px' }
            : {
                fontStyle: 'italic',
                fontSize: '14px',
                color: theme.colors.secondary,
              }
        }
      >
        {formLabel}
      </FormLabel>
      <MuiRadioGroup
        sx={{ justifyContent: `${justifyContent}`, gap: '10px' }}
        style={customRadioGroupStyle}
        onChange={handleChange}
        row={row}
        value={radioValue}
      >
        {radioButtons.length > 0
          ? radioButtons.map((radio, index) => (
              <FormControlLabel
                key={radio}
                value={radio}
                checked={allUncheck}
                disabled={
                  disable && disabledValues && disabledValues.includes(radio)
                }
                style={{
                  paddingBottom: extraPaddingBottom,
                  width: radioButtonWidth ? radioButtonWidth : '',
                }}
                control={
                  <Radio
                    sx={{
                      '& .MuiSvgIcon-root': {
                        fontSize: 18,
                      },
                    }}
                    onChange={(e) => {
                      fireEvent('radioInput', e, {
                        label: radioGroupName,
                        value: radio,
                        description: `${'Selected'} option ${radio} in ${radioGroupName}`,
                      })
                    }}
                  />
                }
                label={
                  isValueModified
                    ? getLabel(
                        radio
                          .toString()
                          .substring(
                            0,
                            radio.toString().indexOf(valueToBeRemoved)
                          )
                      )
                    : getLabel(radio)
                }
                sx={
                  error === true
                    ? {
                        ...errorRadioStyles,
                        '& .MuiFormControlLabel-label': {
                          fontSize,
                        },
                        '& .MuiFormControlLabel-root': {
                          paddingBottom: '3px !important',
                        },
                      }
                    : type === 'default'
                    ? {
                        '@media screen and (max-width: 750px)': {
                          width: width,
                        },
                        ...radioStyles,
                        ...radioButtonStyle,
                        '& .MuiFormControlLabel-label': {
                          fontSize,
                        },
                        '& .MuiFormControlLabel-root': {
                          paddingBottom: '3px !important',
                        },
                      }
                    : {
                        ...unstyledRadioStyles,
                        '& .MuiFormControlLabel-label': {
                          fontSize,
                        },
                      }
                }
              />
            ))
          : customRadioButtons?.length &&
            customRadioButtons.map((customRadioButton) => (
              <FormControlLabel
                key={customRadioButton.value}
                value={customRadioButton.value}
                checked={allUncheck}
                disabled={
                  disable &&
                  disabledValues &&
                  disabledValues.includes(customRadioButton.value)
                }
                style={{
                  paddingBottom: extraPaddingBottom,
                  width: radioButtonWidth ? radioButtonWidth : '',
                }}
                control={
                  <Radio
                    sx={{
                      '& .MuiSvgIcon-root': {
                        fontSize: 18,
                      },
                    }}
                    onChange={(e) => {
                      fireEvent('radioInput', e, {
                        label: radioGroupName,
                        value: customRadioButton.value,
                        description: `${'Selected'} option ${
                          customRadioButton.value
                        } in ${radioGroupName}`,
                      })
                    }}
                  />
                }
                label={customRadioButton.customLabelComponent}
                sx={
                  error === true
                    ? {
                        ...errorRadioStyles,
                        '& .MuiFormControlLabel-label': {
                          fontSize,
                        },
                        '& .MuiFormControlLabel-root': {
                          paddingBottom: '3px !important',
                        },
                      }
                    : type === 'default'
                    ? {
                        '@media screen and (max-width: 750px)': {
                          width: width,
                        },
                        ...radioStyles,
                        ...radioButtonStyle,
                        '& .MuiFormControlLabel-label': {
                          fontSize,
                        },
                        '& .MuiFormControlLabel-root': {
                          paddingBottom: '3px !important',
                        },
                      }
                    : {
                        ...unstyledRadioStyles,
                        '& .MuiFormControlLabel-label': {
                          fontSize,
                        },
                      }
                }
              />
            ))}
      </MuiRadioGroup>
    </FormControl>
  )
}

export default RadioGroup
