import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'

import Button from '../common/Button/Button.component'
import Icon from '../common/Icon/Icon.component'
import React from 'react'
import { isUndefined } from 'lodash'
import styled from '@emotion/styled'
import theme from '../../global/theme'

interface NavigatorProps {
  handleNext: () => void
  handleBack?: () => void
  handleViewPlans?: () => void
  disableBack?: boolean
  disableNext?: boolean
  buttonText1?: string
  buttonText2?: string
  buttonText3?: string
  isLoading?: boolean
  currentInfoStep?: string
  disableBackButton?: boolean
  button3Variant?: 'primary' | 'secondary'
  currentSectionCount?: number
  currentQuestionCount?: number
  totalQuestionCount?: number
}

const Container = styled.div`
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid #d2d2d1;
  background-color: #fff;
  padding-top: 20px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .view-plans {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

    .mobile-view {
      display: none;
      align-items: center;
    }
    @media screen and (max-width: 850px) {
      .mobile-view {
        display: flex;
      }
      .desktop-view {
        display: none;
      }
    }
  }
`

const NavigationButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  @media screen and (max-width: 850px) {
    justify-content: end;
    gap: 10px;
  }
`

const BottomStepNavigator: React.FC<NavigatorProps> = ({
  handleBack,
  handleNext,
  handleViewPlans,
  disableBack = false,
  disableNext = false,
  buttonText1 = 'View Plans',
  buttonText2 = 'Back',
  buttonText3 = 'Next',
  isLoading = false,
  currentInfoStep = '',
  disableBackButton = false,
  button3Variant = 'primary',
  currentSectionCount = undefined,
  currentQuestionCount = undefined,
  totalQuestionCount = undefined,
}) => {
  return (
    <Container style={{ flexDirection: 'column' }}>
      <div className='view-plans'>
        <div className='mobile-view'>
          <span
            onClick={handleViewPlans}
            style={{
              color: theme.colors.primary,
              textDecoration: 'underline',
              fontWeight: 700,
              fontSize: '14px',
            }}
          >
            {buttonText1}
          </span>
        </div>
        <div className='desktop-view'>
          <Button
            textTransform='uppercase'
            bold={false}
            fontSize='12px'
            width='fit-content'
            height='35px'
            onClick={handleViewPlans}
            description={`'${buttonText1}' Button Click - Info Steps - ${currentInfoStep}`}
          >
            <span style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <p>{buttonText1}</p>
            </span>
          </Button>
        </div>

        <NavigationButtons>
          {!disableBackButton && (
            <Button
              textTransform='uppercase'
              bold={false}
              fontSize='12px'
              width='fit-content'
              height='35px'
              onClick={handleBack}
              disabled={disableBack}
              description={`'${buttonText1}' Button Click - Info Steps - ${currentInfoStep}`}
            >
              <span
                style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
              >
                <Icon fontSize='18px' childComponent={<FiChevronLeft />} />
                <p>{buttonText2}</p>
              </span>
            </Button>
          )}
          <Button
            variant='contained'
            color={button3Variant}
            bold={false}
            textTransform='uppercase'
            fontSize='12px'
            width='fit-content'
            height='35px'
            onClick={handleNext}
            disabled={disableNext || isLoading}
            loading={isLoading}
            description={`'${buttonText3}' Button Click - Info Steps - ${currentInfoStep}`}
          >
            <span style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <p>{buttonText3}</p>
              <Icon fontSize='18px' childComponent={<FiChevronRight />} />
            </span>
          </Button>
        </NavigationButtons>
      </div>
      {!isUndefined(currentSectionCount) &&
      !isUndefined(currentQuestionCount) &&
      !isUndefined(totalQuestionCount) ? (
        <div
          style={{
            display: 'flex',
            alignSelf: 'end',
            color: '#A0A0A0',
            fontWeight: '600',
            fontSize: '12px',
          }}
        >
          Section {currentSectionCount} ~ {currentQuestionCount} of{' '}
          {totalQuestionCount}
        </div>
      ) : (
        <></>
      )}
    </Container>
  )
}

export default BottomStepNavigator
